<template>
  <div class="retroactive-list">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <CancelConfirmDialog ref="cancelConfirmDialog" />
    <v-alert
      v-if="genericAlert.message"
      border="right"
      colored-border
      :type="genericAlert.type"
      elevation="2"
    >
      {{ genericAlert.message }}
    </v-alert>
    <v-alert
      v-if="error"
      border="right"
      colored-border
      type="error"
      elevation="2"
    >
      {{ labels.error }}
    </v-alert>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="table.search"
          :label="labels.search"
          class="mx-4"
        />
        <div>
          <v-icon
            class="retroactive-list__refresh mx-8"
            :class="{'retroactive-list__refresh--loading': loading}"
            @click="getRetroactiveData"
          >
            mdi-refresh
          </v-icon>
        </div>
      </v-card-title>
      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :search="table.search"
        :custom-sort="customSort"
      >
        <template v-slot:item.percentage_formatted="{ item }">
          <StMenu
            v-if="item.count_pre_retroactive !== -1"
            :items="item.detailsCountPosts"
          >
            {{ item.percentage_formatted }}
          </StMenu>
          <span v-else> - </span>
        </template>

        <template v-slot:item.channels="{ item }">
          <StMenu
            :items="item.channels"
          >
            {{ item.channels.length }} {{ item.channels.length > 1 ? "canais" : "canal" }}
          </StMenu>
        </template>

        <template v-slot:item.cancel_retroactive="{ item }">
          <v-btn
            color="warning"
            :loading="item.shouldLoadBtn"
            :disabled="isFinished(item)"
            @click="cancelRetroactive(item)"
          >
            Cancelar
          </v-btn>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip
            v-if="item.status === 'Finalizado'"
            class="ma-2"
            label
            color="green"
            text-color="white"
          >
            {{ item.status }}
          </v-chip>
          <v-chip
            v-if="item.status !== 'Finalizado'"
            class="ma-2"
            label
            text-color="white"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <template
          v-slot:item.task_ids="{ item }"
        >
          <v-list
            v-if="item.task_ids && item.task_ids.length"
          >
            <v-list-item
              v-for="(link, index) in item.task_ids"
              :key="index"
            >
              <a
                class="retroactive-list__link"
                :href="link"
                target="_blank"
              >
                {{ labels.task }} {{ index + 1 }}
              </a>
            </v-list-item>
          </v-list>
          <span v-else> - </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import RetroactiveService from '@/services/RetroactiveService';
import StMenu from '../StMenu.vue';
import PageHeader from '@/components/base/PageHeader';
import CancelConfirmDialog from '@/components/retroactive/components/CancelConfirmDialog';

export default {
  name: 'RetroactiveList',
  components: { StMenu, PageHeader, CancelConfirmDialog },
  data() {
    return {
      labels: {
        h1Title: 'Lista de retroativos dos últimos 30 dias',
        task: 'Pedido',
        search: 'Buscar',
        error: 'Ocorreu um erro para carregar os dados.',
        title: 'Retroativos',
        description: 'Permite visualização detalhada do histórico de retroativos de coleta executados solicitação dos clientes.',
        breadcrumbs: [
          {
            text: 'Home',
            href: '/'
          },
          {
            text: 'Retroativo',
            to: '/retroactive'
          },
          {
            text: 'Lista',
            to: '/retroactive/list'
          }
        ]
      },
      table: {
        headers: [
          {
            text: 'Conta',
            value: 'account_id',
            align: 'center'
          },
          {
            text: 'Pesquisa',
            value: 'universe_id',
            align: 'center'
          },
          {
            text: 'Data Início',
            value: 'begin',
            align: 'center'
          },
          {
            text: 'Data Fim',
            value: 'end',
            align: 'center'
          },
          {
            text: 'Canais',
            value: 'channels',
            align: 'center'
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center'
          },
          {
            text: 'Percentual de aumento',
            value: 'percentage_formatted',
            align: 'center'
          },
          {
            text: 'Finalizado em',
            value: 'end_date_formatted',
            align: 'center'
          },
          {
            text: 'Núm. Descritores',
            value: 'number_of_descriptors',
            align: 'center'
          },
          {
            text: 'Links do pedido',
            value: 'task_ids',
            align: 'center'
          },
          {
            text: 'Responsável',
            value: 'responsible_user',
          },
          {
            text: 'Iniciado em',
            value: 'start_date_formatted',
          },
          {
            text: 'Cancelar retroativo',
            value: 'cancel_retroactive',
          }
        ],
        search: '',
        items: [],
      },
      loading: true,
      error: false,
      genericAlert: {
        type: 'success',
        message: null
      }
    };
  },
  mounted() {
    this.getRetroactiveData();
  },
  methods: {
    async getRetroactiveData() {
      try {
        this.error = false;
        this.table.items = [];
        this.loading = true;
        const jsonResponse = await RetroactiveService.getRetroactives();
        this.setTableItems(jsonResponse.data);
      } catch (e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    getPercentageOfPost(item) {
      if (item.count_pre_retroactive <= 0) {
        item.count_pre_retroactive = 0;
      }
      if (item.count_pos_retroactive <= 0) {
        item.count_pos_retroactive = 0;
      }
      if (item.count_pos_retroactive) {
        return (100 - ((100 * item.count_pre_retroactive) / item.count_pos_retroactive)).toFixed(1);
      }
      return 0;
    },
    getDetailsCountPosts(item) {
      return [
        `Antes do retroativo: ${item.count_pre_retroactive ? item.count_pre_retroactive : 0} publicações`,
        `Depois do retroativo: ${item.count_pos_retroactive ? item.count_pos_retroactive : 0} publicações`,
        `Diferença: ${item.count_pos_retroactive ? item.count_pos_retroactive - item.count_pre_retroactive : 0} publicações`
      ];
    },
    setTableItems(items) {
      const brazilFormat = 'DD/MM/YYYY HH:mm:ss';
      const brazilFormatWithoutHours = 'DD/MM/YYYY';
      const statusMap = {
        pending: 'Pendente',
        in_progress: 'Em execução',
        finished: 'Finalizado',
        cancelled: 'Cancelado',
      };

      this.table.items = items.map(item => {
        item.begin = moment.utc(item.begin).format(brazilFormatWithoutHours);
        item.end = moment.utc(item.end).format(brazilFormatWithoutHours);
        item.end_date_formatted = item.end_date ? moment(item.end_date).format(brazilFormat) : '-';
        item.end_date = item.end_date ? moment(item.end_date).valueOf() : null;
        item.start_date_formatted = moment(item.start_date).format(brazilFormat);
        item.start_date = moment(item.start_date).valueOf();
        if (statusMap[item.status]) {
          item.status = statusMap[item.status];
        }
        if (!item.task_ids) {
          item.task_ids = '';
        }
        if (item.responsible_user) {
          item.responsible_user = item.responsible_user.substring(0, item.responsible_user.indexOf('@'));
        } else {
          item.responsible_user = '';
        }
        item.channels = item.channels.slice(1, -1).split(',');
        const percentage = `${this.getPercentageOfPost(item)}`;
        const percentage_formatted = `${percentage}%`;
        const detailsCountPosts = this.getDetailsCountPosts(item);
        const shouldLoadBtn = false;
        const newItem = { ...item, percentage_formatted, percentage, detailsCountPosts, shouldLoadBtn };

        return newItem;
      });
    },
    customSort(items, index, isDesc) {
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line complexity
      items.sort((a, b) => {
        if (index[0] === 'start_date_formatted') {
          if (!isDesc[0]) {
            return a.start_date - b.start_date;
          }
          return b.start_date - a.start_date;
        } else if (index[0] === 'end_date_formatted') {
          if (!isDesc[0]) {
            return a.end_date - b.end_date;
          }
          return b.end_date - a.end_date;
        } else if (index[0] === 'percentage_formatted') {
          if (!isDesc[0]) {
            return a.percentage - b.percentage;
          }
          return b.percentage - a.percentage;
        } else if (!isDesc[0]) {
          return a[index] < b[index] ? -1 : 1;
        }
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    },
    async cancelRetroactive(retroactive) {
      const confirm = await this.$refs.cancelConfirmDialog.confirm();

      if (confirm) {
        retroactive.shouldLoadBtn = true;

        await RetroactiveService.cancelRetroactive(retroactive.account_id, retroactive.universe_id).then(() => {
          this.showGenericAlertForFiveSeconds(this.$t('RetroactiveList.cancelation.genericAlertOnSuccess'), 'success');
        }).catch(e => {
          console.error(e);
          this.showGenericAlertForFiveSeconds(this.$t('RetroactiveList.cancelation.genericAlertOnError'), 'error');
        });

        retroactive.shouldLoadBtn = false;
        this.getRetroactiveData();
      }
    },
    isFinished(retroactive) {
      return retroactive.status === 'Cancelado' || retroactive.status === 'Finalizado';
    },
    showGenericAlertForFiveSeconds(message, type) {
      this.genericAlert.message = message;
      this.genericAlert.type = type;
      this.hideGenericAlertAfterFiveSeconds();
    },
    hideGenericAlertAfterFiveSeconds() {
      setTimeout(() => {
        this.genericAlert.message = null;
      }, 5000);
    }
  },
};

</script>

<style scoped lang="scss">

.retroactive-list__refresh {
  cursor: pointer;

  &--loading {
    color: #00aaa7;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
}

.retroactive-list {
  .v-chip.green {
    background: #2c9688 !important;
  }
}

@-moz-keyframes spin-moz {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-web {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.retroactive-list__link {
  color: #00ccbe;
  width: 58px;
}

</style>
