import Rox from 'rox-browser';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';

export const featureFlagController = Rox;

/**
 * Rollout api key
 */
const RolloutProduction = '5cc1e870fff77f252b8bb8f5';
const RolloutStaging = '5d7bd9a623722f034dba20a3';
export const featureFlagKey = isProdVersion() ? RolloutProduction : RolloutStaging;

/**
 * Flags
 */
export const featureFlagFlags = {
  // Gateway controller
  gatewayProd: new featureFlagController.Configuration('https://secure-backend-api.stilingue.com.br/'),
  gatewayStaging: new featureFlagController.Configuration('https://secure-backend-api-internal-staging.stilingue.com.br/'),
  /**
   * Service Versions Controller
   */
  // Company Service
  CompanyServiceVersionProd: new featureFlagController.Configuration('prod'),
  CompanyServiceVersionStaging: new featureFlagController.Configuration('staging'),
  // User Legacy Service
  UserLegacyServiceVersionProd: new featureFlagController.Configuration('prod'),
  UserLegacyServiceVersionStaging: new featureFlagController.Configuration('staging'),
  // Auth Service
  AuthServiceVersionProd: new featureFlagController.Configuration('prod'),
  AuthServiceVersionStaging: new featureFlagController.Configuration('staging'),
  // Admin Tools Service
  AdminToolsServiceVersionProd: new featureFlagController.Configuration('prod'),
  AdminToolsServiceVersionStaging: new featureFlagController.Configuration('staging'),
  // Routes Service
  RoutingServiceVersionProd: new featureFlagController.Configuration('ts-534'),
  RoutingServiceVersionStaging: new featureFlagController.Configuration('staging'),
  // IT Service
  ITServiceVersionProd: new featureFlagController.Configuration('prod'),
  ITServiceVersionStaging: new featureFlagController.Configuration('staging'),
  // SSO Service
  SsoServiceVersionProd: new featureFlagController.Configuration('prod'),
  SsoServiceVersionStaging: new featureFlagController.Configuration('staging'),
  // EndPoint Service
  EndpointServiceVersionProd: new featureFlagController.Configuration('prod'),
  EndpointServiceVersionStaging: new featureFlagController.Configuration('staging'),
  // CreditsSystem Service
  CreditsSystemServiceVersionProd: new featureFlagController.Configuration('prod'),
  CreditsSystemServiceVersionStaging: new featureFlagController.Configuration('staging'),
  // PanelsService Service
  PanelsServiceVersionProd: new featureFlagController.Configuration('prod'),
  PanelsServiceVersionStaging: new featureFlagController.Configuration('staging'),
  // ProprietaryPage Service
  ProprietaryPageServiceVersionProd: new featureFlagController.Configuration('prod'),
  ProprietaryPageServiceVersionStaging: new featureFlagController.Configuration('staging'),
  // SeCreditsSystem Service
  SeCreditsSystemServiceProd: new featureFlagController.Configuration('prod'),
  SeCreditsSystemServiceStaging: new featureFlagController.Configuration('staging'),
};

/**
 * Set the custom property in feature flag
 * @param key Searchable key, this key can be used to filter the access to the flag
 * @param value of this property
 */
function setCustomAttribute(key, value) {
  featureFlagController.setCustomStringProperty(key, value);
}

export function insertRolloutMetadata({
  user_email,
  user_name,
  user_id,
  user_group
} = {}) {
  // Should have Sentry installed and all user attributes
  const allAttributes = { user_email, user_name, user_id, user_group };
  if (Object.values(allAttributes).every(Boolean)) {
    for (const attr in allAttributes) {
      if (allAttributes.hasOwnProperty(attr)) {
        setCustomAttribute(`${attr}`, `${allAttributes[attr]}`);
      }
    }
    return true;
  }
  // If should install and do not have mandatory info
  console.error('It may be missing some mandatory info, check the following', allAttributes);
}

/**
 * Start Rollout
 */
featureFlagController.register('default', featureFlagFlags);
featureFlagController.setup(featureFlagKey, {
  freeze: 'none' // Update in real-time
});

/**
 * Show overrides locally, this is useful to test the featureflag
 * Uncomment the line above to test flags locally
 */
// featureFlagController.showOverrides();

