var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    { attrs: { app: "", flat: "", fixed: "", dark: "", height: "58" } },
    [
      _c("v-app-bar-nav-icon", {
        staticClass: "mr-2",
        attrs: { hidden: "true" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.$emit("side-icon-clicked")
          },
        },
      }),
      _c("StLogo"),
      _vm._t("center"),
      _c("v-spacer"),
      _vm._t("right"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }