<template>
  <v-app-bar
    app
    flat
    fixed
    dark
    height="58"
  >
    <v-app-bar-nav-icon
      hidden="true"
      class="mr-2"
      @click.stop="$emit('side-icon-clicked')"
    />

    <StLogo />

    <slot name="center" />

    <v-spacer />

    <slot name="right" />
  </v-app-bar>
</template>

<script>
import StLogo from '@/components/st-logo/StLogo';

export default {
  components: {
    StLogo
  }
};
</script>

<style lang="scss" scoped>
@import '@stilingue/st-styles/colors.scss';

.v-app-bar {
  padding-left: 0 !important;
  z-index: 9;
  background-color: var(--gray-150);
  border-color: var(--gray-150);
}

.v-app-bar__side-icon {
  margin-right: 15px !important;
}
</style>
