export default {
  cancelation: {
    confirmation: {
      title: 'Deseja realizar essa operação?',
      descriptionBody: 'O cancelamento de retroativo não poderá ser interrompido, deseja realmente continuar?',
      yes: 'Sim',
      cancel: 'Cancelar'
    },
    genericAlertOnSuccess: 'A pesquisa retroativa foi cancelada com sucesso!',
    genericAlertOnError: 'Houve um erro ao cancelar a pesquisa retroativa. Entre em contato com a equipe responável.'
  }
};
