import { insertRolloutMetadata } from '@/plugins/rollout';

export function insertPluginMetadata({
  user,
}) {
  if (!user) {
    console.error('User OR Company not found, we could not set metadata plugins');
    return false;
  }
  /**
   * Rollout
   */
  insertRolloutMetadata({
    user_email: user.email,
    user_name: user.name,
    user_id: user.id,
    user_group: 'STILINGUE'
  });
}
