import StService from '@stilingue/st-service';
import { authentication } from '@stilingue/st-requests';

const authStore = authentication({ tokenName: 'usr_id' });

/**
 * For warroom this class forces the header usr_id for more security
 */
class WarroomApiService {
  constructor(api, options) {
    this.service = new StService(api, options);
  }

  static injectSessionId(params) {
    const usr_id = authStore.getSessionId();
    return { ...params, headers: { usr_id } };
  }

  find(params) {
    return this.service.find(WarroomApiService.injectSessionId(params));
  }

  update(data = {}, options = {}) {
    return this.service.update(WarroomApiService.injectSessionId(data), options);
  }

  modify(data = {}, options = {}) {
    return this.service.modify(WarroomApiService.injectSessionId(data), options);
  }

  insert(data = {}, options = {}) {
    return this.service.insert(WarroomApiService.injectSessionId(data), options);
  }

  remove(params = {}) {
    return this.service.remove(WarroomApiService.injectSessionId(params));
  }
}
export default WarroomApiService;
