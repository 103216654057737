<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />

    <section class="selector-container">
      <v-row>
        <v-col
          cols="4"
        >
          <SingleSelect
            v-model="selectedAccount"
            :items="accounts"
            placeholder="Selecione uma conta"
            text="name"
            sub-text="group"
          />

          <SingleSelect
            v-if="activeAccount"
            v-model="selectedUniverse"
            :items="universes"
            placeholder="Selecione uma Pesquisa"
            text="name"
            sub-text="channel"
          />
        </v-col>
        <v-col
          cols="7"
        >
          <StDatePicker
            v-model="dates"
            range
          />
          <v-textarea
            v-model="posts"
            name="input-7-1"
            label="Posts"
            value=""
            hint="Separe os identificadores dos posts por linha"
            rows="3"
            :rules="postIdsRules"
            :error.sync="postIdsError"
          />
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row>
        <v-col
          cols="11"
        >
          <v-btn
            color="primary"
            elevation="1"
            raised
            :disabled="isSearchDisabled()"
            @click="submitFilter"
          >
            <v-icon left>
              mdi-magnify
            </v-icon>
            Buscar
          </v-btn>
        </v-col>
      </v-row>
    </section>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          class="mx-4"
          append-icon="mdi-refresh"
          label="Filtrar dados desta página por"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        :headers="annotationsHeaders"
        :items="annotations"
        :loading="loadingAnnotations"
        :custom-sort="customSort"
        :search="search"
        items-per-page="30"
        class="elevation-1"
        hide-default-footer
      />
      <div
        v-if="paginationParams.total > 1"
        class="text-center pt-2"
      >
        <StPagination
          v-model="paginationParams"
          @page:changed="pageChanged"
        />
      </div>
    </v-card>

    <v-snackbar
      v-model="showErrorMessage"
    >
      Não foi possível localizar histórico de anotações para essa busca.
      <v-btn
        color="blue"
        text
        @click="showErrorMessage = false"
      >
        Fechar
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import SingleSelect from '../components/SingleSelect';
import StDatePicker from '../components/StDatePicker';
import StPagination from '../components/StPagination';
import PageHeader from '@/components/base/PageHeader';
import AnnotationsHistoryService from '../services/AnnotationsHistoryService';

const lastMonth = new Date();
lastMonth.setMonth(lastMonth.getMonth() - 1);
const today = new Date();
const defaultDateRange = [
  `${lastMonth.getFullYear()}-${(lastMonth.getMonth() + 1).toString().padStart(2, '0')}-${lastMonth.getDate().toString().padStart(2, '0')}`,
  `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`,
];

export default {
  name: 'AnnotationsHistory',
  components: {
    SingleSelect,
    StDatePicker,
    StPagination,
    PageHeader
  },
  data() {
    return {
      labels: {
        title: 'Anotação em publicação',
        description: 'Permite conferência do histórico de anotações automáticas e manuais, ' +
          'que podem auxiliar no esclarecimento de possível falha em qualquer operação que gere ' +
          'uma anotação. Não somente pelas específicas em publicação como também as decorrentes de operações do SmartCare e Roteamento.',
        breadcrumbs: [
          {
            text: 'Home',
            href: '/'
          },
          {
            text: 'Histórico',
            to: '/history'
          },
          {
            text: 'Anotação',
            to: '/history/annotations'
          }
        ]
      },
      loadingAnnotations: false,
      dates: defaultDateRange,
      postsList: [],
      postsListEncoded: [],
      annotationsHeaders: [
        { text: 'ID', value: 'pid' },
        { text: 'Canal', value: 'channel' },
        { text: 'Operação', value: 'operation' },
        { text: 'Campo', value: 'field' },
        { text: 'De', value: 'oldValue' },
        { text: 'Para', value: 'newValue' },
        { text: 'Usuário', value: 'user' },
        { text: 'Anotado em', value: 'createdAtFormatted' }
      ],
      annotations: [],
      showErrorMessage: false,
      pagination: {
        sortBy: 'createdAt',
        descending: true,
        rowsPerPage: -1,
      },
      postIdsRules: [
        function(input) {
          return input.length > 1700 ? 'Limite de 1700 caracteres atingido' : true;
        }
      ],
      postIdsError: false,
      paginationParams: {
        limit: 30,
        offset: 0,
        total: 0
      },
      effectiveSeachFilter: {},
      search: ''
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    ...mapState('universe', ['universes', 'activeUniverse']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      },
      set(selectedUniverse) {
        this.selectUniverse(selectedUniverse);
      },
    },
    userId() {
      return this.user.id;
    },
    posts: {
      get() {
        this.postsListEncoded.join('\n');
        return this.postsList.join('\n');
      },
      set(posts) {
        this.postsList = posts.split('\n')
          .map(p => p.trim())
          .filter(p => p !== '');

        this.postsListEncoded = posts.split('\n')
          .map(p => p.trim())
          .filter(p => p !== '')
          .map(p => encodeURIComponent(p));
      }
    }
  },
  async created() {
    await this.getAccounts({ userId: this.userId });
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount, this.selectedUniverse);
    }
  },
  methods: {
    ...mapActions('account', ['getAccounts', 'setAccount']),
    ...mapActions('universe', ['getAccountUniverses', 'setUniverse', 'unsetUniverse']),
    ...mapMutations('account', {
      mutate: 'MUTATE',
    }),
    selectAccount(selectedAccount, selectedUniverse) {
      this.setAccount({ selectedAccount });
      this.unsetUniverse();
      this.getAccountUniverses({ selectedAccount }).then(() => {
        if (selectedUniverse) {
          this.selectedUniverse = selectedUniverse;
        }
        this.selectUniverse(this.selectedUniverse);
      });
    },
    selectUniverse(selectedUniverse) {
      this.setUniverse({ selectedUniverse });
    },
    isSearchDisabled() {
      return !this.selectedAccount ||
        !this.selectedUniverse ||
        !this.isDatesValid() ||
        this.loadingAnnotations ||
        this.postIdsError;
    },
    isDatesValid() {
      return this.dates && this.dates.length === 2;
    },
    submitFilter() {
      this.paginationParams.offset = 0;

      this.effectiveSeachFilter = {
        accountId: this.selectedAccount.id,
        universeId: this.selectedUniverse.id,
        pids: this.postsListEncoded,
        startingAt: this.dates[0],
        endingAt: this.dates[1],
      };
      this.searchAnnotations();
    },
    async searchAnnotations() {
      try {
        this.annotations = [];
        this.loadingAnnotations = true;
        const response = await AnnotationsHistoryService.getPostsAnnotations({
          accountId: this.effectiveSeachFilter.accountId,
          universeId: this.effectiveSeachFilter.universeId,
          startingAt: this.effectiveSeachFilter.startingAt,
          endingAt: this.effectiveSeachFilter.endingAt,
          pids: this.effectiveSeachFilter.pids,
          limit: this.paginationParams.limit,
          offset: this.paginationParams.offset
        });

        this.annotations = this.formatResponse(response.annotations);
        this.paginationParams = this.formatPagination(response);
      } catch (error) {
        this.showErrorMessage = true;
        console.error(error);
      } finally {
        this.loadingAnnotations = false;
      }
    },
    formatResponse(annotations) {
      return annotations.map(annotation => {
        const createdAt = new Date(annotation.createdAt);
        const user = `${annotation.username}`;
        return {
          ...annotation,
          createdAtFormatted: createdAt.toLocaleString('pt-br'),
          user
        };
      });
    },
    customSort(items, index, isDesc) {
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line complexity
      items.sort((a, b) => {
        if (index[0] === 'createdAtFormatted') {
          if (!isDesc[0]) {
            return a.createdAt - b.createdAt;
          }
          return b.createdAt - a.createdAt;
        } else if (!isDesc[0]) {
          return a[index] < b[index] ? -1 : 1;
        }
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    },
    formatPagination(response) {
      const total = response.total;
      return {
        ...this.paginationParams,
        total,
      };
    },
    pageChanged({ limit, offset }) {
      this.paginationParams = {
        ...this.paginationParams,
        limit,
        offset
      };
      this.searchAnnotations();
    }
  }
};
</script>

<style lang="scss" scoped>
  .content-body {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
  }

  .selector-container {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: left;
    margin-top: 20px;

    .col-4 {
      .single-select:nth-child(2) {
        padding-top: 47px;
      }
    }

    .col-7 {
      margin-top: 15px;
    }
  }

  button {
    float: right;
  }

  .v-card {
    margin-top: 40px;
  }
</style>
