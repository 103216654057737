var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container", class: _vm.containerClasses },
    [
      _c("StSidebar", {
        attrs: { minimize: _vm.$vuetify.breakpoint.smAndDown, permanent: "" },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function () {
              return [
                _c("SidebarItemList", {
                  attrs: { "is-sidebar-mini": _vm.isSidebarMini },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-row",
        { staticClass: "layout-content no-gutters" },
        [_c("transition", { attrs: { name: "fade" } }, [_c("router-view")], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }