import StService from '@/services/StService';
import { createApi } from '@stilingue/st-auth-utils';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import { StagingUrl, ProductionUrl } from './config';

const gateway = isProdVersion()
  ? ProductionUrl
  : StagingUrl;

const afterParser = response => response.data;

class UserGroupService extends StService {
  constructor(gateway) {
    const api = createApi('', gateway);
    super(api, { afterParser });
  }

  async postUserActionBablic(params = {}) {
    return this.api.post({ url: `/user/international?emails=${params.emails}&activate=${params.activate}` }).then(this.afterParser);
  }

  async getUserByUserId(id) {
    return this.api.get({ url: `/user/user_id/${id}` }).then(this.afterParser);
  }
}

export default new UserGroupService(gateway);
