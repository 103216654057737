import StService from './StService';
import { createApi } from '@stilingue/st-auth-utils';

import { featureFlagFlags } from '@/plugins/rollout';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';

import gateway from './Gateway';

const afterParser = response => response.data;

const version = isProdVersion()
  ? featureFlagFlags.AuthServiceVersionProd.getValue()
  : featureFlagFlags.AuthServiceVersionStaging.getValue();

class SnlpService extends StService {
  constructor(gateway, version = 'staging') {
    const api = createApi(`admin-tools/${version}`, gateway, { timeout: 120000 });
    super(api, { afterParser });
  }

  getPolarity(searchedPhrase) {
    const params = {
      searchedPhrase,
    };

    return this.api.get({ params, url: '/snlp' }).then(this.afterParser);
  }
}

export default new SnlpService(gateway, version);
