export default {
  label: {
    placeholder: {
      tableSearch: 'Entre com o ID da cotação para buscar',
      currentValue: 'Valor Atual',
      newValue: 'Valor Novo',
      opportunityId: 'ID da oportunidade',
      quotationId: 'ID da cotação',
      userGroup: 'Grupo de de Usuário',
      creditType: 'Tipo do crédito',
      panel: 'Painel',
      isCreditAvailable: 'Crédito disponível',
      creationDate: 'Data de criação',
      expirationDate: 'Data de expiração',
      creditEdition: 'Editar crédito',
      editProduct: 'Editar produto',
    },
    info: {
      newExpirationDate: 'Caso não queira alterar e permanecer com a data atual, altere a nova data para ficar igual à antiga',
      newCreditValue: 'Caso não queira alterar deixe o campo novo vazio',
      newUserGroup: 'Inicie o cadastro de um novo User Group por "ST_"',
    }
  },
};
