var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "v-toolbar",
        { attrs: { flat: "" } },
        [
          _c("v-icon", { attrs: { large: "" } }, [
            _vm._v("\n      mdi-desktop-mac-dashboard\n    "),
          ]),
          _vm.selectedUniverse && _vm.selectedAccount
            ? _c("v-toolbar-title", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.selectedUniverse.name) +
                    " (" +
                    _vm._s(_vm.selectedAccount.name) +
                    ")\n    "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.error
        ? _c(
            "v-alert",
            {
              attrs: {
                border: "right",
                "colored-border": "",
                type: "error",
                elevation: "2",
              },
            },
            [
              _vm._v(
                "\n    Erro inesperado ao carregar o histórico de reprocessamento.\n  "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                staticClass: "mx-4",
                attrs: { label: _vm.labels.search },
                model: {
                  value: _vm.table.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.table, "search", $$v)
                  },
                  expression: "table.search",
                },
              }),
              _c(
                "div",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "reprocessing-list__refresh mx-8",
                      class: {
                        "reprocessing-list__refresh--loading": _vm.loading,
                      },
                      on: { click: _vm.getReprocessing },
                    },
                    [_vm._v("\n          mdi-refresh\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("StLoader", { attrs: { loading: _vm.loading } }),
          _c("v-data-table", {
            attrs: {
              headers: _vm.table.headers,
              items: _vm.table.items,
              search: _vm.table.search,
              "custom-sort": _vm.customSort,
              "hide-default-footer": "",
            },
            scopedSlots: _vm._u([
              {
                key: "item.percentage_formatted",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center percentage" },
                      [
                        _c(
                          "v-progress-circular",
                          {
                            attrs: {
                              rotate: -90,
                              size: 90,
                              width: 15,
                              value: item.percentage_formatted,
                              color: "teal",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.percentage_formatted) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.types",
                fn: function ({ item }) {
                  return [_c("StMenu", { attrs: { items: item.types } })]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }