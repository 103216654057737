export default {
  email: 'Email',
  password: 'Senha',
  enter: 'Entrar',
  emailIsRequired: 'E-mail não pode ser vazio',
  emailIsInvalid: 'E-mail não válido',
  passwordIsRequired: 'Senha não pode ser vazia',
  notFound: 'Usuário não encontrado',
  Unauthorized: 'Senha inválida',
  Forbidden: 'Acesso não permitido',
  genericErrorMsg: 'Ops, não conseguimos fazer o login, tente novamente mais tarde.'
};
