<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <v-alert
      v-if="error"
      right
      colored-border
      type="error"
      elevation="2"
    >
      Erro inesperado ao carregar lista de Benchmarks. Favor contactar o time de N2.
    </v-alert>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="table.search"
          label="Buscar"
          class="mx-4"
          prepend-icon="mdi-magnify"
        />
        <div>
          <v-icon
            class="benchmarks-list__refresh mx-8"
            :class="{'benchmarks-list__refresh--loading': loading}"
            @click="getBenchmarks"
          >
            mdi-refresh
          </v-icon>
        </div>
      </v-card-title>
      <StLoader :loading="loading" />
      <v-data-table
        v-if="!error"
        :headers="table.headers"
        :items="table.items"
        :search="table.search"
        :custom-sort="customSort"
        class="elevation-1"
      >
        <template v-slot:item.url="{ item }">
          <a
            :href="item.url"
            target="_blank"
          >
            {{ item.url.slice(0, 50) + '...' }}
          </a>
        </template>
        <template v-slot:item.freePlan="{ item }">
          <v-chip
            v-if="item.freePlan == 'yes'"
            outlined
            filter
            color="primary"
          >
            Sim
          </v-chip>
          <v-chip
            v-else
            outlined
            filter
            color="yellow"
          >
            Não
          </v-chip>
        </template>
        <template v-slot:item.expiryDateFormatted="{ item }">
          <v-chip
            v-if="item.expired"
            outlined
            filter
            color="red"
          >
            Expirado em {{ item.expiryDateFormatted }}
          </v-chip>
          <span v-if="item.expiryDateFormatted && !item.expired">
            {{ item.expiryDateFormatted }}
          </span>
          <span v-if="!item.expiryDateFormatted && !item.expired">-</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import StLoader from '@stilingue/st-loader';
import PageHeader from '@/components/base/PageHeader';
import BenchmarksService from '@/services/BenchmarksService';
import dateComponents from '@/utils/date-utils';

export default {
  name: 'BenchMarksSearch',
  components: {
    PageHeader, StLoader
  },
  data: () => ({
    labels: {
      title: 'Consultar Benchmarks',
      description: 'Permite buscar Benchmarks pelo user group, acessar direto pela URL e identificar se versão gratuita ou não.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Benchmarks',
          to: '/benchmarks'
        },
        {
          text: 'Editar',
          to: '/benchmarks/search'
        }
      ]
    },
    table: {
      headers: [
        {
          text: 'Data e hora da criação',
          value: 'createdDateFormatted',
          align: 'center'
        },
        {
          text: 'Id do plano',
          value: 'planId',
          align: 'center'
        },
        {
          text: 'Nome do Benchmarks',
          value: 'name',
          align: 'center'
        },
        {
          text: 'User group',
          value: 'planName',
          align: 'center'
        },
        {
          text: 'URL',
          value: 'url',
        },
        {
          text: 'Período gratuito?',
          value: 'freePlan',
          align: 'center'
        },
        {
          text: 'Data expiração',
          value: 'expiryDateFormatted',
          align: 'center'
        },
      ],
      search: '',
      'must-sort': true,
      items: []
    },
    loading: false,
    error: false,
    errorUpdate: false,
    benchmarks: []
  }),
  computed: {
    ...mapState('benchmark', ['activePeriod']),
    selectedPeriod: {
      get() {
        return this.activePeriod;
      },
      set(selectedPeriod) {
        this.selectPeriod(selectedPeriod);
      }
    }
  },
  created() {
    this.getBenchmarks();
  },
  methods: {
    ...mapActions('benchmark', ['setPeriod']),
    async getBenchmarks() {
      try {
        this.error = false;
        this.table.items = [];
        this.loading = true;
        this.benchmarks = await BenchmarksService.getBenchmarks();
        this.setTableItems();
      } catch (e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    setTableItems() {
      this.table.items = this.benchmarks.map(item => {
        item.createdDateFormatted = dateComponents.formatDate(item.createdDate, 'DD/MM/YY - HH:mm');
        if (item.expiryDate) {
          item.expiryDateFormatted = dateComponents.formatDate(item.expiryDate, 'DD/MM/YYYY');
          item.freePlan = 'yes';
        } else {
          item.expiryDateFormatted = '';
          item.freePlan = 'no';
        }
        return item;
      });
    },
    customSort(items, index, isDesc) {
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line complexity
      items.sort((a, b) => {
        if (index[0] === 'createdDateFormatted') {
          if (!isDesc[0]) {
            return a.createdDate - b.createdDate;
          }
          return b.createdDate - a.createdDate;
        } else if (index[0] === 'expiryDateFormatted') {
          if (!isDesc[0]) {
            return a.expiryDate - b.expiryDate;
          }
          return b.expiryDate - a.expiryDate;
        } else if (!isDesc[0]) {
          return a[index] < b[index] ? -1 : 1;
        }
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    },
  }
};
</script>

<style lang="scss" scoped>
  .v-card {
    .dialog-title {
      font-size: 16px !important;
      font-weight: 600;
      padding-bottom: 25px;
    }

    .v-btn {
      text-transform: none;
      padding: 0 25px;
    }

    .v-card__text {
      color: #ffff;
    }

    .v-alert {
      margin-top: 20px;
    }

    .v-select {
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }
</style>
