var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _vm.error
        ? _c(
            "v-alert",
            {
              attrs: {
                right: "",
                "colored-border": "",
                type: "error",
                elevation: "2",
              },
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.BenchmarksEdit.errorLoadList) + "\n  "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                staticClass: "mx-4",
                attrs: { label: "Buscar", "prepend-icon": "mdi-magnify" },
                model: {
                  value: _vm.table.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.table, "search", $$v)
                  },
                  expression: "table.search",
                },
              }),
              _c(
                "div",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "benchmarks-list__refresh mx-8",
                      class: {
                        "benchmarks-list__refresh--loading": _vm.loading,
                      },
                      on: { click: _vm.getBenchmarks },
                    },
                    [_vm._v("\n          mdi-refresh\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("StLoader", { attrs: { loading: _vm.loading } }),
          !_vm.error
            ? _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.table.headers,
                  items: _vm.table.items,
                  search: _vm.table.search,
                  "custom-sort": _vm.customSort,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.url",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "a",
                            { attrs: { href: item.url, target: "_blank" } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.url.slice(0, 50) + "...") +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.freePlan",
                      fn: function ({ item }) {
                        return [
                          item.freePlan == "yes"
                            ? _c(
                                "v-chip",
                                {
                                  attrs: {
                                    outlined: "",
                                    filter: "",
                                    color: "primary",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("benchmarksEdit.yes")) +
                                      "\n        "
                                  ),
                                ]
                              )
                            : _c(
                                "v-chip",
                                {
                                  attrs: {
                                    outlined: "",
                                    filter: "",
                                    color: "yellow",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("benchmarksEdit.no")) +
                                      "\n        "
                                  ),
                                ]
                              ),
                        ]
                      },
                    },
                    {
                      key: "item.expiryDateFormatted",
                      fn: function ({ item }) {
                        return [
                          item.expired
                            ? _c(
                                "v-chip",
                                {
                                  attrs: {
                                    outlined: "",
                                    filter: "",
                                    color: "red",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$t("benchmarksEdit.expired")) +
                                      " " +
                                      _vm._s(item.expiryDateFormatted) +
                                      "\n        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          item.expiryDateFormatted && !item.expired
                            ? _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(item.expiryDateFormatted) +
                                    "\n        "
                                ),
                              ])
                            : _vm._e(),
                          !item.expiryDateFormatted && !item.expired
                            ? _c("span", [_vm._v("-")])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "item.action",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              attrs: { small: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.editItem(item)
                                },
                              },
                            },
                            [_vm._v("\n          mdi-pencil\n        ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "top",
                      fn: function () {
                        return [
                          _c(
                            "v-dialog",
                            {
                              attrs: { "max-width": "400px" },
                              model: {
                                value: _vm.dialog,
                                callback: function ($$v) {
                                  _vm.dialog = $$v
                                },
                                expression: "dialog",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "dialog-title" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "benchmarksEdit.editFreeTrial"
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "benchmarksEdit.confirmFreeTrial"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                      _c(
                                        "v-radio-group",
                                        {
                                          attrs: { row: "" },
                                          model: {
                                            value: _vm.editedItem.freePlan,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editedItem,
                                                "freePlan",
                                                $$v
                                              )
                                            },
                                            expression: "editedItem.freePlan",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Não",
                                              value: "no",
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Sim",
                                              value: "yes",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.editedItem.freePlan == "yes"
                                        ? _c(
                                            "div",
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.periods,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                  label:
                                                    "Selecione o período gratuito",
                                                  "return-object": "",
                                                },
                                                model: {
                                                  value: _vm.selectedPeriod,
                                                  callback: function ($$v) {
                                                    _vm.selectedPeriod = $$v
                                                  },
                                                  expression: "selectedPeriod",
                                                },
                                              }),
                                              _vm.expiryDateSelected
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Data expiração: " +
                                                        _vm._s(
                                                          _vm.expiryDateSelectedFormatted
                                                        )
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.confirmation &&
                                      _vm.editedItem.freePlan == "no"
                                        ? _c(
                                            "v-alert",
                                            { attrs: { type: "warning" } },
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "benchmarksEdit.removePeriod"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.errorUpdate
                                        ? _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                "colored-border": "",
                                                type: "error",
                                                dense: "",
                                                outlined: "",
                                                elevation: "2",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "benchmarksEdit.errorUnexpected"
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-2",
                                          attrs: { outlined: "" },
                                          on: { click: _vm.close },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t("benchmarksEdit.cancel")
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                      _vm.editedItem.freePlan == "yes" ||
                                      !_vm.confirmation
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.save()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "benchmarksEdit.save"
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.editedItem.freePlan == "no" &&
                                      _vm.confirmation
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.save()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "benchmarksEdit.yesConfirm"
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1968776489
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }