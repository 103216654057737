<template>
  <component
    :is="component"
    v-model="selected"
    v-bind="$attrs"
    :items="items"
    :search-input.sync="searchInput"
    return-object
    multiple
    v-on="$listeners"
    @click:clear="$emit('clearClick')"
    @blur="searchInput = ''"
  >
    <template
      v-if="items.length && !searchInput"
      v-slot:prepend-item
    >
      <v-list-item
        ripple
        @click="toggle"
      >
        <v-list-item-action>
          <v-icon :color="color">
            {{ icon }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ selectAllLabel }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" />
    </template>

    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
    >
      <slot
        :name="name"
        v-bind="slotData"
      />
    </template>
  </component>
</template>

<script>

import { VSelect, VAutocomplete } from 'vuetify/lib';

export default {
  name: 'MultipleSelect',
  inheritAttrs: true,
  props: {
    selectAllLabel: {
      type: String,
      default: 'Selecione Todos',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [...this.value],
      searchInput: '',
    };
  },
  computed: {
    color() {
      return this.selected.length > 0 ? 'primary darken-2' : '';
    },
    filteredItems() {
      return this.items.filter((item) => !item.ignore);
    },
    allSelected() {
      return this.filteredItems.length === this.selected.length;
    },
    someSelected() {
      return this.selected.length > 0 && !this.allSelected;
    },
    icon() {
      if (this.allSelected) return 'mdi-checkbox-marked';
      if (this.someSelected) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    component() {
      return this.searchable ? VAutocomplete : VSelect;
    },
  },
  watch: {
    value(value) {
      this.selected = [...value];
    },
  },
  methods: {
    toggle() {
      return this.$nextTick(() => {
        this.selected = this.allSelected ? [] : [...this.filteredItems];
        this.$emit('input', [...this.selected]);
      });
    },
  },
};
</script>

<style scoped lang="scss">

  ::v-deep .v-list-item {
    flex-flow: row;
  }

</style>
