var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "sidebar-item-container" } }, [
    _c(
      "div",
      { staticClass: "product" },
      [
        _c(
          "div",
          { staticClass: "product__title mb-4" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isSidebarMini,
                      expression: "!isSidebarMini",
                    },
                  ],
                },
                [_vm._v("\n          ADMIN TOOLS\n        ")]
              ),
            ]),
          ],
          1
        ),
        _vm._l(_vm.items, function (item) {
          return [
            _c(
              "v-list",
              { key: item.name },
              [
                !item.children
                  ? _c("SidebarItem", {
                      attrs: {
                        "is-sidebar-mini": _vm.isSidebarMini,
                        icon: item.icon,
                        name: item.name,
                        to: item.url,
                      },
                    })
                  : _c("SidebarNestedItem", {
                      attrs: {
                        title: item.name,
                        icon: item.icon,
                        "is-sidebar-mini": _vm.isSidebarMini,
                        "sub-items": item.children,
                      },
                    }),
              ],
              1
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }