<template>
  <div
    v-if="length > 1"
    class="text-center pt-2"
  >
    <v-pagination
      v-model="page"
      :length="length"
    />
  </div>
</template>

<script>
export default {
  name: 'StPagination',
  props: {
    value: {
      type: Object,
      default() {
        return {
          limit: 30,
          offset: 0,
          total: 0
        };
      }
    },
  },
  computed: {
    page: {
      get() {
        if (this.value.offset < 1) {
          return 1;
        }
        return (this.value.offset / this.value.limit) + 1;
      },
      set(newPage) {
        this.value.offset = (newPage - 1) * this.value.limit;
        this.$emit('page:changed', { limit: this.value.limit, offset: this.value.offset });
      }
    },
    length() {
      return Math.ceil(this.value.total / this.value.limit);
    }
  },
};
</script>
