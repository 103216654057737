import Vue from 'vue';
import '@/plugins/noty/noty';
import vuetify from '@/plugins/vuetify';
import App from '@/App';
import router from '@/router';
import store from '@/store/store';
import i18n from '@/plugins/i18n';
import moment from 'moment';

moment.locale('pt-br');

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app');

/**
 * Import third party libs
 */
import '@/plugins/v-tooltip/v-tooltip';
