<template>
  <div>
    <h2> Olá Coruja! Seja bem-vinda ao Admin Tools! </h2>
    <v-dialog
      v-model="dialog"
      max-width="514px"
      persistent
    >
      <v-toolbar flat>
        <v-toolbar-title>
          Vamos otimizar o atendimento?
        </v-toolbar-title>

        <template v-slot:extension>
          <v-tabs
            v-model="tabs"
            fixed-tabs
          >
            <v-tabs-slider />
            <v-tab
              href="#mobile-tabs-5-1"
              class="primary--text"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-desktop-mac-dashboard
                  </v-icon>
                </template>
                <span>Universo</span>
              </v-tooltip>
            </v-tab>

            <v-tab
              href="#mobile-tabs-5-2"
              class="primary--text"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-account-key
                  </v-icon>
                </template>
                <span>Acessos e Permissões</span>
              </v-tooltip>
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="tabs">
        <v-tab-item
          v-for="i in 2"
          :key="i"
          :value="'mobile-tabs-5-' + i"
        >
          <v-card flat>
            <v-card-text v-if="i == 1">
              <span>
                {{ labels.universeDescription }}
              </span>
              <UniverseSearch />
            </v-card-text>
            <v-card-text v-if="i == 2">
              <span>
                {{ labels.userGroupDescription }}
              </span>
              <AccessSearch />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                text
                @click="cancel"
              >
                Agora não
              </v-btn>
              <v-btn
                color="light-blue"
                class="mr-4"
                @click="overview(i)"
              >
                Visão Geral
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-dialog>
  </div>
</template>

<script>
import UniverseSearch from '@/components/universe/Search';
import AccessSearch from '@/components/usergroup/Search';

export default {
  name: 'Home',
  components: {
    UniverseSearch,
    AccessSearch
  },
  data: () => ({
    dialog: true,
    tabs: null,
    labels: {
      universeDescription: 'Para melhor contexto e maior agilidade, o Visão Geral do Universo carrega configurações do Setup e do SmartCare ' +
        'e preenche automaticamente os dados de Conta/Pesquisa em todas as ferramentas. Não é necessário definí-los novamente e ' +
        'nem mesmo acessar à plataforma para conferência de tais informações durante o atendimento.',
      userGroupDescription: 'Para acesso centralizado e independente durante o atendimento, o Visão Geral de Acessos e Permissões ' +
        'traz o detalhamento para os contextos de Grupo de Usuário, Conta e Usuário.'
    }
  }),
  methods: {
    cancel() {
      this.dialog = false;
    },
    usergroupOverview() {
      this.$router.replace({
        path: '/usergroup/overview',
      });
    },
    overview(tab) {
      if (tab === 1) {
        this.$router.replace({
          path: '/universe/overview',
        });
      } else {
        this.$router.replace({
          path: '/usergroup/overview',
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .v-dialog {
    border: 2px solid;

    .v-card {
      padding: 15px 5px;

      .v-card__text {
        text-align: justify;
      }
    }
  }
</style>
