import { MUTATE, MUTATE_MULTIPLE } from '@/store/modules/mutation_types';
import UserLegacyService from '@/services/UserLegacyService';
import AuthService from '@/services/AuthService';
import { login } from '@stilingue/st-auth-utils';

export async function getUserMe({ commit, state }) {
  if (state.user) {
    commit(MUTATE, {
      property: 'requestStatus',
      with: 'success'
    });
    return Promise.resolve(state.user);
  }

  try {
    const user = await UserLegacyService.me();

    commit(MUTATE_MULTIPLE, [{
      property: 'user',
      with: user
    }, {
      property: 'requestStatus',
      with: 'success'
    }]);

    return user;
  } catch (e) {
    console.error(e);
    commit(MUTATE, {
      property: 'requestStatus',
      with: 'error'
    });
  }
}

export function makeLoginRequest({}, { username, password } = {}) {
  if (!(username && password)) {
    return false;
  }

  return AuthService
    .login(username, password)
    .then(data => {
      if (data && 'error' in data) {
        return Promise.reject(data);
      }

      const SSID = data.headers['stilingue-session-id'];
      const usr_id = data.headers['warroom-session-id'];

      if (!(SSID && usr_id)) {
        return Promise.reject({
          response: {
            data: {
              error: 'No Token Received'
            }
          }
        });
      }

      login({ SSID, usr_id });

      return Promise.resolve();
    })
    .catch(error => {
      return Promise.reject(error);
    });
}

export function setUserEmail({ commit }, { selectedUserEmail }) {
  commit(MUTATE, {
    property: 'activeUserEmail',
    with: selectedUserEmail,
  });
}
