var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "retroactive-list" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c("CancelConfirmDialog", { ref: "cancelConfirmDialog" }),
      _vm.genericAlert.message
        ? _c(
            "v-alert",
            {
              attrs: {
                border: "right",
                "colored-border": "",
                type: _vm.genericAlert.type,
                elevation: "2",
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.genericAlert.message) + "\n  ")]
          )
        : _vm._e(),
      _vm.error
        ? _c(
            "v-alert",
            {
              attrs: {
                border: "right",
                "colored-border": "",
                type: "error",
                elevation: "2",
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.labels.error) + "\n  ")]
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                staticClass: "mx-4",
                attrs: { label: _vm.labels.search },
                model: {
                  value: _vm.table.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.table, "search", $$v)
                  },
                  expression: "table.search",
                },
              }),
              _c(
                "div",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "retroactive-list__refresh mx-8",
                      class: {
                        "retroactive-list__refresh--loading": _vm.loading,
                      },
                      on: { click: _vm.getRetroactiveData },
                    },
                    [_vm._v("\n          mdi-refresh\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              headers: _vm.table.headers,
              items: _vm.table.items,
              search: _vm.table.search,
              "custom-sort": _vm.customSort,
            },
            scopedSlots: _vm._u([
              {
                key: "item.percentage_formatted",
                fn: function ({ item }) {
                  return [
                    item.count_pre_retroactive !== -1
                      ? _c(
                          "StMenu",
                          { attrs: { items: item.detailsCountPosts } },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.percentage_formatted) +
                                "\n        "
                            ),
                          ]
                        )
                      : _c("span", [_vm._v(" - ")]),
                  ]
                },
              },
              {
                key: "item.channels",
                fn: function ({ item }) {
                  return [
                    _c("StMenu", { attrs: { items: item.channels } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(item.channels.length) +
                          " " +
                          _vm._s(
                            item.channels.length > 1 ? "canais" : "canal"
                          ) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "item.cancel_retroactive",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "warning",
                          loading: item.shouldLoadBtn,
                          disabled: _vm.isFinished(item),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.cancelRetroactive(item)
                          },
                        },
                      },
                      [_vm._v("\n          Cancelar\n        ")]
                    ),
                  ]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [
                    item.status === "Finalizado"
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-2",
                            attrs: {
                              label: "",
                              color: "green",
                              "text-color": "white",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.status) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    item.status !== "Finalizado"
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-2",
                            attrs: { label: "", "text-color": "white" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.status) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.task_ids",
                fn: function ({ item }) {
                  return [
                    item.task_ids && item.task_ids.length
                      ? _c(
                          "v-list",
                          _vm._l(item.task_ids, function (link, index) {
                            return _c("v-list-item", { key: index }, [
                              _c(
                                "a",
                                {
                                  staticClass: "retroactive-list__link",
                                  attrs: { href: link, target: "_blank" },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.labels.task) +
                                      " " +
                                      _vm._s(index + 1) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ])
                          }),
                          1
                        )
                      : _c("span", [_vm._v(" - ")]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }