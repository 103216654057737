import StSystemNavigationDrawer from '@/language/pt/StSystemNavigationDrawer';
import StCompanySelection from '@/language/pt/StCompanySelection';
import LoginView from '@/language/pt/LoginView';
import Layout from '@/language/pt/Layout';
import UsergroupNew from '@/language/pt/UsergroupNew';
import benchmarksEdit from '@/language/pt/benchmarksEdit';
import RetroactiveList from '@/language/pt/RetroactiveList';
import EyesActivationAndList from '@/language/pt/EyesActivationAndList';
import Liberation from '@/language/pt/Liberation';
import Forms from '@/language/pt/Forms';
import HttpResponses from '@/language/pt/HttpResponses';
import ListLiberation from '@/language/pt/ListLiberation';

export default {
  StSystemNavigationDrawer,
  StCompanySelection,
  LoginView,
  Layout,
  UsergroupNew,
  benchmarksEdit,
  RetroactiveList,
  EyesActivationAndList,
  Liberation,
  Forms,
  HttpResponses,
  ListLiberation,
};
