<template>
  <section
    class="routing-status__search-container"
  >
    <v-form
      ref="routingSearch"
      v-model="valid"
      class="routing-status__form"
      :lazy-validation="false"
    >
      <SingleSelect
        v-model="selectedAccount"
        :items="accounts"
        placeholder="Selecione uma conta"
        required
        text="name"
        class="routing-status__select"
        :rules="isNotEmpty"
        sub-text="group"
      />

      <SingleSelect
        v-model="selectedUniverse"
        :disabled="isFetching"
        :items="universes"
        required
        placeholder="Selecione uma Pesquisa"
        text="name"
        :rules="isNotEmpty"
        sub-text="channel"
      />
      <v-btn
        :disabled="!valid || isFetching"
        depressed
        large
        color="primary"
        @click="handleEvent"
      >
        PESQUISAR
      </v-btn>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import SingleSelect from '../../components/SingleSelect';

export default {
  name: 'RouterSearch',
  components: {
    SingleSelect,
  },
  data: () => ({
    valid: true,
    isNotEmpty: [v => Boolean(v) || 'Este campo deve ser preenchido'],
  }),
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    ...mapState('routing', ['routings']),
    ...mapState('universe', ['universes', 'activeUniverse', 'history', 'requestStatus']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      },
      set(selectedUniverse) {
        this.selectUniverse(selectedUniverse);
      },
    },
    userId: {
      get() {
        return this.user.id;
      }
    },
    isFetching() {
      return this.requestStatus === 'loading';
    }
  },
  async created() {
    await this.getAccounts({ userId: this.userId });
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount, this.selectedUniverse);
    }
    this.changeBreadcrumbs({ text: 'Roteamento', to: '/routing/search', index: 2 });
  },
  methods: {
    ...mapActions('account', ['getAccounts', 'setAccount']),
    ...mapActions('routing', ['getRoutingList', 'changeBreadcrumbs']),
    ...mapActions('universe', ['getAccountUniverses', 'setUniverse']),
    ...mapMutations('account', {
      mutate: 'MUTATE',
    }),
    selectAccount(selectedAccount, selectedUniverse) {
      this.setAccount({ selectedAccount });
      this.getAccountUniverses({ selectedAccount, fetchHistory: false }).then(() => {
        if (selectedUniverse) {
          this.selectedUniverse = selectedUniverse;
        }
        this.selectUniverse(this.selectedUniverse);
      });
    },
    selectUniverse(selectedUniverse) {
      this.setUniverse({ selectedUniverse });
    },
    async handleEvent() {
      await this.getRoutingList({
        selectedAccount: this.selectedAccount,
        selectedUniverse: this.selectedUniverse
      });
      this.$router.push('/routing/list');
    }
  },
  validate() {
    this.$refs.form.validate();
  },
};
</script>

<style scoped lang="scss">
.single-select {
  width: 500px;
  padding: 0 10px;
}

.routing-status__search-container {
  width: 500px;
}

.v-input__slot,
.v-select__slot {
  height: 48px !important;
}

.v-btn {
  width: 100%;
  margin-top: 22px;
}

.failure-notification {
  display: flex;
  height: 300px;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.failure-notification span {
  width: 130px;
  text-align: center;
  cursor: pointer;
}
</style>
