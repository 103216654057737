<template>
  <div class="content-body">
    <PageHeader
      :title="title"
      :description="description"
      :breadcrumbs="breadcrumbs"
    />

    <section class="new-benchmarks-container">
      <v-text-field
        class="text-field-group"
        :label="textField.benchmarksName"
      />
      <v-text-field
        class="text-field-group"
        :label="textField.userGroup"
      />
      <div class="new-benchmarks-trial">
        <v-checkbox
          v-model="terms"
          color="white"
          label="Trial"
        />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <DsIcon
                name="questions"
                size="large"
                class="new-benchmarks-tooltip"
              />
            </v-btn>
          </template>
          <span>{{ textTooltip }}</span>
        </v-tooltip>
      </div>
      <v-radio-group
        v-if="terms"
        class="new-benchmarks-radios"
        row
      >
        <v-radio
          :label="labelRadios.radioOne"
          color="white"
          value="1"
        />
        <v-radio
          :label="labelRadios.radioTwo"
          color="white"
          value="2"
        />
        <v-radio
          :label="labelRadios.radioThree"
          color="white"
          value="3"
        />
      </v-radio-group>
    </section>

    <section class="new-benchmarks-button">
      <v-btn
        width="116px"
        class="text-capitalize "
        rounded
        color="#00CCBE"
      >
        {{ textButton }}
      </v-btn>
    </section>
  </div>
</template>

<script>
import { DsIcon } from '@ds/icons';
import PageHeader from '@/components/base/PageHeader';

export default {
  name: 'NewBenchmarks',
  components: {
    DsIcon,
    PageHeader
  },
  data() {
    return {
      title: 'Novo Benchmarks',
      description: 'Pra começar precisamos das seguintes informações',
      textField: {
        benchmarksName: 'Nome do Benchmarks',
        userGroup: 'User Group',
      },
      textTooltip: 'Período de teste determinado',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Benchmarks',
          to: '/benchmarks'
        },
        {
          text: 'Novo Benchmarks',
          to: '/benchmarks/new-benchmarks'
        },
      ],
      labelRadios: {
        radioOne: '7 dias',
        radioTwo: '15 dias',
        radioThree: '30 dias',
      },
      terms: false,
    };
  },
  computed: {
    textButton() {
      return this.terms ? 'Cadastrar' : 'Próximo';
    }
  },
};
</script>

<style scoped lang="scss">
.new-benchmarks {
  &-container {
    min-height: 400px;
  }

  &-trial {
    display: flex;
    align-items: center;
  }

  &-tooltip {
    background: rgba(255, 255, 255, 0.5) !important;
    color: var(--ds-color-text-1);
  }

  &-button {
    display: flex;
    width: 80%;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &-radios {
    margin: 0;
  }
}

.content-body {
  width: 100%;
}

.text-field-group {
  width: 80%;
  margin: 32px 0;
  color: var(--ds-color-text-1);
}

.v-tooltip__content {
  background: #fff;
  color: black;
}

.text-capitalize {
  color: #242c5a;
  height: 40px;
  width: 232px;
  font-weight: 600;
}

</style>

