var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "section",
        [
          _c(
            "v-form",
            { ref: "sla-analyze", attrs: { "lazy-validation": false } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5" } },
                    [
                      _c("UniverseSearch"),
                      _vm.activeAccount
                        ? _c("SingleSelect", {
                            attrs: {
                              items: _vm.pages,
                              placeholder: "Selecione uma Página",
                              text: "name",
                              "sub-text": "channel",
                            },
                            model: {
                              value: _vm.selectedPage,
                              callback: function ($$v) {
                                _vm.selectedPage = $$v
                              },
                              expression: "selectedPage",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pa-10", attrs: { cols: "5" } },
                    [
                      _c("SingleSelect", {
                        attrs: {
                          items: _vm.sacTypes,
                          placeholder: "Selecione o Tipo de Interação",
                          text: "name",
                          "sub-text": " ",
                        },
                        model: {
                          value: _vm.models.selectedSacType,
                          callback: function ($$v) {
                            _vm.$set(_vm.models, "selectedSacType", $$v)
                          },
                          expression: "models.selectedSacType",
                        },
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: "Tempo Médio",
                                  rules: _vm.rules.isInteger,
                                },
                                model: {
                                  value: _vm.models.averageTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.models, "averageTime", $$v)
                                  },
                                  expression: "models.averageTime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: "Tempo Limite",
                                  rules: _vm.rules.isInteger,
                                },
                                model: {
                                  value: _vm.models.limitTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.models, "limitTime", $$v)
                                  },
                                  expression: "models.limitTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("StDatePicker", {
                        attrs: { range: "" },
                        model: {
                          value: _vm.models.dates,
                          callback: function ($$v) {
                            _vm.$set(_vm.models, "dates", $$v)
                          },
                          expression: "models.dates",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            elevation: "1",
                            raised: "",
                            disabled: _vm.isSearchDisabled(),
                          },
                          on: { click: _vm.submitAnalyze },
                        },
                        [_vm._v("\n            Analisar\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.warning
                ? _c(
                    "v-alert",
                    {
                      attrs: {
                        border: "right",
                        "colored-border": "",
                        type: "warning",
                        elevation: "2",
                      },
                    },
                    [
                      _vm._v(
                        "\n        Nenhuma publicação encontrada. Por favor, verifique os dados de entrada.\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.error
                ? _c(
                    "v-alert",
                    {
                      attrs: {
                        border: "right",
                        "colored-border": "",
                        type: "error",
                        elevation: "2",
                      },
                    },
                    [
                      _vm._v(
                        "\n        Erro inesperado ao analisar SLA. Favor entrar em contato com o time de N2.\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("StLoader", { attrs: { loading: _vm.loading } }),
        ],
        1
      ),
      _vm.success && _vm.response
        ? _c(
            "section",
            { staticClass: "mt-2" },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-around" } },
                _vm._l(_vm.response.report, function (report, i) {
                  return _c(
                    "v-col",
                    { key: `report-${i}` },
                    [
                      _c(
                        "v-sheet",
                        {
                          staticClass: "pa-12",
                          attrs: { color: "gray lighten-3", rounded: "" },
                        },
                        [
                          _c("div", [
                            _c("strong", [_vm._v(_vm._s(report.type))]),
                          ]),
                          _c("div", { staticClass: "pa-5" }, [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.response.posts_analyzed)),
                            ]),
                            _vm._v(" publicações "),
                            _vm.models.dates.length === 0
                              ? _c("strong", [
                                  _vm._v(
                                    " das\n              últimas 24 horas\n            "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" foram analisadas e "),
                            _c("strong", [_vm._v(_vm._s(report.posts_total))]),
                            _vm._v(" tem tempo de processamento superior a "),
                            _c("strong", [_vm._v(_vm._s(report.time) + "m")]),
                            _vm._v(".\n          "),
                          ]),
                          _c(
                            "v-carousel",
                            { attrs: { height: "605" } },
                            _vm._l(report.posts, function (post, post_index) {
                              return _c(
                                "v-carousel-item",
                                { key: `post-${post_index}` },
                                [
                                  _c(
                                    "v-sheet",
                                    {
                                      staticClass: "pa-10",
                                      attrs: {
                                        color: "teal lighten-3",
                                        height: "100%",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "mx-auto",
                                          attrs: { "max-width": "344" },
                                        },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              src: _vm.postImage,
                                              height: "200px",
                                            },
                                          }),
                                          _c("v-card-title", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(post.text) +
                                                "\n                  "
                                            ),
                                          ]),
                                          _c("v-card-subtitle", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(post.post_url) +
                                                "\n                  "
                                            ),
                                          ]),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "orange lighten-2",
                                                    text: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Detalhes\n                    "
                                                  ),
                                                ]
                                              ),
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.show = !_vm.show
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.show
                                                          ? "mdi-chevron-up"
                                                          : "mdi-chevron-down"
                                                      )
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-expand-transition", [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.show,
                                                    expression: "show",
                                                  },
                                                ],
                                              },
                                              [
                                                _c("v-divider"),
                                                _c("v-card-text", [
                                                  _c("div", [
                                                    _vm._v(" Identificador: "),
                                                    _c("strong", [
                                                      _vm._v(_vm._s(post.pid)),
                                                    ]),
                                                  ]),
                                                  _c("div", [
                                                    _vm._v("Publicada em "),
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(post.posted_at)
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("div", [
                                                    _vm._v("Coletada em "),
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(post.crawled_at)
                                                      ),
                                                    ]),
                                                  ]),
                                                  _c("div", [
                                                    _vm._v("Processada em "),
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          post.processed_at
                                                        )
                                                      ),
                                                    ]),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }