var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "credits-by-usergroup" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.$t("CreditsByUsergroup.title"),
          description: _vm.$t("CreditsByUsergroup.description"),
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c("StLoader", { attrs: { loading: _vm.loadingGroups } }),
      _c(
        "div",
        { staticClass: "usergroups-selector" },
        [
          _c("SingleSelect", {
            attrs: {
              items: _vm.usergroups,
              placeholder: "Selecione o Usergroup",
              text: "name",
              sub_text: "  ",
            },
            model: {
              value: _vm.selectedUsergroup,
              callback: function ($$v) {
                _vm.selectedUsergroup = $$v
              },
              expression: "selectedUsergroup",
            },
          }),
        ],
        1
      ),
      _c("StLoader", { attrs: { loading: _vm.loadingCredits } }),
      _c(
        "div",
        { staticClass: "usergroups-credits" },
        [
          _c("h2", { staticClass: "usergroups-credits__overview" }, [
            _c("span", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CreditsByUsergroup.total")) +
                  " " +
                  _vm._s(_vm.total) +
                  "\n      "
              ),
            ]),
            _c("span", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CreditsByUsergroup.usedTotal")) +
                  " " +
                  _vm._s(_vm.usedCreditsTable.credits.total) +
                  "\n      "
              ),
            ]),
            _c("span", [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("CreditsByUsergroup.nonUsedTotal")) +
                  " " +
                  _vm._s(_vm.nonUsedCreditsTable.credits.total) +
                  "\n      "
              ),
            ]),
          ]),
          _c("h3", { staticClass: "usergroups-credits__table-section-title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("CreditsByUsergroup.usedTotal")) +
                "\n    "
            ),
          ]),
          _c("v-data-table", {
            staticClass: "usergroups-credits__table",
            attrs: {
              headers: _vm.usedCreditsTable.headers,
              items: _vm.usedCreditsTable.credits,
              "items-per-page": 10,
            },
          }),
          _c("h3", { staticClass: "usergroups-credits__table-section-title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("CreditsByUsergroup.nonUsedTotal")) +
                "\n    "
            ),
          ]),
          _c("v-data-table", {
            staticClass: "usergroups-credits__table",
            attrs: {
              headers: _vm.usedCreditsTable.headers,
              items: _vm.nonUsedCreditsTable.credits,
              "items-per-page": 10,
            },
          }),
          _c("h3", { staticClass: "usergroups-credits__table-section-title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("CreditsByUsergroup.usedDetails")) +
                "\n    "
            ),
          ]),
          _c("v-data-table", {
            staticClass: "usergroups-credits__table",
            attrs: {
              headers: _vm.table.headers,
              items: _vm.table.credits,
              "items-per-page": 10,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }