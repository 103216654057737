export default {
  stSessionId: null,
  user: null,
  role: {
    isSuperAdmin: true,
    isAccountAdmin: true,
    master: true,
    permissions: { canViewPermissions: true }
  },
  requestStatus: 'loading',
  repliersById: {},
  activeUserEmail: null
};
