import StService from '@/services/StService';
import { createApi } from '@stilingue/st-auth-utils';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import { StagingUrl, ProductionUrl } from './config';

const gateway = isProdVersion()
  ? ProductionUrl
  : StagingUrl;

const afterParser = response => response.data;

class UniverseService extends StService {
  constructor(gateway) {
    const api = createApi('', gateway);
    super(api, { afterParser });
  }

  async updateLimit(params = {}) {
    return this.api.patch({ url: `/universe/${params.universe_id}/limit?num_max_posts=${params.num_max_posts}&limit_type=${params.limit_type}` }).then(this.afterParser);
  }

  async processRetweet(params = {}) {
    return this.api.patch({ url: `/universe/${params.universe_id}/process_retweet?&activate=${params.activate}` }).then(this.afterParser);
  }

  async addLanguage(params = {}) {
    return this.api.post({ url: `/universe/${params.universe_id}/languages?&lang=${params.lang}` }).then(this.afterParser);
  }

  async getOverview(id) {
    return this.api.get({ url: `/universe/${id}` }).then(this.afterParser);
  }

  async getHistory(params = {}) {
    let baseUrl = `/universe/${params.universe_id}/get_history?since_date=${params.since_date}`;
    if (params.actual_state) {
      baseUrl = `${baseUrl}&add_actual_state=${params.actual_state}`;
    }

    return this.api.get({ url: baseUrl }).then(this.afterParser);
  }
}

export default new UniverseService(gateway);
