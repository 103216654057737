export default {
  label: {
    message: {
      error: {
        requestNotCompleted: 'Erro inesperado. Requisição não completada.',
        requestRepeat: 'Erro ao processar a solicitação. Repita a operação.',
        internalServerError: 'Erro no servidor.',
        badRequest: 'Erro na requisição.',
        serviceUnavailable: 'Servidor indisponível.',
      },
      success: {
        ok: 'Requisição executada com sucesso.',
      },
      warning: {
        serverBusy: 'Servidor ocupado. Tente novamente em alguns instantes.',
      }
    },
  },
};
