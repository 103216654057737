var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.$t("UsergroupNew.title"),
          description: _vm.$t("UsergroupNew.description"),
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "v-form",
        {
          ref: "usergroupNewForm",
          attrs: { "lazy-validation": false },
          model: {
            value: _vm.models.valid,
            callback: function ($$v) {
              _vm.$set(_vm.models, "valid", $$v)
            },
            expression: "models.valid",
          },
        },
        [
          _c("v-text-field", {
            attrs: {
              placeholder: _vm.$t("UsergroupNew.phName"),
              rules: _vm.rules.isBool,
            },
            on: { input: _vm.setErrorFalse },
            model: {
              value: _vm.models.newUsergroup,
              callback: function ($$v) {
                _vm.$set(_vm.models, "newUsergroup", $$v)
              },
              expression: "models.newUsergroup",
            },
          }),
          _c("v-text-field", {
            attrs: {
              placeholder: _vm.$t("UsergroupNew.phLimit"),
              rules: _vm.rules.isInteger,
            },
            on: { input: _vm.setErrorFalse },
            model: {
              value: _vm.models.limit,
              callback: function ($$v) {
                _vm.$set(_vm.models, "limit", $$v)
              },
              expression: "models.limit",
            },
          }),
          _c("v-text-field", {
            attrs: {
              placeholder: _vm.$t("UsergroupNew.phEmail"),
              rules: _vm.rules.isEmail,
            },
            model: {
              value: _vm.models.email,
              callback: function ($$v) {
                _vm.$set(_vm.models, "email", $$v)
              },
              expression: "models.email",
            },
          }),
          _c(
            "v-radio-group",
            {
              attrs: { row: "" },
              model: {
                value: _vm.models.planType,
                callback: function ($$v) {
                  _vm.$set(_vm.models, "planType", $$v)
                },
                expression: "models.planType",
              },
            },
            [
              _c("v-radio", { attrs: { label: "1 - Trial", value: "1" } }),
              _c("v-radio", { attrs: { label: "2 - Light", value: "2" } }),
              _c("v-radio", { attrs: { label: "3 - Ultra", value: "3" } }),
              _c("v-radio", { attrs: { label: "4 - Elite", value: "4" } }),
              _c("v-radio", { attrs: { label: "5 - Realtime", value: "5" } }),
            ],
            1
          ),
          _c("StOneDatePicker", {
            attrs: {
              label: _vm.$t("UsergroupNew.lbDate"),
              "max-date": _vm.fiveYearsDate,
              "min-date": _vm.todayDate,
              rules: _vm.rules.isDate,
            },
            on: { dateToSubmit: _vm.setExpireDate, input: _vm.setErrorFalse },
            model: {
              value: _vm.models.date,
              callback: function ($$v) {
                _vm.$set(_vm.models, "date", $$v)
              },
              expression: "models.date",
            },
          }),
          _c(
            "v-btn",
            {
              attrs: {
                disabled: !_vm.models.valid || _vm.loading,
                color: "light-blue",
              },
              on: { click: _vm.actionCreateUsergroup },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("UsergroupNew.create")) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _c("StLoader", { attrs: { loading: _vm.loading } }),
      _vm.success
        ? _c(
            "v-alert",
            {
              attrs: {
                border: "right",
                "colored-border": "",
                type: "success",
                elevation: "2",
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.labels.successMessage) + ".\n  ")]
          )
        : _vm._e(),
      _vm.error
        ? _c(
            "v-alert",
            {
              attrs: {
                border: "right",
                "colored-border": "",
                type: "error",
                elevation: "2",
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.labels.errorMessage) + ".\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }