import Vue from 'vue';
import Router from 'vue-router';

if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(Router);
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/LoginView.vue'),
      props: true,
    },
    {
      path: '/companies',
      name: 'companies',
      component: () => import('./views/CompanySelection.vue'),
      props: true,
    },
    {
      path: '/',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('./views/Home.vue'),
          props: true
        }
      ]
    },
    {
      path: '/publications',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'platform-details',
          name: 'publicationDetails',
          component: () => import('./components/PostDetails.vue'),
          props: true
        },
        {
          path: 'setup-validate',
          name: 'publicationSetupValidate',
          component: () => import('./components/NotCrawled.vue'),
          props: true
        },
        {
          path: 'facebook-details',
          name: 'publicationFacebookDetails',
          component: () => import('./components/FbPostDetails.vue'),
          props: true
        }
      ]
    },
    {
      path: '/history',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'pages',
          name: 'pageHistory',
          component: () => import('./views/PageHistory.vue'),
          props: true,
        },
        {
          path: 'annotations',
          name: 'annotationsHistory',
          component: () => import('./views/AnnotationsHistory.vue'),
          props: true,
        },
        {
          path: 'page-auth-error',
          name: 'page-auth-error',
          component: () => import('./views/PageAuthError.vue'),
          props: true,
        },
        {
          path: 'universe',
          name: 'universeHistory',
          component: () => import('./views/UniverseHistory.vue'),
          props: true,
        },
        {
          path: 'actions',
          name: 'actionsHistory',
          component: () => import('./components/account/ActionsHistory.vue'),
          props: true,
        }
      ],
    },
    {
      path: '/routing',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: '/',
          component: () => import('./views/RoutingHistory.vue'),
          props: true,
          children: [
            {
              path: 'search',
              component: () => import('./components/routing/RoutingSearch.vue'),
              name: 'router-search',
              props: true,
            },
            {
              path: 'list',
              component: () => import('./components/routing/RoutingList.vue'),
              props: true,
            },
            {
              path: 'user-list',
              component: () => import('./components/routing/RoutingUserList.vue'),
              props: true,
            },
            {
              path: 'user',
              component: () => import('./components/routing/RoutingUser.vue'),
              props: true,
            },
          ]
        },
      ],
    },
    {
      path: '/universe',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'limit',
          name: 'universeLimit',
          component: () => import('./components/universe/Limit.vue'),
          props: true
        },
        {
          path: 'retweet',
          name: 'processRetweet',
          component: () => import('./components/universe/Retweet.vue'),
          props: true
        },
        {
          path: 'language',
          name: 'addLanguage',
          component: () => import('./components/universe/Language.vue'),
          props: true
        },
        {
          path: 'search',
          name: 'search',
          component: () => import('./components/universe/Search.vue'),
          props: true
        },
        {
          path: 'overview',
          name: 'uniOverview',
          component: () => import('./components/universe/Overview.vue'),
          props: true
        },
        {
          path: 'post-insertion',
          name: 'PostInsertion',
          component: () => import('./components/PostInsertion.vue'),
          props: true
        },
        {
          path: 'reprocess-history',
          name: 'reprocessHistory',
          component: () => import('./components/universe/ReprocessHistory.vue'),
          props: true
        }
      ],
    },
    {
      path: '/darkposts',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'instagram/validate',
          name: 'darkPostValidate',
          component: () => import('./views/DarkPostColect.vue'),
          props: true,
        },
      ],
    },
    {
      path: '/adaccounts',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'instagram/validate',
          name: 'instagramAdAccount',
          component: () => import('./views/AdAccountChecking.vue'),
          props: true,
        },
        {
          path: 'linkedin/validate',
          name: 'linkedinAdAccount',
          component: () => import('./views/LinkedinAdAccountCheck.vue'),
          props: true,
        }
      ],
    },
    {
      path: '/retroactive',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'list',
          name: 'retroactiveList',
          component: () => import('./components/retroactive/RetroactiveList.vue'),
          props: true,
        },
        {
          path: 'new',
          name: 'retroactiveNew',
          component: () => import('./components/retroactive/RetroactiveNew.vue'),
          props: true,
        },
        {
          path: 'mass',
          name: 'retroactiveNewMass',
          component: () => import('./components/retroactive/RetroactiveNewMass.vue'),
          props: true,
        },
      ],
    },
    {
      path: '/proprietary-retroactive',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'list',
          name: 'proprietaryRetroactiveList',
          component: () => import('./components/proprietary-retroactive/ProprietaryRetroactiveList.vue'),
          props: true,
        },
        {
          path: 'inbox-list',
          name: 'inboxRetroactiveList',
          component: () => import('./components/proprietary-retroactive/InboxRetroactiveList.vue'),
          props: true,
        },
        {
          path: 'proprietary-new',
          name: 'proprietaryRetroactiveNew',
          component: () => import('./components/proprietary-retroactive/ProprietaryRetroactiveNew.vue'),
          props: true,
        },
      ],
    },
    {
      path: '/reprocessing',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'list',
          name: 'reprocessingList',
          component: () => import('./components/reprocessing/ReprocessingList.vue'),
          props: true,
        },
        {
          path: 'new',
          name: 'reprocessingNew',
          component: () => import('./components/reprocessing/ReprocessingNew.vue'),
          props: true
        }
      ],
    },
    {
      path: '/batch',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'edit',
          name: 'multiple-edition',
          component: () => import('./components/multiple-edition/Edit.vue'),
          props: true,
        },
        {
          path: 'history',
          name: 'multiple-edition-history',
          component: () => import('./components/multiple-edition/History.vue'),
          props: true,
        }
      ]
    },
    {
      path: '/monitoring',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'crawling-health',
          name: 'crawlingHealth',
          component: () => import('./components/ProprietaryCrawlingHealth.vue'),
          props: true
        }
      ],
    },
    {
      path: '/usergroup',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'overview',
          name: 'groupOverview',
          component: () => import('./components/usergroup/Overview.vue'),
          props: true
        },
        {
          path: 'update',
          name: 'podUpdate',
          component: () => import('./components/usergroup/Update.vue'),
          props: true
        },
        {
          path: 'new',
          name: 'usergroupNew',
          component: () => import('./components/usergroup/UsergroupNew.vue'),
          props: true
        },
        {
          path: 'credits',
          name: 'creditsByUsergroup',
          component: () => import('./components/usergroup/CreditsByUsergroup.vue'),
          props: true
        }
      ],
    },
    {
      path: '/user',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'bablic',
          name: 'actionBablic',
          component: () => import('./components/user/Bablic.vue'),
          props: true
        }
      ],
    },
    {
      path: '/sla',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'proprietary-crawler',
          name: 'proprietaryCrawler',
          component: () => import('./components/sla/ProprietaryCrawlerAnalyze.vue'),
          props: true
        }
      ],
    },
    {
      path: '/sso',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'configuration',
          name: 'configuration',
          component: () => import('./components/sso/ConfigurationSso.vue'),
          props: true,
        },
        {
          path: 'status',
          name: 'status',
          component: () => import('./components/sso/StatusSso.vue'),
          props: true
        }
      ],
    },
    {
      path: '/snlp',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'snlp-linechart',
          name: 'snlpLinechart',
          component: () => import('./components/snlp-chart/SnlpLineChart.vue'),
          props: true
        },
      ]
    },
    {
      path: '/benchmarks',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'search',
          name: 'benchmarksSearch',
          component: () => import('./components/benchmarks/benchmarksSearch.vue'),
          props: true,
        },
        {
          path: 'new',
          name: 'benchmarksNew',
          component: () => import('./components/benchmarks/benchmarksSearch.vue'),
          props: true
        },
        {
          path: 'new-benchmarks',
          name: 'newBenchmarks',
          component: () => import('./components/benchmarks/NewBenchmarks.vue'),
          props: true
        },
        {
          path: 'edit',
          name: 'benchmarksEdit',
          component: () => import('./components/benchmarks/benchmarksEdit.vue'),
          props: true
        }
      ],
    },
    {
      path: '/eyes',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'activation-and-list',
          name: 'eyesActivationAndList',
          component: () => import('./components/eyes/EyesActivationAndList.vue'),
          props: true
        },
      ]
    },
    {
      path: '/liberation',
      component: () => import('./views/Layout.vue'),
      props: true,
      children: [
        {
          path: 'activation-credits',
          name: 'liberation',
          component: () => import('./components/liberation/Liberation.vue'),
          props: true
        },
      ]
    },
    {
      path: '*',
      redirect: 'error',
      props: true,
    },
    {
      path: '/',
      name: 'loading',
      component: () => import('./views/Loading.vue'),
      meta: { noPath: true },
    },
  ],
});

export default router;
