var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "section",
        { staticClass: "selector-container" },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: { flat: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "extension",
                      fn: function () {
                        return [
                          _c(
                            "v-tabs",
                            {
                              attrs: { "fixed-tabs": "" },
                              model: {
                                value: _vm.tabs,
                                callback: function ($$v) {
                                  _vm.tabs = $$v
                                },
                                expression: "tabs",
                              },
                            },
                            [
                              _c("v-tabs-slider"),
                              _c(
                                "v-tab",
                                {
                                  staticClass: "primary--text",
                                  attrs: { href: "#mobile-tabs-1" },
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { dark: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-account-group\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [_c("span", [_vm._v("Grupo de Usuário")])]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-tab",
                                {
                                  staticClass: "primary--text",
                                  attrs: {
                                    id: "v-tab-account",
                                    href: "#mobile-tabs-2",
                                  },
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { dark: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-domain\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [_c("span", [_vm._v("Conta")])]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-tab",
                                {
                                  staticClass: "primary--text",
                                  attrs: {
                                    id: "v-tab-user",
                                    href: "#mobile-tabs-3",
                                  },
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { dark: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-account-box\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [_c("span", [_vm._v("Usuário")])]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v("\n          mdi-account-key\n        "),
                  ]),
                  _c("v-toolbar-title", [
                    _vm._v("\n          Acessos e Permissões\n        "),
                  ]),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tabs,
                    callback: function ($$v) {
                      _vm.tabs = $$v
                    },
                    expression: "tabs",
                  },
                },
                _vm._l(3, function (i) {
                  return _c(
                    "v-tab-item",
                    { key: i, attrs: { value: "mobile-tabs-" + i } },
                    [
                      i == 1
                        ? _c(
                            "v-row",
                            { attrs: { justify: "space-around" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "subheading font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.selectedUsergroup.name
                                              ) +
                                              "\n                  "
                                          ),
                                          _vm.expirationDate
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "my-2 icon-align",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "warning lighten-2",
                                                                          fab: "",
                                                                          dark: "",
                                                                          text: "",
                                                                          icon: "",
                                                                        },
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-progress-alert"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "O contrato desse cliente vence em: " +
                                                        _vm._s(
                                                          _vm.expirationDate
                                                        )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("v-card-subtitle", [
                                        _vm._v(
                                          "Detalha contas e usuários do grupo."
                                        ),
                                      ]),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              "input-value":
                                                _vm.models
                                                  .userGroupAccessStilingue,
                                              label: "Stilingue",
                                              inset: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.getUserGroupAccess(
                                                  _vm.models
                                                    .userGroupAccessStilingue
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.models
                                                  .userGroupAccessStilingue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.models,
                                                  "userGroupAccessStilingue",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "models.userGroupAccessStilingue",
                                            },
                                          }),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "my-2 icon-align",
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      fab: "",
                                                                      small: "",
                                                                      dark: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.getUniverseCreateLimit()
                                                                        },
                                                                    },
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "\n                            mdi-desktop-mac-dashboard\n                          "
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Limites de Pesquisa"),
                                              ]),
                                            ]
                                          ),
                                          _vm.customerSuccess
                                            ? _c(
                                                "v-card",
                                                {
                                                  staticClass: "owners",
                                                  attrs: {
                                                    width: "256",
                                                    tile: "",
                                                  },
                                                },
                                                [
                                                  _c("v-system-bar", {
                                                    attrs: { height: "14" },
                                                  }),
                                                  _c(
                                                    "v-list",
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        [
                                                          _c(
                                                            "v-list-item-avatar",
                                                            [
                                                              _c("v-img", {
                                                                attrs: {
                                                                  src: _vm.ownl,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              staticClass:
                                                                "text-h10",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Customer Success\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item",
                                                        [
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h6",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .customerSuccess
                                                                          .name
                                                                      ) +
                                                                      "\n                          "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-list-item-subtitle",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .customerSuccess
                                                                        .email
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.sales
                                            ? _c(
                                                "v-card",
                                                {
                                                  staticClass: "owners",
                                                  attrs: {
                                                    width: "256",
                                                    tile: "",
                                                  },
                                                },
                                                [
                                                  _c("v-system-bar", {
                                                    attrs: { height: "14" },
                                                  }),
                                                  _c(
                                                    "v-list",
                                                    [
                                                      _c(
                                                        "v-list-item",
                                                        [
                                                          _c(
                                                            "v-list-item-avatar",
                                                            [
                                                              _c("v-img", {
                                                                attrs: {
                                                                  src: _vm.ownl,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-item-title",
                                                            {
                                                              staticClass:
                                                                "text-h10",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Sales\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-list-item",
                                                        [
                                                          _c(
                                                            "v-list-item-content",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "text-h6",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .sales
                                                                          .name
                                                                      ) +
                                                                      "\n                          "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-list-item-subtitle",
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.sales
                                                                        .email
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("StLoader", {
                                        attrs: {
                                          loading: _vm.loadingUserGroup,
                                        },
                                      }),
                                      !_vm.loadingUserGroup &&
                                      _vm.userGroupAccessData
                                        ? _c(
                                            "v-expansion-panels",
                                            {
                                              attrs: {
                                                accordion: "",
                                                dark: "",
                                              },
                                            },
                                            _vm._l(
                                              _vm.userGroupAccessData,
                                              function (item, j) {
                                                return _c(
                                                  "v-expansion-panel",
                                                  { key: j },
                                                  [
                                                    item.users.internal
                                                      .length == 0 &&
                                                    item.users.external
                                                      .length == 0
                                                      ? _c(
                                                          "v-expansion-panel-header",
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                "(sem acesso)"
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-expansion-panel-header",
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                    _c(
                                                      "v-expansion-panel-content",
                                                      [
                                                        item.users.internal
                                                          .length > 0 ||
                                                        item.users.external
                                                          .length > 0
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  text: "",
                                                                  icon: "",
                                                                  color:
                                                                    "primary lighten-2",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.showAccount(
                                                                        item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      dark: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          mdi-magnify\n                        "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _c("v-data-table", {
                                                          attrs: {
                                                            headers:
                                                              _vm.headers,
                                                            items:
                                                              item.users
                                                                .internal,
                                                            "multi-sort":
                                                              _vm.multiSort,
                                                            "hide-default-header":
                                                              "",
                                                            dark: "",
                                                            dense: "",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "item.email",
                                                                fn: function ({
                                                                  item,
                                                                }) {
                                                                  return [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.email
                                                                        )
                                                                      ),
                                                                    ]),
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          icon: "",
                                                                          color:
                                                                            "primary lighten-2",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.showUser(
                                                                                item.email
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                dark: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              mdi-magnify\n                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                        _c("v-data-table", {
                                                          attrs: {
                                                            headers:
                                                              _vm.headers,
                                                            items:
                                                              item.users
                                                                .external,
                                                            "multi-sort":
                                                              _vm.multiSort,
                                                            "hide-default-header":
                                                              "",
                                                            dark: "",
                                                            dense: "",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "top",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-toolbar",
                                                                      {
                                                                        staticClass:
                                                                          "toolbar-external",
                                                                        attrs: {
                                                                          dense:
                                                                            "",
                                                                          flat: "",
                                                                          shaped:
                                                                            "",
                                                                          height:
                                                                            "35",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-toolbar-title",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              Acessos Externos\n                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                              {
                                                                key: "item.email",
                                                                fn: function ({
                                                                  item,
                                                                }) {
                                                                  return [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.email
                                                                        )
                                                                      ),
                                                                    ]),
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          icon: "",
                                                                          color:
                                                                            "primary lighten-2",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.showUser(
                                                                                item.email
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                dark: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              mdi-magnify\n                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.loadingUserGroup &&
                                      !_vm.userGroupAccessData
                                        ? _c("v-card-text", [
                                            _vm._v(
                                              "\n                  Nenhum dado encontrado.\n                "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      i == 2
                        ? _c(
                            "v-row",
                            { attrs: { justify: "space-around" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "subheading font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.selectedAccount.name ||
                                                  "Indefinido"
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _c("v-card-subtitle", [
                                        _vm._v(
                                          "Detalha permissões dos usuários com acesso à conta."
                                        ),
                                      ]),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              "input-value":
                                                _vm.models
                                                  .accountAccessStilingue,
                                              label: "Stilingue",
                                              inset: "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.getAccountAccess(
                                                  _vm.models
                                                    .accountAccessStilingue
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.models
                                                  .accountAccessStilingue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.models,
                                                  "accountAccessStilingue",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "models.accountAccessStilingue",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("StLoader", {
                                        attrs: { loading: _vm.loadingAccount },
                                      }),
                                      !_vm.loadingAccount &&
                                      _vm.accountAccessData
                                        ? _c(
                                            "v-expansion-panels",
                                            {
                                              attrs: {
                                                accordion: "",
                                                dark: "",
                                              },
                                            },
                                            _vm._l(
                                              _vm.accountAccessData,
                                              function (item, j) {
                                                return _c(
                                                  "v-expansion-panel",
                                                  { key: j },
                                                  [
                                                    item.users.internal
                                                      .length == 0 &&
                                                    item.users.external
                                                      .length == 0
                                                      ? _c(
                                                          "v-expansion-panel-header",
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                "(sem acesso)"
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-expansion-panel-header",
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                " (" +
                                                                _vm._s(
                                                                  item.usergroup
                                                                ) +
                                                                ")\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                    _c(
                                                      "v-expansion-panel-content",
                                                      [
                                                        _c("v-data-table", {
                                                          attrs: {
                                                            headers:
                                                              _vm.headers,
                                                            items:
                                                              item.users
                                                                .internal,
                                                            "multi-sort":
                                                              _vm.multiSort,
                                                            "hide-default-header":
                                                              "",
                                                            dark: "",
                                                            dense: "",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "item.email",
                                                                fn: function ({
                                                                  item,
                                                                }) {
                                                                  return [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.email
                                                                        )
                                                                      ),
                                                                    ]),
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          icon: "",
                                                                          color:
                                                                            "primary lighten-2",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.showUser(
                                                                                item.email
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    dark: "",
                                                                                  },
                                                                              },
                                                                              "v-icon",
                                                                              _vm.attrs,
                                                                              false
                                                                            ),
                                                                            _vm.on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              mdi-magnify\n                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                        _c("v-data-table", {
                                                          attrs: {
                                                            headers:
                                                              _vm.headers,
                                                            items:
                                                              item.users
                                                                .external,
                                                            "multi-sort":
                                                              _vm.multiSort,
                                                            "hide-default-header":
                                                              "",
                                                            dark: "",
                                                            dense: "",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "top",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-toolbar",
                                                                      {
                                                                        staticClass:
                                                                          "toolbar-external",
                                                                        attrs: {
                                                                          dense:
                                                                            "",
                                                                          flat: "",
                                                                          shaped:
                                                                            "",
                                                                          height:
                                                                            "35",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-toolbar-title",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              Acessos Externos\n                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                              {
                                                                key: "item.email",
                                                                fn: function ({
                                                                  item,
                                                                }) {
                                                                  return [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.email
                                                                        )
                                                                      ),
                                                                    ]),
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          text: "",
                                                                          icon: "",
                                                                          color:
                                                                            "primary lighten-2",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.showUser(
                                                                                item.email
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          _vm._g(
                                                                            _vm._b(
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    dark: "",
                                                                                  },
                                                                              },
                                                                              "v-icon",
                                                                              _vm.attrs,
                                                                              false
                                                                            ),
                                                                            _vm.on
                                                                          ),
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              mdi-magnify\n                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.loadingAccount &&
                                      !_vm.accountAccessData
                                        ? _c("v-card-text", [
                                            _vm._v(
                                              "\n                  Nenhum dado encontrado.\n                "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      i == 3
                        ? _c(
                            "v-row",
                            { attrs: { justify: "space-around" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "subheading font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.selectedUserEmail ||
                                                  "Indefinido"
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _c("v-card-subtitle", [
                                        _vm._v(
                                          "Detalha contas que o usuário tem acesso e suas permissões."
                                        ),
                                      ]),
                                      _c("StLoader", {
                                        attrs: { loading: _vm.loadingUser },
                                      }),
                                      !_vm.loadingUser && _vm.userAccessData
                                        ? _c(
                                            "v-expansion-panels",
                                            {
                                              attrs: {
                                                accordion: "",
                                                dark: "",
                                              },
                                            },
                                            _vm._l(
                                              _vm.userAccessData,
                                              function (item, j) {
                                                return _c(
                                                  "v-expansion-panel",
                                                  { key: j },
                                                  [
                                                    item.accounts.internal
                                                      .length == 0 &&
                                                    item.accounts.external
                                                      .length == 0
                                                      ? _c(
                                                          "v-expansion-panel-header",
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                "(sem acesso)"
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-expansion-panel-header",
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  item.name
                                                                ) +
                                                                "\n                    "
                                                            ),
                                                          ]
                                                        ),
                                                    _c(
                                                      "v-expansion-panel-content",
                                                      [
                                                        _c(
                                                          "v-card",
                                                          {
                                                            attrs: {
                                                              "max-width":
                                                                "400",
                                                              tile: "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item",
                                                              [
                                                                _c(
                                                                  "v-list-item-content",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          "Permissão"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-list-item-subtitle",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.role
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item",
                                                              {
                                                                attrs: {
                                                                  "two-line":
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-content",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          "Grupo"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-list-item-subtitle",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.usergroup
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item",
                                                              {
                                                                attrs: {
                                                                  "two-line":
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-list-item-content",
                                                                  [
                                                                    _c(
                                                                      "v-list-item-title",
                                                                      [
                                                                        _vm._v(
                                                                          "Criado em"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-list-item-subtitle",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.created_at
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c("v-data-table", {
                                                          attrs: {
                                                            headers:
                                                              _vm.headers,
                                                            items:
                                                              item.accounts
                                                                .internal,
                                                            "multi-sort":
                                                              _vm.multiSort,
                                                            "hide-default-header":
                                                              "",
                                                            dark: "",
                                                            dense: "",
                                                          },
                                                        }),
                                                        _c("v-data-table", {
                                                          attrs: {
                                                            headers:
                                                              _vm.headers,
                                                            items:
                                                              item.accounts
                                                                .external,
                                                            "multi-sort":
                                                              _vm.multiSort,
                                                            "hide-default-header":
                                                              "",
                                                            dark: "",
                                                            dense: "",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "top",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "v-toolbar",
                                                                      {
                                                                        staticClass:
                                                                          "toolbar-external",
                                                                        attrs: {
                                                                          dense:
                                                                            "",
                                                                          flat: "",
                                                                          shaped:
                                                                            "",
                                                                          height:
                                                                            "35",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-toolbar-title",
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              Acessos Externos\n                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.loadingUser && !_vm.userAccessData
                                        ? _c("v-card-text", [
                                            _vm._v(
                                              "\n                  Nenhum dado encontrado.\n                "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { width: "450", "content-class": "universe-limit-dialog" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "text-h5" },
                    [
                      _c(
                        "v-icon",
                        { attrs: { large: "", color: "primary darken-2" } },
                        [
                          _vm._v(
                            "\n            mdi-desktop-mac-dashboard\n          "
                          ),
                        ]
                      ),
                      _vm._v("\n          Limites de Pesquisa\n        "),
                    ],
                    1
                  ),
                  _c("StLoader", { attrs: { loading: _vm.loadingLimit } }),
                  _c(
                    "v-card-text",
                    [
                      _vm.universeCreateLimit
                        ? _c(
                            "v-sheet",
                            {
                              attrs: {
                                color: "primary",
                                elevation: "1",
                                shaped: "",
                                width: "250",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.universeCreateLimit) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.errorUniverseLimit
                        ? _c(
                            "v-alert",
                            {
                              attrs: {
                                border: "right",
                                "colored-border": "",
                                type: "error",
                                elevation: "2",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.errorUniverseLimit) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_vm._v("\n            Fechar\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }