import { isRunningProductionEnvironment } from '@/utils/is-running-production-environment.js';

export default {
  computed: {
    items() {
      return [
        {
          icon: 'home',
          name: 'Home',
          url: {
            name: 'home',
          }
        },
        {
          icon: 'post',
          name: 'Publicação',
          url: {
            name: 'publicationDetails',
          },
          children: [
            {
              icon: 'clipboard-list-outline',
              name: 'Plataforma',
              url: {
                name: 'publicationDetails',
              },
            },
            {
              icon: 'clock-alert-outline',
              name: 'Pesquisa',
              url: {
                name: 'publicationSetupValidate',
              },
            },
            {
              icon: 'facebook',
              name: 'Facebook',
              url: {
                name: 'publicationFacebookDetails',
              },
            },
          ],
        },
        {
          icon: 'history',
          name: 'Histórico',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'facebook',
              name: 'Conexão de página',
              url: {
                name: 'pageHistory',
              },
            },
            {
              icon: 'earth',
              name: 'Pesquisa',
              url: {
                name: 'universeHistory',
              },
            },

            {
              icon: 'alert-circle',
              name: 'Falha de Autenticação',
              url: {
                name: 'page-auth-error',
              },
            },
            {
              icon: 'pencil',
              name: 'Anotação',
              url: {
                name: 'annotationsHistory',
              }
            },
            {
              icon: 'radar',
              name: 'Ações',
              url: {
                name: 'actionsHistory',
              }
            }
          ],
        },
        {
          icon: 'account-check',
          name: 'Ad Account',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'instagram',
              name: 'Instagram',
              url: {
                name: 'instagramAdAccount',
              },
            },
            {
              icon: 'linkedin',
              name: 'Linkedin',
              url: {
                name: 'linkedinAdAccount',
              },
            }
          ],
        },
        {
          icon: 'cash',
          name: 'Dark Post',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'instagram',
              name: 'Instagram',
              url: {
                name: 'darkPostValidate',
              },
            },
          ],
        },
        {
          icon: 'timetable',
          name: 'Retroativo',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'timer-sand',
              name: 'Lista',
              url: {
                name: 'retroactiveList',
              },
            },
            {
              icon: 'plus-circle-outline',
              name: 'Novo',
              url: {
                name: 'retroactiveNew',
              },
            },
            {
              icon: 'plus-circle-multiple-outline',
              name: 'Em Massa',
              url: {
                name: 'retroactiveNewMass',
              },
            },
          ],
        },
        {
          icon: 'account-clock',
          name: 'Retroativo Proprietário',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'timer-sand',
              name: 'Lista',
              url: {
                name: 'proprietaryRetroactiveList',
              },
            },
            {
              icon: 'instagram',
              name: 'Lista DM intagram',
              url: {
                name: 'inboxRetroactiveList',
              },
            },
            {
              icon: 'plus-circle-outline',
              name: 'Novo',
              url: {
                name: 'proprietaryRetroactiveNew',
              },
            }
          ],
        },
        {
          icon: 'monitor-dashboard',
          name: 'Monitoramento',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'hand-heart',
              name: 'Coleta Proprietária',
              url: {
                name: 'crawlingHealth',
              },
            }
          ],
        },
        {
          icon: 'file-document-edit',
          name: 'Reprocessamento',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'timer-sand',
              name: 'Lista',
              url: {
                name: 'reprocessingList',
              }
            },
            {
              icon: 'plus-circle-outline',
              name: 'Novo',
              url: {
                name: 'reprocessingNew',
              }
            }
          ],
        },
        {
          icon: 'chart-box-outline',
          name: 'Benchmarks',
          url: {
            name: 'benchmarksSearch',
          },
          children: [
            {
              icon: 'magnify',
              name: 'Consultar',
              url: {
                name: 'benchmarksSearch',
              },
            },
            (isRunningProductionEnvironment ? null : {
              icon: 'plus-circle-outline',
              name: 'Novo Benchmarks',
              url: {
                name: 'newBenchmarks',
              }
            }),
            {
              icon: 'pencil',
              name: 'Editar',
              url: {
                name: 'benchmarksEdit',
              },
            }
          ].filter(Boolean),
        },
        {
          icon: 'pencil-box-multiple',
          name: 'Alteração de status',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'pencil',
              name: 'Alterar',
              url: {
                name: 'multiple-edition',
              },
            },
            {
              icon: 'history',
              name: 'Histórico',
              url: {
                name: 'multiple-edition-history',
              },
            }
          ],
        },
        {
          icon: 'account-clock',
          name: 'Roteamento',
          url: {
            name: 'router-search',
          }
        },
        {
          icon: 'desktop-mac-dashboard',
          name: 'Pesquisa',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'view-dashboard',
              name: 'Visão Geral',
              url: {
                name: 'uniOverview',
              },
            },
            {
              icon: 'file-sync-outline',
              name: 'Limite de publicações',
              url: {
                name: 'universeLimit',
              },
            },
            {
              icon: 'twitter',
              name: 'Retweet',
              url: {
                name: 'processRetweet',
              },
            },
            {
              icon: 'translate',
              name: 'Idiomas',
              url: {
                name: 'addLanguage',
              },
            },
            {
              icon: 'plus',
              name: 'Publicação',
              url: {
                name: 'PostInsertion',
              },
            },
            {
              icon: 'file-document-edit',
              name: 'Reprocessamentos',
              url: {
                name: 'reprocessHistory',
              },
            },
          ],
        },
        {
          icon: 'account',
          name: 'Usuário',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'format-quote-close-outline',
              name: 'Bablic',
              url: {
                name: 'actionBablic',
              },
            }
          ],
        },
        {
          icon: 'timer-sand',
          name: 'SLA',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'post',
              name: 'Coleta',
              url: {
                name: 'proprietaryCrawler',
              },
            }
          ],
        },
        {
          icon: 'login',
          name: 'SSO',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'plus-circle-outline',
              name: 'Configuração',
              url: {
                name: 'configuration',
              }
            },
            {
              icon: 'timer-sand',
              name: 'Status',
              url: {
                name: 'status',
              }
            }
          ],
        },
        {
          icon: 'chart-areaspline',
          name: 'SNLP',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'magnify',
              name: 'Consultar',
              url: {
                name: 'snlpLinechart',
              }
            }
          ]
        },
        {
          icon: 'group',
          name: 'Usergroups',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'pencil',
              name: 'Alteração de POD',
              url: {
                name: 'podUpdate',
              },
            },
            {
              icon: 'plus-circle-outline',
              name: 'Novo',
              url: {
                name: 'usergroupNew',
              },
            },
            {
              icon: 'cash',
              name: 'Créditos',
              url: {
                name: 'creditsByUsergroup',
              },
            }
          ],
        },
        {
          icon: 'eye',
          name: 'Eyes',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'timer-sand',
              name: 'Lista Eyes',
              url: {
                name: 'eyesActivationAndList',
              }
            },
          ],
        },
        {
          icon: 'cash-usd',
          name: 'Liberações',
          url: {
            name: 'home',
          },
          children: [
            {
              icon: 'cash-usd',
              name: 'Liberações',
              url: {
                name: 'liberation',
              }
            },
          ],
        },
      ];
    }
  }
};
