var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "crawling-health" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c("div", { staticClass: "crawling-health__form-and-result" }, [
        _c(
          "div",
          [
            _c(
              "v-form",
              {
                ref: "crawling-health-form",
                staticClass: "crawling-health__form mb-10",
                attrs: { "lazy-validation": false },
                model: {
                  value: _vm.valid,
                  callback: function ($$v) {
                    _vm.valid = $$v
                  },
                  expression: "valid",
                },
              },
              [
                _c("SingleSelect", {
                  attrs: {
                    required: "",
                    items: _vm.accounts,
                    placeholder: _vm.labels.account,
                    text: "name",
                    "sub-text": "group",
                  },
                  model: {
                    value: _vm.selectedAccount,
                    callback: function ($$v) {
                      _vm.selectedAccount = $$v
                    },
                    expression: "selectedAccount",
                  },
                }),
                _vm.activeAccount
                  ? _c("SingleSelect", {
                      attrs: {
                        items: _vm.universes,
                        placeholder: _vm.labels.universe,
                        text: "name",
                        "sub-text": "channel",
                      },
                      model: {
                        value: _vm.selectedUniverse,
                        callback: function ($$v) {
                          _vm.selectedUniverse = $$v
                        },
                        expression: "selectedUniverse",
                      },
                    })
                  : _vm._e(),
                _c("StDatePicker", {
                  attrs: { range: "" },
                  on: { input: _vm.validFormData },
                  model: {
                    value: _vm.models.dates,
                    callback: function ($$v) {
                      _vm.$set(_vm.models, "dates", $$v)
                    },
                    expression: "models.dates",
                  },
                }),
                _c("v-text-field", {
                  attrs: {
                    required: "",
                    rules: _vm.rules.taskId,
                    placeholder: _vm.labels.task,
                  },
                  model: {
                    value: _vm.models.task,
                    callback: function ($$v) {
                      _vm.$set(_vm.models, "task", $$v)
                    },
                    expression: "models.task",
                  },
                }),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-4",
                    attrs: {
                      disabled:
                        !_vm.valid || !_vm.validFormData() || _vm.loading,
                      color: "light-blue",
                    },
                    on: { click: _vm.loadCrawlingHealthData },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.labels.getDataButton) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm.error || _vm.errorToLoadData
              ? _c(
                  "v-alert",
                  {
                    attrs: {
                      border: "right",
                      "colored-border": "",
                      type: "error",
                      elevation: "2",
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.labels.errorMessage) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.chart.crawlingHealthData
          ? _c("div", { staticClass: "crawling-health__chart" }, [
              _c("h3", { staticClass: "crawling-health__showing-charts-for" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.showingChartsFor) + "\n      "
                ),
              ]),
              _c(
                "div",
                { staticClass: "mt-15 mb-15" },
                [
                  _c("Chart", {
                    attrs: {
                      options: _vm.crawlingHealthChartConfig.byAmountOfPosts,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-15 mb-15" },
                [
                  _c("Chart", {
                    attrs: {
                      options:
                        _vm.crawlingHealthChartConfig.byAverageDelayInHours,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }