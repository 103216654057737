<template>
  <StLogin
    :loading="loading"
    :i18n="i18n"
    @login="login"
  />
</template>

<script>
import StLogin from '@stilingue/st-login';
import has from 'lodash.has';
import { mapActions } from 'vuex';

export default {
  name: 'LoginView',
  components: {
    StLogin
  },
  data() {
    return {
      loading: false,
      i18n: {
        email: this.$t('LoginView.email'),
        password: this.$t('LoginView.password'),
        enter: this.$t('LoginView.enter'),
        emailIsRequired: this.$t('LoginView.emailIsRequired'),
        emailIsInvalid: this.$t('LoginView.emailIsInvalid'),
        passwordIsRequired: this.$t('LoginView.passwordIsRequired')
      },
      genericErrorMsg: this.$t('LoginView.genericErrorMsg'),
      specificErrors: {
        'Not Found': this.$t('LoginView.notFound'),
        'Unauthorized': this.$t('LoginView.Unauthorized'),
        'Forbidden': this.$t('LoginView.Forbidden'),
        'No Token Received': this.$t('LoginView.genericErrorMsg')
      }
    };
  },
  methods: {
    ...mapActions('user', [
      'makeLoginRequest'
    ]),
    login({ username, password }) {
      this.loading = true;

      if (!(username.endsWith('@stilingue.com.br') || username.endsWith('@blip.ai'))) { //Verificação de domínio
        this.handleError({ response: { data: { error: 'Forbidden' } } });
        this.loading = false;
        return;
      }

      this
        .makeLoginRequest({
          username,
          password
        })
        .then(this.handleLoginSuccess)
        .catch(this.handleError)
        .finally(() => {
          this.loading = false;
        });
    },
    async handleLoginSuccess() {
      window.location.href = '/';
    },
    handleError(data) {
      console.warn(data);

      let errorMsg = this.genericErrorMsg;
      if (has(data, 'response.data.error') && data.response.data.error in this.specificErrors) {
        errorMsg = this.specificErrors[data.response.data.error];
      }

      this.$noty.error(errorMsg);

      return errorMsg;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
