<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <v-row class="selector-container">
      <v-col cols="5">
        <v-form
          ref="fb-postDetailsForm"
          v-model="valid"
          class="fb-post-details__form"
          :lazy-validation="false"
        >
          <v-text-field
            v-model="pageId"
            required
            :rules="isNumber"
            label="ID da página"
            @keydown="post=null"
          />
          <v-text-field
            v-model="postId"
            required
            :rules="isNumber"
            label="ID da publicação"
            @keydown="post=null"
          />
          <v-btn
            :disabled="!valid"
            color="light-blue"
            class="mr-4"
            @click="loadFbPostInfo"
          >
            Visualizar informações da publicação
          </v-btn>
        </v-form>
      </v-col>
      <v-col cols="7">
        <StLoader :loading="loading" />
        <v-card v-if="post">
          <v-list v-if="post.text">
            <v-subheader>Conteúdo</v-subheader>
            <v-list-item>
              <v-list-item-content>
                {{ post.text }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider />
          <v-list
            subheader
            two-line
            class="fb-post-details__details-list"
          >
            <v-subheader>Detalhes</v-subheader>
            <v-list-item
              v-for="(item, index) in postDetails"
              :key="index"
            >
              <StIcon :icon-name="item.icon" />
              {{ item.label }}
              <v-chip label>
                {{ item.value }}
              </v-chip>
            </v-list-item>
          </v-list>
        </v-card>
        <v-alert
          v-if="postNotFound"
          type="warning"
        >
          Publicação inválida.
        </v-alert>
        <v-alert
          v-if="error"
          type="error"
        >
          Erro inesperado ao tentar buscar pela publicação. Por favor, entre em contato com o N2.
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminToolsService from '../services/AdminToolsService';
import StIcon from '@stilingue/st-icon/StIcon';
import StLoader from '@stilingue/st-loader';
import PageHeader from '@/components/base/PageHeader';
import moment from 'moment';

export default {
  name: 'FbPostDetails',
  components: {
    StIcon,
    PageHeader,
    StLoader
  },
  data: () => ({
    labels: {
      postedAt: 'Publicado em: ',
      likes: 'Curtidas: ',
      reactions: 'Reações: ',
      aggregatedCommentsTotal: 'Total de comentários:',
      topLevelComments: 'Comentários pai:',
      title: 'Detalhes no Facebook',
      description: 'Permite conferência dos detalhes da publicação proprietária no Facebook.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Publicação',
          to: '/publications'
        },
        {
          text: 'Facebook',
          to: '/publications/facebook-details'
        }
      ]
    },
    icons: {
      postedAt: 'clock-time-eight',
      likes: 'thumb-up',
      reactions: 'hand-heart',
      aggregatedCommentsTotal: 'message',
      topLevelComments: 'message-bulleted',
      exists: 'check',
    },
    pageId: '',
    postId: '',
    valid: true,
    error: false,
    loading: false,
    post: undefined,
    postDetails: [],
    isNumber: [v => (v && !isNaN(v)) || 'Este campo deve ser preenchido com um ID.'],
  }),
  computed: {
    formattedPostedAt() {
      return moment(this.post.postedAt).format('DD/MM/YYYY hh:mm:ss');
    },
    existsLabel() {
      return this.post.exists ? 'Sim' : 'Não';
    }
  },
  methods: {
    async loadFbPostInfo() {
      this.loading = true;
      AdminToolsService.getFbPostInfo({
        pageId: this.pageId,
        postId: this.postId
      }).then(data => {
        this.loading = false;
        this.post = data;
        this.postDetails = [];
        Object.keys(data).forEach(key => {
          let value = data[key];
          const [label, icon] = [this.labels[key], this.icons[key]];

          value = key === 'postedAt' ? this.formattedPostedAt : value;
          value = key === 'exists' ? this.existsLabel : value;
          if (key === 'text') return;
          this.postDetails.push({ value, label, icon });
        });
      }).catch(error => {
        this.loading = false;
        if (error.response.status === 404) {
          this.postNotFound = true;
          return;
        }
        this.error = true;
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    reset() {
      this.$refs.form.reset();
    },
  }
};
</script>

<style scoped lang="scss">
  .selector-container {
    width: 1200px;
  }

  .fb-post-details__form-and-result {
    display: flex;
    flex-direction: row;
    min-width: 400px;
  }

  .fb-post-details__form {
    min-width: 325px;
  }

  .fb-post-details {
    align-content: center;
    width: 100%;
  }

  .fb-post-info {
    margin-left: 200px;
    min-width: 425px;
    max-width: 425px;
  }

  .fb-post-details__details-list {
    .v-icon {
      font-size: 19px;
      margin-right: 10px;
    }

    .v-chip {
      margin-left: 10px;
      height: 23px;
    }

    .v-list-item {
      min-height: 38px;
    }
  }
</style>
