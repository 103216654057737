<template>
  <div class="content-body">
    <div>
      <v-alert
        v-if="showAlert"
        closable
        border="right"
        colored-border
        :type="labels.alertType"
        elevation="2"
      >
        {{ labels.alertMessage }}
      </v-alert>
    </div>
    <PageHeader
      :title="labels.headerInfo.title"
      :description="labels.headerInfo.description"
      :breadcrumbs="labels.headerInfo.breadcrumbs"
    />
    <section class="selector-container">
      <v-form
        ref="snlpForm"
        v-model="models.valid"
        :lazy-validation="false"
      >
        <v-textarea
          v-model="formData.polarityPhrase"
          required
          :rules="rules.isNotEmpty"
          :label="labels.phrase"
        />
        <v-btn
          :disabled="!models.valid"
          color="light-blue"
          class="mr-4"
          @click="searchPhrase"
        >
          {{ labels.search }}
        </v-btn>
      </v-form>
      <section
        v-if="formData.chartData.active"
        class="line-chart"
      >
        <div class="polarity-icons">
          <v-list-item-icon class="mr-4">
            <StIcon
              class="single-icon"
              :icon-name="icons.sad.name"
              :color="icons.sad.color"
            />
            <StIcon
              class="single-icon"
              :icon-name="icons.neutral.name"
              :color="icons.neutral.color"
            />
            <StIcon
              class="single-icon"
              :icon-name="icons.happy.name"
              :color="icons.happy.color"
            />
          </v-list-item-icon>
        </div>
        <v-switch
          v-model="models.polarityMode"
          class="switch-button"
          :input-value="models.polarityMode"
          inset
          @change="changeLineChartLabelsMode()"
        >
          <template v-slot:label>
            {{ labels.highlight }}  {{ labels.highlightType }}
          </template>
        </v-switch>

        <VueApexCharts
          ref="realtimeChart"
          height="300"
          :options="formData.chartData.options"
          :series="formData.chartData.series"
          :loading="loading"
        />
      </section>
      <StLoader :loading="loading" />
      <v-divider />
      <v-data-table
        :headers="table.headers"
        :items="table.items"
      />
    </section>
  </div>
</template>

<script>

import PageHeader from '@/components/base/PageHeader';
import SnlpService from '@/services/SnlpService';
import StLoader from '@stilingue/st-loader';
import VueApexCharts from 'vue-apexcharts';
import snlpComponents from '@/utils/snlp-utils';
import StIcon from '@stilingue/st-icon/StIcon';

export default {
  name: 'PolaritySearch',
  components: {
    PageHeader, StLoader, VueApexCharts, StIcon
  },
  data: () => ({
    showAlert: false,
    labels: {
      alertMessage: 'Um comportamento inesperado aconteceu 😰! Contate o suporte! Ops, você é o suporte neh😱 ?!?',
      alertType: 'error',
      phrase: 'Frase a ser analisada',
      search: 'Search',
      highlight: 'Destacando por: ',
      highlightType: 'Polaridade',
      headerInfo:
      {
        title: 'Análise do gráfico de polaridade de comentários',
        description: '',
        breadcrumbs: [
          {
            text: 'Home',
            href: '/home'
          },
          {
            text: 'Snlp',
            to: '/snlp'
          },
          {
            text: 'Consultar',
            to: '/snlp/snlp-linechart'
          }
        ]
      }
    },
    icons: {
      sad: {
        name: 'emoticon-sad',
        color: 'white',
      },
      neutral: {
        name: 'emoticon-neutral',
        color: 'white',
      },
      happy: {
        name: 'emoticon-happy',
        color: 'white',
      },
    },
    table: {
      headers: [
        {
          text: 'Palavra Digitada',
          value: 'word',
          align: 'center'
        },
        {
          text: 'Palavra Processada',
          value: 'normalized',
          align: 'center'
        },
        {
          text: 'Termos',
          value: 'lemmas',
          align: 'center'
        },
        {
          text: 'Sentimento',
          value: 'polarity',
          align: 'center'
        },
      ],
      'must-sort': true,
      items: [],
    },
    models: {
      valid: false,
      polarityMode: true,
    },
    formData: {
      polarityPhrase: '',
      documentPolarity: '',
      normalizedPhrase: '',
      processedData: {},
      chartData: {
        active: false,
        options: {
          chart: {
            id: 'polarity-chart',
            type: 'line',
          },
          stroke: {
            curve: 'stepline',
          },
          markers: {
            size: 5,
          },
          grid: {
            position: 'back',
            borderColor: '#232323',
          },
          xaxis: {
            categories: [''],
            tickPlacement: 'between',
            labels: {
              style: {
                colors: [],
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: '#ffffff',
              }
            },
          },
          tooltip: {
            theme: 'dark',
          },
        }, //options
        series: [{
          name: 'Polaridade',
          data: [],
        }], //series
      }, //chartData
    }, //formData
    rules: {
      isNotEmpty: [v => (v && v.length) > 0 || 'Este campo deve ser preenchido'],
    },
    loading: false,
  }),
  methods: {
    updateLineChart() {
      this.$refs.realtimeChart.updateOptions({
        xaxis: {
          categories: this.formData.chartData.options.xaxis.categories,
        },
      }, false, true);

      this.$refs.realtimeChart.updateSeries(this.formData.chartData.series, true);
    },
    async searchPhrase() {
      this.loading = true;
      this.showAlert = false;
      this.formData.chartData.active = false;
      await SnlpService.getPolarity(this.formData.polarityPhrase).then(resp => {
        const processedData = snlpComponents.processData(resp);
        this.formData.chartData.options.xaxis.categories = processedData.wordsList;
        this.formData.chartData.series[0].data = processedData.polarityList;
        this.formData.chartData.documentPolarity = processedData.snlpData.documentPolarity;
        this.formData.chartData.normalizedPhrase = processedData.snlpData.normalized;
        this.formData.chartData.options.xaxis.labels.style.colors = !this.models.polarityMode ? processedData.colors : processedData.polarity_colors;
        this.table.items = processedData.log;
        this.formData.processedData = processedData;
        this.loading = false;
        this.setPolarity();
        this.formData.chartData.active = true;
      }).then(() => {
        this.updateLineChart();
      }).catch(e => {
        this.showAlert = true;
        console.error(e);
        this.loading = false;
      });
    },
    async changeLineChartLabelsMode() {
      this.formData.chartData.active = false;
      this.formData.chartData.options.xaxis.labels.style.colors = !this.models.polarityMode ? this.formData.processedData.colors : this.formData.processedData.polarity_colors;
      this.labels.highlightType = !this.models.polarityMode ? 'Possíveis erros' : 'Polaridade';
      await this.delay(1);
      this.formData.chartData.active = true;
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    setPolarity() {
      this.icons.sad.color = 'white';
      this.icons.neutral.color = 'white';
      this.icons.happy.color = 'white';

      const polarity = this.formData.chartData.documentPolarity;

      if (!polarity) {
        this.icons.neutral.color = 'yellow';
      } else if (polarity < 0) {
        this.icons.sad.color = 'red';
      } else {
        this.icons.happy.color = 'green';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content-body {
  width: 80%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}

.line-chart {
  background-color: #1e1e1e;
}

.v-form {
  padding-bottom: 25px;
}

.switch-button {
  padding-left: 20px;
}

.polarity-icons {
  padding-left: 40%;
}

.single-icon {
  padding-left: 50px;
}

</style>
