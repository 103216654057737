import { MUTATE, MUTATE_MULTIPLE } from '@/store/modules/mutation_types';
import WarroomService from '@/services/WarroomService';
import moment from 'moment';

const { UniversesService, UniverseHistoryService, GroupsService } = WarroomService;

export function getAccountUniverses({ state, commit }, { selectedAccount, fetchHistory = false }) {
  commit(MUTATE_MULTIPLE, [{
    property: 'loadingHistory',
    with: true,
  }, {
    property: 'historyIsLoaded',
    with: false,
  }]);

  commit(MUTATE, {
    property: 'requestStatus',
    with: 'loading',
  });

  const { id: accountId } = selectedAccount;

  return UniversesService.find({ url: `/${accountId}` })
    .then(data => {
      const { message = [] } = data;

      commit(MUTATE_MULTIPLE, [
        {
          property: 'requestStatus',
          with: 'success',
        }, {
          property: 'universes',
          with: message,
        },
      ]);
      if (message.length >= 1) {
        commit(MUTATE_MULTIPLE, [{
          property: 'activeUniverse',
          with: message[0],
        }]);

        if (fetchHistory) {
          getUniverseHistory({ state, commit });
        }
      }

      return Promise.resolve(message);
    }).catch((e) => {
      console.error(e);
      commit(MUTATE, {
        property: 'requestStatus',
        with: 'error',
      });
    });
}

export function getGroupsByUniverse({ commit }, universeId) {
  GroupsService.find({ url: `/${universeId}` })
    .then(data => {
      const { message = { groups: [] } } = data;
      if (message && message.groups.length) {
        commit(MUTATE, {
          property: 'groups',
          with: message.groups,
        });
      }
      return Promise.resolve(message.groups);
    }).catch(e => {
      console.error(e);
      return Promise.resolve([]);
    });
}

export function setUniverse({ commit }, { selectedUniverse }) {
  commit(MUTATE_MULTIPLE, [{
    property: 'activeUniverse',
    with: selectedUniverse,
  }, {
    property: 'loadingHistory',
    with: true,
  }, {
    property: 'historyIsLoaded',
    with: false,
  }]);
}

export function unsetUniverse({ commit }) {
  commit(MUTATE, {
    property: 'activeUniverse',
    with: null,
  });
}

export function getUniverseHistory({ state, commit }) {
  commit(MUTATE, {
    property: 'requestStatus',
    with: 'loading',
  });

  const { id: universeId } = state.activeUniverse;

  return UniverseHistoryService.find({ url: '', params: { uni: universeId, limit: 100 } })
    .then(data => {
      const { message = [] } = data;
      message.map(item => {
        item.created_at = moment(item.created_at).add(-3, 'hour').format('DD/MM/YYYY HH:mm:ss');
        const newItem = { ...item };
        return newItem;
      });
      commit(MUTATE_MULTIPLE, [
        {
          property: 'requestStatus',
          with: 'success',
        }, {
          property: 'history',
          with: message,
        }, {
          property: 'loadingHistory',
          with: false,
        }, {
          property: 'historyIsLoaded',
          with: true,
        },
      ]);

      return Promise.resolve(message);
    }).catch((e) => {
      console.error(e);
      commit(MUTATE_MULTIPLE, [{
        property: 'requestStatus',
        with: 'error',
      }, {
        property: 'historyIsLoaded',
        with: false,
      }, {
        property: 'loadingHistory',
        with: false,
      }]);
    });
}
