import StService from '@/services/StService';
import { createApi } from '@stilingue/st-auth-utils';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import { StagingUrl, ProductionUrl } from './config';

const gateway = isProdVersion()
  ? ProductionUrl
  : StagingUrl;

const afterParser = response => response.data;

class ActionHistoryService extends StService {
  constructor(gateway) {
    const api = createApi('', gateway);
    super(api, { afterParser });
  }

  async getActionsHistory(params = {}) {
    let url = `/actionhistory/read_action/${params.accountId}?action=${params.action}`;
    if (params.universeId) {
      url = `${url}&universe_id=${params.universeId}`;
    }
    return this.api.get({ url }).then(this.afterParser);
  }
}

export default new ActionHistoryService(gateway);
