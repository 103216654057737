var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-retroactive-prorietary" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "div",
        { staticClass: "warning" },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-card-text", [
                _c("span", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.labels.documentation) +
                      "\n          "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://stilingue-jira.atlassian.net/wiki/spaces/CDS/pages/553123880/Aumentar+limite+de+tempo+e+quantidade+para+coleta+de+m+tricas+propriet+rias",
                      },
                    },
                    [_vm._v("documentação\n          ")]
                  ),
                ]),
                _vm._v(".\n      "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "new-retroactive-prorietary__form-and-result" },
        [
          _c("StLoader", { attrs: { loading: _vm.loading } }),
          _c(
            "v-radio-group",
            {
              on: {
                change: function ($event) {
                  return _vm.getAccountPages({
                    selectedAccount: _vm.selectedAccount,
                  })
                },
              },
              model: {
                value: _vm.crawlerOrItServices,
                callback: function ($$v) {
                  _vm.crawlerOrItServices = $$v
                },
                expression: "crawlerOrItServices",
              },
            },
            [
              _c(
                "div",
                { staticClass: "new-retroactive__crawler-or-it-services" },
                [
                  _c("v-radio", {
                    attrs: { label: _vm.labels.crawler, value: "crawler" },
                    on: { change: _vm.clearAll },
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.labels.itServices,
                      value: "itServices",
                    },
                    on: { change: _vm.clearAll },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("SingleSelect", {
            attrs: {
              required: "",
              items: _vm.accounts,
              placeholder: _vm.labels.account,
              rules: _vm.rules.isValue,
              text: "name",
              "sub-text": "group",
            },
            on: { change: _vm.clearAll },
            model: {
              value: _vm.selectedAccount,
              callback: function ($$v) {
                _vm.selectedAccount = $$v
              },
              expression: "selectedAccount",
            },
          }),
          _c(
            "v-form",
            {
              ref: "form",
              staticClass: "new-retroactive-prorietary__form",
              attrs: { "lazy-validation": false },
              model: {
                value: _vm.models.valid,
                callback: function ($$v) {
                  _vm.$set(_vm.models, "valid", $$v)
                },
                expression: "models.valid",
              },
            },
            [
              _vm.activeAccount
                ? _c("PageSelection", {
                    attrs: { items: _vm.pages, required: "" },
                    model: {
                      value: _vm.activePages,
                      callback: function ($$v) {
                        _vm.activePages = $$v
                      },
                      expression: "activePages",
                    },
                  })
                : _vm._e(),
              _c("v-text-field", {
                attrs: {
                  required: "",
                  name: Math.random(),
                  rules: _vm.rules.isTask,
                  label: _vm.labels.task,
                },
                on: { input: _vm.setErrorFalse },
                model: {
                  value: _vm.models.task,
                  callback: function ($$v) {
                    _vm.$set(_vm.models, "task", $$v)
                  },
                  expression: "models.task",
                },
              }),
              [
                _c(
                  "v-menu",
                  {
                    attrs: {
                      "close-on-content-click": false,
                      "nudge-right": 40,
                      transition: "scale-transition",
                      "offset-y": "",
                      "min-width": "auto",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-text-field",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      label: _vm.labels.inputs.executionDate,
                                      "prepend-icon": "mdi-calendar",
                                      readonly: "",
                                    },
                                    model: {
                                      value: _vm.date,
                                      callback: function ($$v) {
                                        _vm.date = $$v
                                      },
                                      expression: "date",
                                    },
                                  },
                                  "v-text-field",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.modal,
                      callback: function ($$v) {
                        _vm.modal = $$v
                      },
                      expression: "modal",
                    },
                  },
                  [
                    _c("v-date-picker", {
                      attrs: { max: _vm.today },
                      on: {
                        input: function ($event) {
                          _vm.modal = false
                        },
                      },
                      model: {
                        value: _vm.date,
                        callback: function ($$v) {
                          _vm.date = $$v
                        },
                        expression: "date",
                      },
                    }),
                  ],
                  1
                ),
              ],
              _c(
                "v-expansion-panels",
                { attrs: { accordion: "" } },
                [
                  _c(
                    "v-expansion-panel",
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.labels.advancedOptions) +
                            "\n          "
                        ),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [_c("v-divider", { staticClass: "mt-2" })],
                        1
                      ),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c(
                            "div",
                            { staticClass: "max-posts" },
                            [
                              _c(
                                "div",
                                { staticClass: "max-posts-input" },
                                [
                                  _c("v-icon", { attrs: { color: "yellow" } }, [
                                    _vm._v(
                                      "\n                  mdi-alert-circle\n                "
                                    ),
                                  ]),
                                  !_vm.crawlerService
                                    ? _c("v-text-field", {
                                        attrs: {
                                          hint: "Apenas números",
                                          type: "number",
                                          label: _vm.labels.maxPosts,
                                        },
                                        on: {
                                          input: _vm.setErrorFalse,
                                          focus: _vm.showMaxPostsAlert,
                                          blur: _vm.hideMaxPostsAlert,
                                        },
                                        model: {
                                          value: _vm.models.maxPosts,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.models,
                                              "maxPosts",
                                              $$v
                                            )
                                          },
                                          expression: "models.maxPosts",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              !_vm.isDisabled
                                ? _c(
                                    "v-card",
                                    [
                                      _c("v-card-text", [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.labels.maxPostsAlert) +
                                            "\n                "
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          !_vm.crawlerService
                            ? _vm._l(_vm.channels, function (item) {
                                return _c("MultipleSelect", {
                                  key: item.channelName,
                                  class: { disabled: item.isDisabled },
                                  attrs: {
                                    items: item.flowTypes,
                                    label: item.label,
                                    text: item.flowTypes,
                                    chips: "",
                                  },
                                  on: { input: _vm.setErrorFalse },
                                  model: {
                                    value: item.activeFlowTypes,
                                    callback: function ($$v) {
                                      _vm.$set(item, "activeFlowTypes", $$v)
                                    },
                                    expression: "item.activeFlowTypes",
                                  },
                                })
                              })
                            : _vm._l(_vm.channels, function (item) {
                                return _c("MultipleSelect", {
                                  key: item.channelName,
                                  class: { disabled: item.isDisabled },
                                  attrs: {
                                    items: item.crawlerFlowTypes,
                                    label: item.label,
                                    text: item.crawlerFlowTypes,
                                    chips: "",
                                  },
                                  on: { input: _vm.setErrorFalse },
                                  model: {
                                    value: item.activeFlowTypes,
                                    callback: function ($$v) {
                                      _vm.$set(item, "activeFlowTypes", $$v)
                                    },
                                    expression: "item.activeFlowTypes",
                                  },
                                })
                              }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.crawlerService
                ? _c("v-select", {
                    attrs: {
                      items: _vm.state,
                      label: _vm.labels.state,
                      rules: _vm.rules.isValue,
                      required: "",
                    },
                    on: { input: _vm.setErrorFalse },
                    model: {
                      value: _vm.models.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.models, "state", $$v)
                      },
                      expression: "models.state",
                    },
                  })
                : _vm._e(),
              _vm._v("\n      " + _vm._s(_vm.showActiveFlowTypes()) + "\n    "),
            ],
            2
          ),
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: {
                disabled: !_vm.models.valid || _vm.loading,
                color: "primary",
              },
              on: { click: _vm.startNewRetroactive },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.labels.startRetroactive) + "\n    "
              ),
            ]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mr-4",
              attrs: { disabled: _vm.loading, color: "secondary" },
              on: { click: _vm.clearAll },
            },
            [_vm._v("\n      " + _vm._s(_vm.labels.clearData) + "\n    ")]
          ),
          _vm.error
            ? _c(
                "v-alert",
                {
                  attrs: {
                    border: "right",
                    "colored-border": "",
                    type: "error",
                    elevation: "2",
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.labels.errorMessage) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._l(_vm.failedPageIdsAndErrors, function (page) {
            return _c(
              "v-alert",
              {
                key: page,
                attrs: {
                  border: "right",
                  "colored-border": "",
                  type: "error",
                  elevation: "2",
                },
              },
              [_vm._v("\n      " + _vm._s(page) + "\n    ")]
            )
          }),
          _vm.success
            ? _c(
                "v-alert",
                {
                  attrs: {
                    border: "right",
                    "colored-border": "",
                    type: "success",
                    elevation: "2",
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.labels.successResponse) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._l(_vm.succeededPageIds, function (page) {
            return _c(
              "v-alert",
              {
                key: page,
                attrs: {
                  border: "right",
                  "colored-border": "",
                  type: "success",
                  elevation: "2",
                },
              },
              [_vm._v("\n      " + _vm._s(page) + "\n    ")]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }