<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <section
      v-show="true"
      class="selector-container"
    >
      <v-form
        ref="processRetweetForm"
        v-model="models.valid"
        :lazy-validation="false"
      >
        <SingleSelect
          v-model="selectedAccount"
          required
          :items="accounts"
          placeholder="Selecione uma Conta"
          text="name"
          sub-text="group"
        />
        <SingleSelect
          v-if="activeAccount"
          v-model="selectedUniverse"
          :items="universes"
          placeholder="Selecione uma Pesquisa"
          text="name"
          sub-text=""
        />
        <v-radio-group v-model="models.actionType">
          <div class="action-type">
            <v-radio
              label="Ativar"
              value="Ativar"
            />
            <v-radio
              label="Desativar"
              value="Desativar"
            />
          </div>
        </v-radio-group>
        <v-btn
          :disabled="!models.actionType ||loading"
          color="light-blue"
          class="mr-4"
          @click="actionProcessRetweet"
        >
          Salvar alteração
        </v-btn>
        <v-btn
          class="mr-4"
          @click="$router.go(-1)"
        >
          Voltar
        </v-btn>
      </v-form>
      <StLoader :loading="loading" />
      <v-alert
        v-if="success"
        border="right"
        colored-border
        type="success"
        elevation="2"
      >
        {{ labels.successMessage }}.
      </v-alert>
      <v-alert
        v-if="error"
        border="right"
        colored-border
        type="error"
        elevation="2"
      >
        {{ labels.errorMessage }}.
      </v-alert>
      <v-alert
        v-if="info"
        border="right"
        colored-border
        type="info"
        elevation="2"
      >
        {{ labels.infoMessage }}
      </v-alert>
    </section>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import SingleSelect from '../SingleSelect.vue';
import StLoader from '@stilingue/st-loader';
import PageHeader from '@/components/base/PageHeader';
import UniverseService from '@/services/DataStoreService/UniverseService';

export default {
  name: 'ProcessRetweet',
  components: {
    SingleSelect, PageHeader, StLoader
  },
  data: () => ({
    labels: {
      title: 'Retweet',
      description: 'Permite ativar/desativar o coleta de retweet na pesquisa.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Pesquisa',
          to: '/universe'
        },
        {
          text: 'Retweet',
          to: '/universe/retweet'
        }
      ],
      successMessage: null,
      errorMessage: null,
      infoMessage: null,
    },
    models: {
      valid: false,
      actionType: '',
    },
    error: false,
    success: false,
    loading: false,
    info: false
  }),
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    ...mapState('universe', ['universes', 'activeUniverse']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      },
      set(selectedUniverse) {
        this.selectUniverse(selectedUniverse);
      },
    },
    userId: {
      get() {
        return this.user.id;
      }
    }
  },
  async created() {
    await this.getAccounts({ userId: this.userId });
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount, this.selectedUniverse);
    }
    if (this.$route.query.action) {
      this.models.actionType = this.$route.query.action === 'true' ? 'Ativar' : 'Desativar' ;
    }
  },
  methods: {
    setErrorFalse() {
      this.error = false;
    },
    ...mapActions('account', ['getAccounts', 'setAccount']),
    ...mapActions('universe', ['getAccountUniverses', 'setUniverse']),
    ...mapMutations('account', {
      mutate: 'MUTATE',
    }),
    selectAccount(selectedAccount, selectedUniverse) {
      this.setAccount({ selectedAccount });
      this.getAccountUniverses({ selectedAccount, fetchHistory: false }).then(() => {
        if (selectedUniverse) {
          this.selectedUniverse = selectedUniverse;
        }
        this.selectUniverse(this.selectedUniverse);
      });
    },
    selectUniverse(selectedUniverse) {
      this.setUniverse({ selectedUniverse });
    },
    getParameters() {
      const parameters = {
        // eslint-disable-next-line radix
        universe_id: parseInt(this.selectedUniverse.id),
        // eslint-disable-next-line radix
        activate: this.models.actionType
      };
      return parameters;
    },
    async actionProcessRetweet() {
      this.loading = true;
      this.error = false;
      this.success = false;
      this.info = false;
      const parameters = this.getParameters();
      UniverseService.processRetweet(parameters).then(resp => {
        this.loading = false;
        this.success = true;
        this.labels.successMessage = `Ação de ${this.models.actionType} realizada com sucesso`;
        console.log(resp);
      }).catch(e => {
        this.error = true;
        this.loading = false;
        if (e.response.status === 409) {
          this.error = false;
          this.info = true;
          this.labels.infoMessage = e.response.data.detail;
        } else if (e.response.status === 422) {
          this.labels.errorMessage = 'Falha na autenticação!';
        } else {
          this.labels.errorMessage = 'Erro inesperado ao tentar realizar operação.';
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.selector-container {
  width: 700px;

  form {
    margin-bottom: 35px;
  }

  .action-type {
    > div.v-radio {
      float: left;
      padding-right: 30px;
    }
  }
}
</style>
