<template>
  <v-form
    ref="search"
    :lazy-validation="false"
  >
    <SingleSelect
      v-model="selectedAccount"
      required
      :items="accounts"
      placeholder="Selecione uma conta"
      text="name"
      sub-text="group"
    />
    <SingleSelect
      v-model="selectedUniverse"
      :items="universes"
      placeholder="Selecione uma pesquisa"
      text="name"
      sub-text=""
    />
  </v-form>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import SingleSelect from '../SingleSelect.vue';

export default {
  name: 'Search',
  components: {
    SingleSelect
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    ...mapState('universe', ['universes', 'activeUniverse']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      },
      set(selectedUniverse) {
        this.selectUniverse(selectedUniverse);
      },
    },
    userId: {
      get() {
        return this.user.id;
      }
    },
    selectedPage: {
      get() {
        return this.activePage;
      },
      set(selectedPage) {
        this.selectPage(selectedPage);
      }
    }
  },
  async created() {
    await this.getAccounts({ userId: this.userId });
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount, this.selectedUniverse);
    }
  },
  methods: {
    ...mapActions('account', ['getAccounts', 'setAccount']),
    ...mapActions('universe', ['getAccountUniverses', 'setUniverse']),
    ...mapActions('page', ['getAccountPages', 'setPage']),
    ...mapMutations('account', {
      mutate: 'MUTATE',
    }),
    ...mapMutations('page', {
      mutate: 'MUTATE',
    }),
    selectAccount(selectedAccount, selectedUniverse) {
      this.setAccount({ selectedAccount });
      this.getAccountUniverses({ selectedAccount, fetchHistory: false }).then(() => {
        if (selectedUniverse) {
          this.selectedUniverse = selectedUniverse;
        }
        this.selectUniverse(this.selectedUniverse);
      });
      this.getAccountPages({ selectedAccount });
    },
    selectUniverse(selectedUniverse) {
      this.setUniverse({ selectedUniverse });
    },
    selectPage(selectedPage) {
      console.log(selectedPage);
      this.setPage({ selectedPage });
    }
  }
};
</script>
<style lang="scss" scoped>
  form {
    margin-bottom: 25px;
    margin-top: 25px;
  }
</style>
