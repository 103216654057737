<template>
  <div>
    <PageHeader
      :title="$t('UsergroupNew.title')"
      :description="$t('UsergroupNew.description')"
      :breadcrumbs="labels.breadcrumbs"
    />
    <v-form
      ref="usergroupNewForm"
      v-model="models.valid"
      :lazy-validation="false"
    >
      <v-text-field
        v-model="models.newUsergroup"
        :placeholder="$t('UsergroupNew.phName')"
        :rules="rules.isBool"
        @input="setErrorFalse"
      />
      <v-text-field
        v-model="models.limit"
        :placeholder="$t('UsergroupNew.phLimit')"
        :rules="rules.isInteger"
        @input="setErrorFalse"
      />
      <v-text-field
        v-model="models.email"
        :placeholder="$t('UsergroupNew.phEmail')"
        :rules="rules.isEmail"
      />
      <v-radio-group
        v-model="models.planType"
        row
      >
        <v-radio
          label="1 - Trial"
          value="1"
        />
        <v-radio
          label="2 - Light"
          value="2"
        />
        <v-radio
          label="3 - Ultra"
          value="3"
        />
        <v-radio
          label="4 - Elite"
          value="4"
        />
        <v-radio
          label="5 - Realtime"
          value="5"
        />
      </v-radio-group>
      <StOneDatePicker
        v-model="models.date"
        :label="$t('UsergroupNew.lbDate')"
        :max-date="fiveYearsDate"
        :min-date="todayDate"
        :rules="rules.isDate"
        @dateToSubmit="setExpireDate"
        @input="setErrorFalse"
      />
      <v-btn
        :disabled="!models.valid || loading"
        color="light-blue"
        @click="actionCreateUsergroup"
      >
        {{ $t('UsergroupNew.create') }}
      </v-btn>
    </v-form>
    <StLoader :loading="loading" />
    <v-alert
      v-if="success"
      border="right"
      colored-border
      type="success"
      elevation="2"
    >
      {{ labels.successMessage }}.
    </v-alert>
    <v-alert
      v-if="error"
      border="right"
      colored-border
      type="error"
      elevation="2"
    >
      {{ labels.errorMessage }}.
    </v-alert>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StLoader from '@stilingue/st-loader';
import PageHeader from '@/components/base/PageHeader';
import StOneDatePicker from '@/components/StOneDatePicker.vue';
import UserGroupService from '@/services/DataStoreService/UserGroupService';
import moment from 'moment';

export default {
  name: 'UsergroupNew',
  components: {
    PageHeader, StOneDatePicker, StLoader
  },
  data() {
    return {
      labels: {
        breadcrumbs: [
          {
            text: 'Home',
            href: '/'
          },
          {
            text: 'Usergroups',
            to: '/usergroup'
          },
          {
            text: 'Novo',
            to: '/usergroup/new'
          }
        ],
        successMessage: null,
        errorMessage: null,
      },
      models: {
        valid: false,
        limit: null,
        email: '',
        newUsergroup: null,
        planType: '1',
        date: null,
      },
      error: false,
      success: false,
      loading: false,
      rules: {
        isInteger: [v => {
          if (v !== null && v.length > 0) {
            const pattern = /^([0-9]\d{0,6})$/u;
            console.log(this.$t('UsergroupNew.errorLimit'));
            return pattern.test(v) || this.$t('UsergroupNew.errorLimit');
          }
          return false;
        }],
        isBool: [v => {
          return Boolean(v) || this.$t('UsergroupNew.errorName');
        }],
        isEmail: [v => {
          const pattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/u;
          if (v !== null && v.length > 0) {
            return pattern.test(v) || this.$t('UsergroupNew.errorEmail');
          }
          return false;
        }]
      }
    };
  },
  computed: {
    ...mapState('user', ['user']),
    userId() {
      return this.user.id;
    },
    todayDate() {
      return moment().format('YYYY-MM-DD');
    },
    fiveYearsDate() {
      return moment().add(5, 'years').format('YYYY-MM-DD');
    }
  },
  created() {
    this.setExpireDate(null);
  },
  methods: {
    setExpireDate(date) {
      this.models.date = date;
    },
    setErrorFalse() {
      this.error = false;
    },
    getParameters() {
      const parameters = {
        usergroup_name: this.models.newUsergroup,
        universe_limit: this.models.limit,
        plan: this.models.planType,
        email: this.models.email,
        expiration_date: this.models.date.replaceAll('-', '').concat('0000'),
      };
      parameters.responsible_user = this.user.email;
      return parameters;
    },
    async actionCreateUsergroup() {
      this.loading = true;
      this.error = false;
      this.success = false;
      const parameters = this.getParameters();
      UserGroupService.createUsergroup(parameters).then(resp => {
        this.loading = false;
        this.success = true;
        this.labels.successMessage = resp.result;
      }).catch(e => {
        this.error = true;
        this.loading = false;
        if (e.response.status === 404) {
          this.error = true;
          this.labels.errorMessage = e.response.data.detail;
        } else if (e.response.status === 409) {
          this.error = true;
          this.labels.errorMessage = e.response.data.detail;
        } else if (e.response.status === 422) {
          this.labels.errorMessage = this.$t('UsergroupNew.errorAuth');
        } else {
          this.labels.errorMessage = this.$t('UsergroupNew.error');
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
</style>
