var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "section",
        { staticClass: "selector-container" },
        [
          _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12" } },
                    [
                      _c("SingleSelect", {
                        attrs: {
                          items: _vm.accounts,
                          placeholder: _vm.labels.selectAccountPlaceholder,
                          text: "name",
                          "sub-text": "group",
                        },
                        model: {
                          value: _vm.selectedAccount,
                          callback: function ($$v) {
                            _vm.selectedAccount = $$v
                          },
                          expression: "selectedAccount",
                        },
                      }),
                      _vm.activeAccount
                        ? _c("SingleSelect", {
                            attrs: {
                              items: _vm.pages,
                              placeholder: _vm.labels.selectPagePlaceholder,
                              text: "name",
                              "sub-text": "channel",
                            },
                            model: {
                              value: _vm.selectedPage,
                              callback: function ($$v) {
                                _vm.selectedPage = $$v
                              },
                              expression: "selectedPage",
                            },
                          })
                        : _vm._e(),
                      _c("v-text-field", {
                        attrs: { placeholder: "Ad Account ID válida" },
                        model: {
                          value: _vm.adAccountId,
                          callback: function ($$v) {
                            _vm.adAccountId = $$v
                          },
                          expression: "adAccountId",
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            depressed: "",
                            disabled: _vm.shallDisableBtnAction,
                            color: "primary",
                          },
                          on: { click: _vm.checkAdAccount },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.labels.btnLabel) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("StLoader", {
                staticClass: "st-loader",
                attrs: { loading: _vm.isFetching },
              }),
              !_vm.isFetching && !_vm.changedAdIdValue
                ? [
                    _vm.adAccountFound !== null && !_vm.adAccountFound
                      ? _c(
                          "v-alert",
                          {
                            attrs: {
                              border: "right",
                              "colored-border": "",
                              type: "warning",
                              elevation: "2",
                            },
                          },
                          [
                            _vm._v(
                              "\n          A página foi conectada na plataforma pelo usuário da Stilingue "
                            ),
                            _c("b", [_vm._v(_vm._s(_vm.activePage.usr_name))]),
                            _vm._v(
                              " utilizando um usuário do linkedin que não tem acesso à Ad Account informada. "
                            ),
                            _c("br"),
                            _c("br"),
                            _vm._v(
                              " Favor solicitar\n          ao cliente que entre em contato com o mesmo.\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.adAccountFound !== null && _vm.adAccountFound
                      ? _c(
                          "v-alert",
                          {
                            attrs: {
                              border: "right",
                              "colored-border": "",
                              type: "success",
                              elevation: "2",
                            },
                          },
                          [
                            _vm._v(
                              '\n          A Ad Account "' +
                                _vm._s(_vm.adAccountProprietary) +
                                '" pertence ao usuário que conectou a página.'
                            ),
                            _c("br"),
                          ]
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm.serverError
        ? _c(
            "v-alert",
            {
              attrs: {
                border: "right",
                "colored-border": "",
                type: "error",
                elevation: "2",
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.labels.unexpectedError) + "\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }