import StService from './StService';
import { createApi } from '@stilingue/st-auth-utils';
import { featureFlagFlags } from '@/plugins/rollout';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import gateway from './Gateway';

const version = isProdVersion()
  ? featureFlagFlags.AuthServiceVersionProd.getValue()
  : featureFlagFlags.AuthServiceVersionStaging.getValue();

const afterParser = response => response.data;

const buildAnnotationsUrl = (accountId, universeId, pids) => {
  return `accounts/${accountId}/universes/${universeId}/annotations?${buildPidsList(pids)}`;
};

const buildPidsList = (pids) => {
  if (pids.length < 1) return '';
  console.log(pids.map(pid => encodeURIComponent(pid)).join('&pids='));
  return `pids=${pids.map(pid => encodeURIComponent(pid)).join('&pids=')}`;
};

const dateStringToTimestamp = (yearMonthDayString, { hours, minutes, seconds, milliseconds }) => {
  const dateValues = yearMonthDayString.split('-');
  const date = new Date(dateValues[0], dateValues[1] - 1, dateValues[2], hours, minutes, seconds, milliseconds);
  return date.getTime();
};

class AnnotationsHistoryService extends StService {
  constructor(gateway, version = 'staging') {
    const api = createApi(`annotations-history/${version}/`, gateway);
    super(api, { afterParser });
  }

  getPostsAnnotations({ accountId, universeId, pids, startingAt, endingAt, limit, offset }) {
    const startingAtTimestamp = dateStringToTimestamp(startingAt, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const endingAtTimestamp = dateStringToTimestamp(endingAt, { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 });
    return this.find({
      url: buildAnnotationsUrl(accountId, universeId, pids),
      params: {
        startingAt: startingAtTimestamp,
        endingAt: endingAtTimestamp,
        limit,
        offset,
      },
    });
  }
}

export default new AnnotationsHistoryService(gateway, version);
