<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <section class="selector-container">
      <SingleSelect
        v-model="selectedAccount"
        :items="accounts"
        placeholder="Selecione uma conta"
        text="name"
        sub-text="group"
      />

      <SingleSelect
        v-if="activeAccount"
        v-model="selectedPage"
        :items="pages"
        placeholder="Selecione uma Página"
        text="name"
        sub-text="channel"
      />
    </section>
    <section class="table-container">
      <StLoader :loading="loadingHistory" />
      <v-card-title v-if="historyIsLoaded&&!loadingHistory">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Filtrar por"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        v-if="historyIsLoaded&&!loadingHistory"
        :headers="headers"
        :items="history"
        :search="search"
        :multi-sort="multiSort"
        :custom-sort="customSort"
        dark
      />
      <section
        v-if="accountWithoutPage"
        class="failure-notification failure-notification--no-pages"
      >
        <span><v-icon>mdi-alert-outline</v-icon> Nenhuma página conectada encontrada para a conta.</span>
      </section>
      <section
        v-if="historyError"
        class="failure-notification"
      >
        <span @click="getPageHistory"><v-icon>mdi-reload</v-icon> Recarregar</span>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import StLoader from '@stilingue/st-loader';
import SingleSelect from '../components/SingleSelect';
import PageHeader from '@/components/base/PageHeader';

export default {
  name: 'PageHistory',
  components: {
    SingleSelect,
    StLoader,
    PageHeader
  },
  data() {
    return {
      labels: {
        title: 'Conexão da Página',
        description: 'Permite conferência do histórico detalhado de conexão da página proprietária para ' +
          'auxílio na análise de possíveis justificativas de falha de coleta.',
        breadcrumbs: [
          {
            text: 'Home',
            href: '/'
          },
          {
            text: 'Histórico',
            to: '/history'
          },
          {
            text: 'Conexão de página',
            to: '/history/pages'
          }
        ]
      },
      headers: [
        {
          text: 'Ação',
          value: 'action',
          align: 'center',
          class: 'page-action'
        },
        {
          text: 'Pesquisa',
          value: 'universe_name',
          align: 'center',
          width: 150
        }, {
          text: 'Usuário',
          value: 'user_name',
          align: 'center',
          width: 150
        }, {
          text: 'Email',
          value: 'email',
          align: 'center',
          width: 200
        }, {
          text: 'Observação',
          value: 'observation',
          sortable: false,
        }, {
          text: 'Data',
          value: 'created_at_formatted',
          align: 'center',
          width: 200
        },
      ],
      search: '',
      multiSort: true
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    ...mapState('page', ['pages', 'activePage', 'history', 'historyIsLoaded', 'loadingHistory', 'requestStatus']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    selectedPage: {
      get() {
        return this.activePage;
      },
      set(selectedPage) {
        this.selectPage(selectedPage);
      },
    },
    historyError: {
      get() {
        return !this.historyIsLoaded && !this.loadingHistory && !this.accountWithoutPage;
      },
    },
    accountWithoutPage: {
      get() {
        return this.pages.length === 0 && this.requestStatus === 'empty';
      }
    },
    userId: {
      get() {
        return this.user.id;
      }
    },
  },
  async created() {
    await this.getAccounts();
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount);
    }
  },
  methods: {
    ...mapActions('account', ['getAccounts', 'setAccount']),
    ...mapActions('page', ['getAccountPages', 'setPage', 'getPageHistory']),
    ...mapMutations('page', {
      mutate: 'MUTATE',
    }),
    selectAccount(selectedAccount) {
      this.setAccount({ selectedAccount });
      this.getAccountPages({ selectedAccount });
    },
    selectPage(selectedPage) {
      this.setPage({ selectedPage });
      this.getPageHistory({ selectedAccount: this.selectedAccount });
    },
    customSort(items, index, isDesc) {
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line complexity
      items.sort((a, b) => {
        if (index[0] === 'created_at_formatted') {
          if (!isDesc[0]) {
            return a.created_at - b.created_at;
          }
          return b.created_at - a.created_at;
        } else if (!isDesc[0]) {
          return a[index] < b[index] ? -1 : 1;
        }
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    }
  },
};
</script>

<style lang="scss" scoped>
.content-body {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}

.selector-container {
  width: 700px;
  display: flex;
  flex-flow: row;
  justify-content: left;
}

.single-select {
  width: 50%;
  margin-right: 20px;
}

.table-container {
  width: 100%;
  min-height: 600px;
}

.failure-notification {
  display: flex;
  height: 300px;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.failure-notification span {
  width: 130px;
  text-align: center;
  cursor: pointer;
}

.failure-notification--no-pages span {
  width: 500px;

  i {
    vertical-align: top;
  }
}

.v-card__title {
  padding-left: 0;
  width: 700px;
}
</style>
