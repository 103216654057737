import StService from './StService';
import { createApi } from '@stilingue/st-auth-utils';
import gateway from './Gateway';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import { featureFlagFlags } from '@/plugins/rollout';

// this passkey only works on that custom endpoint
const version = isProdVersion()
  ? featureFlagFlags.ITServiceVersionProd.getValue()
  : featureFlagFlags.ITServiceVersionStaging.getValue();
const afterParser = response => response.data;
const getCookieByName = (name) => {
  const cookie = {};
  document.cookie.split(';').forEach(function(el) {
    const [key, value] = el.split('=');
    cookie[key.trim()] = value;
  });
  return cookie[name];
};

class ITService extends StService {
  constructor(gateway, version) {
    const api = createApi(`it-services/${version}/`, gateway, { timeout: 120000 });
    super(api, { afterParser });
  }

  getProprietaryCrawlingHealth(params = {}) {
    return this.api.post({ ...params, url: 'universe-health-check/proprietary' }).then(this.afterParser);
  }

  insertPostInUniverse(params = {}) {
    return this.api.post({ ...params, url: 'post-inserter' }).then(this.afterParser);
  }

  editCallStatus(params = {}) {
    return this.api.post({ ...params, url: 'edit-call-status' }).then(this.afterParser);
  }

  newRetroactive(params = {}) {
    const headers = {
      'stilingue-session-id': getCookieByName('stilingue-session-id')
    };
    return this.api.post({ ...params, url: 'retroactive' }, { headers }).then(this.afterParser);
  }

  newRetroactiveCrawler(params = {}) {
    const headers = {
      'stilingue-session-id': getCookieByName('stilingue-session-id')
    };
    return this.api.post({ ...params, url: 'etl-crawler-retroactive' }, { headers }).then(this.afterParser);
  }

  serviceHistory({ path, limit, execution_date, order_by }) {
    const urlQuery = `limit=${limit}&execution_date=${execution_date}&order_by=${order_by}`;
    return this.api.post({ path, url: `service-history?${urlQuery}` }).then(this.afterParser);
  }

  eyesService(params = {}) {
    const headers = {
      'stilingue-session-id': getCookieByName('stilingue-session-id')
    };
    return this.api.post({ ...params, url: 'eyes/computer-vision-configurator' }, { headers }).then(this.afterParser);
  }

  eyesServiceList(params = {}) {
    const headers = {
      'stilingue-session-id': getCookieByName('stilingue-session-id')
    };
    return this.api.get({ params, url: 'eyes/computer-vision-configurator' }, { headers }).then(this.afterParser);
  }

  creditsUpdate(params = {}) {
    const headers = {
      'stilingue-session-id': getCookieByName('stilingue-session-id')
    };
    return this.api.put({ ...params, url: 'credits' }, { headers });
  }
}

export default new ITService(gateway, version);
