var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        { name: "show", rawName: "v-show", value: true, expression: "true" },
      ],
    },
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.prepareData(_vm.activeRouting),
          "items-per-page": 15,
        },
        on: { "click:row": _vm.handleClick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }