var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    { ref: "search", attrs: { "lazy-validation": false } },
    [
      _c("SingleSelect", {
        attrs: {
          required: "",
          items: _vm.accounts,
          placeholder: "Selecione uma conta",
          text: "name",
          "sub-text": "group",
        },
        model: {
          value: _vm.selectedAccount,
          callback: function ($$v) {
            _vm.selectedAccount = $$v
          },
          expression: "selectedAccount",
        },
      }),
      _c("SingleSelect", {
        attrs: {
          items: _vm.universes,
          placeholder: "Selecione uma pesquisa",
          text: "name",
          "sub-text": "",
        },
        model: {
          value: _vm.selectedUniverse,
          callback: function ($$v) {
            _vm.selectedUniverse = $$v
          },
          expression: "selectedUniverse",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }