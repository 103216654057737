<template>
  <div class="new-retroactive">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <div class="new-retroactive__form-and-result">
      <StLoader :loading="loading" />
      <v-form
        ref="newRetroactiveForm"
        v-model="models.valid"
        class="new-retroactive__form"
        :lazy-validation="false"
      >
        <v-radio-group v-model="byUniverseOrUsergroup">
          <div class="new-retroactive__universe-or-usergroup">
            <v-radio
              :label="labels.byUniverse"
              value="universe"
            />
            <v-radio
              :label="labels.byUsergroup"
              value="usergroup"
            />
          </div>
        </v-radio-group>
        <SingleSelect
          v-if="isByUsergroup"
          v-model="selectedUsergroup"
          :items="usergroups"
          :placeholder="labels.usergroup"
          text="name"
        />
        <SingleSelect
          v-if="!isByUsergroup"
          v-model="selectedAccount"
          required
          :items="accounts"
          :placeholder="labels.account"
          text="name"
          sub-text="group"
        />
        <SingleSelect
          v-if="!isByUsergroup && activeAccount"
          v-model="selectedUniverse"
          :items="universes"
          :placeholder="labels.universe"
          text="name"
          sub-text="channel"
        />
        <v-text-field
          v-model="models.link"
          required
          :name="Math.random()"
          :rules="rules.isLink"
          :label="labels.link"
          @input="setErrorFalse"
        />
        <StOneDatePicker
          :label="labels.startDate"
          @dateToSubmit="setStartDate"
          @input="setErrorFalse"
        />
        <StOneDatePicker
          :label="labels.endDate"
          @dateToSubmit="setEndDate"
          @input="setErrorFalse"
        />
        <v-select
          v-model="models.channels"
          :items="availableChannels"
          multiple
          chips
          :label="labels.channels"
          @input="setErrorFalse"
        />
        <v-btn
          :disabled="!models.valid || loading"
          color="light-blue"
          class="mr-4"
          @click.stop="startNewRetroactive"
        >
          {{ labels.startRetroactive }}
        </v-btn>
      </v-form>
      <v-alert
        v-if="error"
        border="right"
        colored-border
        type="error"
        elevation="2"
      >
        {{ labels.errorMessage }}
      </v-alert>
      <v-alert
        v-if="success"
        border="right"
        colored-border
        type="success"
        elevation="2"
      >
        Retroativo cadastrado com sucesso.
      </v-alert>
      <v-dialog
        v-if="hasAlreadyRetroactive"
        v-model="dialog"
        max-width="514px"
        persistent
      >
        <v-card>
          <span class="card_title"> Atualização de coleta retroativa </span>

          <v-card-text>
            Identificamos uma solicitação de retroativo para a mesma pesquisa no período de {{ hasAlreadyRetroactive.begin.format(brazilFormatWithoutHours) }}
            a {{ hasAlreadyRetroactive.end.format(brazilFormatWithoutHours) }}.<br>

            Deseja atualizar o período para {{ compareDates(hasAlreadyRetroactive) }}?
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="cancel"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="button-dialog-update"
              text
              @click="agree"
            >
              Atualizar
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex';
import moment from 'moment';
import StLoader from '@stilingue/st-loader';
import RetroactiveService from '@/services/RetroactiveService';
import StOneDatePicker from '../StOneDatePicker.vue';
import SingleSelect from '../SingleSelect.vue';
import PageHeader from '@/components/base/PageHeader';

export default {
  name: 'NewRetroactive',
  components: { StOneDatePicker, SingleSelect, StLoader, PageHeader },
  data: () => ({
    availableChannels: [
      'Blogs',
      'Facebook',
      'FacebookComments',
      'Fóruns',
      'GooglePlus',
      'Portais',
      'ReclameAqui',
      'Tumblr',
      'Twitter',
      'YouTube',
      'YouTubeComments'
    ],
    mapChannels: {
      'Blogs': [
        'Feedly',
      ],
      'Fóruns': [
        'DiariosOficiais',
        'HardMob',
      ],
      'Portais': [
        'BoxNetWebNews',
        'News'
      ],
      'TV': [
        'BoxNetTV'
      ],
      'Rádio': [
        'BoxNetRadio'
      ],
      'Impressas': [
        'BoxNetPrintedNews'
      ],
      'Comentários em Artigos': [
        'Disqus',
      ]
    },
    labels: {
      errorMessage: 'Erro inesperado ao tentar iniciar o retroativo. Por favor, entre em contato com o time de N2.',
      account: 'Selecione uma Conta',
      channels: 'Canais (opcional)',
      byUsergroup: 'Por usergroup',
      byUniverse: 'Por pesquisa',
      universe: 'Selecione uma Pesquisa',
      usergroup: 'Usergroup',
      startDate: 'Data de início (dia/mês/ano)',
      endDate: 'Data de fim (dia/mês/ano) (opcional)',
      startRetroactive: 'Iniciar retroativo',
      link: 'Link da tarefa ou ticket associado (jira, zendesk...)',
      title: 'Novo Retroativo',
      description: 'Permite execução de retroativo de coleta com base nos dados informados pelo cliente.',
      finishedStatus: 'finished',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Retroativo',
          to: '/retroactive'
        },
        {
          text: 'Novo',
          to: '/retroactive/new'
        }
      ]
    },
    models: {
      channels: undefined,
      usergroup: undefined,
      startDate: undefined,
      endDate: undefined,
      valid: false,
      link: undefined,
    },
    rules: {
      isLink: [v => {
        // eslint-disable-next-line
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z:\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(v) || 'URL inválida';
      }]
    },
    byUniverseOrUsergroup: 'universe',
    loading: false,
    error: false,
    success: false,
    hasAlreadyRetroactive: null,
    resolve: null,
    reject: null,
    brazilFormatWithoutHours: 'DD/MM/YYYY',
  }),
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount', 'usergroups', 'activeUsergroup']),
    ...mapState('universe', ['universes', 'activeUniverse']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      },
      set(selectedUniverse) {
        this.selectUniverse(selectedUniverse);
      },
    },
    userId: {
      get() {
        return this.user.id;
      }
    },
    selectedUsergroup: {
      get() {
        return this.activeUsergroup;
      },
      set(selectedUsergroup) {
        this.selectUsergroup(selectedUsergroup);
      }
    },
    isByUsergroup() {
      return this.byUniverseOrUsergroup === 'usergroup';
    },
  },
  async created() {
    await this.getAccounts({ userId: this.userId });
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount, this.selectedUniverse);
    }
    this.getUsergroups();
  },
  methods: {
    setStartDate(date) {
      this.models.startDate = date;
    },
    setEndDate(date) {
      this.models.endDate = date;
    },
    setErrorFalse() {
      this.error = false;
    },
    getChannelsParam(channels) {
      let paramChannels = channels;
      for (const channel in this.mapChannels) {
        if (paramChannels.includes(channel)) {
          paramChannels.splice(paramChannels.indexOf(channel), 1);
          paramChannels = paramChannels.concat(this.mapChannels[channel]);
        }
      }

      return paramChannels.join(',');
    },
    getParameters() {
      let parameters = {
        // eslint-disable-next-line
        account_id: parseInt(this.selectedAccount.id),
        // eslint-disable-next-line
        universe_id: parseInt(this.selectedUniverse.id),
      };

      if (this.isByUsergroup) {
        parameters = {
          group: this.selectedUsergroup.name,
        };
      }

      parameters.begin = this.models.startDate;

      if (this.models.endDate) {
        parameters.end = this.models.endDate;
      }

      if (this.models.channels && this.models.channels.length) {
        parameters.channels = this.getChannelsParam(this.models.channels);
      }

      if (this.models.link) {
        parameters.task_ids = [this.models.link];
      }

      return parameters;
    },
    async startNewRetroactive() {
      const parameters = this.getParameters();
      const dateStartSplit = parameters.begin.split('-');
      let dateEndSplit = undefined;
      if (parameters.end) {
        dateEndSplit = parameters.end.split('-');
      }

      const dateEnd = dateEndSplit ? new Date(
        Number(dateEndSplit[0]),
        (Number(dateEndSplit[1]) - 1),
        Number(dateEndSplit[2])
      ) : new Date();
      const dateStart = new Date(
        Number(dateStartSplit[0]),
        (Number(dateStartSplit[1]) - 1),
        Number(dateStartSplit[2])
      );

      if (dateStart > dateEnd) {
        this.error = true;
        this.labels.errorMessage = ` ${this.labels.endDate} deve ser maior que a ${this.labels.startDate}`;
        return;
      }
      const retroactives = await RetroactiveService.getRetroactives();
      this.hasAlreadyRetroactive = retroactives.data.find(retroactive => {
        return retroactive.universe_id === parameters.universe_id && retroactive.status !== this.labels.finishedStatus;
      });

      if (this.hasAlreadyRetroactive) {
        this.hasAlreadyRetroactive = (({ begin, end }) => ({ begin, end }))(this.hasAlreadyRetroactive);
        this.hasAlreadyRetroactive.begin = moment(this.hasAlreadyRetroactive.begin);
        this.hasAlreadyRetroactive.end = moment(this.hasAlreadyRetroactive.end);
        this.dialog = true;
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        }).then(() => {
          return this.makeNewRetroactive(parameters);
        }).catch(() => {
          this.loading = false;
        });
      }
      /*
       * uni id test 4543494504841217
       * acc id test 5553093224693760
       * link test https://stilingue-jira.atlassian.net/browse/FRONT-221
       */
      this.makeNewRetroactive(parameters);
    },
    makeNewRetroactive(parameters) {
      this.error = false;
      this.loading = true;
      RetroactiveService.newRetroactive(parameters).then(resp => {
        if (resp.data.status === 'success') {
          this.success = true;
        } else {
          this.error = true;
        }
        this.loading = false;
      }).catch(e => {
        console.error(e);
        this.error = true;
        this.loading = false;
        const error = e.toString().slice(-3);
        switch (error) {
        case '400':
          this.labels.errorMessage = 'Por favor, certifique-se pelo Visão Geral da pesquisa se a mesma está Em execução.';
          break;
        case '500':
          this.labels.errorMessage = 'Falha ao cadastrar o retroativo. Por favor, entre em contato com o time de N2.';
          break;
        default:
          break;
        }
      });
    },
    ...mapActions('account', ['getAccounts', 'setAccount', 'getUsergroups', 'setUsergroup']),
    ...mapActions('universe', ['getAccountUniverses', 'setUniverse']),
    ...mapMutations('account', {
      mutate: 'MUTATE',
    }),
    selectAccount(selectedAccount, selectedUniverse) {
      this.setAccount({ selectedAccount });
      this.getAccountUniverses({ selectedAccount, fetchHistory: false }).then(() => {
        if (selectedUniverse) {
          this.selectedUniverse = selectedUniverse;
        }
        this.selectUniverse(this.selectedUniverse);
      });
    },
    selectUniverse(selectedUniverse) {
      this.setUniverse({ selectedUniverse });
    },
    selectUsergroup(selectedUsergroup) {
      this.setUsergroup({ selectedUsergroup });
    },
    agree() {
      this.resolve(true);
      this.loading = true;
      this.dialog = false;
    },
    cancel() {
      this.reject(true);
      this.dialog = false;
      // redirect to retroactive list
      const redirectTo = '/retroactive/list';
      this.$router.replace({
        path: redirectTo,
      }).catch(/*istanbul ignore next*/() => {
        //do nothing because it's just page redirection
      });
    },
    compareDates(hasAlreadyRetroactive) {
      const startDateInBrazil = moment(this.models.startDate);
      const endDateInBrazil = moment(this.models.endDate);
      const olderDate = hasAlreadyRetroactive.begin < startDateInBrazil ? hasAlreadyRetroactive.begin : startDateInBrazil;
      const newerDate = hasAlreadyRetroactive.end > endDateInBrazil ? hasAlreadyRetroactive.end : endDateInBrazil;
      return `${olderDate.format(this.brazilFormatWithoutHours)} a ${newerDate.format(this.brazilFormatWithoutHours)}`;
    }
  },
};

</script>

<style lang="scss" scoped>
  .new-retroactive__universe-or-usergroup {
    display: flex;
    justify-content: space-around;

    .v-radio {
      margin-bottom: 0 !important;
    }
  }

  .v-card {
    background: #333;
    border-radius: 6px;
    padding: 48px;
    text-align: center;
  }

  .card_title {
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  .theme--dark.v-card > .v-card__text {
    color: #ffff;
    padding: 24px 0;
    text-align: center;
  }

  .v-card__actions > .v-btn.v-btn {
    border: 1px solid;
    border-radius: 40px;
    box-sizing: border-box;
    margin: 0 16px;
  }

  .button-dialog-update {
    border-color: #00ccbe;
    background-color: #00ccbe;
    color: #242c5a;
  }

  .v-alert {
    margin-top: 20px;
  }
</style>
