var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MultipleSelect",
    _vm._g(
      _vm._b(
        {
          attrs: {
            items: _vm.items,
            label: _vm.placeholder,
            "item-text": _vm.getText,
            "return-object": "",
            searchable: true,
          },
        },
        "MultipleSelect",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }