var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        "close-on-content-click": false,
        transition: "scale-transition",
        "offset-y": "",
        "min-width": "290px",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        value: _vm.dateText,
                        label: "Selecione intervalo de datas",
                        "prepend-icon": "mdi-calendar",
                        readonly: "",
                      },
                    },
                    "v-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _c("v-date-picker", {
        attrs: { "no-title": "", scrollable: "", range: _vm.range },
        model: {
          value: _vm.dateValue,
          callback: function ($$v) {
            _vm.dateValue = $$v
          },
          expression: "dateValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }