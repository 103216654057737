<template>
  <MultipleSelect
    :items="items"
    :label="placeholder"
    :item-text="getText"
    return-object
    :searchable="true"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import MultipleSelect from '@/components/MultipleSelect.vue';

export default {
  name: 'PageSelection',
  components: { MultipleSelect },
  inheritAttrs: true,
  props: {
    placeholder: {
      type: String,
      default: 'Seleção de páginas proprietárias'
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    checkbox: false,
    selectAllLabel: 'Selecionar tudo',
  }),
  methods: {
    getText(item) {
      return `${item.name}(${item.channel})`;
    },
  }
};
</script>

<style scoped>
  .v-list-item__content {
    flex-flow: row;
  }
</style>
