<template>
  <v-row
    class="base-layout no-gutters"
  >
    <StHeader
      @side-icon-clicked="toggleDrawer"
    >
      <template #right>
        <StUserMenu
          :user="user"
          :greeting="$t('Layout.greeting')"
          :logout-label="$t('Layout.logout')"
          @logout-item-clicked="logoutAndSendUserToLoginPage"
        />
      </template>
    </StHeader>

    <v-main
      class="content-dark"
    >
      <v-row class="no-gutters fill-height">
        <LayoutContent />

        <StSystemNavigationDrawer
          :drawer="drawer"
          :items="itemsInDrawer"
          :active-url="itemActive"
          @navigate="clickOnNavigate($event)"
        />
      </v-row>
    </v-main>
  </v-row>
</template>

<script>
import StHeader from '@/components/st-header/StHeader';
import StUserMenu from '@stilingue/st-user-menu';
import StSystemNavigationDrawer from '@stilingue/st-system-navigation-drawer';
import LayoutContent from '@/components/LayoutContent';
import { insertPluginMetadata } from '@/plugins/insert_plugins_metadata';
import { logoutAndSendUserToLoginPage } from '@stilingue/st-auth-utils';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'Layout',
  components: {
    StSystemNavigationDrawer,
    StHeader,
    StUserMenu,
    LayoutContent
  },
  data() {
    return {
      itemActive: 'https://tickets.stilingue.com.br',
      itemsInDrawer: [
        {
          name: this.$t('StSystemNavigationDrawer.ojesed'),
          link: 'https://admin-tools.stilingue.com.br'
        }
      ]
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('misc', ['drawer']),
  },
  async created() {
    insertPluginMetadata({
      user: this.user,
      activeCompany: this.activeCompany
    });
  },
  methods: {
    clickOnNavigate(event) {
      window.open(event.link, '_blank');
    },
    logoutAndSendUserToLoginPage,
    ...mapMutations('misc', {
      toggleDrawer: 'TOGGLE_DRAWER'
    }),
    ...mapMutations('company', {
      mutateCompany: 'MUTATE'
    }),
  }
};
</script>

<style scoped lang="scss">
</style>
