var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _vm.error
        ? _c(
            "v-alert",
            {
              attrs: {
                right: "",
                "colored-border": "",
                type: "error",
                elevation: "2",
              },
            },
            [
              _vm._v(
                "\n    Erro inesperado ao carregar lista de Benchmarks. Favor contactar o time de N2.\n  "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                staticClass: "mx-4",
                attrs: { label: "Buscar", "prepend-icon": "mdi-magnify" },
                model: {
                  value: _vm.table.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.table, "search", $$v)
                  },
                  expression: "table.search",
                },
              }),
              _c(
                "div",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "benchmarks-list__refresh mx-8",
                      class: {
                        "benchmarks-list__refresh--loading": _vm.loading,
                      },
                      on: { click: _vm.getBenchmarks },
                    },
                    [_vm._v("\n          mdi-refresh\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("StLoader", { attrs: { loading: _vm.loading } }),
          !_vm.error
            ? _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.table.headers,
                  items: _vm.table.items,
                  search: _vm.table.search,
                  "custom-sort": _vm.customSort,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.url",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "a",
                            { attrs: { href: item.url, target: "_blank" } },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.url.slice(0, 50) + "...") +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.freePlan",
                      fn: function ({ item }) {
                        return [
                          item.freePlan == "yes"
                            ? _c(
                                "v-chip",
                                {
                                  attrs: {
                                    outlined: "",
                                    filter: "",
                                    color: "primary",
                                  },
                                },
                                [_vm._v("\n          Sim\n        ")]
                              )
                            : _c(
                                "v-chip",
                                {
                                  attrs: {
                                    outlined: "",
                                    filter: "",
                                    color: "yellow",
                                  },
                                },
                                [_vm._v("\n          Não\n        ")]
                              ),
                        ]
                      },
                    },
                    {
                      key: "item.expiryDateFormatted",
                      fn: function ({ item }) {
                        return [
                          item.expired
                            ? _c(
                                "v-chip",
                                {
                                  attrs: {
                                    outlined: "",
                                    filter: "",
                                    color: "red",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          Expirado em " +
                                      _vm._s(item.expiryDateFormatted) +
                                      "\n        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          item.expiryDateFormatted && !item.expired
                            ? _c("span", [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(item.expiryDateFormatted) +
                                    "\n        "
                                ),
                              ])
                            : _vm._e(),
                          !item.expiryDateFormatted && !item.expired
                            ? _c("span", [_vm._v("-")])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3948735510
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }