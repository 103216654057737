import StService from './StService';
import { createApi } from '@stilingue/st-auth-utils';
import gateway from './Gateway';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import { featureFlagFlags } from '@/plugins/rollout';

const version = isProdVersion()
  ? featureFlagFlags.SeCreditsSystemServiceProd.getValue()
  : featureFlagFlags.SeCreditsSystemServiceStaging.getValue();

const afterParser = response => response.data;

const getCookieByName = (name) => {
  const cookie = {};
  document.cookie.split(';').forEach(function(el) {
    const [key, value] = el.split('=');
    cookie[key.trim()] = value;
  });
  return cookie[name];
};

class SeCreditsSystemService extends StService {
  constructor(gateway, version = 'staging') {
    const api = createApi(`se-credit-system/${version}/`, gateway, { timeout: 120000 });
    super(api, { afterParser });
  }

  addNewCreditsExistentUser(params = {}) {
    const headers = {
      'stilingue-session-id': getCookieByName('stilingue-session-id')
    };
    return this.api.post({ ...params, url: 'contracts' }, { headers });
  }

  listCredits(params = {}) {
    const headers = {
      'stilingue-session-id': getCookieByName('stilingue-session-id')
    };
    const urlQuery = `page=${params.page}&size=${params.size}`;
    return this.api.get({ url: `contracts?${urlQuery}` }, { headers }).then(this.afterParser);
  }

  listCreditsByQuotationId(params = {}) {
    const headers = {
      'stilingue-session-id': getCookieByName('stilingue-session-id')
    };
    const urlQuery = `page=${params.page}&size=${params.size}&quotationId=${params.quotationId}`;
    return this.api.get({ url: `contracts?${urlQuery}` }, { headers }).then(this.afterParser);
  }

  updateCredits(params = {}) {
    const headers = {
      'stilingue-session-id': getCookieByName('stilingue-session-id')
    };
    const urlQuery = `${params.id}`;
    return this.api.patch({ ...params, url: `contracts/${urlQuery}` }, { headers });
  }
}
export default new SeCreditsSystemService(gateway, version);
