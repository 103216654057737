var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.component,
    _vm._g(
      _vm._b(
        {
          tag: "component",
          attrs: {
            items: _vm.items,
            "search-input": _vm.searchInput,
            "return-object": "",
            multiple: "",
          },
          on: {
            "update:searchInput": function ($event) {
              _vm.searchInput = $event
            },
            "update:search-input": function ($event) {
              _vm.searchInput = $event
            },
            "click:clear": function ($event) {
              return _vm.$emit("clearClick")
            },
            blur: function ($event) {
              _vm.searchInput = ""
            },
          },
          scopedSlots: _vm._u(
            [
              _vm.items.length && !_vm.searchInput
                ? {
                    key: "prepend-item",
                    fn: function () {
                      return [
                        _c(
                          "v-list-item",
                          { attrs: { ripple: "" }, on: { click: _vm.toggle } },
                          [
                            _c(
                              "v-list-item-action",
                              [
                                _c("v-icon", { attrs: { color: _vm.color } }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.icon) +
                                      "\n        "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(_vm.selectAllLabel)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-divider", { staticClass: "mt-2" }),
                      ]
                    },
                    proxy: true,
                  }
                : null,
              _vm._l(_vm.$scopedSlots, function (_, name) {
                return {
                  key: name,
                  fn: function (slotData) {
                    return [_vm._t(name, null, null, slotData)]
                  },
                }
              }),
            ],
            null,
            true
          ),
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        "component",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }