var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "retroactive-list" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "6", md: "15" } },
        [
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": false,
                "nudge-right": 40,
                transition: "scale-transition",
                "offset-y": "",
                "min-width": "auto",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-text-field",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                label: _vm.labels.inputs.executionDate,
                                "prepend-icon": "mdi-calendar",
                                readonly: "",
                              },
                              model: {
                                value: _vm.date,
                                callback: function ($$v) {
                                  _vm.date = $$v
                                },
                                expression: "date",
                              },
                            },
                            "v-text-field",
                            attrs,
                            false
                          ),
                          on
                        )
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.menu,
                callback: function ($$v) {
                  _vm.menu = $$v
                },
                expression: "menu",
              },
            },
            [
              _c("v-date-picker", {
                on: {
                  input: function ($event) {
                    ;(_vm.menu = false), _vm.getRetroactiveData()
                  },
                },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      [
        _c("v-data-table", {
          staticClass: "elevation-1",
          attrs: {
            headers: _vm.table.headers,
            items: _vm.items,
            "sort-by": _vm.items.execution_date,
            "sort-desc": [false, true],
          },
          scopedSlots: _vm._u([
            {
              key: "item",
              fn: function (row) {
                return [
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(row.item.accountId))]),
                    _c("td", [_vm._v(_vm._s(row.item.pageId))]),
                    _c("td", [_vm._v(_vm._s(row.item.sinceDateMillis))]),
                    _c("td", [_vm._v(_vm._s(row.item.authenticated_entity))]),
                    _c("td", { attrs: { m: "12px" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(row.item.execution_date) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ]
              },
            },
          ]),
        }),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }