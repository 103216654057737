export default {
  label: {
    placeholder: {
      breadCrumbSidePath: 'Liberação',
      breadCrumbPage: 'Ativação e Lista',
      pageTitle: 'Liberação - Ativação de créditos',
      pageDescription: 'Formulário de inserção de créditos.',
      sideMenuName: 'Liberação',
      pageName: 'Ativação e Lista',
      opportunityId: 'ID da oportunidade',
      quotationId: 'ID da cotação',
      activationType: 'Tipo de ativação',
      userGroup: 'Grupo de Usuário',
      emailMaster: 'Email do Master',
      clientName: 'Nome do Cliente associado ao email Master',
      accountName: 'Nome da Conta',
      accountExecutiveEmail: 'Email da Conta Executiva',
      accountExecutiveName: 'Nome associado à Conta Executiva',
      accountManagerEmail: 'Email do Account Manager (AM)',
      accountManagerName: 'Nome do Account Manager (AM)',
      emails: 'Email(s) a ser(em) avisado(s) da criação do crédito',
      isItToSendEmail: 'Enviar email?',
      creditType: 'Tipo do crédito',
      creditValue: 'Valor do crédito',
      prodQtyTable: 'Qtd. produtos',
      prodQty: 'Quantidade de produtos',
      prodNum: 'número de produtos',
      expirationDate: 'Data de expiração',
      contractCommonDataTitle: 'Dados da cotação / contrato',
      contractCommonDataTitleEmpty: 'Nenhuma cotação / contrato adicionado',
      contractCommonDataIsItToSendEmail: 'Enviar email sobre criação de créditos?',
      productsListTitle: 'Lista de Produtos da cotação / contrato',
      productsListTitleEmpty: 'Nenhum produto adicionado',
      editProduct: 'Editar produto',
      currentExpirationDate: 'Data de expiração atual:',
      newExpirationDate: 'Nova data de expiração:',
    },
    message: {
      success: {
        productAddedToCart: 'Produto adicionado ao carrinho!',
        productRemovedFromCart: 'Produto removido do carrinho!',
        productListDeleted: 'Lista de produtos apagada!',
      },
    },
    info: {
      newUserGroup: 'Inicie o cadastro de um novo User Group por "STC_"',
    }
  },
};
