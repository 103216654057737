<template>
  <div class="content-body">
    <v-col>
      <v-card>
        <v-card-title class="subheading font-weight-bold">
          Histórico
          <StLoader :loading="loadingHistory" />
        </v-card-title>
        <v-divider />
        <v-card-actions>
          <v-col cols="3">
            <StOneDatePicker
              label="Data Início"
              @dateToSubmit="setInitDate"
            />
          </v-col>
          <v-col cols="3">
            <v-checkbox
              v-model="models.actualState"
              label="Adicionar estado atual"
              color="primary"
              :value="models.actualState"
              hide-details
              class="mt-0"
            />
          </v-col>
          <v-col cols="3">
            <v-btn
              class="mr-4"
              @click="getHistory"
            >
              Buscar
            </v-btn>
            <v-btn
              :loading="exporting"
              color="primary"
              @click="exportHistory()"
            >
              Exportar
              <StIcon
                icon-name="file-document-edit"
              />
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
      <v-card>
        <v-col cols="6">
          <v-alert
            v-if="error"
            border="right"
            colored-border
            type="error"
            elevation="2"
          >
            {{ errorMessage }}
          </v-alert>
        </v-col>
        <v-card-text
          v-if="configDetails"
        >
          <v-textarea
            filled
            label="Detalhamento"
            :value="configDetails.result"
            outlined
            auto-grow
            readonly
          />
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StLoader from '@stilingue/st-loader';
import StIcon from '@stilingue/st-icon/StIcon';
import StOneDatePicker from '../StOneDatePicker.vue';
import UniverseService from '@/services/DataStoreService/UniverseService';

export default {
  name: 'History',
  components: { StLoader, StOneDatePicker, StIcon },
  data: () => ({
    loadingHistory: false,
    configDetails: null,
    exporting: false,
    error: false,
    errorMessage: 'Erro inesperado. Por favor, entre em contato com o time de N2.',
    models: {
      actualState: true,
      initDate: undefined
    }
  }),
  computed: {
    ...mapState('universe', ['activeUniverse']),
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      }
    }
  },
  methods: {
    setInitDate(date) {
      this.models.initDate = date;
    },
    getParameters() {
      const parameters = {
        // eslint-disable-next-line
        universe_id: parseInt(this.selectedUniverse.id),
        since_date: this.models.initDate.concat(' 00:00:00'),
        actual_state: this.models.actualState
      };

      return parameters;
    },
    getHistory() {
      this.error = false;
      this.loadingHistory = true;
      const parameters = this.getParameters();
      this.configDetails = null;
      UniverseService.getHistory(parameters).then(resp => {
        this.configDetails = resp;
      }).catch(e => {
        console.error(e);
        this.error = true;
      }).finally(() => {
        this.loadingHistory = false;
      });
    },
    exportHistory() {
      this.error = false;
      this.exporting = true;
      const parameters = this.getParameters();
      UniverseService.getHistory(parameters).then(resp => {
        const url = window.URL.createObjectURL(new Blob([resp.result]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.selectedUniverse.id);
        document.body.appendChild(link);
        link.click();
      }).catch(e => {
        this.error = true;
        this.configDetails = null;
        console.error(e);
      }).finally(() => {
        this.exporting = false;
      });
    }
  }
};
</script>
