<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <section class="selector-container">
      <SingleSelect
        v-model="selectedAccount"
        :items="accounts"
        placeholder="Selecione uma conta"
        text="name"
        sub-text="group"
      />

      <SingleSelect
        v-if="activeAccount"
        v-model="selectedUniverse"
        :items="universes"
        placeholder="Selecione uma Pesquisa"
        text="name"
        sub-text="channel"
      />
    </section>
    <section class="table-container">
      <StLoader :loading="loadingHistory" />
      <v-expansion-panels
        v-if="historyIsLoaded&&!loadingHistory"
        accordion
        dark
      >
        <v-expansion-panel
          v-for="(item,i) in history"
          :key="i"
        >
          <v-expansion-panel-header>
            Editado em: {{ item.created_at }} - Editado por: {{
              item.author
            }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ item.changes }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <section
        v-if="historyError"
        class="failure-notification"
      >
        <span @click="getUniverseHistory"><v-icon>mdi-reload</v-icon> Recarregar</span>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import StLoader from '@stilingue/st-loader';
import SingleSelect from '../components/SingleSelect';
import PageHeader from '@/components/base/PageHeader';

export default {
  name: 'UniverseHistory',
  components: {
    SingleSelect,
    StLoader,
    PageHeader
  },
  data() {
    return {
      labels: {
        title: 'Edição de Pesquisa',
        description: 'Permite conferência do histórico de edições realizadas na configuração de pesquisa ' +
          'para esclarecimentos tanto do motivo de ter entrado quanto de não ter entrado uma publicação no painel. ',
        breadcrumbs: [
          {
            text: 'Home',
            href: '/'
          },
          {
            text: 'Histórico',
            to: '/history'
          },
          {
            text: 'Pesquisa',
            to: '/history/universe'
          }
        ]
      }
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    ...mapState('universe', ['universes', 'activeUniverse', 'history', 'historyIsLoaded', 'loadingHistory']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      },
      set(selectedUniverse) {
        this.selectUniverse(selectedUniverse);
      },
    },
    userId: {
      get() {
        return this.user.id;
      }
    },
    historyError: {
      get() {
        return !this.historyIsLoaded && !this.loadingHistory;
      },
    },
  },
  async created() {
    await this.getAccounts({ userId: this.userId });
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount, this.selectedUniverse);
    }
  },
  methods: {
    ...mapActions('account', ['getAccounts', 'setAccount']),
    ...mapActions('universe', ['getAccountUniverses', 'setUniverse', 'getUniverseHistory']),
    ...mapMutations('account', {
      mutate: 'MUTATE',
    }),
    selectAccount(selectedAccount, selectedUniverse) {
      this.setAccount({ selectedAccount });
      this.getAccountUniverses({ selectedAccount, fetchHistory: true }).then(() => {
        if (selectedUniverse) {
          this.selectedUniverse = selectedUniverse;
        }
        this.selectUniverse(this.selectedUniverse);
      });
    },
    selectUniverse(selectedUniverse) {
      this.setUniverse({ selectedUniverse });
      this.getUniverseHistory({});
    },
  },
};
</script>

<style scoped>
.content-body {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}

.selector-container {
  width: 700px;
  display: flex;
  flex-flow: row;
  justify-content: left;
}

.single-select {
  width: 50%;
  margin-right: 20px;
}

.table-container {
  width: 100%;
  min-height: 600px;
}

.failure-notification {
  display: flex;
  height: 300px;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.failure-notification span {
  width: 130px;
  text-align: center;
  cursor: pointer;
}
</style>
