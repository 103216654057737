import { StAuthService } from '@stilingue/st-auth-service';
import { featureFlagFlags } from '@/plugins/rollout';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import gateway from './Gateway';

const version = isProdVersion()
  ? featureFlagFlags.AuthServiceVersionProd.getValue()
  : featureFlagFlags.AuthServiceVersionStaging.getValue();

export default new StAuthService(gateway, version);
