import StService from './StService';
import { createApi } from '@stilingue/st-auth-utils';
import { featureFlagFlags } from '@/plugins/rollout';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import gateway from './Gateway';

const version = isProdVersion()
  ? featureFlagFlags.UserLegacyServiceVersionProd.getValue()
  : featureFlagFlags.UserLegacyServiceVersionStaging.getValue();

const afterParser = response => response.data;

class UserUpmsLegacyService extends StService {
  constructor(gateway, version = 'staging') {
    const api = createApi(`upms-legacy/${version}/user/by-examples`, gateway, { timeout: 30000 });
    super(api, { afterParser });
  }

  getEmailMasterWithUserGroup(body) {
    return this.api.post(body).then(this.afterParser);
  }
}
export default new UserUpmsLegacyService(gateway, version);
