var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "section",
        { staticClass: "selector-container" },
        [
          _c(
            "v-form",
            {
              ref: "newReprocessingForm",
              attrs: { "lazy-validation": false },
              model: {
                value: _vm.models.valid,
                callback: function ($$v) {
                  _vm.$set(_vm.models, "valid", $$v)
                },
                expression: "models.valid",
              },
            },
            [
              _c("SingleSelect", {
                attrs: {
                  required: "",
                  items: _vm.accounts,
                  placeholder: "Selecione uma Conta",
                  text: "name",
                  "sub-text": "group",
                },
                model: {
                  value: _vm.selectedAccount,
                  callback: function ($$v) {
                    _vm.selectedAccount = $$v
                  },
                  expression: "selectedAccount",
                },
              }),
              _vm.activeAccount
                ? _c("SingleSelect", {
                    attrs: {
                      items: _vm.universes,
                      placeholder: "Selecione uma Pesquisa",
                      text: "name",
                      "sub-text": "channel",
                    },
                    model: {
                      value: _vm.selectedUniverse,
                      callback: function ($$v) {
                        _vm.selectedUniverse = $$v
                      },
                      expression: "selectedUniverse",
                    },
                  })
                : _vm._e(),
              _c(
                "v-col",
                { staticClass: "date-area", attrs: { cols: "12" } },
                [
                  _c("StOneDatePicker", {
                    attrs: { label: "Data Início" },
                    on: {
                      dateToSubmit: _vm.setInitDate,
                      input: _vm.setErrorFalse,
                    },
                  }),
                  _c("StOneDatePicker", {
                    attrs: { label: "Data Fim" },
                    on: {
                      dateToSubmit: _vm.setEndDate,
                      input: _vm.setErrorFalse,
                    },
                  }),
                  _vm.endDateInvalid
                    ? _c("span", { staticClass: "date-error" }, [
                        _vm._v(
                          "\n          Data Fim deve ser maior que a Data Início\n        "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c("v-text-field", {
                attrs: {
                  placeholder: "Limite de publicações",
                  rules: _vm.rules.isInteger,
                },
                on: { input: _vm.setErrorFalse },
                model: {
                  value: _vm.models.limit,
                  callback: function ($$v) {
                    _vm.$set(_vm.models, "limit", $$v)
                  },
                  expression: "models.limit",
                },
              }),
              _c(
                "v-col",
                { staticClass: "checkbox-area", attrs: { cols: "12" } },
                [
                  _c("v-checkbox", {
                    attrs: { label: "TAG", value: "TAG" },
                    model: {
                      value: _vm.models.types,
                      callback: function ($$v) {
                        _vm.$set(_vm.models, "types", $$v)
                      },
                      expression: "models.types",
                    },
                  }),
                  _c("v-checkbox", {
                    attrs: { label: "TEMA", value: "THEME" },
                    model: {
                      value: _vm.models.types,
                      callback: function ($$v) {
                        _vm.$set(_vm.models, "types", $$v)
                      },
                      expression: "models.types",
                    },
                  }),
                  _c("v-checkbox", {
                    attrs: { label: "GRUPO", value: "GROUP" },
                    model: {
                      value: _vm.models.types,
                      callback: function ($$v) {
                        _vm.$set(_vm.models, "types", $$v)
                      },
                      expression: "models.types",
                    },
                  }),
                  _c("v-checkbox", {
                    attrs: { label: "TERMO", value: "TERM" },
                    model: {
                      value: _vm.models.types,
                      callback: function ($$v) {
                        _vm.$set(_vm.models, "types", $$v)
                      },
                      expression: "models.types",
                    },
                  }),
                  _c("v-checkbox", {
                    attrs: { label: "SENTIMENTO", value: "POLARITY" },
                    model: {
                      value: _vm.models.types,
                      callback: function ($$v) {
                        _vm.$set(_vm.models, "types", $$v)
                      },
                      expression: "models.types",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: {
                    disabled:
                      !_vm.models.valid || _vm.loading || _vm.endDateInvalid,
                    color: "light-blue",
                  },
                  on: { click: _vm.startNewReprocessing },
                },
                [_vm._v("\n        Iniciar reprocessamento\n      ")]
              ),
            ],
            1
          ),
          _c("StLoader", { attrs: { loading: _vm.loading } }),
          _vm.error
            ? _c(
                "v-alert",
                {
                  attrs: {
                    border: "right",
                    "colored-border": "",
                    type: "error",
                    elevation: "2",
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.labels.errorMessage) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm.success
            ? _c(
                "v-alert",
                {
                  attrs: {
                    border: "right",
                    "colored-border": "",
                    type: "success",
                    elevation: "2",
                  },
                },
                [
                  _vm._v("\n      Reprocessamento iniciado com sucesso."),
                  _c("br"),
                  _c("br"),
                  _vm.success
                    ? _c(
                        "div",
                        { staticClass: "result-percentage" },
                        [
                          _c("div", [
                            _c("span", [_vm._v("Total: " + _vm._s(_vm.total))]),
                            _c("span", [
                              _vm._v("Progresso: " + _vm._s(_vm.progress)),
                            ]),
                          ]),
                          _c(
                            "v-progress-circular",
                            {
                              attrs: {
                                rotate: -90,
                                size: 90,
                                width: 15,
                                value: _vm.percentage,
                                color: "teal",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.percentage) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.hasAlreadyReprocessing
            ? _c(
                "v-dialog",
                {
                  attrs: { "max-width": "514px", persistent: "" },
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("span", { staticClass: "card_title" }, [
                        _vm._v(" Ops, temos um reprocessamento em execução! "),
                      ]),
                      _c("v-card-text", [
                        _vm._v(
                          "\n          Recomenda-se apenas 1 execução por vez, pelo histórico de "
                        ),
                        _c("b", [
                          _vm._v(
                            "queda de performance da plataforma percebida por nossos clientes"
                          ),
                        ]),
                        _vm._v(
                          "\n          onde a causa era sobrecarga da base de dados decorrente de muitos reprocessamentos em execução."
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          "\n          Por favor, entre em contato com o time de N2 para uma análise detalhada do seu reprocessamento a fim de\n          evitarmos qualquer imprevisto semelhante ao cenário aqui apresentado.\n        "
                        ),
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            { attrs: { text: "" }, on: { click: _vm.cancel } },
                            [_vm._v("\n            Vou analisar\n          ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "button-dialog-update",
                              attrs: { text: "" },
                              on: { click: _vm.agree },
                            },
                            [
                              _vm._v(
                                "\n            Analisei e posso iniciar\n          "
                              ),
                            ]
                          ),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }