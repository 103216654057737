var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "section",
        { staticClass: "selector-container" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "4" } },
                [
                  _c("SingleSelect", {
                    attrs: {
                      items: _vm.accounts,
                      placeholder: "Selecione uma conta",
                      text: "name",
                      "sub-text": "group",
                    },
                    model: {
                      value: _vm.selectedAccount,
                      callback: function ($$v) {
                        _vm.selectedAccount = $$v
                      },
                      expression: "selectedAccount",
                    },
                  }),
                  _vm.activeAccount
                    ? _c("SingleSelect", {
                        attrs: {
                          items: _vm.universes,
                          placeholder: "Selecione uma Pesquisa",
                          text: "name",
                          "sub-text": "channel",
                        },
                        model: {
                          value: _vm.selectedUniverse,
                          callback: function ($$v) {
                            _vm.selectedUniverse = $$v
                          },
                          expression: "selectedUniverse",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "7" } },
                [
                  _c("StDatePicker", {
                    attrs: { range: "" },
                    model: {
                      value: _vm.dates,
                      callback: function ($$v) {
                        _vm.dates = $$v
                      },
                      expression: "dates",
                    },
                  }),
                  _c("v-textarea", {
                    attrs: {
                      name: "input-7-1",
                      label: "Posts",
                      value: "",
                      hint: "Separe os identificadores dos posts por linha",
                      rows: "3",
                      rules: _vm.postIdsRules,
                      error: _vm.postIdsError,
                    },
                    on: {
                      "update:error": function ($event) {
                        _vm.postIdsError = $event
                      },
                    },
                    model: {
                      value: _vm.posts,
                      callback: function ($$v) {
                        _vm.posts = $$v
                      },
                      expression: "posts",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "section",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "11" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        elevation: "1",
                        raised: "",
                        disabled: _vm.isSearchDisabled(),
                      },
                      on: { click: _vm.submitFilter },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("\n            mdi-magnify\n          "),
                      ]),
                      _vm._v("\n          Buscar\n        "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                staticClass: "mx-4",
                attrs: {
                  "append-icon": "mdi-refresh",
                  label: "Filtrar dados desta página por",
                  "single-line": "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.annotationsHeaders,
              items: _vm.annotations,
              loading: _vm.loadingAnnotations,
              "custom-sort": _vm.customSort,
              search: _vm.search,
              "items-per-page": "30",
              "hide-default-footer": "",
            },
          }),
          _vm.paginationParams.total > 1
            ? _c(
                "div",
                { staticClass: "text-center pt-2" },
                [
                  _c("StPagination", {
                    on: { "page:changed": _vm.pageChanged },
                    model: {
                      value: _vm.paginationParams,
                      callback: function ($$v) {
                        _vm.paginationParams = $$v
                      },
                      expression: "paginationParams",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          model: {
            value: _vm.showErrorMessage,
            callback: function ($$v) {
              _vm.showErrorMessage = $$v
            },
            expression: "showErrorMessage",
          },
        },
        [
          _vm._v(
            "\n    Não foi possível localizar histórico de anotações para essa busca.\n    "
          ),
          _c(
            "v-btn",
            {
              attrs: { color: "blue", text: "" },
              on: {
                click: function ($event) {
                  _vm.showErrorMessage = false
                },
              },
            },
            [_vm._v("\n      Fechar\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }