var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("default"),
      _c(
        "v-menu",
        {
          attrs: {
            "open-on-hover": "",
            top: "",
            "offset-y": "",
            "close-on-content-click": false,
            rounded: "",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b({ attrs: { dark: "" } }, "v-btn", attrs, false),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v("\n          mdi-information-outline\n        "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            {
              staticClass: "overflow-y-auto",
              staticStyle: { "max-height": "300px" },
            },
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "v-list-item",
                { key: index },
                [_c("v-list-item-title", [_vm._v(" " + _vm._s(item))])],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }