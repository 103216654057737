import { StCompanyService } from '@stilingue/st-company-service';
import { featureFlagFlags } from '@/plugins/rollout';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import gateway from './Gateway';

const version = isProdVersion()
  ? featureFlagFlags.CompanyServiceVersionProd.getValue()
  : featureFlagFlags.CompanyServiceVersionStaging.getValue();

export default new StCompanyService(gateway, version);
