import { now } from 'moment';

export const getDebbuginId = () => {
  const random = Math.floor((Math.random() * 10e13) + 1);
  return [now(), random].join('_');
};

export const getSetUniqueByKey = (arr, keyStrToUnique) => {
  const uniqueSaleIdArr = [...new Set(arr.map(el => el[keyStrToUnique]))];
  return uniqueSaleIdArr;
};
