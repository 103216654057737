import { render, staticRenderFns } from "./CreditsByUsergroup.vue?vue&type=template&id=ad3038e4&scoped=true&"
import script from "./CreditsByUsergroup.vue?vue&type=script&lang=js&"
export * from "./CreditsByUsergroup.vue?vue&type=script&lang=js&"
import style0 from "./CreditsByUsergroup.vue?vue&type=style&index=0&id=ad3038e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad3038e4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/runner/_work/admin-tools-frontend/admin-tools-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ad3038e4')) {
      api.createRecord('ad3038e4', component.options)
    } else {
      api.reload('ad3038e4', component.options)
    }
    module.hot.accept("./CreditsByUsergroup.vue?vue&type=template&id=ad3038e4&scoped=true&", function () {
      api.rerender('ad3038e4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/usergroup/CreditsByUsergroup.vue"
export default component.exports