var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "organizations-view" },
    [
      _c(
        "v-row",
        {
          staticClass: "company-container no-gutters fill-height",
          attrs: { justify: "center", align: "center" },
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "5", lg: "4", xl: "3" } },
            [_c("StLoader", { attrs: { loading: true } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }