var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "section",
        { staticClass: "selector-container" },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: { flat: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "extension",
                      fn: function () {
                        return [
                          _c(
                            "v-tabs",
                            {
                              attrs: { "fixed-tabs": "" },
                              model: {
                                value: _vm.tabs,
                                callback: function ($$v) {
                                  _vm.tabs = $$v
                                },
                                expression: "tabs",
                              },
                            },
                            [
                              _c("v-tabs-slider"),
                              _c(
                                "v-tab",
                                {
                                  staticClass: "primary--text",
                                  attrs: { href: "#mobile-tabs-5-1" },
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { dark: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-database-edit\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Configurações Setup"),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-tab",
                                {
                                  staticClass: "primary--text",
                                  attrs: { href: "#mobile-tabs-5-2" },
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    { attrs: { dark: "" } },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-forum\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("Configurações SmartCare"),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-tab",
                                {
                                  staticClass: "primary--text",
                                  attrs: { href: "#mobile-tabs-5-3" },
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "StIcon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        "icon-name": "history",
                                                      },
                                                    },
                                                    "StIcon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [_c("span", [_vm._v("Histórico")])]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("v-icon", { attrs: { large: "" } }, [
                    _vm._v("\n          mdi-desktop-mac-dashboard\n        "),
                  ]),
                  _vm.selectedUniverse && _vm.selectedAccount
                    ? _c("v-toolbar-title", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.selectedUniverse.name) +
                            " (" +
                            _vm._s(_vm.selectedAccount.name) +
                            ")\n        "
                        ),
                      ])
                    : _c("v-card-title", [
                        _vm._v("\n          Universo indefinido\n        "),
                      ]),
                  _c("StLoader", { attrs: { loading: _vm.loading } }),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tabs,
                    callback: function ($$v) {
                      _vm.tabs = $$v
                    },
                    expression: "tabs",
                  },
                },
                _vm._l(3, function (i) {
                  return _c(
                    "v-tab-item",
                    { key: i, attrs: { value: "mobile-tabs-5-" + i } },
                    [
                      i == 1 && _vm.result
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { sm: "6", md: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "subheading font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Status\n                "
                                          ),
                                        ]
                                      ),
                                      _c("v-card-subtitle", [
                                        _vm._v(
                                          "Data de criação e estado atual"
                                        ),
                                      ]),
                                      _c("v-divider"),
                                      _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-item",
                                            [
                                              _c("v-list-item-content", [
                                                _vm._v(
                                                  "\n                      Criada em " +
                                                    _vm._s(
                                                      _vm.result.created_at
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _vm.result.status === "Parado"
                                                    ? _c(
                                                        "v-progress-linear",
                                                        {
                                                          staticClass:
                                                            "universe_status",
                                                          attrs: {
                                                            value: "50",
                                                            color:
                                                              "red darken-2",
                                                            height: "15",
                                                          },
                                                        },
                                                        [
                                                          [
                                                            _c("strong", [
                                                              _vm._v("Parado"),
                                                            ]),
                                                          ],
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e(),
                                                  _vm.result.status ===
                                                  "Em execução"
                                                    ? _c(
                                                        "v-progress-linear",
                                                        {
                                                          staticClass:
                                                            "universe_status",
                                                          attrs: {
                                                            indeterminate: "",
                                                            color: "teal",
                                                            height: "15",
                                                          },
                                                        },
                                                        [
                                                          [
                                                            _vm._v(
                                                              "\n                          Em execução\n                        "
                                                            ),
                                                          ],
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "subheading font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Publicações\n                "
                                          ),
                                        ]
                                      ),
                                      _c("v-card-subtitle", [
                                        _vm._v("Detalha limites configurados"),
                                      ]),
                                      _c("v-divider"),
                                      _vm.result
                                        ? _c(
                                            "v-list",
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Limite Mensal:"),
                                                  ]),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.result
                                                              .month_posts_limit
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    { attrs: { fab: "" } },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            dark: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.startLimit(
                                                                "Mensal",
                                                                _vm.result
                                                                  .month_posts_limit
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        mdi-pencil\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c("v-list-item-content", [
                                                    _vm._v("Limite Total:"),
                                                  ]),
                                                  _c(
                                                    "v-list-item-content",
                                                    {
                                                      staticClass: "align-end",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.result
                                                              .total_posts_limit
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    { attrs: { fab: "" } },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            dark: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.startLimit(
                                                                "Total",
                                                                _vm.result
                                                                  .total_posts_limit
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        mdi-pencil\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6", md: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "subheading font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Coleta\n                "
                                          ),
                                        ]
                                      ),
                                      _c("v-card-subtitle", [
                                        _vm._v(
                                          "Tipos de coleta e configurações"
                                        ),
                                      ]),
                                      _c("v-divider"),
                                      _c(
                                        "v-list",
                                        [
                                          _c(
                                            "v-list-item",
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  "input-value":
                                                    _vm.models.retweetStatus,
                                                  inset: "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.startRetweet(
                                                      _vm.models.retweetStatus
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c("v-icon", [
                                                            _vm._v(
                                                              "mdi-twitter"
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " Retweet\n                      "
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    _vm.models.retweetStatus,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.models,
                                                      "retweetStatus",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "models.retweetStatus",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  "input-value":
                                                    _vm.result.antispam,
                                                  inset: "",
                                                  readonly: "",
                                                  disabled: "",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                color: "black",
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "span",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {},
                                                                                "span",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-alert"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " Anti-Spam"
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Não é possível alterar essa configuração.\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  "input-value":
                                                    _vm.result
                                                      .is_proprietary_sentimentalization_on,
                                                  inset: "",
                                                  readonly: "",
                                                  disabled: "",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                color: "black",
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "span",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {},
                                                                                "span",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "Análise de sentimento em interações proprietárias"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Não é possível alterar essa configuração.\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  "input-value":
                                                    _vm.result.neutral,
                                                  readonly: "",
                                                  disabled: "",
                                                  inset: "",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                color: "black",
                                                                bottom: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                        attrs,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "span",
                                                                            _vm._g(
                                                                              _vm._b(
                                                                                {},
                                                                                "span",
                                                                                attrs,
                                                                                false
                                                                              ),
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "Neutro na análise de sentimento"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                          Não é possível alterar essa configuração.\n                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6", md: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "subheading font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Idiomas\n                "
                                          ),
                                        ]
                                      ),
                                      _c("v-card-subtitle", [
                                        _vm._v("Idiomas adicionados"),
                                      ]),
                                      _c("v-divider"),
                                      _vm.result && _vm.result.languagesName
                                        ? _c(
                                            "v-card-actions",
                                            [
                                              _c(
                                                "v-list",
                                                _vm._l(
                                                  _vm.result.languagesName,
                                                  function (name) {
                                                    return _c(
                                                      "v-list-item",
                                                      { key: name },
                                                      [
                                                        _c(
                                                          "v-chip",
                                                          {
                                                            attrs: {
                                                              center: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(name) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.result.languagesName
                                                          .length < 3,
                                                      expression:
                                                        "result.languagesName.length < 3",
                                                    },
                                                  ],
                                                  attrs: {
                                                    fab: "",
                                                    top: "",
                                                    absolute: "",
                                                    right: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.startLanguage(
                                                        _vm.result.languages
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        dark: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      mdi-plus\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c("v-card-actions", [
                                            _vm._v(
                                              "\n                  Nenhum idioma encontrado.\n                "
                                            ),
                                          ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "subheading font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Reprocessamentos\n                "
                                          ),
                                        ]
                                      ),
                                      _c("v-card-subtitle", [
                                        _vm._v(
                                          "Lista reprocessamentos solicitados/executados."
                                        ),
                                      ]),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                top: "",
                                                right: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.reprocessHistory()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Detalhar Histórico\n                    "
                                              ),
                                              _c("StIcon", {
                                                attrs: {
                                                  "icon-name":
                                                    "file-document-edit",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      i == 2 && _vm.result
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { sm: "6", md: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "subheading font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Conversas\n                "
                                          ),
                                        ]
                                      ),
                                      _c("v-card-subtitle", [
                                        _vm._v(
                                          "Detalha configurações de conversas"
                                        ),
                                      ]),
                                      _c("v-divider"),
                                      _vm.result.sac_options
                                        ? _c(
                                            "v-list",
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _vm.result.sac_options
                                                    .combine_instagram_comments
                                                    ? _c("v-switch", {
                                                        attrs: {
                                                          "input-value":
                                                            _vm.result
                                                              .sac_options
                                                              .combine_instagram_comments,
                                                          readonly: "",
                                                          disabled: "",
                                                          inset: "",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "label",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "black",
                                                                        bottom:
                                                                          "",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "activator",
                                                                              fn: function ({
                                                                                on,
                                                                                attrs,
                                                                              }) {
                                                                                return [
                                                                                  _c(
                                                                                    "span",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {},
                                                                                        "span",
                                                                                        attrs,
                                                                                        false
                                                                                      ),
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Agrupamento de Instagram"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          Não é possível alterar essa configuração.\n                        "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _vm.result.sac_options
                                                    .automatically_annotate
                                                    ? _c("v-switch", {
                                                        attrs: {
                                                          "input-value":
                                                            _vm.result
                                                              .sac_options
                                                              .automatically_annotate,
                                                          readonly: "",
                                                          disabled: "",
                                                          inset: "",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "label",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "black",
                                                                        bottom:
                                                                          "",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "activator",
                                                                              fn: function ({
                                                                                on,
                                                                                attrs,
                                                                              }) {
                                                                                return [
                                                                                  _c(
                                                                                    "span",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {},
                                                                                        "span",
                                                                                        attrs,
                                                                                        false
                                                                                      ),
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Anotações automáticas"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          Não é possível alterar essa configuração.\n                        "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { sm: "6", md: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass:
                                            "subheading font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Resposta\n                "
                                          ),
                                        ]
                                      ),
                                      _c("v-card-subtitle", [
                                        _vm._v("Acordo de Nível de Serviço"),
                                      ]),
                                      _c("v-divider"),
                                      _vm.result && _vm.result.sac_options
                                        ? _c(
                                            "v-list",
                                            [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _vm.result.sac_options
                                                    .sla_agreed
                                                    ? _c(
                                                        "v-list-item-content",
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                _vm.result
                                                                  .sac_options
                                                                  .sla_agreed
                                                                  .months
                                                              ) +
                                                              " meses e\n                      " +
                                                              _vm._s(
                                                                _vm.result
                                                                  .sac_options
                                                                  .sla_agreed
                                                                  .days
                                                              ) +
                                                              " dias às\n                      " +
                                                              _vm._s(
                                                                _vm.result
                                                                  .sac_options
                                                                  .sla_agreed
                                                                  .hours
                                                              ) +
                                                              " horas e\n                      " +
                                                              _vm._s(
                                                                _vm.result
                                                                  .sac_options
                                                                  .sla_agreed
                                                                  .minutes
                                                              ) +
                                                              " minutos.\n                    "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm.result.sac_options
                                                .time_to_close
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-list-item-content",
                                                        [
                                                          _vm._v(
                                                            "Fechamento automático após " +
                                                              _vm._s(
                                                                _vm.result
                                                                  .sac_options
                                                                  .time_to_close
                                                              ) +
                                                              " horas."
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      i == 3 && _vm.result
                        ? _c("v-row", [_c("UniverseHistory")], 1)
                        : _vm._e(),
                      !_vm.loading && (!_vm.result || _vm.error)
                        ? _c(
                            "v-row",
                            [
                              _c("v-card-text", [
                                _vm._v("Nenhum dado encontrado."),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      i == 2 &&
                      !_vm.loading &&
                      _vm.result &&
                      !_vm.result.sac_options
                        ? _c(
                            "v-row",
                            [
                              _c("v-card-text", [
                                _vm._v("Nenhum dado encontrado."),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }