<template>
  <div>
    <slot />
    <v-menu
      open-on-hover
      top
      offset-y
      :close-on-content-click="false"
      rounded
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark>
            mdi-information-outline
          </v-icon>
        </v-btn>
      </template>

      <v-list
        style="max-height: 300px"
        class="overflow-y-auto"
      >
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
        >
          <v-list-item-title> {{ item }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: 'StMenu',
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      required: true
    },
  }
};
</script>
