export default {
  label: {
    button: {
      next: 'Próximo',
      back: 'Voltar',
      cancel: 'Cancelar',
      activate: 'Ativar',
      deactivate: 'Desativar',
      add: 'Adicionar',
      edit: 'Editar',
      delete: 'Excluir',
      remove: 'Remover',
      clean: 'Limpar',
      submit: 'Submeter',
      send: 'Enviar',
      search: 'Buscar',
    },
    placeholder: {
      yes: 'Sim',
      no: 'Não',
      link: 'Link',
    },
    message: {
      hint: {
        email: 'Entre um email e pressione Enter',
      },
      success: {
        changed: 'Alteração realizada com sucesso.'
      },
      error: {
        withoutSpace: 'Não são permitidos espaços',
        mustBeNumber: 'Não são permitidos letras',
        invalidInfo: 'Máximo de 16 caracteres',
        emptyField: 'Não pode ser vazio',
        addEmail: 'Adicione algum email',
        addAllEmails: 'Todos emails precisam ser validos',
        email: 'Endereço de e-mail inválido',
      },
    },
  },
};
