<template>
  <v-main class="organizations-view">
    <v-row
      class="company-container no-gutters fill-height"
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        md="5"
        lg="4"
        xl="3"
      >
        <StLoader :loading="true" />
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import StLoader from '@stilingue/st-loader';

export default {
  name: 'Loading',
  components: {
    StLoader
  }
};
</script>
