import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import { createApi } from '@stilingue/st-auth-utils';
import WarroomApiService from './ServiceClass';
import { StagingUrl, ProductionUrl } from './params';

const gateway = isProdVersion()
  ? ProductionUrl
  : StagingUrl;

const afterParser = (response) => {
  return response.data;
};

const makeService = (url) => {
  const desiredAPI = createApi(url, gateway, { timeout: 60000 });
  return new WarroomApiService(desiredAPI, { afterParser });
};

const AccountsService = makeService('ojesed/getaccounts');
const PagesService = makeService('ojesed/getpagesbyaccount');
const PageHistoryService = makeService('ojesed/getpagehistory');
const UniversesService = makeService('ojesed/getuniversesbyaccount');
const UniverseHistoryService = makeService('ojesed/report/universe/history');
const GroupsService = makeService('ojesed/getgroupsbyuniverse');
const UserGroupAccessService = makeService('ojesed/report/access/usergroup');
const AccountAccessService = makeService('ojesed/report/access/account');
const UserAccessService = makeService('ojesed/report/access/user');

const WarroomService = {
  AccountsService,
  PagesService,
  PageHistoryService,
  UniversesService,
  UniverseHistoryService,
  GroupsService,
  UserGroupAccessService,
  AccountAccessService,
  UserAccessService
};

export default WarroomService;
