<template>
  <div class="retroactive-list">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <v-alert
      v-if="error"
      border="right"
      colored-border
      type="error"
      elevation="2"
    >
      {{ labels.error }}
    </v-alert>
    <section class="selector-container">
      <v-form
        ref="eyesForm"
        v-model="models.valid"
        :lazy-validation="false"
      >
        <SingleSelect
          v-model="selectedAccount"
          required
          :items="accounts"
          :placeholder="$t('EyesActivationAndList.selectAnAccount')"
          text="name"
          sub-text="group"
        />
        <SingleSelect
          v-if="activeAccount"
          v-model="selectedUniverse"
          :items="universes"
          :placeholder="$t('EyesActivationAndList.selectAnUniverse')"
          text="name"
          sub-text="channel"
          :rules="rules.mustBeFilled"
        />
        <v-btn
          :disabled="!models.valid || loading"
          color="light-blue"
          class="mr-4"
          @click.stop="addItem"
        >
          {{ $t('EyesActivationAndList.requestActivation') }}
        </v-btn>
      </v-form>
      <StLoader :loading="loading" />
      <v-alert
        v-if="error"
        border="right"
        colored-border
        type="error"
        elevation="2"
      >
        {{ labels.errorMessage }}
      </v-alert>
      <v-alert
        v-if="warning"
        border="right"
        colored-border
        type="warning"
        elevation="2"
      >
        {{ labels.warningMessage }}
      </v-alert>
      <v-alert
        v-if="success"
        border="right"
        colored-border
        type="success"
        elevation="2"
      >
        {{ labels.successMessage }}
      </v-alert>
    </section>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="table.search"
          :label="$t('EyesActivationAndList.search')"
          class="mx-4"
        />
        <div>
          <v-icon
            class="retroactive-list__refresh mx-8"
            :class="{'retroactive-list__refresh--loading': loading}"
            @click="getEyesList"
          >
            mdi-refresh
          </v-icon>
        </div>
      </v-card-title>
      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :search="table.search"
      >
        <template v-slot:item.url="{ item }">
          <a
            :href="item.url"
            target="_blank"
          >
            {{ $t('EyesActivationAndList.link') }}
          </a>
        </template>
        <template v-slot:item.rollout_enabled="{ item }">
          <v-chip
            outlined
            filter
            :color="enabledChipStatus(item.rollout_enabled).color"
          >
            {{ enabledChipStatus(item.rollout_enabled).text }}
          </v-chip>
        </template>

        <template v-slot:item.mongo_enabled="{ item }">
          <v-chip
            outlined
            filter
            :color="enabledChipStatus(item.mongo_enabled).color"
          >
            {{ enabledChipStatus(item.mongo_enabled).text }}
          </v-chip>
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn
            color="light-blue"
            class="mr-4"
            @click.stop="editItem(item)"
          >
            {{ $t('EyesActivationAndList.confirmDeactivationYesBtn') }}
          </v-btn>
        </template>

        <template v-slot:top>
          <v-dialog
            v-model="dialog"
            max-width="400px"
          >
            <v-card>
              <v-card-title class="dialog-title">
                {{ $t('EyesActivationAndList.confirmDeactivationTitle') }}
              </v-card-title>
              <v-card-text>
                {{ $t('EyesActivationAndList.confirmDeactivationContent') }}
                <v-alert
                  v-if="error"
                  colored-border
                  type="error"
                  dense
                  outlined
                  elevation="2"
                >
                  {{ labels.confirmDeactivationError }}
                </v-alert>
              </v-card-text>
              <v-card-text>
                {{ models.itemOfList && models.itemOfList.universe_id }}
                <v-alert
                  v-if="error"
                  colored-border
                  type="error"
                  dense
                  outlined
                  elevation="2"
                >
                  {{ labels.confirmDeactivationError }}
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  class="ma-2"
                  outlined
                  @click="close"
                >
                  {{ $t('EyesActivationAndList.confirmDeactivationNoBtn') }}
                </v-btn>
                <v-btn
                  color="primary"
                  @click="updateEyes()"
                >
                  {{ $t('EyesActivationAndList.confirmDeactivationYesBtn') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip
            outlined
            class="ma-1"
            filter
            :color="colorBar(item.status).color"
          >
            {{ item.status }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import ITService from '@/services/ITService';
import PageHeader from '@/components/base/PageHeader';
import { mapActions, mapState } from 'vuex';
import SingleSelect from '../SingleSelect.vue';
import StLoader from '@stilingue/st-loader';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import WarroomService from '@/services/WarroomService';
import { getSetUniqueByKey } from '@/utils/utils';

const { UniversesService } = WarroomService;

export default {
  name: 'EyesActivationAndList',
  components: { PageHeader, StLoader, SingleSelect },
  data() {
    return {
      status: [
        'Ativar', 'Desativar'
      ],
      labels: {
        confirmDeactivationError: 'Erro inesperado! Por favor, refaça a operação.',
        error: 'Erro ao carregar os dados.',
        successMessage: 'Requisição enviada com sucesso.',
        warningMessage: '',
        errorMessage: 'Erro inesperado. Requisição não completada.',
        title: 'Eyes - Ativação e Lista de ativados',
        description: 'Formulário de ativação/desativação e Lista de contas / painéis (pesquisa), contendo o serviço Eyes ativado.',
        breadcrumbs: [
          {
            text: 'Home',
            href: '/'
          },
          {
            text: 'Eyes',
            to: '/eyes'
          },
          {
            text: 'Ativação e Lista',
            to: '/eyes/activation-and-list'
          }
        ],
      },
      table: {
        headers: [
          {
            text: 'Conta',
            value: 'account_id',
            align: 'center'
          },
          {
            text: 'Nome da Conta',
            value: 'account_id_name',
            align: 'center'
          },
          {
            text: 'Painel',
            value: 'universe_id',
            align: 'center'
          },
          {
            text: 'Nome do Painel',
            value: 'universe_id_name',
            align: 'center'
          },
          {
            text: 'Grupo',
            value: 'group',
            align: 'center'
          },
          {
            text: 'Status do Painel',
            value: 'status',
            align: 'center'
          },
          {
            text: 'Ativado no Rollout',
            value: 'rollout_enabled',
            align: 'center'
          },
          {
            text: 'Ativado no Mongo',
            value: 'mongo_enabled',
            align: 'center'
          },
          {
            text: 'URL',
            value: 'url',
            align: 'center'
          },
          {
            text: 'Ativado por',
            value: 'requester',
            align: 'center'
          },
          {
            text: 'Data de Ativação',
            value: 'last_updated',
            align: 'center'
          },
          {
            text: 'Desativar Eyes',
            value: 'action',
            align: 'center'
          },
        ],
        search: '',
        items: [],
      },
      models: {
        valid: false,
        status: true,
        itemOfList: undefined,
        accountsList: undefined,
        uniqueAccounts: undefined,
        activeUniverses: [],
      },
      rules: {
        mustBeFilled: [v => {
          return Boolean(v) || 'Não pode ser vazio';
        }],
      },
      error: false,
      success: false,
      warning: false,
      loading: false,
      confirmation: false,
      dialog: false,
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    ...mapState('universe', ['universes', 'activeUniverse']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      },
      set(selectedUniverse) {
        this.selectUniverse(selectedUniverse);
      },
    },
    userId() {
      return this.user.id;
    },
  },
  async created() {
    await this.getAccounts({ userId: this.userId });
    if (!this.selectedAccount) return {};
  },
  async mounted() {
    this.models.accountsList = await this.getAccounts({ userId: this.userId });
    await this.getEyesList();
  },
  methods: {
    ...mapActions('account', ['getAccounts', 'setAccount']),
    ...mapActions('universe', ['getAccountUniverses', 'setUniverse']),
    close () {
      this.dialog = false;
    },
    addItem() {
      this.models.status = true;
      this.updateEyes();
    },
    editItem(item) {
      this.defaultDialog();
      this.models.status = false;
      this.models.itemOfList = item;
      this.dialog = true;
    },
    defaultDialog() {
      this.confirmation = false;
    },
    selectAccount(selectedAccount, selectedUniverse) {
      this.setAccount({ selectedAccount });
      this.getAccountUniverses({ selectedAccount, fetchHistory: true }).then(() => {
        if (selectedUniverse) {
          this.selectedUniverse = selectedUniverse;
        } else {
          this.selectedUniverse = undefined;
        }
        this.selectUniverse(this.selectedUniverse);
      });
    },
    selectUniverse(selectedUniverse) {
      this.setUniverse({ selectedUniverse });
    },
    requesterTreatment(stringRequester) {
      const internalRequester = {
        [this.$t('EyesActivationAndList.tableStatusNoAuth')]: this.$t('EyesActivationAndList.internalEdited')
      };
      let result = '';
      if (stringRequester) {
        result = internalRequester[stringRequester] ? internalRequester[stringRequester] : stringRequester.split('@')[0].split('.').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');
      } else if (!stringRequester) {
        result = this.$t('EyesActivationAndList.tableStatusNotAvailable');
      }
      return result;
    },
    getParameters() {
      let parameters = null;
      if (this.models.status) {
        parameters = {
          accountId: Number(this.selectedAccount.id),
          universeId: Number(this.selectedUniverse.id),
          status: this.models.status,
        };
        return parameters;
      }
      parameters = {
        accountId: Number(this.models.itemOfList.account_id),
        universeId: Number(this.models.itemOfList.universe_id),
        status: this.models.status,
      };
      return parameters;
    },
    showVAlert(res) {
      const respStOk = () => {
        this.success = true;
        this.warning = this.error = false;
        this.labels.successMessage = this.models.status === true ? this.$t('EyesActivationAndList.successfulyActivated') : this.$t('EyesActivationAndList.successfulyDeactivated');
      };
      const respStNotMod = () => {
        this.success = this.error = false;
        this.warning = true;
        this.labels.warningMessage = this.models.status === true ? this.$t('EyesActivationAndList.alreadyActivated') : this.$t('EyesActivationAndList.alreadyDeactivated');
      };
      const respStServUnav = () => {
        this.success = this.error = false;
        this.warning = true;
        this.labels.warningMessage = this.$t('EyesActivationAndList.serverBusy');
      };
      const respStErr = () => {
        this.success = this.warning = false;
        this.error = true;
        this.labels.errorMessage = this.$t('EyesActivationAndList.requestError');
      };
      const cases = {
        [this.$t('EyesActivationAndList.respStatusOk')]: respStOk,
        [this.$t('EyesActivationAndList.respStatusNotModified')]: respStNotMod,
        [this.$t('EyesActivationAndList.respStatusServiceUnavailable')]: respStServUnav,
      };
      const switchFn = (lookupObject, defaultCase = '_default') =>
        expression => (lookupObject[expression] || lookupObject[defaultCase])();
      const caseSwitch = switchFn(cases, respStErr);
      caseSwitch(res.statusCode);
    },
    updateEyes() {
      this.error = false;
      this.loading = true;
      const parameters = this.getParameters();
      ITService.eyesService(parameters).then((response) => {
        this.showVAlert(response);
        this.close();
        this.getEyesList();
      }).catch(err => {
        this.error = true;
        this.loading = false;
        this.labels.errorMessage = err.description;
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    async setActiveUniverses(universes) {
      this.models.uniqueAccounts = await getSetUniqueByKey(universes, 'account_id');
      const parArr = [];
      await Promise.all(
        this.models.uniqueAccounts.map(async el => {
          await UniversesService.find({ url: `/${el}` })
            .then(async data => {
              const { message = [] } = await data;
              parArr.push(...message);
            }).catch((e) => {
              console.error(e);
            });
        })
      );

      return parArr;
    },
    async getEyesList() {
      try {
        this.error = false;
        this.table.items = [];
        this.loading = true;
        const jsonResponse = await ITService.eyesServiceList();

        const activeUniversesList = await this.setActiveUniverses(jsonResponse.universes);

        await this.setTableItems(jsonResponse.universes, activeUniversesList);
      } catch (e) {
        this.error = true;
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    findItemInList(listToFindValue, AccIdOrUnivIdValueToFindInList) {
      const foundAccIdOrUnivId = listToFindValue.find(eachElemInList => eachElemInList.id === AccIdOrUnivIdValueToFindInList);
      return foundAccIdOrUnivId === undefined || foundAccIdOrUnivId === null ? this.$t('EyesActivationAndList').tableStatusNotAvailable : foundAccIdOrUnivId.name;
    },
    async setTableItems(items, activeUniversesList) {
      const statusMap = {
        [this.$t('EyesActivationAndList').tableStatusStopped]: this.$t('EyesActivationAndList').statusNamePaused,
        [this.$t('EyesActivationAndList').tableStatusInExecution]: this.$t('EyesActivationAndList').tableStatusActive
      };

      const arrWithoutDate = items.filter(el => !el.last_updated);
      const arrWithDate = items.filter(el => el.last_updated);

      const arrSorted = arrWithDate.sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated));

      this.table.items = await arrSorted.concat(arrWithoutDate).map(item => {
        const version = isProdVersion() ? this.$t('EyesActivationAndList').warroomProdPath : this.$t('EyesActivationAndList').warroomStagingPath ;
        item.url = `https://${version}.com.br/ativarcontapesquisa/${item.account_id}/${item.universe_id}`;

        item.account_id_name = this.findItemInList(this.models.accountsList, item.account_id);

        item.universe_id_name = this.findItemInList(activeUniversesList, item.universe_id);

        if (statusMap[item.status]) {
          item.status = statusMap[item.status];
        }
        item.requester = this.requesterTreatment(item.requester);
        if (item.last_updated) {
          item.last_updated = new Date(item.last_updated).toLocaleString('pt-BR');
        }
        if (!item.last_updated) {
          item.last_updated = this.$t('EyesActivationAndList').tableStatusNotAvailable;
        }
        const newItem = { ...item };

        return newItem;
      });
    },
    colorBar(statusName) {
      if (statusName === this.$t('EyesActivationAndList').statusNameDeleted) {
        return { color: this.$t('EyesActivationAndList').colorRed, text: this.$t('EyesActivationAndList').colorWhite, };
      }
      if (statusName === this.$t('EyesActivationAndList').statusNameLimited) {
        return { color: this.$t('EyesActivationAndList').colorOrange, text: this.$t('EyesActivationAndList').colorBlack, };
      }
      if (statusName === this.$t('EyesActivationAndList').statusNamePaused) {
        return { color: this.$t('EyesActivationAndList').colorYellow, text: this.$t('EyesActivationAndList').colorBlack, };
      }
      return { color: this.$t('EyesActivationAndList').colorPrimary, text: this.$t('EyesActivationAndList').colorWhite };
    },
    enabledChipStatus(itemReceived) {
      if (itemReceived === true) {
        return { color: this.$t('EyesActivationAndList').colorPrimary, text: this.$t('EyesActivationAndList').textYes };
      }
      return { color: this.$t('EyesActivationAndList').colorYellow, text: this.$t('EyesActivationAndList').textNo };
    },
  },
};

</script>

<style scoped lang="scss">

.v-btn {
  text-transform: none;
  padding: 0 25px;
}

.v-alert {
  margin-top: 20px;
}

.v-select {
  padding-top: 15px;
  padding-bottom: 10px;
}

.v-card {
  .dialog-title {
    font-size: var(--ds-font-size-baseline) !important;
    font-weight: var(--ds-font-weight-semibold);
    padding-bottom: 25px;
  }

  .v-btn {
    text-transform: none;
    padding: 0 25px;
  }

  .v-card__text {
    color: var(--ds-color-white);
  }

  .v-alert {
    margin-top: 20px;
  }

  .v-select {
    padding-top: 15px;
    padding-bottom: 10px;
  }
}

.ma-1 {
  width: 95px;
  display: flex;
  justify-content: center;
}

.ma-2 {
  width: 115px;
  display: flex;
  justify-content: center;
}

.retroactive-list__refresh {
  cursor: pointer;

  &--loading {
    color: #00aaa7;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
}

.retroactive-list {
  .v-chip.green {
    background: #2c9688 !important;
  }
}

@-moz-keyframes spin-moz {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-web {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.selector-container {
  width: 700px;

  form {
    margin-bottom: 35px;
  }

  .action-type {
    > div.v-radio {
      float: left;
      padding-right: 30px;
    }
  }

  .checkbox-area {
    padding: 0;

    > div {
      display: inline-block;
      margin-right: 50px;
    }
  }

  .date-area {
    padding: 0;

    > div {
      width: 45%;
      display: inline-block;
    }

    > div:nth-child(2) {
      margin-left: 70px;
    }

    span.date-error {
      font-size: 13px;
      text-align: center;
      display: block;
      caret-color: #fff !important;
      background-color: rgba(201, 76, 76, 0.3);
      border-radius: 5px;
      padding: 5px;
    }
  }

  .result-percentage {
    div:first-child {
      float: left;
      padding-top: 15px;
      padding-right: 15px;

      span {
        display: block;
      }
    }
  }
}

.card_title {
  font-style: normal;
  font-weight: var(--ds-font-weight-semibold);
  line-height: 28px;
}

.theme--dark.v-card > .v-card__text {
  color: var(--ds-color-white);
  padding: 16px 24px 25px;
  text-align: justify;

  > b {
    color: #ff5400;
  }
}

.v-card__actions > .v-btn.v-btn {
  border: var(--ds-border-width-hairline) solid;
  box-sizing: border-box;
  margin: 0 var(--ds-spacing-stack-xs);
}

.button-dialog-update {
  border-color: var(--ds-color-primary);
  background-color: var(--ds-color-primary);
  color: var(--ds-color-brand-3);
}

</style>
