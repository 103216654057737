var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "base-layout no-gutters" },
    [
      _c("StHeader", {
        on: { "side-icon-clicked": _vm.toggleDrawer },
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function () {
              return [
                _c("StUserMenu", {
                  attrs: {
                    user: _vm.user,
                    greeting: _vm.$t("Layout.greeting"),
                    "logout-label": _vm.$t("Layout.logout"),
                  },
                  on: {
                    "logout-item-clicked": _vm.logoutAndSendUserToLoginPage,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-main",
        { staticClass: "content-dark" },
        [
          _c(
            "v-row",
            { staticClass: "no-gutters fill-height" },
            [
              _c("LayoutContent"),
              _c("StSystemNavigationDrawer", {
                attrs: {
                  drawer: _vm.drawer,
                  items: _vm.itemsInDrawer,
                  "active-url": _vm.itemActive,
                },
                on: {
                  navigate: function ($event) {
                    return _vm.clickOnNavigate($event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }