import StService from './StService';
import { createApi } from '@stilingue/st-auth-utils';
import { featureFlagFlags } from '@/plugins/rollout';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';

import gateway from './Gateway';

const version = isProdVersion()
  ? featureFlagFlags.CreditsSystemServiceVersionProd.getValue()
  : featureFlagFlags.CreditsSystemServiceVersionStaging.getValue();

const afterParser = response => response.data;

class CreditsSystemService extends StService {
  constructor(gateway, version = 'staging') {
    const api = createApi(`credits-system/${version}/by-examples`, gateway, { timeout: 120000 });
    super(api, { afterParser });
  }

  getCredits(body) {
    return this.api.post(body).then(this.afterParser);
  }
}

export default new CreditsSystemService(gateway, version);
