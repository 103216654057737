<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <v-alert
      v-if="error"
      right
      colored-border
      type="error"
      elevation="2"
    >
      {{ BenchmarksEdit.errorLoadList }}
    </v-alert>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="table.search"
          label="Buscar"
          class="mx-4"
          prepend-icon="mdi-magnify"
        />
        <div>
          <v-icon
            class="benchmarks-list__refresh mx-8"
            :class="{'benchmarks-list__refresh--loading': loading}"
            @click="getBenchmarks"
          >
            mdi-refresh
          </v-icon>
        </div>
      </v-card-title>
      <StLoader :loading="loading" />
      <v-data-table
        v-if="!error"
        :headers="table.headers"
        :items="table.items"
        :search="table.search"
        :custom-sort="customSort"
        class="elevation-1"
      >
        <template v-slot:item.url="{ item }">
          <a
            :href="item.url"
            target="_blank"
          >
            {{ item.url.slice(0, 50) + '...' }}
          </a>
        </template>
        <template v-slot:item.freePlan="{ item }">
          <v-chip
            v-if="item.freePlan == 'yes'"
            outlined
            filter
            color="primary"
          >
            {{ $t('benchmarksEdit.yes') }}
          </v-chip>
          <v-chip
            v-else
            outlined
            filter
            color="yellow"
          >
            {{ $t('benchmarksEdit.no') }}
          </v-chip>
        </template>
        <template v-slot:item.expiryDateFormatted="{ item }">
          <v-chip
            v-if="item.expired"
            outlined
            filter
            color="red"
          >
            {{ $t('benchmarksEdit.expired') }} {{ item.expiryDateFormatted }}
          </v-chip>
          <span v-if="item.expiryDateFormatted && !item.expired">
            {{ item.expiryDateFormatted }}
          </span>
          <span v-if="!item.expiryDateFormatted && !item.expired">-</span>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
        <template v-slot:top>
          <v-dialog
            v-model="dialog"
            max-width="400px"
          >
            <v-card>
              <v-card-title class="dialog-title">
                {{ $t('benchmarksEdit.editFreeTrial') }}
              </v-card-title>
              <v-card-text>
                {{ $t('benchmarksEdit.confirmFreeTrial') }}
                <v-radio-group
                  v-model="editedItem.freePlan"
                  row
                >
                  <v-radio
                    label="Não"
                    value="no"
                  />
                  <v-radio
                    label="Sim"
                    value="yes"
                  />
                </v-radio-group>
                <div v-if="editedItem.freePlan == 'yes'">
                  <v-select
                    v-model="selectedPeriod"
                    :items="periods"
                    item-text="name"
                    item-value="id"
                    label="Selecione o período gratuito"
                    return-object
                  />
                  <span v-if="expiryDateSelected">Data expiração: {{ expiryDateSelectedFormatted }}</span>
                </div>
                <v-alert
                  v-if="confirmation && editedItem.freePlan == 'no'"
                  type="warning"
                >
                  <b>{{ $t('benchmarksEdit.removePeriod') }}</b>
                </v-alert>
                <v-alert
                  v-if="errorUpdate"
                  colored-border
                  type="error"
                  dense
                  outlined
                  elevation="2"
                >
                  {{ $t('benchmarksEdit.errorUnexpected') }}
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  class="ma-2"
                  outlined
                  @click="close"
                >
                  {{ $t('benchmarksEdit.cancel') }}
                </v-btn>
                <v-btn
                  v-if="editedItem.freePlan == 'yes' || !confirmation"
                  color="primary"
                  @click="save()"
                >
                  {{ $t('benchmarksEdit.save') }}
                </v-btn>
                <v-btn
                  v-if="editedItem.freePlan == 'no' && confirmation"
                  color="primary"
                  @click="save()"
                >
                  {{ $t('benchmarksEdit.yesConfirm') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import StLoader from '@stilingue/st-loader';
import PageHeader from '@/components/base/PageHeader';
import BenchmarksService from '@/services/BenchmarksService';
import dateComponents from '@/utils/date-utils';

export default {
  name: 'BenchMarksSearch',
  components: {
    PageHeader, StLoader
  },
  data: () => ({
    labels: {
      title: 'Editar Benchmarks',
      description: 'Permite buscar Benchmarks pelo user group, acessar direto pela URL e editar a versão.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Benchmarks',
          to: '/benchmarks'
        },
        {
          text: 'Editar',
          to: '/benchmarks/edit'
        }
      ]
    },
    table: {
      headers: [
        {
          text: 'Data e hora da criação',
          value: 'createdDateFormatted',
          align: 'center'
        },
        {
          text: 'Id do plano',
          value: 'planId',
          align: 'center'
        },
        {
          text: 'Nome do Benchmarks',
          value: 'name',
          align: 'center'
        },
        {
          text: 'User group',
          value: 'planName',
          align: 'center'
        },
        {
          text: 'URL',
          value: 'url',
        },
        {
          text: 'Período gratuito?',
          value: 'freePlan',
          align: 'center'
        },
        {
          text: 'Data expiração',
          value: 'expiryDateFormatted',
          align: 'center'
        },
        {
          text: 'Ação',
          value: 'action',
          align: 'center'
        }
      ],
      search: '',
      'must-sort': true,
      items: []
    },
    loading: false,
    error: false,
    errorUpdate: false,
    dialog: false,
    editedItem: {},
    saveName: 'Salvar',
    confirmation: false,
    expiryDateSelected: false,
    periods: [
      { id: 7, name: '7 dias' },
      { id: 15, name: '15 dias' },
      { id: 30, name: '30 dias' },
      { id: 45, name: '45 dias' },
      { id: 60, name: '60 dias' }
    ],
    benchmarks: []
  }),
  computed: {
    ...mapState('benchmark', ['activePeriod']),
    selectedPeriod: {
      get() {
        return this.activePeriod;
      },
      set(selectedPeriod) {
        this.selectPeriod(selectedPeriod);
      }
    }
  },
  watch: {
    dialog (val) {
      val || this.close();
    }
  },
  created() {
    this.getBenchmarks();
  },
  methods: {
    ...mapActions('benchmark', ['setPeriod']),
    async getBenchmarks() {
      try {
        this.error = false;
        this.table.items = [];
        this.loading = true;
        this.benchmarks = await BenchmarksService.getBenchmarks();
        this.setTableItems();
      } catch (e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    defaultDialog() {
      this.confirmation = false;
      this.selectPeriod(this.periods[1]);
    },
    selectPeriod(selectedPeriod) {
      this.setPeriod({ selectedPeriod });
      this.expiryDateSelected = dateComponents.addDays(selectedPeriod.id);
      this.expiryDateSelectedFormatted = dateComponents.formatDate(this.expiryDateSelected, 'DD/MM/YYYY');
    },
    editItem(item) {
      this.defaultDialog();
      this.editedIndex = this.table.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    setTableItems() {
      this.table.items = this.benchmarks.map(item => {
        item.createdDateFormatted = dateComponents.formatDate(item.createdDate, 'DD/MM/YY - HH:mm');
        if (item.expiryDate) {
          item.expiryDateFormatted = dateComponents.formatDate(item.expiryDate, 'DD/MM/YYYY');
          item.freePlan = 'yes';
        } else {
          item.expiryDateFormatted = '';
          item.freePlan = 'no';
        }
        return item;
      });
    },
    customSort(items, index, isDesc) {
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line complexity
      items.sort((a, b) => {
        if (index[0] === 'createdDateFormatted') {
          if (!isDesc[0]) {
            return a.createdDate - b.createdDate;
          }
          return b.createdDate - a.createdDate;
        } else if (index[0] === 'expiryDateFormatted') {
          if (!isDesc[0]) {
            return a.expiryDate - b.expiryDate;
          }
          return b.expiryDate - a.expiryDate;
        } else if (!isDesc[0]) {
          return a[index] < b[index] ? -1 : 1;
        }
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    },
    async save() {
      try {
        this.loading = true;
        let expiryDate = '';
        this.errorUpdate = false;
        if (this.editedItem.freePlan === 'no' && !this.confirmation) {
          this.confirmation = true;
          return false;
        }

        if (this.editedItem.freePlan === 'yes') {
          expiryDate = dateComponents.formatDate(this.expiryDateSelected, 'YYYY-MM-DD');
        }

        const itemUpdated = await BenchmarksService.updateBenchmarks(expiryDate, this.editedItem.id);
        if (!itemUpdated.expiryDate) {
          itemUpdated.expiryDate = '';
        }
        Object.assign(this.benchmarks[this.editedIndex], itemUpdated);
        this.setTableItems();
        this.close();
      } catch (e) {
        this.errorUpdate = true;
      } finally {
        this.loading = false;
      }
    },
    close () {
      this.dialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
  .v-card {
    .dialog-title {
      font-size: 16px !important;
      font-weight: 600;
      padding-bottom: 25px;
    }

    .v-btn {
      text-transform: none;
      padding: 0 25px;
    }

    .v-card__text {
      color: #ffff;
    }

    .v-alert {
      margin-top: 20px;
    }

    .v-select {
      padding-top: 15px;
      padding-bottom: 10px;
    }
  }
</style>
