<template>
  <div class="new-retroactive-prorietary">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <div class="warning">
      <v-card flat>
        <v-card-text>
          <span>
            {{ labels.documentation }}
            <a
              target="_blank"
              href="https://stilingue-jira.atlassian.net/wiki/spaces/CDS/pages/553123880/Aumentar+limite+de+tempo+e+quantidade+para+coleta+de+m+tricas+propriet+rias"
            >documentação
            </a>
          </span>.
        </v-card-text>
      </v-card>
    </div>
    <div class="new-retroactive-prorietary__form-and-result">
      <StLoader :loading="loading" />
      <v-radio-group
        v-model="crawlerOrItServices"
        @change="getAccountPages({ selectedAccount })"
      >
        <div class="new-retroactive__crawler-or-it-services">
          <v-radio
            :label="labels.crawler"
            value="crawler"
            @change="clearAll"
          />
          <v-radio
            :label="labels.itServices"
            value="itServices"
            @change="clearAll"
          />
        </div>
      </v-radio-group>
      <SingleSelect
        v-model="selectedAccount"
        required
        :items="accounts"
        :placeholder="labels.account"
        :rules="rules.isValue"
        text="name"
        sub-text="group"
        @change="clearAll"
      />
      <v-form
        ref="form"
        v-model="models.valid"
        class="new-retroactive-prorietary__form"
        :lazy-validation="false"
      >
        <PageSelection
          v-if="activeAccount"
          v-model="activePages"
          :items="pages"
          required
        />
        <v-text-field
          v-model="models.task"
          required
          :name="Math.random()"
          :rules="rules.isTask"
          :label="labels.task"
          @input="setErrorFalse"
        />
        <template>
          <v-menu
            v-model="modal"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :label="labels.inputs.executionDate"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="date"
              :max="today"
              @input="modal = false"
            />
          </v-menu>
        </template>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ labels.advancedOptions }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider class="mt-2" />
            </v-expansion-panel-content>
            <v-expansion-panel-content>
              <div class="max-posts">
                <div class="max-posts-input">
                  <v-icon
                    color="yellow"
                  >
                    mdi-alert-circle
                  </v-icon>
                  <v-text-field
                    v-if="!crawlerService"
                    v-model="models.maxPosts"
                    hint="Apenas números"
                    type="number"
                    :label="labels.maxPosts"
                    @input="setErrorFalse"
                    @focus="showMaxPostsAlert"
                    @blur="hideMaxPostsAlert"
                  />
                </div>
                <v-card
                  v-if="!isDisabled"
                >
                  <v-card-text>
                    {{ labels.maxPostsAlert }}
                  </v-card-text>
                </v-card>
              </div>
              <template
                v-if="!crawlerService"
              >
                <MultipleSelect
                  v-for="item in channels"
                  :key="item.channelName"
                  v-model="item.activeFlowTypes"
                  :class="{ disabled: item.isDisabled }"
                  :items="item.flowTypes"
                  :label="item.label"
                  :text="item.flowTypes"
                  chips
                  @input="setErrorFalse"
                />
              </template>
              <template
                v-else
              >
                <MultipleSelect
                  v-for="item in channels"
                  :key="item.channelName"
                  v-model="item.activeFlowTypes"
                  :class="{ disabled: item.isDisabled }"
                  :items="item.crawlerFlowTypes"
                  :label="item.label"
                  :text="item.crawlerFlowTypes"
                  chips
                  @input="setErrorFalse"
                />
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-select
          v-if="!crawlerService"
          v-model="models.state"
          :items="state"
          :label="labels.state"
          :rules="rules.isValue"
          required
          @input="setErrorFalse"
        />
        {{ showActiveFlowTypes() }}
      </v-form>
      <v-btn
        :disabled="!models.valid || loading"
        color="primary"
        class="mr-4"
        @click="startNewRetroactive"
      >
        {{ labels.startRetroactive }}
      </v-btn>
      <v-btn
        :disabled="loading"
        color="secondary"
        class="mr-4"
        @click="clearAll"
      >
        {{ labels.clearData }}
      </v-btn>
      <v-alert
        v-if="error"
        border="right"
        colored-border
        type="error"
        elevation="2"
      >
        {{ labels.errorMessage }}
      </v-alert>
      <v-alert
        v-for="page in failedPageIdsAndErrors"
        :key="page"
        border="right"
        colored-border
        type="error"
        elevation="2"
      >
        {{ page }}
      </v-alert>
      <v-alert
        v-if="success"
        border="right"
        colored-border
        type="success"
        elevation="2"
      >
        {{ labels.successResponse }}
      </v-alert>
      <v-alert
        v-for="page in succeededPageIds"
        :key="page"
        border="right"
        colored-border
        type="success"
        elevation="2"
      >
        {{ page }}
      </v-alert>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import StLoader from '@stilingue/st-loader';
import SingleSelect from '../SingleSelect.vue';
import MultipleSelect from '../MultipleSelect.vue';
import PageSelection from '../PageSelection.vue';
import PageHeader from '@/components/base/PageHeader';
import WarroomService from '@/services/WarroomService';
import ITService from '../../services/ITService';
import { FACEBOOK_FLOWTYPES, INSTAGRAM_FLOWTYPES, TWITTER_FLOWTYPES } from '@/utils/constants.js';

const { PagesService } = WarroomService;

export default {
  name: 'ProprietaryRetroactiveNew',
  components: { SingleSelect, StLoader, PageHeader, MultipleSelect, PageSelection },
  data: () => ({
    pages: [],
    activePages: [],
    state: ['ON', 'OFF'],
    crawlerServiceChannels: ['TikTok', 'instagram'],
    itServicesDisallowedChannels: ['googlemybusiness', 'linkedin', 'googleplay', 'reclameaqui', 'TikTok'],
    channels: {
      facebook: {
        channelName: 'facebook',
        flowTypes: FACEBOOK_FLOWTYPES,
        label: 'Tipos de interação do Facebook',
        activeFlowTypes: [],
        isDisabled: true,
      },
      instagram: {
        channelName: 'instagram',
        flowTypes: INSTAGRAM_FLOWTYPES,
        crawlerFlowTypes: [
          'Inbox',
        ],
        label: 'Tipos de interação do Instagram',
        activeFlowTypes: [],
        isDisabled: true,
      },
      TikTok: {
        channelName: 'TikTok',
        crawlerFlowTypes: [
          'Post'
        ],
        label: 'Tipos de interação do TikTok',
        activeFlowTypes: [],
        isDisabled: true,
      },
      twitter: {
        channelName: 'twitter',
        flowTypes: TWITTER_FLOWTYPES,
        label: 'Tipos de interação do Twitter',
        activeFlowTypes: [],
        isDisabled: true,
      },
      twitterEnterprise: {
        channelName: 'twitterEnterprise',
        flowTypes: TWITTER_FLOWTYPES,
        label: 'Tipos de interação do Twitter Enterprise',
        activeFlowTypes: [],
        isDisabled: true,
      },
      youtube: {
        channelName: 'youtube',
        flowTypes: [
          'YouTubePosts'
        ],
        label: 'Tipos de interação do Youtube',
        activeFlowTypes: [],
        isDisabled: true,
      }
    },
    today: new Date().toISOString().substring(0, 10),
    labels: {
      itServices: 'Demais interações',
      crawler: 'TikTok e DM do Instagram',
      errorMessage: 'Erro inesperado ao tentar iniciar o retroativo. Por favor, entre em contato com o time de Solution Engineering.',
      crawlerErrorMessage: 'Erro inesperado ao tentar iniciar o retroativo. Por favor, entre em contato com o time de Solution Engineering.',
      crawlerSuccessResponse: '',
      successResponse: 'Retroativo cadastrado com sucesso.',
      documentation: 'Existem limitações de postagens máximas e dias retroativos. Antes de iniciar o retroativo proprietário, leia a ',
      account: 'Selecione uma Conta',
      startRetroactive: 'Iniciar retroativo',
      task: 'Código da tarefa no jira (no formato xxxx-nnnn)',
      advancedOptions: 'Opções avançadas',
      maxPosts: 'Quantidade máxima de publicações que serão coletadas',
      maxPostsAlert: 'Atenção ao alterar este valor, pode ter impacto nos tokens de coleta do cliente.',
      retroactiveDays: 'Número de dias para coletar o retroativo',
      title: 'Novo Retroativo Proprietário',
      state: 'Status de ativação',
      description: 'Permite execução de retroativo da coleta das páginas proprietárias com base nos dados informados pelo cliente.',
      finishedStatus: 'finished',
      clearData: 'Limpar dados',
      cancel: 'Cancelar',
      ok: 'Confirmar',
      inputs: {
        executionDate: 'Escolha o início do retroativo'
      },
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Retroativo Proprietário',
          to: '/proprietary-retroactive'
        },
        {
          text: 'Novo',
          to: '/proprietary-retroactive/proprietary-new'
        }
      ]
    },
    models: {
      selectedAccount: undefined,
      retroactiveDays: undefined,
      maxPosts: null,
      valid: false,
      task: undefined,
      state: undefined,
    },
    rules: {
      isTask: [v => {
        // eslint-disable-next-line
        const pattern = new RegExp('[A-Za-z]+[_-][0-9]'); // jira task template
        return pattern.test(v) || 'Task inválida';
      }],
      isValue: [v => Boolean(v) || 'Valor obrigatório'],
      isNotEmpty: [(v) => v.length > 0 || 'Seleção obrigatória'],
    },
    crawlerOrItServices: 'crawler',
    failedPageIdsAndErrors: [],
    succeededPageIds: [],
    isDisabled: true,
    loading: false,
    error: false,
    errorCrawler: false,
    success: false,
    successCrawler: false,
    menu: false,
    session: undefined,
    date: null,
    modal: false,
    dialog: false,
    millisecondsToDaysConverter: 86400000,
  }),
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    accountId: {
      get() {
        return this.activeAccount.id;
      },
    },
    crawlerService() {
      return this.crawlerOrItServices === 'crawler';
    },
  },
  async created() {
    await this.getAccounts();
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount);
    }
  },
  methods: {
    ...mapActions('account', ['getAccounts', 'setAccount']),
    selectAccount(selectedAccount) {
      this.setAccount({ selectedAccount });
      this.getAccountPages({ selectedAccount });
    },
    getAccountPages({ selectedAccount }) {
      const { id: accountId } = selectedAccount;

      PagesService.find({ url: `/${accountId}` })
        .then(data => {
          const { message = [] } = data;
          if (!this.crawlerService) {
            this.pages = message.length >= 1
              ? message.filter(page => !this.itServicesDisallowedChannels.includes(page.channel))
              : [];
          } else {
            this.pages = message.length >= 1
              ? message.filter(page => this.crawlerServiceChannels.includes(page.channel))
              : [];
          }
        }).catch((e) => {
          console.error(e);
        });
    },
    setRetroactiveDateMillis() {
      const selectedDate = new Date(this.date);
      selectedDate.setHours(0, 0, 0, 0);
      const convertedDate = this.setTimeZone(selectedDate);
      return convertedDate;
    },
    setTimeZone(selectedDate) {
      const timezoneOffset = selectedDate.getTimezoneOffset();
      selectedDate.setMinutes(selectedDate.getMinutes() - timezoneOffset);
      return selectedDate.getTime();
    },
    setRetroactiveDate() {
      const today = new Date(this.today);
      const sinceDateMillis = today.getTime() - this.setRetroactiveDateMillis();
      return sinceDateMillis / this.millisecondsToDaysConverter;
    },
    setErrorFalse() {
      this.error = false;
    },
    setMaxPosts(maxPosts) {
      this.models.maxPosts = maxPosts;
    },
    getActiveChannels() {
      const channels = [];
      for (let i = 0; i < this.activePages.length; i++) {
        channels.push(this.activePages[i].channel);
      }
      const activeChannels = channels.filter((item, i) => channels.indexOf(item) === i);
      return activeChannels;
    },
    showActiveFlowTypes() {
      Object.keys(this.channels).forEach(item => {
        this.channels[item].isDisabled = !this.getActiveChannels().includes(this.channels[item].channelName);
      });
    },
    showMaxPostsAlert() {
      this.isDisabled = false;
    },
    hideMaxPostsAlert() {
      this.isDisabled = true;
    },
    selectedFlowTypes() {
      let flowTypes = [];
      Object.keys(this.channels).forEach(channel => {
        if (!this.channels[channel].isDisabled && !this.channels[channel].activeFlowTypes.length) {
          flowTypes = flowTypes.concat(this.channels[channel].flowTypes);
        }
        flowTypes = flowTypes.concat(this.channels[channel].activeFlowTypes);
      });
      return flowTypes;
    },
    clearForm() {
      this.$refs.form.reset();
    },
    clearAll() {
      this.clearForm();
      this.clearMessages();
    },
    clearMessages() {
      this.activePages = [];
      this.failedPageIdsAndErrors = [];
      this.succeededPageIds = [];
      this.success = false;
      this.error = false;
    },
    getPageId() {
      return this.activePages.map(page => page.id);
    },
    getParameters() {
      const parameters = {
        'task': this.models.task,
        'pageIds': this.getPageId(),
        'accountId': Number(this.selectedAccount.id),
        'retroactiveDays': this.setRetroactiveDate(),
        'state': this.models.state,
        'flowTypes': this.selectedFlowTypes(),
      };
      if (this.models.maxPosts) {
        parameters.maxPosts = this.models.maxPosts;
      }
      return parameters;
    },
    getCrawlerServiceParameters() {
      const crawlerServiceParameters = [];
      let parameters;
      this.activePages.forEach(activePage => {
        const formattedChannel = (activePage.channel).charAt(0).toUpperCase() + (activePage.channel).substr(1);
        const selectedInteractions = this.channels[activePage.channel].activeFlowTypes;
        const interactions = this.setInteractionsForCrawlerServiceParameters(selectedInteractions, activePage);
        interactions.forEach(interaction => {
          parameters = {
            'accountId': Number(this.selectedAccount.id),
            'pageId': activePage.id,
            'channel': formattedChannel,
            'interactionType': interaction,
            'sinceDateMillis': this.setRetroactiveDateMillis(),
            'task': this.models.task
          };
          crawlerServiceParameters.push(parameters);
        });
      });
      return crawlerServiceParameters;
    },
    setInteractionsForCrawlerServiceParameters(activeInteractions, activePage) {
      if (!activeInteractions.length) {
        return this.channels[activePage.channel].crawlerFlowTypes;
      }
      return activeInteractions;
    },
    startNewRetroactive() {
      this.loading = true;
      if (this.crawlerService) {
        const crawlerServiceParameters = this.getCrawlerServiceParameters();
        this.makeCrawlerServiceRetroactive(crawlerServiceParameters);
      }
      if (!this.crawlerService) {
        const parameters = this.getParameters();
        this.makeNewRetroactiveItServices(parameters);
      }
      this.loading = false;
      this.clearForm();
    },
    makeNewRetroactiveItServices(parameters) {
      ITService.newRetroactive(parameters).then(resp => {
        this.labels.successResponse = `Retroativo cadastrado com sucesso. Resposta da API: ${resp.value}`;
        this.success = true;
      }).catch(e => {
        console.error(e);
        this.error = true;
        const error = e.toString().slice(-3);
        if (e.response) {
          switch (error) {
          case '400':
            this.labels.errorMessage = `Por favor, verifique se as informações estão corretas. Erro da API: ${e.response.data.data[0].message}`;
            break;
          case '500':
            this.labels.errorMessage = 'Falha ao cadastrar o retroativo proprietário.';
            break;
          default:
            break;
          }
        }
      });
    },
    makeCrawlerServiceRetroactive(parameters) {
      for (let i = 0; i < parameters.length; i++) {
        ITService.newRetroactiveCrawler(parameters[i]).then(resp => {
          this.succeededPageIds.push(`Total de postagens processadas para o page id ${parameters[i].pageId} : ${resp.processedPosts}`);
        }).catch(e => {
          console.error(e);
          this.failedPageIdsAndErrors.push(`Falha ao cadastrar retroativo para o page id ${parameters[i].pageId}. Erro da API: ${e.response.data.description}`);
        });
      }
    }
  }
};

</script>

<style lang="scss" scoped>

.new-retroactive__crawler-or-it-services {
  display: flex;
  justify-content: space-around;

  .v-radio {
    margin-bottom: 0 !important;
  }
}

.theme--dark.v-card > .v-card__text {
  color: #fff;
  padding: 24px 0;
  text-align: center;
}

.button-dialog-update {
  border-color: #00ccbe;
  background-color: #00ccbe;
  color: #242c5a;
}

.v-alert {
  margin-top: 20px;
}

.v-expansion-panels {
  border-radius: initial;
  margin-bottom: 25px;
  padding: 15px;
  background: #333;
}

.v-expansion-panel-header {
  background: var(--gray-050);
  font-size: 16px;
}

.v-expansion-panel-content {
  background: var(--gray-050);
}

.disabled {
  display: none;
}

.warning {
  .v-card {
    padding: initial;
    border-radius: initial;
    margin-bottom: 25px;

    .v-card__text {
      text-align: justify;
      padding: 15px;
    }
  }
}

.max-posts {
  .max-posts-input {
    padding: 0 3px 0;
    display: flex;
    flex-direction: row;
    gap: 7px;
  }

  .v-card {
    padding: initial;
    border-radius: initial;
    background: orange;
    color: #fff;

    .v-card__text {
      padding: 15px;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

</style>
