const snlpComponents = {
  processData(response) {
    let data = response;
    data = this.getNormalizedWordList(data);
    data = this.getWordColorList(data);
    data = this.getLog(data);

    return data;
  },

  getNormalizedWordList(response) {
    const data = response;

    const normalized_words_list = [];
    const words_lemmas = [];

    const paragraphs = data.snlpData.paragraphs;

    for (let paragraph = 0; paragraph < paragraphs.length; paragraph++) {
      const sentences = paragraphs[paragraph].sentences;

      for (let sentence = 0; sentence < sentences.length; sentence++) {
        const tokens = sentences[sentence].tokens;

        for (let token = 0; token < tokens.length; token++) {
          normalized_words_list.push(tokens[token].lexeme);
          words_lemmas.push(tokens[token].lemmas);
        }
      }
    }
    data.normalized_words_list = normalized_words_list;
    data.words_lemmas = words_lemmas;

    return data;
  },

  getWordColorList(response) {
    const data = response;

    const colors = [];
    const polarity_colors = [];
    const words_list = data.wordsList;

    for (let word = 0; word < words_list.length; word++) {
      colors.push(getWordColor(data, word));
      polarity_colors.push(getColorByPolarity(data, word));
    }

    data.colors = colors;
    data.polarity_colors = polarity_colors;

    return data;
  },

  getLog(response) {
    const data = response;
    const log = [];

    for (let word = 0; word < data.wordsList.length; word++) {
      const log_data = {
        'word': data.wordsList[word],
        'normalized': data.normalized_words_list[word],
        'lemmas': data.words_lemmas[word],
        'polarity': data.polarityList[word],
        'color': data.colors[word]
      };

      log.push(log_data);
    }

    data.log = log;
    return data;
  }
};

function getWordColor(response, index) {
  if (index) {
    if (!isWordEqualsNormalizedVersion(response, index) && hasPolarityChanged(response, index)) {
      return 'red';
    }
  }
  return 'white';
}

function getColorByPolarity(response, index) {
  const polarity_list = response.polarityList;

  if (!polarity_list[index]) {
    return 'white';
  }
  return polarity_list[index] < 0 ? 'red' : 'lime';
}

function isWordEqualsNormalizedVersion(response, index) {
  const words_list = response.wordsList;
  const normalized_words_list = response.normalized_words_list;

  return words_list[index].toUpperCase() === normalized_words_list[index].toUpperCase();
}

function hasPolarityChanged(response, index) {
  const polarity_list = response.polarityList;

  return polarity_list[index] !== polarity_list[index - 1];
}

export default snlpComponents;
