import { MUTATE, MUTATE_MULTIPLE } from '@/store/modules/mutation_types';
import AdminToolsService from '@/services/AdminToolsService';
import WarroomService from '@/services/WarroomService';
import moment from 'moment';

const { PagesService } = WarroomService;

export function getPageAuthError({ state, commit }, { selectedAccount }) {
  commit(MUTATE, {
    property: 'requestStatus',
    with: 'loading',
  });

  const { id: accountId } = selectedAccount;
  const { id: pageId } = state.activePage;

  return AdminToolsService.getAuthErrors({
    pageId,
    accountId,
  }).then(message => {
    const authErrorsHistory = message.occurrences.map(item => {
      const newOccurrences = item.occurrences.map(occurrence => {
        return moment(occurrence).format('DD/MM/YYYY HH:mm:ss');
      });

      return {
        date_formatted: moment(item.date).format('DD/MM/YYYY'),
        date: item.date,
        message: item.message,
        total: item.total,
        occurrences: newOccurrences
      };
    });

    commit(MUTATE_MULTIPLE, [
      {
        property: 'requestStatus',
        with: 'success',
      }, {
        property: 'history',
        with: authErrorsHistory,
      }, {
        property: 'loadingHistory',
        with: false,
      }, {
        property: 'historyIsLoaded',
        with: true,
      },
    ]);

    return Promise.resolve(message);
  }).catch((e) => {
    console.error(e);
  });
}

export function getAccountPages({ state, commit }, { selectedAccount }) {
  commit(MUTATE_MULTIPLE, [{
    property: 'loadingHistory',
    with: true,
  }, {
    property: 'historyIsLoaded',
    with: false,
  }, {
    property: 'requestStatus',
    with: 'loading',
  }]);

  const { id: accountId } = selectedAccount;

  return PagesService.find({ url: `/${accountId}` })
    .then(data => {
      const { message = [] } = data;
      commit(MUTATE_MULTIPLE, [
        {
          property: 'requestStatus',
          with: 'success',
        }, {
          property: 'pages',
          with: message,
        },
      ]);
      if (message.length >= 1) {
        commit(MUTATE_MULTIPLE, [{
          property: 'activePage',
          with: message[0],
        }]);
        getPageAuthError({ state, commit }, { selectedAccount });
      }
      commit(MUTATE, {
        property: 'loadingHistory',
        with: false,
      });
      commit(MUTATE, {
        property: 'requestStatus',
        with: 'empty',
      });

      return Promise.resolve(message);
    }).catch((e) => {
      console.error(e);
      commit(MUTATE, {
        property: 'requestStatus',
        with: 'error',
      });
    });
}

export function setPage({ commit }, { selectedPage }) {
  commit(MUTATE_MULTIPLE, [{
    property: 'activePage',
    with: selectedPage,
  }, {
    property: 'loadingHistory',
    with: true,
  }, {
    property: 'historyIsLoaded',
    with: false,
  }]);
}

export function setFilterDate({ commit }, { date }) {
  commit(MUTATE, {
    property: 'filterDate',
    with: date,
  });
}
