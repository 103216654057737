<template>
  <div
    class="container"
    :class="containerClasses"
  >
    <StSidebar
      :minimize="$vuetify.breakpoint.smAndDown"
      permanent
    >
      <template #items>
        <SidebarItemList :is-sidebar-mini="isSidebarMini" />
      </template>
    </StSidebar>
    <v-row
      class="layout-content no-gutters"
    >
      <transition name="fade">
        <router-view />
      </transition>
    </v-row>
  </div>
</template>

<script>
import StSidebar from '@stilingue/st-sidebar';
import SidebarItemList from '../components/base/SidebarItemList';

export default {
  name: 'LayoutContent',
  components: {
    StSidebar,
    SidebarItemList,
  },
  data() {
    return {
      isSidebarMini: false,
      expandContainer: true,
    };
  },
  computed: {
    containerClasses() {
      return {
        'container--expanded': this.expandContainer,
      };
    },
  },
  methods: {
    sideBarChanged({ mini }) {
      this.expandContainer = !mini;
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.container {
  width: 100vw;
  max-width: 100vw;
  padding: 0 0 0 93px;
  margin: 0;
  -webkit-transition: padding 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  -moz-transition: padding 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition: padding 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: padding 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.container--expanded {
  padding-left: 310px;
}

.layout-content {
  height: calc(100vh - 58px);
  padding: 25px 25px 25px 10px;
  overflow: auto;
}

.v-list-item {
  a {
    display: flex;
  }
}
</style>
