<template>
  <div class="content-body">
    <div v-if="!isConfigurationSubmited">
      <PageHeader
        :title="labels.title"
        :description="labels.description"
        :breadcrumbs="labels.breadcrumbs"
      />
      <v-card>
        <v-card-title class="font-weight-bold pb-6">
          {{ labels.tutorial.stepOne.title }}
        </v-card-title>

        <v-card-subtitle>
          {{ labels.tutorial.stepOne.description }}
        </v-card-subtitle>

        <v-card-text>
          <ul class="font-weight-bold">
            <li
              v-for="(document, index) in documents"
              :key="index"
            >
              <a
                :href="document.link"
                target="_blank"
              >
                {{ document.text }}
              </a>
            </li>
          </ul>
          <div class="mt-4">
            <strong>
              {{ labels.tutorial.stepOne.important }}
            </strong>
            {{ labels.tutorial.stepOne.reminder }}
          </div>
        </v-card-text>
      </v-card>

      <StLoader :loading="loading" />

      <v-card>
        <v-card-title class="font-weight-bold pb-6">
          {{ labels.tutorial.stepTwo.title }}
        </v-card-title>

        <v-card-subtitle>
          {{ labels.tutorial.stepTwo.description }}
        </v-card-subtitle>

        <v-card-text>
          <v-form
            ref="newsso"
            v-model="models.valid"
            :lazy-validation="false"
          >
            <SingleSelect
              v-model="selectedUsergroup"
              :items="usergroups"
              :placeholder="labels.placeholders.selectUsergroup"
              text="userGroupName"
            />

            <template v-if="!groupHasSso">
              <v-text-field
                v-model="models.idpIssuer"
                :rules="rules.isNotEmpty"
                :placeholder="labels.placeholders.idp"
                required
              />
              <v-text-field
                v-model="models.idpUrl"
                :rules="rules.isLink"
                :placeholder="labels.placeholders.idpUrl"
                required
              />
            </template>
          </v-form>
        </v-card-text>
      </v-card>

      <v-alert
        v-if="groupHasSso && selectedUsergroup"
        border="right"
        colored-border
        type="error"
        color="warning"
      >
        Cliente já possui SSO configurado. Para verificar as informações acesse a página <a @click="goToStatusSso">Status</a>.
      </v-alert>

      <DsButton
        :disabled="!models.valid || loading || groupHasSso || !selectedUsergroup"
        data-color-theme="dark"
        @click="submitSso"
      >
        Finalizar configuração
      </DsButton>
    </div>

    <section v-if="isConfigurationSubmited">
      <div v-if="error">
        <v-alert
          class="pa-7"
          border="right"
          colored-border
          color="red darken-2"
          icon="mdi-cancel"
        >
          <v-row
            no-gutters
            class="pb-3 d-flex align-center"
          >
            <v-col
              cols="11"
              class="px-3"
            >
              <span class="font-weight-bold"> Configuração da conta {{ selectedUsergroup.userGroupName }} não foi concluída! </span>
            </v-col>
          </v-row>
          <span>
            Não conseguimos concluir a operação. Verifique se os dados inseridos estão corretos ou se houve algum problema na criação da aplicação na ferramenta de gestão do cliente. Caso o problema persista, acione o time de Onboarding e Setup.
          </span>
        </v-alert>

        <DsButton
          color="secondary"
          data-color-theme="dark"
          @click="redirectToConfiguration"
        >
          Voltar para configuração
        </DsButton>
      </div>

      <div v-if="success">
        <v-alert
          v-if="true"
          class="pa-7"
          border="right"
          colored-border
          color="success"
          icon="mdi-check"
        >
          <v-row
            no-gutters
            class="pb-3 d-flex align-center"
          >
            <v-col
              cols="11"
              class="px-3"
            >
              <span class="font-weight-bold"> Configuração da conta {{ selectedUsergroup.userGroupName }} concluída! </span>
            </v-col>
          </v-row>

          <span>
            Acompanhe o andamento da solicitação e altere as configurações necessárias na página de <a @click="goToStatusSso"> Status</a>. É importante lembrar que o cliente deve cadastrar seus usuários na ferramenta de gestão e também na STILINGUE para o processo funcionar corretamente.
          </span>
        </v-alert>

        <DsButton
          data-color-theme="dark"
          @click="newConfiguration"
        >
          Nova configuração
        </DsButton>
      </div>
    </section>
  </div>
</template>

<script>
import PageHeader from '@/components/base/PageHeader';
import SingleSelect from '@/components/SingleSelect';
import StLoader from '@stilingue/st-loader';
import { mapActions, mapMutations, mapState } from 'vuex';
import { DsButton } from '@ds/components';
import SingleSignOnService from '@/services/SingleSignOnService';

export default {
  name: 'NewSsoConfiguration',
  components: {
    PageHeader,
    SingleSelect,
    DsButton,
    StLoader,
  },
  data: () => ({
    labels: {
      title: 'Nova configuração SSO',
      description: 'Permite que o cliente faça gestão de login de seus usuários utilizando as mesmas credenciais de ferramentas corporativas que utilizem o protocolo SAML.',
      dsButtonRedirection: 'Voltar para configuração',
      tutorial: {
        stepOne: {
          title: 'Passo 1',
          description: 'Antes de iniciar a configuração, o cliente precisa preparar a ferramenta de gestão de acessos. Confirme qual é a plataforma utilizada e solicite a criação de uma aplicação da STILINGUE conforme os tutorais disponíveis:',
          important: 'Importante:',
          reminder: 'Para enviar para o cliente, baixe a documentação do Confluence para um PDF clicando nos 3 pontinhos > Exportar > Exportar para PDF.'
        },
        stepTwo: {
          title: 'Passo 2',
          description: 'Para concluir a configuração, selecione o User Group do cliente e solicite os dados gerados de Identity Provider Issuer e Identity Provider Metadata URL gerados pela Azure ou Okta.',
        }
      },
      placeholders: {
        selectUsergroup: 'Selecione um usergroup',
        idp: 'Insira o link do Identity Provider Issuer',
        idpUrl: 'Insira o link do Identity Provider Metadata URL',
      },
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'SSO',
          to: '/sso'
        },
        {
          text: 'Configuração',
          to: '/sso/configuration'
        },
      ],
    },
    models: {
      idpIssuer: null,
      idpUrl: null,
      valid: false,
    },
    rules: {
      isNotEmpty: [v => (v && v.length) > 0 || 'Este campo deve ser preenchido'],
      isLink: [
        v => (v && v.length) > 0 || 'Este campo deve ser preenchido',
        v => {
        // eslint-disable-next-line
          const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z:\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
          return pattern.test(v) || 'URL inválida';
        }
      ]
    },
    loading: false,
    isConfigurationSubmited: false,
    error: false,
    success: false,
    documents: [
      {
        link: 'https://drive.google.com/file/d/1Lmi14ChSG_oLgO45U7cdaGO8vjCvYWHf/view?usp=sharing',
        text: 'Microsoft Azure',
      },
      {
        link: 'https://drive.google.com/file/d/1L872nnbxD3GVyph48abnr5qGuNNtzNmB/view?usp=sharing',
        text: 'Okta'
      }
    ],
  }),
  computed: {
    ...mapState('usergroup', ['usergroups', 'activeUsergroup']),
    selectedUsergroup: {
      get() {
        return this.activeUsergroup;
      },
      set(selectedUsergroup) {
        this.selectUsergroup(selectedUsergroup);
      }
    },
    groupHasSso() {
      return this.selectedUsergroup ? this.selectedUsergroup.existSoo : false;
    }
  },
  async created() {
    await this.getUsergroups();
  },
  methods: {
    ...mapActions('usergroup', ['getUsergroups', 'setUsergroup', 'getUsergroups']),
    ...mapMutations('usergroup', {
      mutate: 'MUTATE',
    }),
    selectUsergroup(selectedUsergroup) {
      this.setUsergroup({ selectedUsergroup });
    },
    async submitSso() {
      this.error = false;
      this.loading = true;

      const parameters = {
        identityProvider: this.models.idpIssuer,
        urlFederationXml: this.models.idpUrl,
        userGroupName: this.selectedUsergroup.userGroupName,
      };

      SingleSignOnService.createNewSsoConfig(parameters).then(() => {
        this.success = true;
        this.loading = false;
        this.isConfigurationSubmited = true;
      }).catch(e => {
        console.error(e);
        this.success = false;
        this.error = true;
        this.loading = false;
        this.isConfigurationSubmited = true;
      });
    },
    goToStatusSso() {
      const redirectTo = '/sso/status';
      this.$router.replace({
        path: redirectTo,
      }).catch(/*istanbul ignore next*/() => {
        //do nothing because it's just page redirection
      });
    },
    redirectToConfiguration() {
      this.isConfigurationSubmited = false;
    },
    async newConfiguration() {
      this.models.idpIssuer = '';
      this.models.idpUrl = '';
      await this.getUsergroups();
      this.isConfigurationSubmited = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-card {
    padding: var(--ds-spacing-inset-quark);
  }

  .content-body {
    .ds-button {
      float: right;
      margin: 16px 0;
    }

    .v-card {
      margin: var(--ds-spacing-inset-xs) 0;
    }
  }

  a {
    color: var(--default-green-color);
    text-decoration: underline;
    font-weight: var(--ds-font-weight-bold);
  }

</style>
