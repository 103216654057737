var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("StLogin", {
    attrs: { loading: _vm.loading, i18n: _vm.i18n },
    on: { login: _vm.login },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }