<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <section>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="table.search"
            label="Search"
            class="mx-4"
          />
          <DsButton
            icon
            name="refresh"
            color="ghost"
            data-color-theme="dark"
            @click="getList"
          />
        </v-card-title>
        <StLoader :loading="loading" />
        <v-data-table
          :headers="table.headers"
          :items="table.items"
          :search="table.search"
        >
          <template v-slot:header.status="{ header }">
            <div class="table-th__tooltip teste">
              <span class="mr-1"> {{ header.text }} </span>
              <DsTooltip
                position="left"
                :content="labels.tooltips.status"
                data-color-theme="dark"
              >
                <DsIcon
                  name="questions"
                  size="small"
                />
              </DsTooltip>
            </div>
          </template>

          <template v-slot:header.onlySsoLogin="{ header }">
            <div class="table-th__tooltip">
              <span class="mr-1"> {{ header.text }} </span>
              <DsTooltip
                position="left"
                :content="labels.tooltips.onlySsoLogin"
                data-color-theme="dark"
              >
                <DsIcon
                  name="questions"
                  size="small"
                />
              </DsTooltip>
            </div>
          </template>

          <template v-slot:item.status="{ item }">
            <DsSwitch
              v-model="item.status"
              data-color-theme="dark"
              @toggle="handleModal(item, 'status', item.status)"
            />
          </template>

          <template v-slot:item.onlySsoLogin="{ item }">
            <DsSwitch
              v-model="item.onlySsoLogin"
              :disabled="!item.shouldAllowSsoOnly"
              data-color-theme="dark"
              @toggle="handleModal(item, 'onlySsoLogin', item.onlySsoLogin)"
            />
          </template>
        </v-data-table>
      </v-card>
    </section>

    <DsSnackbar
      v-if="showSnackbar"
      :type="typeSnackbar"
      :show="showSnackbar"
      :text="labels.snackbar.current"
      :custom-max-length="customMaxLengthSnackbar"
      data-color-theme="dark"
      @close="showSnackbar = !showSnackbar"
    />

    <DsModal
      :open="openModal"
      :title="labels.modal.title.current"
      :text="labels.modal.text.current"
      :primary-button-label="labels.modal.primaryButton.current"
      :secondary-button-label="labels.modal.secondaryButton"
      data-color-theme="dark"
      width="600px"
      @primary="accept"
      @secondary="cancel"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/base/PageHeader';
import StLoader from '@stilingue/st-loader';
import { DsSwitch, DsButton, DsTooltip, DsSnackbar, DsModal } from '@ds/components';
import { DsIcon } from '@ds/icons';
import SingleSignOnService from '@/services/SingleSignOnService';
import moment from 'moment';

export default {
  name: 'StatusSsoList',
  components: {
    PageHeader,
    StLoader,
    DsSwitch,
    DsButton,
    DsIcon,
    DsTooltip,
    DsSnackbar,
    DsModal,
  },
  data: () => ({
    labels: {
      title: 'Acessos SSO configurados',
      description: 'Permite a visualização do status da implementação de SSO do cliente e habilitar/desabilitar a função e seus acessos.',
      tooltips: {
        status: 'Controla a configuração de SSO validando os logins através da ferramenta de gestão. Ao desativar, a validação volta a ser apenas pelo login e senha.',
        onlySsoLogin: 'Ao habilitar essa função, a validação de logins da STILINGUE passa a ser apenas via SSO, desconsiderando o login e senha.',
      },
      snackbar: {
        statusEnabled: 'SSO habilitado! Os acessos serão validados entre a sua ferramenta de gestão e a STILINGUE.',
        statusDisabled: 'SSO desabilitado! A validação de acesso será feita apenas via STILINGUE.',
        onlySsoEnabled: 'Login apenas por SSO habilitado! Os usuários só poderão logar via SSO.',
        onlySsoDisabled: 'Login apenas por SSO desabilitado! Os usuários poderão logar SSO ou por e-mail e senha.',
        current: '',
        error: 'Não foi possível alterar as configurações no momento. Tente novamente mais tarde.',
      },
      modal: {
        title: {
          status: {
            enable: 'Deseja habilitar a configuração do SSO?',
            disable: 'Deseja desabilitar a configuração do SSO?'
          },
          onlySso: {
            enable: 'Deseja habilitar login apenas por SSO?',
            disable: 'Deseja desabilitar login apenas por SSO?',
          },
          current: '',
        },
        text: {
          status: {
            enable: 'Ao habilitar a configuração SSO, a validação de login é feita através de e-mail e senha na STILINGUE e também por SSO.',
            disable: 'Ao desabilitar a configuração SSO, a validação de login é feita através de e-mail e senha na STILINGUE.',
          },
          onlySso: {
            enable: 'Ao habilitar o login apenas por SSO, o cliente só poderá logar utilizando SSO pois o e-mail e senha será desabilitado.',
            disable: 'Ao desabilitar o login apenas por SSO, o cliente pode logar tanto por SSO quanto por e-mail e senha.',
          },
          current: '',
        },
        primaryButton: {
          enable: 'Habilitar',
          disable: 'Desabilitar',
          current: '',
        },
        secondaryButton: 'Cancelar',
      },
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'SSO',
          to: '/sso'
        },
        {
          text: 'Configuração',
          to: '/sso/status'
        },
      ]
    },
    table: {
      headers: [
        {
          text: 'Data da configuração',
          align: 'start',
          value: 'createdAt',
        },
        { text: 'Usergroup', value: 'userGroupName' },
        { text: 'Status da configuração', value: 'status', sortable: false },
        { text: 'Login apenas por SSO', value: 'onlySsoLogin', sortable: false },
      ],
      search: '',
      items: [],
    },
    typeSnackbar: 'success',
    showSnackbar: false,
    customMaxLengthSnackbar: 100,
    openModal: false,
    loading: false,
    error: false,
    brazilFormat: 'DD/MM/YYYY',
  }),
  async created() {
    await this.getList();
  },
  methods: {
    accept() {
      this.resolve(true);
      this.openModal = false;
    },
    cancel() {
      this.reject(true);
      this.openModal = false;
    },
    async getList() {
      try {
        this.table.items = [];
        this.error = false;
        this.loading = true;
        const response = await SingleSignOnService.getSsoList();
        this.setTableItems(response);
      } catch (e) {
        this.error = true;
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    getParameters(item) {
      const parameters = {
        id: item.id,
        lock: !item.status,
        passwordLogin: !item.onlySsoLogin,
      };
      return parameters;
    },
    setTableItems(items) {
      this.table.items = items.map(item => {
        const { lock, createdAt, userGroupName, id, passwordLogin } = item;
        return {
          id,
          createdAt: moment(createdAt).format(this.brazilFormat),
          userGroupName,
          status: !lock,
          shouldAllowSsoOnly: !lock,
          onlySsoLogin: !passwordLogin
        };
      });
    },
    handleLabelsModal(attribute, isEnable) {
      const status = isEnable ? 'enable' : 'disable';

      this.labels.modal.primaryButton.current = this.labels.modal.primaryButton[status];
      this.labels.modal.title.current = attribute === 'status'
        ? this.labels.modal.title.status[status]
        : this.labels.modal.title.onlySso[status];

      this.labels.modal.text.current = attribute === 'status'
        ? this.labels.modal.text.status[status]
        : this.labels.modal.text.onlySso[status];
    },
    async handleModal(item, attribute, attributeValue) {
      this.handleLabelsModal(attribute, attributeValue);

      this.openModal = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      }).then(() => {
        this.update(item, attribute);
      }).catch(() => {
        this.loading = false;
        item[attribute] = !item[attribute];
      });
    },
    handleSuccessSnackbar(item, attribute) {
      if (attribute === 'status') {
        item.shouldAllowSsoOnly = item.status;
        this.labels.snackbar.current = item.status
          ? this.labels.snackbar.statusEnabled
          : this.labels.snackbar.statusDisabled;
      } else {
        this.labels.snackbar.current = item.onlySsoLogin
          ? this.labels.snackbar.onlySsoEnabled
          : this.labels.snackbar.onlySsoDisabled;
      }

      this.showSnackbar = true;
    },
    async update(item, attribute) {
      this.error = false;
      this.loading = true;
      const parameters = this.getParameters(item);

      SingleSignOnService.updateSso(parameters).then(() => {
        this.loading = false;
        item.shouldAllowSsoOnly = item.status;
        this.typeSnackbar = 'success';
        this.handleSuccessSnackbar(item, attribute);
      }).catch (e => {
        this.error = true;
        this.loading = false;
        this.typeSnackbar = 'error';
        this.labels.snackbar.current = this.labels.snackbar.error;
        this.showSnackbar = true;
        item[attribute] = !item[attribute];
        console.error(e);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-body {
  width: 100%;

  .table-th__tooltip {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .v-card::v-deep .v-data-table > .v-data-table__wrapper {
    overflow: visible;
    z-index: 998;
  }
}

.ds-snackbar::v-deep .ds-snackbar__text {
  color: var(--ds-color-text-black);
}

</style>
