<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <section
      class="selector-container"
    >
      <v-card>
        <v-toolbar flat>
          <v-icon large>
            mdi-desktop-mac-dashboard
          </v-icon>
          <v-toolbar-title v-if="selectedUniverse && selectedAccount">
            {{ selectedUniverse.name }} ({{ selectedAccount.name }})
          </v-toolbar-title>
          <v-card-title v-else>
            Universo indefinido
          </v-card-title>
          <StLoader :loading="loading" />
          <template v-slot:extension>
            <v-tabs
              v-model="tabs"
              fixed-tabs
            >
              <v-tabs-slider />
              <v-tab
                href="#mobile-tabs-5-1"
                class="primary--text"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-database-edit
                    </v-icon>
                  </template>
                  <span>Configurações Setup</span>
                </v-tooltip>
              </v-tab>
              <v-tab
                href="#mobile-tabs-5-2"
                class="primary--text"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-forum
                    </v-icon>
                  </template>
                  <span>Configurações SmartCare</span>
                </v-tooltip>
              </v-tab>
              <v-tab
                href="#mobile-tabs-5-3"
                class="primary--text"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <StIcon
                      icon-name="history"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span>Histórico</span>
                </v-tooltip>
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-tabs-items v-model="tabs">
          <v-tab-item
            v-for="i in 3"
            :key="i"
            :value="'mobile-tabs-5-' + i"
          >
            <v-row v-if="i == 1 && result">
              <v-col
                sm="6"
                md="4"
              >
                <v-card>
                  <v-card-title class="subheading font-weight-bold">
                    Status
                  </v-card-title>
                  <v-card-subtitle>Data de criação e estado atual</v-card-subtitle>
                  <v-divider />
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        Criada em {{ result.created_at }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-progress-linear
                          v-if="result.status === 'Parado'"
                          value="50"
                          color="red darken-2"
                          height="15"
                          class="universe_status"
                        >
                          <template>
                            <strong>Parado</strong>
                          </template>
                        </v-progress-linear>
                        <v-progress-linear
                          v-if="result.status === 'Em execução'"
                          indeterminate
                          color="teal"
                          height="15"
                          class="universe_status"
                        >
                          <template>
                            Em execução
                          </template>
                        </v-progress-linear>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
                <v-card>
                  <v-card-title class="subheading font-weight-bold">
                    Publicações
                  </v-card-title>
                  <v-card-subtitle>Detalha limites configurados</v-card-subtitle>
                  <v-divider />
                  <v-list v-if="result">
                    <v-list-item>
                      <v-list-item-content>Limite Mensal:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ result.month_posts_limit }}
                      </v-list-item-content>
                      <v-btn
                        fab
                      >
                        <v-icon
                          color="primary"
                          dark
                          @click="startLimit('Mensal', result.month_posts_limit)"
                        >
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Limite Total:</v-list-item-content>
                      <v-list-item-content class="align-end">
                        {{ result.total_posts_limit }}
                      </v-list-item-content>
                      <v-btn
                        fab
                      >
                        <v-icon
                          color="primary"
                          dark
                          @click="startLimit('Total', result.total_posts_limit)"
                        >
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-card>
                  <v-card-title class="subheading font-weight-bold">
                    Coleta
                  </v-card-title>
                  <v-card-subtitle>Tipos de coleta e configurações</v-card-subtitle>
                  <v-divider />
                  <v-list>
                    <v-list-item>
                      <v-switch
                        v-model="models.retweetStatus"
                        :input-value="models.retweetStatus"
                        inset
                        @change="startRetweet(models.retweetStatus)"
                      >
                        <template v-slot:label>
                          <v-icon>mdi-twitter</v-icon> Retweet
                        </template>
                      </v-switch>
                    </v-list-item>
                    <v-list-item>
                      <v-switch
                        :input-value="result.antispam"
                        inset
                        readonly
                        disabled
                      >
                        <template v-slot:label>
                          <v-tooltip
                            color="black"
                            bottom
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              ><v-icon>mdi-alert</v-icon> Anti-Spam</span>
                            </template>
                            Não é possível alterar essa configuração.
                          </v-tooltip>
                        </template>
                      </v-switch>
                    </v-list-item>
                    <v-list-item>
                      <v-switch
                        :input-value="result.is_proprietary_sentimentalization_on"
                        inset
                        readonly
                        disabled
                      >
                        <template v-slot:label>
                          <v-tooltip
                            color="black"
                            bottom
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >Análise de sentimento em interações proprietárias</span>
                            </template>
                            Não é possível alterar essa configuração.
                          </v-tooltip>
                        </template>
                      </v-switch>
                    </v-list-item>
                    <v-list-item>
                      <v-switch
                        :input-value="result.neutral"
                        readonly
                        disabled
                        inset
                      >
                        <template v-slot:label>
                          <v-tooltip
                            color="black"
                            bottom
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >Neutro na análise de sentimento</span>
                            </template>
                            Não é possível alterar essa configuração.
                          </v-tooltip>
                        </template>
                      </v-switch>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-card>
                  <v-card-title class="subheading font-weight-bold">
                    Idiomas
                  </v-card-title>
                  <v-card-subtitle>Idiomas adicionados</v-card-subtitle>
                  <v-divider />
                  <v-card-actions v-if="result && result.languagesName">
                    <v-list>
                      <v-list-item
                        v-for="name in result.languagesName"
                        :key="name"
                      >
                        <v-chip
                          center
                        >
                          {{ name }}
                        </v-chip>
                      </v-list-item>
                    </v-list>
                    <v-btn
                      v-show="result.languagesName.length < 3"
                      fab
                      top
                      absolute
                      right
                      @click="startLanguage(result.languages)"
                    >
                      <v-icon
                        color="primary"
                        dark
                      >
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-card-actions v-else>
                    Nenhum idioma encontrado.
                  </v-card-actions>
                </v-card>

                <v-card>
                  <v-card-title class="subheading font-weight-bold">
                    Reprocessamentos
                  </v-card-title>
                  <v-card-subtitle>Lista reprocessamentos solicitados/executados.</v-card-subtitle>
                  <v-divider />
                  <v-card-actions>
                    <v-btn
                      top
                      right
                      color="primary"
                      @click="reprocessHistory()"
                    >
                      Detalhar Histórico
                      <StIcon
                        icon-name="file-document-edit"
                      />
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="i == 2 && result">
              <v-col
                sm="6"
                md="4"
              >
                <v-card>
                  <v-card-title class="subheading font-weight-bold">
                    Conversas
                  </v-card-title>
                  <v-card-subtitle>Detalha configurações de conversas</v-card-subtitle>
                  <v-divider />
                  <v-list v-if="result.sac_options">
                    <v-list-item>
                      <v-switch
                        v-if="result.sac_options.combine_instagram_comments"
                        :input-value="result.sac_options.combine_instagram_comments"
                        readonly
                        disabled
                        inset
                      >
                        <template v-slot:label>
                          <v-tooltip
                            color="black"
                            bottom
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >Agrupamento de Instagram</span>
                            </template>
                            Não é possível alterar essa configuração.
                          </v-tooltip>
                        </template>
                      </v-switch>
                    </v-list-item>
                    <v-list-item>
                      <v-switch
                        v-if="result.sac_options.automatically_annotate"
                        :input-value="result.sac_options.automatically_annotate"
                        readonly
                        disabled
                        inset
                      >
                        <template v-slot:label>
                          <v-tooltip
                            color="black"
                            bottom
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >Anotações automáticas</span>
                            </template>
                            Não é possível alterar essa configuração.
                          </v-tooltip>
                        </template>
                      </v-switch>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
              <v-col
                sm="6"
                md="4"
              >
                <v-card>
                  <v-card-title class="subheading font-weight-bold">
                    Resposta
                  </v-card-title>
                  <v-card-subtitle>Acordo de Nível de Serviço</v-card-subtitle>
                  <v-divider />
                  <v-list v-if="result && result.sac_options">
                    <v-list-item>
                      <v-list-item-content v-if="result.sac_options.sla_agreed">
                        {{ result.sac_options.sla_agreed.months }} meses e
                        {{ result.sac_options.sla_agreed.days }} dias às
                        {{ result.sac_options.sla_agreed.hours }} horas e
                        {{ result.sac_options.sla_agreed.minutes }} minutos.
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="result.sac_options.time_to_close">
                      <v-list-item-content>Fechamento automático após {{ result.sac_options.time_to_close }} horas.</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="i == 3 && result">
              <UniverseHistory />
            </v-row>
            <v-row v-if="!loading && (!result || error)">
              <v-card-text>Nenhum dado encontrado.</v-card-text>
            </v-row>
            <v-row v-if="i == 2 && !loading && (result && !result.sac_options)">
              <v-card-text>Nenhum dado encontrado.</v-card-text>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import StLoader from '@stilingue/st-loader';
import StIcon from '@stilingue/st-icon/StIcon';
import PageHeader from '@/components/base/PageHeader';
import UniverseService from '@/services/DataStoreService/UniverseService';
import UniverseHistory from '@/components/universe/History';

export default {
  name: 'Overview',
  components: {
    PageHeader,
    StLoader,
    StIcon,
    UniverseHistory
  },
  data: () => ({
    labels: {
      title: 'Visão Geral',
      description: 'Detalha dados e possibilidades de atualizações em pesquisa do cliente.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Pesquisa',
          to: '/universe'
        },
        {
          text: 'Visão Geral',
          to: '/universe/overview'
        }
      ]
    },
    loading: false,
    tabs: null,
    result: null,
    error: false,
    langs: {
      'es': 'Espanhol',
      'pt': 'Português',
      'en': 'Inglês'
    },
    models: {
      retweetStatus: null
    }
  }),
  computed: {
    ...mapState('account', ['accounts', 'activeAccount']),
    ...mapState('universe', ['universes', 'activeUniverse']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      }
    },
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      }
    },
  },
  async created() {
    if (this.selectedUniverse) {
      await this.getOverview();
    }
  },
  methods: {
    startLimit(limitType, value) {
      this.$router.push({
        path: '/universe/limit',
        query: { type: limitType, limit: value }
      });
    },
    startRetweet(value) {
      this.$router.push({
        path: '/universe/retweet',
        query: { action: value }
      });
    },
    startLanguage(languages) {
      this.$router.push({
        path: '/universe/language',
        query: { langs: languages }
      });
    },
    reprocessHistory(value) {
      this.$router.push({
        path: '/universe/reprocess-history',
        query: { action: value }
      });
    },
    async getOverview() {
      this.loading = true;
      await UniverseService.getOverview(this.selectedUniverse.id).then(resp => {
        this.loading = false;
        if (!resp.result) {
          return;
        }
        this.result = resp.result;
        this.result.created_at = moment(this.result.created_at).format('DD/MM/YYYY HH:mm:ss');
        this.models.retweetStatus = this.result.process_retweet;

        if (this.result.languages) {
          this.result.languagesName = this.result.languages.split(',').map(name => {
            return this.langs[name];
          });
        }

        if (this.result.sac_options) {
          this.result.sac_options = JSON.parse(this.result.sac_options);
        }
      }).catch(e => {
        console.log('error data');
        console.log(e);
        this.error = true;
        this.loading = false;
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
  .content-body {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;

    .selector-container {
      width: 80%;

      .v-card {
        .v-label {
          i {
            padding-right: 5px;
          }
        }

        .v-toolbar__content {
          i {
            padding-right: 10px;
          }
        }

        .limit {
          border: 1px solid white;
          width: 200px;
          padding: 10px;
        }

        span.v-chip {
          margin-right: 10px;
        }

        .v-btn--fab.v-size--default {
          height: 40px;
          width: 40px;
        }

        .v-btn--fab.v-size--default.v-btn--absolute.v-btn--top {
          top: 100px;
        }
      }

      .universe_status {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
</style>
