import StService from './StService';
import { createApi } from '@stilingue/st-auth-utils';

import { featureFlagFlags } from '@/plugins/rollout';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';

import gateway from './Gateway';

const version = isProdVersion()
  ? featureFlagFlags.AuthServiceVersionProd.getValue()
  : featureFlagFlags.AuthServiceVersionStaging.getValue();

const afterParser = response => response.data;

class AdminToolsService extends StService {
  constructor(gateway, version = 'staging') {
    const api = createApi(`admin-tools/${version}/`, gateway, { timeout: 120000 });
    super(api, { afterParser });
  }

  getPostById({ accountId, universeId, pid }) {
    return this.find({ params: { accountId, universeId, pid }, url: 'post' });
  }

  getFbPostInfo({ pageId, postId }) {
    return this.find({ url: `facebook/posts/${pageId}_${postId}` });
  }

  getDescriptorsInfo({ accountId, universeId, postId, channel }) {
    return this.find({ params: { accountId, universeId, postId, channel }, url: 'descriptors' });
  }

  getAuthErrors({ accountId, pageId }) {
    return this.find({ url: `/accounts/${accountId}/pages/${pageId}/autherrors` });
  }

  analizeSLA(params = {}) {
    const url = 'sla/crawler/proprietary/analyze';
    return this.api.post({ ...params, url }).then(this.afterParser);
  }
}

export default new AdminToolsService(gateway, version);
