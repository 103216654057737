<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <section
      class="selector-container"
    >
      <v-card>
        <v-toolbar flat>
          <v-icon large>
            mdi-account-key
          </v-icon>
          <v-toolbar-title>
            Acessos e Permissões
          </v-toolbar-title>
          <template v-slot:extension>
            <v-tabs
              v-model="tabs"
              fixed-tabs
            >
              <v-tabs-slider />
              <v-tab
                href="#mobile-tabs-1"
                class="primary--text"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-account-group
                    </v-icon>
                  </template>
                  <span>Grupo de Usuário</span>
                </v-tooltip>
              </v-tab>
              <v-tab
                id="v-tab-account"
                href="#mobile-tabs-2"
                class="primary--text"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-domain
                    </v-icon>
                  </template>
                  <span>Conta</span>
                </v-tooltip>
              </v-tab>
              <v-tab
                id="v-tab-user"
                href="#mobile-tabs-3"
                class="primary--text"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-account-box
                    </v-icon>
                  </template>
                  <span>Usuário</span>
                </v-tooltip>
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-tabs-items v-model="tabs">
          <v-tab-item
            v-for="i in 3"
            :key="i"
            :value="'mobile-tabs-' + i"
          >
            <v-row
              v-if="i == 1"
              justify="space-around"
            >
              <v-col>
                <v-card>
                  <v-card-title class="subheading font-weight-bold">
                    {{ selectedUsergroup.name }}
                    <v-tooltip
                      v-if="expirationDate"
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div class="my-2 icon-align">
                          <v-btn
                            color="warning lighten-2"
                            fab
                            dark
                            text
                            icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>mdi-progress-alert</v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>O contrato desse cliente vence em: {{ expirationDate }}</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-subtitle>Detalha contas e usuários do grupo.</v-card-subtitle>
                  <v-card-actions>
                    <v-switch
                      v-model="models.userGroupAccessStilingue"
                      :input-value="models.userGroupAccessStilingue"
                      label="Stilingue"
                      inset
                      @change="getUserGroupAccess(models.userGroupAccessStilingue)"
                    />

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div class="my-2 icon-align">
                          <v-btn
                            color="primary"
                            fab
                            small
                            dark
                            v-bind="attrs"
                            v-on="on"
                            @click="getUniverseCreateLimit()"
                          >
                            <v-icon>
                              mdi-desktop-mac-dashboard
                            </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>Limites de Pesquisa</span>
                    </v-tooltip>
                    <v-card
                      v-if="customerSuccess"
                      class="owners"
                      width="256"
                      tile
                    >
                      <v-system-bar
                        height="14"
                      />
                      <v-list>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-img :src="ownl" />
                          </v-list-item-avatar>
                          <v-list-item-title class="text-h10">
                            Customer Success
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-h6">
                              {{ customerSuccess.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ customerSuccess.email }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                    <v-card
                      v-if="sales"
                      width="256"
                      class="owners"
                      tile
                    >
                      <v-system-bar
                        height="14"
                      />
                      <v-list>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-img :src="ownl" />
                          </v-list-item-avatar>
                          <v-list-item-title class="text-h10">
                            Sales
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-h6">
                              {{ sales.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>{{ sales.email }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-card-actions>
                  <StLoader :loading="loadingUserGroup" />
                  <v-expansion-panels
                    v-if="!loadingUserGroup && userGroupAccessData"
                    accordion
                    dark
                  >
                    <v-expansion-panel
                      v-for="(item,j) in userGroupAccessData"
                      :key="j"
                    >
                      <v-expansion-panel-header
                        v-if="item.users.internal.length == 0 && item.users.external.length == 0"
                      >
                        {{ item.name }}
                        <span>(sem acesso)</span>
                      </v-expansion-panel-header>

                      <v-expansion-panel-header
                        v-else
                      >
                        {{ item.name }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-btn
                          v-if="item.users.internal.length > 0 || item.users.external.length > 0"
                          text
                          icon
                          color="primary lighten-2"
                          @click="showAccount(item)"
                        >
                          <v-icon
                            dark
                          >
                            mdi-magnify
                          </v-icon>
                        </v-btn>
                        <v-data-table
                          :headers="headers"
                          :items="item.users.internal"
                          :multi-sort="multiSort"
                          hide-default-header
                          dark
                          dense
                        >
                          <template v-slot:item.email="{ item }">
                            <span>{{ item.email }}</span>
                            <v-btn
                              text
                              icon
                              color="primary lighten-2"
                              @click="showUser(item.email)"
                            >
                              <v-icon
                                dark
                              >
                                mdi-magnify
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-data-table>
                        <v-data-table
                          :headers="headers"
                          :items="item.users.external"
                          :multi-sort="multiSort"
                          hide-default-header
                          dark
                          dense
                        >
                          <template v-slot:top>
                            <v-toolbar
                              dense
                              flat
                              shaped
                              height="35"
                              class="toolbar-external"
                            >
                              <v-toolbar-title>
                                Acessos Externos
                              </v-toolbar-title>
                            </v-toolbar>
                          </template>
                          <template v-slot:item.email="{ item }">
                            <span>{{ item.email }}</span>
                            <v-btn
                              text
                              icon
                              color="primary lighten-2"
                              @click="showUser(item.email)"
                            >
                              <v-icon
                                dark
                              >
                                mdi-magnify
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-card-text
                    v-if="!loadingUserGroup && !userGroupAccessData"
                  >
                    Nenhum dado encontrado.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row
              v-if="i == 2"
              justify="space-around"
            >
              <v-col>
                <v-card>
                  <v-card-title class="subheading font-weight-bold">
                    {{ selectedAccount.name || 'Indefinido' }}
                  </v-card-title>
                  <v-card-subtitle>Detalha permissões dos usuários com acesso à conta.</v-card-subtitle>
                  <v-card-actions>
                    <v-switch
                      v-model="models.accountAccessStilingue"
                      :input-value="models.accountAccessStilingue"
                      label="Stilingue"
                      inset
                      @change="getAccountAccess(models.accountAccessStilingue)"
                    />
                  </v-card-actions>
                  <StLoader :loading="loadingAccount" />
                  <v-expansion-panels
                    v-if="!loadingAccount && accountAccessData"
                    accordion
                    dark
                  >
                    <v-expansion-panel
                      v-for="(item,j) in accountAccessData"
                      :key="j"
                    >
                      <v-expansion-panel-header
                        v-if="item.users.internal.length == 0 && item.users.external.length == 0"
                      >
                        {{ item.name }}
                        <span>(sem acesso)</span>
                      </v-expansion-panel-header>

                      <v-expansion-panel-header
                        v-else
                      >
                        {{ item.name }} ({{ item.usergroup }})
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="headers"
                          :items="item.users.internal"
                          :multi-sort="multiSort"
                          hide-default-header
                          dark
                          dense
                        >
                          <template v-slot:item.email="{ item }">
                            <span>{{ item.email }}</span>
                            <v-btn
                              text
                              icon
                              color="primary lighten-2"
                              @click="showUser(item.email)"
                            >
                              <v-icon
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-magnify
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-data-table>
                        <v-data-table
                          :headers="headers"
                          :items="item.users.external"
                          :multi-sort="multiSort"
                          hide-default-header
                          dark
                          dense
                        >
                          <template v-slot:top>
                            <v-toolbar
                              dense
                              flat
                              shaped
                              height="35"
                              class="toolbar-external"
                            >
                              <v-toolbar-title>
                                Acessos Externos
                              </v-toolbar-title>
                            </v-toolbar>
                          </template>
                          <template v-slot:item.email="{ item }">
                            <span>{{ item.email }}</span>
                            <v-btn
                              text
                              icon
                              color="primary lighten-2"
                              @click="showUser(item.email)"
                            >
                              <v-icon
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-magnify
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-card-text
                    v-if="!loadingAccount && !accountAccessData"
                  >
                    Nenhum dado encontrado.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row
              v-if="i == 3"
              justify="space-around"
            >
              <v-col>
                <v-card>
                  <v-card-title class="subheading font-weight-bold">
                    {{ selectedUserEmail || 'Indefinido' }}
                  </v-card-title>
                  <v-card-subtitle>Detalha contas que o usuário tem acesso e suas permissões.</v-card-subtitle>
                  <StLoader :loading="loadingUser" />
                  <v-expansion-panels
                    v-if="!loadingUser && userAccessData"
                    accordion
                    dark
                  >
                    <v-expansion-panel
                      v-for="(item,j) in userAccessData"
                      :key="j"
                    >
                      <v-expansion-panel-header
                        v-if="item.accounts.internal.length == 0 && item.accounts.external.length == 0"
                      >
                        {{ item.name }}
                        <span>(sem acesso)</span>
                      </v-expansion-panel-header>

                      <v-expansion-panel-header
                        v-else
                      >
                        {{ item.name }}
                      </v-expansion-panel-header>

                      <v-expansion-panel-content>
                        <v-card
                          max-width="400"
                          tile
                        >
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>Permissão</v-list-item-title>
                              <v-list-item-subtitle>{{ item.role }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title>Grupo</v-list-item-title>
                              <v-list-item-subtitle>{{ item.usergroup }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title>Criado em</v-list-item-title>
                              <v-list-item-subtitle>{{ item.created_at }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-card>
                        <v-data-table
                          :headers="headers"
                          :items="item.accounts.internal"
                          :multi-sort="multiSort"
                          hide-default-header
                          dark
                          dense
                        />
                        <v-data-table
                          :headers="headers"
                          :items="item.accounts.external"
                          :multi-sort="multiSort"
                          hide-default-header
                          dark
                          dense
                        >
                          <template v-slot:top>
                            <v-toolbar
                              dense
                              flat
                              shaped
                              height="35"
                              class="toolbar-external"
                            >
                              <v-toolbar-title>
                                Acessos Externos
                              </v-toolbar-title>
                            </v-toolbar>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-card-text
                    v-if="!loadingUser && !userAccessData"
                  >
                    Nenhum dado encontrado.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <v-dialog
        v-model="dialog"
        width="450"
        content-class="universe-limit-dialog"
      >
        <v-card>
          <v-card-title class="text-h5">
            <v-icon
              large
              color="primary darken-2"
            >
              mdi-desktop-mac-dashboard
            </v-icon>
            Limites de Pesquisa
          </v-card-title>
          <StLoader :loading="loadingLimit" />
          <v-card-text>
            <v-sheet
              v-if="universeCreateLimit"
              color="primary"
              elevation="1"
              shaped
              width="250"
            >
              {{ universeCreateLimit }}
            </v-sheet>
            <v-alert
              v-if="errorUniverseLimit"
              border="right"
              colored-border
              type="error"
              elevation="2"
            >
              {{ errorUniverseLimit }}
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary darken-1"
              text
              @click="dialog = false"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PageHeader from '@/components/base/PageHeader';
import StLoader from '@stilingue/st-loader';
import WarroomService from '@/services/WarroomService';
import UserGroupService from '@/services/DataStoreService/UserGroupService';
import moment from 'moment';
import ownl from '@/assets/img/coruja.jpg';

const { UserGroupAccessService, AccountAccessService, UserAccessService } = WarroomService;

export default {
  name: 'Overview',
  components: {
    PageHeader, StLoader
  }, data: () => ({
    labels: {
      title: 'Visão Geral',
      description: 'Lista acessos e permissões de usuários por grupo de usuário, conta e e-mail.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Grupo de Usuário',
          to: '/usergroup'
        },
        {
          text: 'Visão Geral',
          to: '/usergroup/overview'
        }
      ],
      successMessage: null,
    },
    tabs: null,
    models: {
      accountAccessStilingue: false,
      userGroupAccessStilingue: false
    },
    loadingUserGroup: false,
    loadingAccount: false,
    loadingUser: false,
    loadingLimit: false,
    customerSuccess: null,
    sales: null,
    userGroupAccessData: null,
    accountAccessData: null,
    userAccessData: null,
    errorUniverseLimit: null,
    expirationDate: null,
    multiSort: true,
    universeCreateLimit: null,
    dialog: false,
    headers: [
      {
        text: 'Nome',
        value: 'name',
        align: 'center'
      },
      {
        text: 'E-mail',
        value: 'email',
        align: 'center'
      },
      {
        text: 'Grupo',
        value: 'usergroup',
        align: 'center'
      },
      {
        text: 'Permissão',
        value: 'role',
        align: 'center'
      },
      {
        text: 'Criado em',
        value: 'created_at',
        align: 'center'
      }
    ]
  }),
  computed: {
    ...mapState('user', ['activeUserEmail']),
    ...mapState('account', ['accounts', 'activeAccount', 'usergroups', 'activeUsergroup']),
    selectedUsergroup: {
      get() {
        return this.activeUsergroup;
      }
    },
    selectedAccount: {
      get() {
        return this.activeAccount;
      }
    },
    selectedUserEmail: {
      get() {
        return this.activeUserEmail;
      }
    }
  },
  async created() {
    if (this.selectedUsergroup) {
      this.getUserGroupAccess(false);
    }
    if (this.selectedAccount) {
      this.getAccountAccess(false);
    }
    if (this.selectedUserEmail) {
      this.getUserAccess();
    }
    this.ownl = ownl;
  },
  methods: {
    ...mapActions('user', ['setUserEmail']),
    ...mapActions('account', ['setAccount']),
    selectUserEmail(selectedUserEmail) {
      this.setUserEmail({ selectedUserEmail });
    },
    selectAccount(selectedAccount) {
      this.setAccount({ selectedAccount });
    },
    getUserGroupAccess(stilingue) {
      this.loadingUserGroup = true;
      let url = `?usergroup=${this.selectedUsergroup.name}`;
      if (stilingue === true) {
        url = `${url}&stilingue=1`;
      }
      UserGroupAccessService.find({ url })
        .then(data => {
          this.loadingUserGroup = false;
          if (data.message) {
            this.userGroupAccessData = this.treatAccountUsers(data.message.accounts);
            this.customerSuccess = data.message.customer_success;
            this.sales = data.message.sales;
            this.expirationDate = moment(data.message.expiration_date).add(-3, 'hour').format('DD/MM/YYYY HH:mm:ss');
          }
        }).catch((e) => {
          this.loadingUserGroup = false;
          console.error(e);
        });
    },
    getAccountAccess(stilingue) {
      this.loadingAccount = true;
      let url = `?accs=${this.selectedAccount.id}`;
      if (stilingue === true) {
        url = `${url}&stilingue=1`;
      }
      AccountAccessService.find({ url })
        .then(data => {
          this.loadingAccount = false;
          if (data.message) {
            this.accountAccessData = this.treatAccountUsers(data.message.accounts);
          }
        }).catch((e) => {
          this.loadingAccount = false;
          console.error(e);
        });
    },
    getUserAccess() {
      this.loadingUser = true;
      const url = `?emails=${this.selectedUserEmail}`;
      UserAccessService.find({ url })
        .then(data => {
          this.loadingUser = false;
          if (data.message) {
            this.userAccessData = this.convertDate(data.message.users);
          }
        }).catch((e) => {
          this.loadingUser = false;
          console.error(e);
        });
    },
    showUser(selectedUserEmail) {
      this.selectUserEmail(selectedUserEmail);
      this.getUserAccess();
      document.getElementById('v-tab-user').click();
    },
    showAccount(selectedAccount) {
      this.selectAccount(selectedAccount);
      this.getAccountAccess();
      document.getElementById('v-tab-account').click();
    },
    getUniverseCreateLimit() {
      this.dialog = true;
      this.loadingLimit = true;
      UserGroupService.getUniverseCreateLimit(this.selectedUsergroup.name, 'UserGroup').then(resp => {
        this.loadingLimit = false;
        // eslint-disable-next-line require-unicode-regexp
        const pattern = new RegExp(': None', 'i');
        if (pattern.test(resp.result)) {
          this.universeCreateLimit = `O UserGroup ${this.selectedUsergroup.name} utiliza o novo sistema de créditos. ` +
          'É necessário verificar na plataforma.';
        } else {
          this.universeCreateLimit = resp.result;
        }
      }).catch(e => {
        console.log(e);
        this.errorUniverseLimit = 'Erro inesperado ao tentar buscar o limite. Por favor, informe ao time de N2.';
        this.loadingLimit = false;
      });
    },
    close() {
      this.dialog = false;
    },
    convertDate(data) {
      return data.map(item => {
        item.created_at = moment(item.created_at).add(-3, 'hour').format('DD/MM/YYYY HH:mm:ss');
        const newItem = { ...item };
        return newItem;
      });
    },
    treatAccountUsers(accounts) {
      accounts.forEach(account => {
        account.users.internal = this.convertDate(account.users.internal);
        account.users.external = this.convertDate(account.users.external);
      });
      return accounts;
    }
  }
};
</script>
<style lang="scss" scoped>

  .universe-limit-dialog {
    .v-card__title {
      i {
        padding-right: 10px;
      }
    }

    .v-card__text {
      padding: 30px;

      .v-sheet {
        margin: 0 auto;
        padding: 20px;
      }
    }
  }

  .content-body {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;

    .selector-container {
      width: 80%;

      .v-card {
        .v-label {
          i {
            padding-right: 5px;
          }
        }

        .v-toolbar__content {
          i {
            padding-right: 10px;
          }
        }

        .v-expansion-panel-header {
          font-weight: 600;

          > span {
            color: #27ccded4;
            margin-left: 10px;
            font-size: 12px;
          }
        }

        .toolbar-external {
          .v-toolbar__content {
            height: none;
          }

          .v-toolbar__title {
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            width: 100%;
          }
        }

        .icon-align {
          margin-left: 40px;
          cursor: pointer;
        }
      }

      .owners {
        margin-left: 50px;
      }
    }
  }
</style>
