import StService from './StService';
import { createApi } from '@stilingue/st-auth-utils';

import { featureFlagFlags } from '@/plugins/rollout';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';

import gateway from './Gateway';

const version = isProdVersion()
  ? featureFlagFlags.AuthServiceVersionProd.getValue()
  : featureFlagFlags.AuthServiceVersionStaging.getValue();

const afterParser = response => response.data;

class BenchmarksService extends StService {
  constructor(gateway, version = 'staging') {
    const api = createApi(`benchmarks-db-manager/${version}/`, gateway, { timeout: 120000 });
    super(api, { afterParser });
  }

  getBenchmarks(params = {}) {
    return this.api.get({ params, url: 'benchmarks' }).then(this.afterParser);
  }

  updateBenchmarks(expiryDate, id) {
    return this.api.patch({ expiryDate, url: `benchmarks/${id}` }).then(this.afterParser);
  }
}
export default new BenchmarksService(gateway, version);
