const SPLIT_REGEX = /[,\t\n]/ug;

export default {
  data() {
    return {
      newUsers: '',
      currentUsersList: [],
      users: [],
      isNumber: [
        v => !/\s/gu.test(v) || this.$t('Forms.label.message.error.withoutSpace'),
        v => !isNaN(v) || this.$t('Forms.label.message.error.mustBeNumber'),
        v => /^[0-9]{16}$/u.test(v) || this.$t('Forms.label.message.error.invalidInfo'),
      ],
      existsAndIsNumber: [
        v => Boolean(v) || this.$t('Forms.label.message.error.emptyField'),
        v => !/\s/gu.test(v) || this.$t('Forms.label.message.error.withoutSpace'),
        v => !isNaN(v) || this.$t('Forms.label.message.error.mustBeNumber'),
        v => /^[0-9]{16}$/u.test(v) || this.$t('Forms.label.message.error.invalidInfo'),
      ],
      emailRules: [
        v => (v && v.every(vv => Boolean(vv))) || this.$t('Forms.label.message.error.addEmail'),
        v => (v && v.every(vv => /\S+@\S+\.\S+/u.test(vv))) || this.$t('Forms.label.message.error.addAllEmails'),
      ],
      mustBeFilled: [v => {
        return Boolean(v) || this.$t('Forms.label.message.error.emptyField');
      }],
      isEmail: [v => {
        const pattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/u;
        if (v && v !== null && v.length > 0) {
          return pattern.test(v) || this.$t('Forms.label.message.error.email');
        }
        return false;
      }],
    };
  },
  methods: {
    splitUsers() {
      const areThereMultipleUsers = this.newUsers && this.newUsers.split(SPLIT_REGEX).length > 1;

      if (areThereMultipleUsers) {
        let splittedUsers = this.newUsers.split(SPLIT_REGEX);
        splittedUsers = [...new Set(splittedUsers)].filter(value => String(value).trim());

        this.currentUsersList = [...new Set(this.currentUsersList.concat(splittedUsers))];

        this.users = this.stringArrayToObjectArray(this.currentUsersList);
        this.newUsers = '';
      }
    },
    stringArrayToObjectArray(array, isAdmin) {
      return array.map((string) => this.emailToUserObject(string, isAdmin));
    },
  }
};
