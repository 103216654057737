export const HTTP_RESPONSES = {
  STATUS_200: 200,
  STATUS_400: 400,
  STATUS_500: 500,
};

export const FACEBOOK_FLOWTYPES = [
  'FacebookPosts',
  'FacebookReviews',
  'FacebookMentions',
  'FacebookInbox',
  'FacebookComments',
  'FacebookVisitorPosts',
  'FacebookDarkPosts',
  'FacebookMentionsHooks',
  'FacebookReviewsHooks',
  'FacebookPostsHooks',
  'FacebookCommentsHooks',
  'FacebookInboxHooks',
  'FacebookDynamicComments'
];

export const INSTAGRAM_FLOWTYPES = [
  'InstagramBusiness',
  'InstagramBusinessDarkPosts',
  'InstagramBusinessComments',
  'InstagramBusinessDarkComments',
];

export const TWITTER_FLOWTYPES = [
  'TwitterPosts',
  'TwitterMentions'
];
