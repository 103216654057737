import StService from './StService';
import { createApi } from '@stilingue/st-auth-utils';
import { featureFlagFlags } from '@/plugins/rollout';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import gateway from './Gateway';

const version = isProdVersion()
  ? featureFlagFlags.RoutingServiceVersionProd.getValue()
  : featureFlagFlags.RoutingServiceVersionStaging.getValue();

const afterParser = response => response.data;

const passkey = isProdVersion()
  ? 'f272e9323db6eb1295d90917fd85df550a973cc7'
  : '2NVHxvwK2dXNndUFUDmCDBiWro8ITelCiizo6C0D';

class RoutingService extends StService {
  constructor(gateway, version) {
    const api = createApi(`sac-router/${version}/`, gateway);
    super(api, { afterParser });
  }

  getRoutes(params = {}) {
    params.passkey = passkey;
    return this.api.get({ params, url: 'router/setting' }).then(this.afterParser);
  }

  getRouterSettings(routingId = '', params = {}) {
    params.passkey = passkey;
    return this.api.get({ params, url: `router/setting/${routingId}` }).then(this.afterParser);
  }

  getUserStatusHistory(params = {}) {
    const { userId, accountId, universeId, period } = params;

    return this.api.get({
      params: period ? { passkey, period } : { passkey },
      url: `accounts/${accountId}/universes/${universeId}/router/users/${userId}/statuses`
    }).then(this.afterParser);
  }
}

export default new RoutingService(gateway, version);
