<template>
  <div>
    <DsModal
      :open="show"
      :title="this.$t('RetroactiveList.cancelation.confirmation.title')"
      :text="this.$t('RetroactiveList.cancelation.confirmation.descriptionBody')"
      :primary-button-label="this.$t('RetroactiveList.cancelation.confirmation.yes')"
      :secondary-button-label="this.$t('RetroactiveList.cancelation.confirmation.cancel')"
      data-color-theme="dark"
      width="400"
      @primary="agree"
      @secondary="cancel"
    />
  </div>
</template>

<script>
import { DsModal } from '@ds/components';

export default {
  name: 'CancelConfirmDialog',
  components: {
    DsModal,
  },
  data() {
    return {
      show: false
    };
  },
  methods: {
    confirm() {
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.show = false;
    },
    cancel() {
      this.resolve(false);
      this.show = false;
    }
  }
};
</script>
