var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "retroactive-list" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.$t("Liberation.label.placeholder.pageTitle"),
          description: _vm.$t("Liberation.label.placeholder.pageDescription"),
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "section",
        { staticClass: "side-by-side" },
        [
          _c(
            "section",
            { staticClass: "selector-container" },
            [
              _vm.selectedStep === 0
                ? _c(
                    "v-form",
                    {
                      ref: "liberationFormStep1",
                      attrs: { "lazy-validation": false },
                      on: {
                        submit: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                        },
                      },
                      model: {
                        value: _vm.models.valid,
                        callback: function ($$v) {
                          _vm.$set(_vm.models, "valid", $$v)
                        },
                        expression: "models.valid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.mustBeFilled,
                          required: "",
                          label: _vm.$t(
                            "Liberation.label.placeholder.opportunityId"
                          ),
                        },
                        on: { input: _vm.setErrorFalse },
                        model: {
                          value: _vm.models.opportunityId,
                          callback: function ($$v) {
                            _vm.$set(_vm.models, "opportunityId", $$v)
                          },
                          expression: "models.opportunityId",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.mustBeFilled,
                          required: "",
                          label: _vm.$t(
                            "Liberation.label.placeholder.quotationId"
                          ),
                        },
                        on: { input: _vm.setErrorFalse },
                        model: {
                          value: _vm.models.quotationId,
                          callback: function ($$v) {
                            _vm.$set(_vm.models, "quotationId", $$v)
                          },
                          expression: "models.quotationId",
                        },
                      }),
                      _c("SingleSelect", {
                        attrs: {
                          required: "",
                          items: _vm.ACTIVATION_TYPE_LIST,
                          rules: _vm.mustBeFilled,
                          placeholder: _vm.$t(
                            "Liberation.label.placeholder.activationType"
                          ),
                          label: _vm.$t(
                            "Liberation.label.placeholder.activationType"
                          ),
                          text: "name",
                          "sub-text": "value",
                        },
                        on: {
                          input: _vm.setErrorFalse,
                          change: _vm.judgeIfIsNewContract,
                        },
                        model: {
                          value: _vm.models.activationType,
                          callback: function ($$v) {
                            _vm.$set(_vm.models, "activationType", $$v)
                          },
                          expression: "models.activationType",
                        },
                      }),
                      _vm.isNewContract
                        ? _c(
                            "div",
                            [
                              _c(
                                "div",
                                { staticClass: "line-block" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      rules: _vm.mustBeFilled,
                                      placeholder: _vm.$t(
                                        "Liberation.label.placeholder.userGroup"
                                      ),
                                      label: _vm.$t(
                                        "Liberation.label.placeholder.userGroup"
                                      ),
                                    },
                                    on: { input: _vm.setErrorFalse },
                                    model: {
                                      value: _vm.models.newUserGroup,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.models,
                                          "newUserGroup",
                                          $$v
                                        )
                                      },
                                      expression: "models.newUserGroup",
                                    },
                                  }),
                                  _c(
                                    "DsTooltip",
                                    {
                                      attrs: {
                                        position: "left",
                                        content: _vm.$t(
                                          "Liberation.label.info.newUserGroup"
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-2",
                                          attrs: { medium: "" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                mdi-information\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  rules: _vm.mustBeFilled.concat(_vm.isEmail),
                                  placeholder: _vm.$t(
                                    "Liberation.label.placeholder.emailMaster"
                                  ),
                                  label: _vm.$t(
                                    "Liberation.label.placeholder.emailMaster"
                                  ),
                                },
                                on: { input: _vm.setErrorFalse },
                                model: {
                                  value: _vm.models.newEmailMaster,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.models, "newEmailMaster", $$v)
                                  },
                                  expression: "models.newEmailMaster",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  rules: _vm.mustBeFilled,
                                  placeholder: _vm.$t(
                                    "Liberation.label.placeholder.clientName"
                                  ),
                                  label: _vm.$t(
                                    "Liberation.label.placeholder.clientName"
                                  ),
                                },
                                on: { input: _vm.setErrorFalse },
                                model: {
                                  value: _vm.models.clientName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.models, "clientName", $$v)
                                  },
                                  expression: "models.clientName",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  rules: _vm.mustBeFilled,
                                  placeholder: _vm.$t(
                                    "Liberation.label.placeholder.accountName"
                                  ),
                                  label: _vm.$t(
                                    "Liberation.label.placeholder.accountName"
                                  ),
                                },
                                on: { input: _vm.setErrorFalse },
                                model: {
                                  value: _vm.models.accountName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.models, "accountName", $$v)
                                  },
                                  expression: "models.accountName",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  rules: _vm.mustBeFilled.concat(_vm.isEmail),
                                  placeholder: _vm.$t(
                                    "Liberation.label.placeholder.accountManagerEmail"
                                  ),
                                  label: _vm.$t(
                                    "Liberation.label.placeholder.accountManagerEmail"
                                  ),
                                },
                                on: { input: _vm.setErrorFalse },
                                model: {
                                  value: _vm.models.accountExecutiveEmail,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.models,
                                      "accountExecutiveEmail",
                                      $$v
                                    )
                                  },
                                  expression: "models.accountExecutiveEmail",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  rules: _vm.mustBeFilled,
                                  placeholder: _vm.$t(
                                    "Liberation.label.placeholder.accountManagerName"
                                  ),
                                  label: _vm.$t(
                                    "Liberation.label.placeholder.accountManagerName"
                                  ),
                                },
                                on: { input: _vm.setErrorFalse },
                                model: {
                                  value: _vm.models.accountExecutiveName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.models,
                                      "accountExecutiveName",
                                      $$v
                                    )
                                  },
                                  expression: "models.accountExecutiveName",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c("SingleSelect", {
                                attrs: {
                                  required: "",
                                  items: _vm.userGroupList,
                                  rules: _vm.mustBeFilled,
                                  placeholder: _vm.$t(
                                    "Liberation.label.placeholder.userGroup"
                                  ),
                                  label: _vm.$t(
                                    "Liberation.label.placeholder.userGroup"
                                  ),
                                  text: "name",
                                  "sub-text": " ",
                                },
                                on: {
                                  input: _vm.setErrorFalse,
                                  change: _vm.loadEmailMastersFromUserGroup,
                                },
                                model: {
                                  value: _vm.models.userGroup,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.models, "userGroup", $$v)
                                  },
                                  expression: "models.userGroup",
                                },
                              }),
                              _c("SingleSelect", {
                                attrs: {
                                  required: "",
                                  items: _vm.emailMastersFromUserGroupList,
                                  rules: _vm.mustBeFilled,
                                  placeholder: _vm.$t(
                                    "Liberation.label.placeholder.emailMaster"
                                  ),
                                  label: _vm.$t(
                                    "Liberation.label.placeholder.emailMaster"
                                  ),
                                  text: "emailMaster",
                                  "sub-text": " ",
                                },
                                on: { input: _vm.setErrorFalse },
                                model: {
                                  value: _vm.models.emailMaster,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.models, "emailMaster", $$v)
                                  },
                                  expression: "models.emailMaster",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  rules: _vm.mustBeFilled,
                                  placeholder: _vm.$t(
                                    "Liberation.label.placeholder.accountName"
                                  ),
                                  label: _vm.$t(
                                    "Liberation.label.placeholder.accountName"
                                  ),
                                },
                                on: { input: _vm.setErrorFalse },
                                model: {
                                  value: _vm.models.accountName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.models, "accountName", $$v)
                                  },
                                  expression: "models.accountName",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  rules: _vm.mustBeFilled.concat(_vm.isEmail),
                                  placeholder: _vm.$t(
                                    "Liberation.label.placeholder.accountManagerEmail"
                                  ),
                                  label: _vm.$t(
                                    "Liberation.label.placeholder.accountManagerEmail"
                                  ),
                                },
                                on: { input: _vm.setErrorFalse },
                                model: {
                                  value: _vm.models.accountExecutiveEmail,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.models,
                                      "accountExecutiveEmail",
                                      $$v
                                    )
                                  },
                                  expression: "models.accountExecutiveEmail",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  rules: _vm.mustBeFilled,
                                  placeholder: _vm.$t(
                                    "Liberation.label.placeholder.accountManagerName"
                                  ),
                                  label: _vm.$t(
                                    "Liberation.label.placeholder.accountManagerName"
                                  ),
                                },
                                on: { input: _vm.setErrorFalse },
                                model: {
                                  value: _vm.models.accountExecutiveName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.models,
                                      "accountExecutiveName",
                                      $$v
                                    )
                                  },
                                  expression: "models.accountExecutiveName",
                                },
                              }),
                            ],
                            1
                          ),
                      _c(
                        "v-col",
                        { staticClass: "checkbox-area", attrs: { cols: "12" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              label: _vm.$t(
                                "Liberation.label.placeholder.isItToSendEmail"
                              ),
                              value: _vm.models.isItToSendEmail,
                            },
                            model: {
                              value: _vm.models.isItToSendEmail,
                              callback: function ($$v) {
                                _vm.$set(_vm.models, "isItToSendEmail", $$v)
                              },
                              expression: "models.isItToSendEmail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedStep === 1
                ? _c(
                    "v-form",
                    {
                      ref: "liberationFormStep2",
                      attrs: { "lazy-validation": false },
                      on: {
                        submit: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                        },
                      },
                      model: {
                        value: _vm.models.valid,
                        callback: function ($$v) {
                          _vm.$set(_vm.models, "valid", $$v)
                        },
                        expression: "models.valid",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          required: "",
                          items: Object.keys(_vm.CREDITS_TYPE),
                          rules: _vm.mustBeFilled,
                          label: _vm.$t(
                            "Liberation.label.placeholder.creditType"
                          ),
                        },
                        on: {
                          input: _vm.setErrorFalse,
                          change: _vm.loadSubCreditTypes,
                        },
                        model: {
                          value: _vm.models.creditType,
                          callback: function ($$v) {
                            _vm.$set(_vm.models, "creditType", $$v)
                          },
                          expression: "models.creditType",
                        },
                      }),
                      _c("v-select", {
                        attrs: {
                          required: "",
                          items: _vm.selectedSubCreditType,
                          rules: _vm.mustBeFilled,
                          label: _vm.$t(
                            "Liberation.label.placeholder.creditValue"
                          ),
                        },
                        on: { input: _vm.setErrorFalse },
                        model: {
                          value: _vm.models.creditValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.models, "creditValue", $$v)
                          },
                          expression: "models.creditValue",
                        },
                      }),
                      _c("v-text-field", {
                        attrs: {
                          placeholder: _vm.$t(
                            "Liberation.label.placeholder.prodNum"
                          ),
                          rules: _vm.mustBeFilled,
                          type: "number",
                          required: "",
                          label: _vm.$t("Liberation.label.placeholder.prodQty"),
                        },
                        on: { input: _vm.setErrorFalse },
                        model: {
                          value: _vm.models.prodQty,
                          callback: function ($$v) {
                            _vm.$set(_vm.models, "prodQty", $$v)
                          },
                          expression: "models.prodQty",
                        },
                      }),
                      _c("StOneDatePicker", {
                        attrs: {
                          label: _vm.$t(
                            "Liberation.label.placeholder.expirationDate"
                          ),
                          "max-date": _vm.fiveYearsDate,
                          "min-date": _vm.todayDate,
                        },
                        on: {
                          dateToSubmit: _vm.setEndDate,
                          input: _vm.setErrorFalse,
                        },
                        model: {
                          value: _vm.models.expirationDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.models, "expirationDate", $$v)
                          },
                          expression: "models.expirationDate",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.selectedStep === 0
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { disabled: _vm.isFilledFieldsOfFirstStep() },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.resetFormStep1.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("Forms.label.button.clean")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.selectedStep !== 0
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.backStep.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("Forms.label.button.back")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.selectedStep === 0
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        disabled: _vm.isFilledFieldsOfFirstStep(),
                        color: "light-blue",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.nextStep.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("Forms.label.button.next")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.selectedStep === 1
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        disabled: !_vm.models.valid || _vm.loading,
                        color: "light-blue",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.addProductToList.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("Forms.label.button.add")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.selectedStep === 1
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        disabled: _vm.isFilledFieldsOfSecondStep(),
                        color: "light-blue",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.resetFormStep2.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("Forms.label.button.clean")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _c("StLoader", { attrs: { loading: _vm.loading } }),
              _vm.error
                ? _c(
                    "DsAlert",
                    { staticClass: "text-black", attrs: { type: "error" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t(
                              "HttpResponses.label.message.error.requestNotCompleted"
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.models.isItToShowSnackbar
            ? _c("DsSnackbar", {
                attrs: {
                  type: _vm.models.typeOfSnackbar,
                  show: _vm.models.isItToShowSnackbar,
                  text: _vm.models.textToShowIntoSnackbar,
                },
                on: {
                  close: function ($event) {
                    _vm.models.isItToShowSnackbar =
                      !_vm.models.isItToShowSnackbar
                  },
                },
              })
            : _vm._e(),
          _vm.httpShowResponseSnackbar
            ? _c("DsSnackbar", {
                attrs: {
                  type: _vm.httpResponseSnackbarType,
                  show: _vm.httpShowResponseSnackbar,
                  text: _vm.httpShowResponseSnackbarText,
                },
                on: {
                  close: function ($event) {
                    _vm.httpShowResponseSnackbar = !_vm.httpShowResponseSnackbar
                  },
                },
              })
            : _vm._e(),
          _c("section", { staticClass: "cart-container" }, [
            _c(
              "div",
              { staticClass: "contract-common-data-container" },
              [
                _c("v-card-title", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "Liberation.label.placeholder.contractCommonDataTitle"
                      )
                    )
                  ),
                ]),
                !_vm.models.isStep1Submitted
                  ? [
                      _c("p", { staticClass: "pl-6" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "Liberation.label.placeholder.contractCommonDataTitleEmpty"
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm.models.isStep1Submitted
                  ? [
                      _c("p", { staticClass: "contract-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "Liberation.label.placeholder.opportunityId"
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "contract-text" }, [
                        _vm._v(_vm._s(_vm.models.opportunityId)),
                      ]),
                      _c("p", { staticClass: "contract-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("Liberation.label.placeholder.quotationId")
                            ) +
                            "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "contract-text" }, [
                        _vm._v(_vm._s(_vm.models.quotationId)),
                      ]),
                      _c("p", { staticClass: "contract-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "Liberation.label.placeholder.activationType"
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "contract-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.models.activationType &&
                              _vm.models.activationType.name
                          )
                        ),
                      ]),
                      _c("p", { staticClass: "contract-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("Liberation.label.placeholder.userGroup")
                            ) +
                            "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "contract-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.isNewContract
                              ? _vm.models.newUserGroup
                              : _vm.models.userGroup &&
                                  _vm.models.userGroup.name
                          )
                        ),
                      ]),
                      _c("p", { staticClass: "contract-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("Liberation.label.placeholder.emailMaster")
                            ) +
                            "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "contract-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.isNewContract
                              ? _vm.models.newEmailMaster
                              : _vm.models.emailMaster &&
                                  _vm.models.emailMaster.emailMaster
                          )
                        ),
                      ]),
                      _c("p", { staticClass: "contract-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("Liberation.label.placeholder.clientName")
                            ) +
                            "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "contract-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.isNewContract
                              ? _vm.models.clientName
                              : _vm.models.emailMaster &&
                                  _vm.models.emailMaster.name
                          )
                        ),
                      ]),
                      _c("p", { staticClass: "contract-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("Liberation.label.placeholder.accountName")
                            ) +
                            "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "contract-text" }, [
                        _vm._v(_vm._s(_vm.models.accountName)),
                      ]),
                      _c("p", { staticClass: "contract-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "Liberation.label.placeholder.accountManagerEmail"
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "contract-text" }, [
                        _vm._v(_vm._s(_vm.models.accountExecutiveEmail)),
                      ]),
                      _c("p", { staticClass: "contract-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "Liberation.label.placeholder.accountManagerName"
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "contract-text" }, [
                        _vm._v(_vm._s(_vm.models.accountExecutiveName)),
                      ]),
                      _c("p", { staticClass: "contract-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "Liberation.label.placeholder.contractCommonDataIsItToSendEmail"
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "contract-text" }, [
                        _vm._v(
                          _vm._s(
                            _vm.models.isItToSendEmail
                              ? _vm.$t("Forms.label.placeholder.yes")
                              : _vm.$t("Forms.label.placeholder.no")
                          )
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "cart-content-cards" },
              [
                _c("v-card-title", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("Liberation.label.placeholder.productsListTitle")
                    )
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "cart-cards" },
              [
                _vm.models.productsCart.length
                  ? [
                      _c("v-data-table", {
                        staticClass: "table",
                        attrs: {
                          headers: _vm.table.headers,
                          items: _vm.models.productsCart,
                          search: _vm.table.search,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item.expirationDate",
                              fn: function ({ item }) {
                                return [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.convertToLocalDate(
                                          item.expirationDate
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "item.action",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeProductFromList(item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                mdi-delete-forever\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3219193341
                        ),
                      }),
                    ]
                  : _vm._e(),
                [
                  _vm.models.productsCart.length === 0
                    ? _c("p", { staticClass: "pl-6" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "Liberation.label.placeholder.productsListTitleEmpty"
                              )
                            ) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                ],
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "cart-buttons" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-4",
                    attrs: {
                      disabled: !_vm.models.productsCart.length || _vm.loading,
                      color: "light-blue",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.cleanCart.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("Forms.label.button.clean")) +
                        "\n        "
                    ),
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-4",
                    attrs: {
                      disabled: !_vm.models.productsCart.length || _vm.loading,
                      color: "light-blue",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.registerNewCredit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("Forms.label.button.activate")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("ListLiberation", {
        key: _vm.componentKey,
        staticClass: "mt-4",
        attrs: { "is-new-contract": _vm.isNewContract },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }