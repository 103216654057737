var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "auth-errors" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "section",
        { staticClass: "selector-container" },
        [
          _c("SingleSelect", {
            attrs: {
              items: _vm.accounts,
              placeholder: "Selecione uma conta",
              text: "name",
              "sub-text": "group",
            },
            model: {
              value: _vm.selectedAccount,
              callback: function ($$v) {
                _vm.selectedAccount = $$v
              },
              expression: "selectedAccount",
            },
          }),
          _vm.activeAccount
            ? _c("SingleSelect", {
                attrs: {
                  items: _vm.pages,
                  placeholder: _vm.pagePlaceholder,
                  text: "name",
                  "sub-text": "channel",
                },
                model: {
                  value: _vm.selectedPage,
                  callback: function ($$v) {
                    _vm.selectedPage = $$v
                  },
                  expression: "selectedPage",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "auth-errors__table-container" },
        [
          _c("StLoader", { attrs: { loading: _vm.loadingHistory } }),
          _vm.historyIsLoaded && !_vm.loadingHistory
            ? _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.history,
                  "multi-sort": _vm.multiSort,
                  "custom-sort": _vm.customSort,
                  dark: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.occurrences",
                      fn: function ({ item }) {
                        return [
                          _c("StMenu", { attrs: { items: item.occurrences } }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  536129513
                ),
              })
            : _vm._e(),
          _vm.historyError
            ? _c("section", { staticClass: "failure-notification" }, [
                _c(
                  "span",
                  { on: { click: _vm.getPageAuthError } },
                  [_c("v-icon", [_vm._v("mdi-reload")]), _vm._v(" Recarregar")],
                  1
                ),
              ])
            : _vm._e(),
          _vm.accountWithoutPage
            ? _c(
                "section",
                {
                  staticClass:
                    "failure-notification failure-notification--no-pages",
                },
                [
                  _c(
                    "span",
                    [
                      _c("v-icon", [_vm._v("mdi-alert-outline")]),
                      _vm._v(
                        " Nenhuma página conectada encontrada para a conta."
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }