<template>
  <div class="new-retroactive">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <div class="new-retroactive__form-and-result">
      <StLoader :loading="loading" />
      <v-form
        ref="newRetroactiveForm"
        v-model="models.valid"
        class="new-retroactive__form"
        :lazy-validation="false"
      >
        <v-textarea
          v-model="retroativeList"
          :hint="labels.hint"
        />
        <v-text-field
          v-model="models.link"
          required
          :name="Math.random()"
          :rules="rules.isLink"
          :label="labels.link"
          @input="setErrorFalse"
        />
        <StOneDatePicker
          :label="labels.startDate"
          @dateToSubmit="setStartDate"
          @input="setErrorFalse"
        />
        <StOneDatePicker
          :label="labels.endDate"
          @dateToSubmit="setEndDate"
          @input="setErrorFalse"
        />
        <v-select
          v-model="models.channels"
          :items="availableChannels"
          multiple
          chips
          :label="labels.channels"
          @input="setErrorFalse"
        />
        <v-btn
          :disabled="!models.valid || loading"
          color="light-blue"
          class="mr-4"
          @click.stop="startNewRetroactiveList"
        >
          {{ labels.startRetroactive }}
        </v-btn>
      </v-form>
      <template v-if="error">
        <v-alert
          v-for="errorMessage in labels.errorMessage"
          :key="errorMessage"
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          {{ errorMessage }}
        </v-alert>
      </template>
      <template v-if="success">
        <v-alert
          v-for="successMessage in labels.successMessage"
          :key="successMessage"
          border="right"
          colored-border
          type="success"
          elevation="2"
        >
          {{ successMessage }}
        </v-alert>
      </template>
      <v-dialog
        v-if="hasAlreadyRetroactive"
        v-model="dialog"
        max-width="514px"
        persistent
      >
        <v-card>
          <span class="card_title"> Atualização de coleta retroativa </span>

          <v-card-text>
            Identificamos uma solicitação de retroativo para a mesma pesquisa no período de {{ hasAlreadyRetroactive.begin.format(brazilFormatWithoutHours) }}
            a {{ hasAlreadyRetroactive.end.format(brazilFormatWithoutHours) }}.<br>

            Deseja atualizar o período para {{ compareDates(hasAlreadyRetroactive) }}?
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="cancel"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="button-dialog-update"
              text
              @click="agree"
            >
              Atualizar
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>

import moment from 'moment';
import StLoader from '@stilingue/st-loader';
import RetroactiveService from '@/services/RetroactiveService';
import StOneDatePicker from '../StOneDatePicker.vue';
import PageHeader from '@/components/base/PageHeader';

export default {
  name: 'NewRetroactive',
  components: { StOneDatePicker, StLoader, PageHeader },
  data: () => ({
    availableChannels: [
      'Blogs',
      'Facebook',
      'FacebookComments',
      'Fóruns',
      'GooglePlus',
      'Portais',
      'ReclameAqui',
      'Tumblr',
      'Twitter',
      'YouTube',
      'YouTubeComments'
    ],
    mapChannels: {
      'Blogs': [
        'Feedly',
      ],
      'Fóruns': [
        'DiariosOficiais',
        'HardMob',
      ],
      'Portais': [
        'BoxNetWebNews',
        'News'
      ],
      'TV': [
        'BoxNetTV'
      ],
      'Rádio': [
        'BoxNetRadio'
      ],
      'Impressas': [
        'BoxNetPrintedNews'
      ],
      'Comentários em Artigos': [
        'Disqus',
      ]
    },
    labels: {
      errorMessage: [],
      successMessage: [],
      channels: 'Canais (opcional)',
      startDate: 'Data de início (dia/mês/ano)',
      endDate: 'Data de fim (dia/mês/ano) (opcional)',
      startRetroactive: 'Iniciar retroativo',
      link: 'Link da tarefa ou ticket associado (jira, zendesk...)',
      title: 'Novo Retroativo',
      description: 'Permite execução em massa de retroativo de coleta com base nos dados informados pelo cliente.',
      finishedStatus: 'finished',
      hint: 'colar lista de account_id e universe_id separados por ponto e virgula. Se houver mais de um universo para o mesmo acc_id separar por virgula.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Retroativo',
          to: '/retroactive'
        },
        {
          text: 'em Massa',
          to: '/retroactive/mass'
        }
      ]
    },
    models: {
      channels: undefined,
      usergroup: undefined,
      startDate: undefined,
      endDate: undefined,
      valid: false,
      link: undefined,
    },
    rules: {
      isLink: [v => {
        // eslint-disable-next-line
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z:\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(v) || 'URL inválida';
      }]
    },
    loading: false,
    error: false,
    success: false,
    hasAlreadyRetroactive: null,
    resolve: null,
    reject: null,
    brazilFormatWithoutHours: 'DD/MM/YYYY',
    retroativeList: undefined,
    retro: [],
  }),
  methods: {
    setStartDate(date) {
      this.models.startDate = date;
    },
    setEndDate(date) {
      this.models.endDate = date;
    },
    setErrorFalse() {
      this.error = false;
    },
    getChannelsParam(channels) {
      let paramChannels = channels;
      for (const channel in this.mapChannels) {
        if (paramChannels.includes(channel)) {
          paramChannels.splice(paramChannels.indexOf(channel), 1);
          paramChannels = paramChannels.concat(this.mapChannels[channel]);
        }
      }

      return paramChannels.join(',');
    },
    getParameters() {
      const parameters = {};

      parameters.begin = this.models.startDate;

      if (this.models.endDate) {
        parameters.end = this.models.endDate;
      }

      if (this.models.channels && this.models.channels.length) {
        parameters.channels = this.getChannelsParam(this.models.channels);
      }

      if (this.models.link) {
        parameters.task_ids = [this.models.link];
      }

      return parameters;
    },
    async startNewRetroactiveList() {
      this.separeRetroativeList();
      await this.retro.forEach(element => {
        this.startNewRetroactive(element.acc_id, element.uni_id);
      });
    },
    async startNewRetroactive(acc_id, uni_id) {
      const parameters = this.getParameters();
      if (this.parametersHandler(parameters)) {
        return;
      }
      parameters.account_id = acc_id;
      parameters.universe_id = uni_id;

      const retroactives = await RetroactiveService.getRetroactives();
      this.hasAlreadyRetroactive = retroactives.data.find(retroactive => {
        return retroactive.universe_id === parameters.universe_id && retroactive.status !== this.labels.finishedStatus;
      });

      if (this.hasAlreadyRetroactive) {
        this.hasAlreadyRetroactive = (({ begin, end }) => ({ begin, end }))(this.hasAlreadyRetroactive);
        this.hasAlreadyRetroactive.begin = moment(this.hasAlreadyRetroactive.begin);
        this.hasAlreadyRetroactive.end = moment(this.hasAlreadyRetroactive.end);
        this.dialog = true;
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        }).then(() => {
          return this.makeNewRetroactive(parameters);
        }).catch(() => {
          this.loading = false;
        });
      }
      this.makeNewRetroactive(parameters);
    },
    makeNewRetroactive(parameters) {
      this.error = false;
      this.loading = true;
      RetroactiveService.newRetroactive(parameters).then(resp => {
        if (resp.data.status === 'success') {
          this.success = true;
          this.labels.successMessage.push(`Retroativo conta ${parameters.account_id} pesquisa ${parameters.universe_id} cadastrado com sucesso.`);
        } else {
          this.error = true;
        }
        this.loading = false;
      }).catch(e => {
        console.error(e);
        this.error = true;
        this.loading = false;
        const error = e.toString().slice(-3);
        switch (error) {
        case '400':
          this.labels.errorMessage.push(`Por favor, certifique-se pelo Visão Geral conta ${parameters.account_id} pesquisa ${parameters.universe_id} se a mesma está Em execução.`);
          break;
        case '500':
          this.labels.errorMessage.push(`Falha ao cadastrar o retroativo conta ${parameters.account_id} pesquisa ${parameters.universe_id}. Por favor, entre em contato com o time de N2.`);
          break;
        default:
          break;
        }
      });
    },
    separeRetroativeList() {
      const retroative = this.retroativeList.split('\n');
      retroative.forEach(element => {
        const el = element.split(';');
        let item = {};
        if (el[1].includes(',')) {
          const it = el[1].split(',');
          it.forEach(m => {
            item = {};
            item.acc_id = el[0];
            item.uni_id = m;
            return this.retro.push(item);
          });
        } else {
          item.acc_id = el[0];
          item.uni_id = el[1];
          return this.retro.push(item);
        }
      });
    },
    agree() {
      this.resolve(true);
      this.loading = true;
      this.dialog = false;
    },
    cancel() {
      this.reject(true);
      this.dialog = false;
      // redirect to retroactive list
      const redirectTo = '/retroactive/list';
      this.$router.replace({
        path: redirectTo,
      }).catch(/*istanbul ignore next*/() => {
        //do nothing because it's just page redirection
      });
    },
    compareDates(hasAlreadyRetroactive) {
      const startDateInBrazil = moment(this.models.startDate);
      const endDateInBrazil = moment(this.models.endDate);
      const olderDate = hasAlreadyRetroactive.begin < startDateInBrazil ? hasAlreadyRetroactive.begin : startDateInBrazil;
      const newerDate = hasAlreadyRetroactive.end > endDateInBrazil ? hasAlreadyRetroactive.end : endDateInBrazil;
      return `${olderDate.format(this.brazilFormatWithoutHours)} a ${newerDate.format(this.brazilFormatWithoutHours)}`;
    },
    parametersHandler(parameters) {
      const dateStartSplit = parameters.begin.split('-');
      let dateEndSplit = undefined;
      if (parameters.end) {
        dateEndSplit = parameters.end.split('-');
      }
      const dateEnd = dateEndSplit ? new Date(
        Number(dateEndSplit[0]),
        (Number(dateEndSplit[1]) - 1),
        Number(dateEndSplit[2])
      ) : new Date();
      const dateStart = new Date(
        Number(dateStartSplit[0]),
        (Number(dateStartSplit[1]) - 1),
        Number(dateStartSplit[2])
      );

      if (dateStart > dateEnd) {
        this.error = true;
        this.labels.errorMessage.push(` ${this.labels.endDate} deve ser maior que a ${this.labels.startDate}`);
        return true;
      }
      return false;
    }
  },
};

</script>

<style lang="scss" scoped>
  .new-retroactive__universe-or-usergroup {
    display: flex;
    justify-content: space-around;

    .v-radio {
      margin-bottom: 0 !important;
    }
  }

  .v-card {
    background: #333;
    border-radius: 6px;
    padding: 48px;
    text-align: center;
  }

  .card_title {
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  .theme--dark.v-card > .v-card__text {
    color: #ffff;
    padding: 24px 0;
    text-align: center;
  }

  .v-card__actions > .v-btn.v-btn {
    border: 1px solid;
    border-radius: 40px;
    box-sizing: border-box;
    margin: 0 16px;
  }

  .button-dialog-update {
    border-color: #00ccbe;
    background-color: #00ccbe;
    color: #242c5a;
  }

  .v-alert {
    margin-top: 20px;
  }
</style>
