<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <section class="selector-container">
      <v-form
        ref="newReprocessingForm"
        v-model="models.valid"
        :lazy-validation="false"
      >
        <SingleSelect
          v-model="selectedAccount"
          required
          :items="accounts"
          placeholder="Selecione uma Conta"
          text="name"
          sub-text="group"
        />
        <SingleSelect
          v-if="activeAccount"
          v-model="selectedUniverse"
          :items="universes"
          placeholder="Selecione uma Pesquisa"
          text="name"
          sub-text="channel"
        />
        <v-col
          cols="12"
          class="date-area"
        >
          <StOneDatePicker
            label="Data Início"
            @dateToSubmit="setInitDate"
            @input="setErrorFalse"
          />
          <StOneDatePicker
            label="Data Fim"
            @dateToSubmit="setEndDate"
            @input="setErrorFalse"
          />
          <span
            v-if="endDateInvalid"
            class="date-error"
          >
            Data Fim deve ser maior que a Data Início
          </span>
        </v-col>
        <v-text-field
          v-model="models.limit"
          placeholder="Limite de publicações"
          :rules="rules.isInteger"
          @input="setErrorFalse"
        />
        <v-col
          cols="12"
          class="checkbox-area"
        >
          <v-checkbox
            v-model="models.types"
            label="TAG"
            value="TAG"
          />
          <v-checkbox
            v-model="models.types"
            label="TEMA"
            value="THEME"
          />
          <v-checkbox
            v-model="models.types"
            label="GRUPO"
            value="GROUP"
          />
          <v-checkbox
            v-model="models.types"
            label="TERMO"
            value="TERM"
          />
          <v-checkbox
            v-model="models.types"
            label="SENTIMENTO"
            value="POLARITY"
          />
        </v-col>
        <v-btn
          :disabled="!models.valid || loading || endDateInvalid"
          color="light-blue"
          class="mr-4"
          @click="startNewReprocessing"
        >
          Iniciar reprocessamento
        </v-btn>
      </v-form>
      <StLoader :loading="loading" />
      <v-alert
        v-if="error"
        border="right"
        colored-border
        type="error"
        elevation="2"
      >
        {{ labels.errorMessage }}
      </v-alert>
      <v-alert
        v-if="success"
        border="right"
        colored-border
        type="success"
        elevation="2"
      >
        Reprocessamento iniciado com sucesso.<br><br>

        <div
          v-if="success"
          class="result-percentage"
        >
          <div>
            <span>Total: {{ total }}</span>
            <span>Progresso: {{ progress }}</span>
          </div>
          <v-progress-circular
            :rotate="-90"
            :size="90"
            :width="15"
            :value="percentage"
            color="teal"
          >
            {{ percentage }}
          </v-progress-circular>
        </div>
      </v-alert>
      <v-dialog
        v-if="hasAlreadyReprocessing"
        v-model="dialog"
        max-width="514px"
        persistent
      >
        <v-card>
          <span class="card_title"> Ops, temos um reprocessamento em execução! </span>

          <v-card-text>
            Recomenda-se apenas 1 execução por vez, pelo histórico de <b>queda de performance da plataforma percebida por nossos clientes</b>
            onde a causa era sobrecarga da base de dados decorrente de muitos reprocessamentos em execução.<br><br>
            Por favor, entre em contato com o time de N2 para uma análise detalhada do seu reprocessamento a fim de
            evitarmos qualquer imprevisto semelhante ao cenário aqui apresentado.
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              @click="cancel"
            >
              Vou analisar
            </v-btn>
            <v-btn
              class="button-dialog-update"
              text
              @click="agree"
            >
              Analisei e posso iniciar
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </section>
  </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex';
import SingleSelect from '../SingleSelect.vue';
import StLoader from '@stilingue/st-loader';
import PageHeader from '@/components/base/PageHeader';
import StOneDatePicker from '../StOneDatePicker.vue';
import ReprocessingService from '@/services/ReprocessingService';

export default {
  name: 'NewReprocessing',
  components: {
    SingleSelect, PageHeader, StOneDatePicker, StLoader
  },
  data: () => ({
    labels: {
      title: 'Novo Reprocessamento',
      description: 'Permite execução de reprocessamento para cliente que tem demanda maior que ' +
        'o atual limite de 100mil publicações da ferramenta disponível na plataforma.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Reprocessamento',
          to: '/reprocessing'
        }
      ],
      errorMessage: 'Erro inesperado. Solicite ajuda ao time de N2.',
      inProgress: 'Em execução'
    },
    models: {
      valid: false,
      types: ['TAG'],
      limit: null,
      initDate: undefined,
      endDate: undefined,
    },
    error: false,
    success: false,
    loading: false,
    endDateInvalid: false,
    rules: {
      isInteger: [v => {
        const pattern = /^([1-9]\d{0,7})$/u;
        if (v && v.length > 0) {
          return pattern.test(v) || 'Por favor, é necessário que entre com número limite de publicações.';
        }
        return true;
      }]
    },
    progress: 0,
    total: 0,
    percentage: '0%',
    dialog: false,
    hasAlreadyReprocessing: false
  }),
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    ...mapState('universe', ['universes', 'activeUniverse']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      },
      set(selectedUniverse) {
        this.selectUniverse(selectedUniverse);
      },
    },
    userId: {
      get() {
        return this.user.id;
      }
    }
  },
  async created() {
    await this.getAccounts({ userId: this.userId });
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount, this.selectedUniverse);
    }
  },
  methods: {
    setInitDate(date) {
      this.models.initDate = date;
      this.checkDates();
    },
    setEndDate(date) {
      this.models.endDate = date;
      this.checkDates();
    },
    setErrorFalse() {
      this.error = false;
    },
    ...mapActions('account', ['getAccounts', 'setAccount']),
    ...mapActions('universe', ['getAccountUniverses', 'setUniverse']),
    ...mapMutations('account', {
      mutate: 'MUTATE',
    }),
    selectAccount(selectedAccount, selectedUniverse) {
      this.setAccount({ selectedAccount });
      this.getAccountUniverses({ selectedAccount, fetchHistory: true }).then(() => {
        if (selectedUniverse) {
          this.selectedUniverse = selectedUniverse;
        }
        this.selectUniverse(this.selectedUniverse);
      });
    },
    selectUniverse(selectedUniverse) {
      this.setUniverse({ selectedUniverse });
    },
    getParameters() {
      const parameters = {
        // eslint-disable-next-line
        account_id: parseInt(this.selectedAccount.id),
        // eslint-disable-next-line
        universe_id: parseInt(this.selectedUniverse.id),
        types: this.models.types,
        modular_reprocessing: true,
        automation: false
      };
      parameters.limit = this.models.limit;
      parameters.init_date = this.models.initDate.replaceAll('-', '').concat('0000');
      if (this.models.endDate) {
        parameters.end_date = this.models.endDate.replaceAll('-', '').concat('2359');
      }
      parameters.responsible_user = this.user.email;
      return parameters;
    },
    checkDates() {
      const initDateSplit = this.models.initDate.split('-');
      let endDateSplit = undefined;
      if (this.models.endDate) {
        endDateSplit = this.models.endDate.split('-');
      }
      const endDateResult = endDateSplit ? new Date(
        Number(endDateSplit[0]),
        (Number(endDateSplit[1]) - 1),
        Number(endDateSplit[2])
      ) : new Date();
      const initDateResult = new Date(
        Number(initDateSplit[0]),
        (Number(initDateSplit[1]) - 1),
        Number(initDateSplit[2])
      );
      this.endDateInvalid = false;
      if (initDateResult > endDateResult) {
        this.endDateInvalid = true;
      }
    },
    async startNewReprocessing() {
      const response = await ReprocessingService.getAllReprocessing();
      this.hasAlreadyReprocessing = response.find(reprocess => {
        return reprocess.status === this.labels.inProgress && reprocess.responsible_user;
      });

      if (this.hasAlreadyReprocessing) {
        this.dialog = true;
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        }).then(() => {
          return this.makeNewReprocessing();
        }).catch(() => {
          this.loading = false;
        });
      }
      this.makeNewReprocessing();
    },
    makeNewReprocessing() {
      this.loading = true;
      const parameters = this.getParameters();
      ReprocessingService.createReprocessing(parameters).then(resp => {
        this.loading = false;
        this.success = true;
        this.progress = resp.value.progress;
        this.total = resp.value.total;
        this.percentage = `${resp.value.percentage}%`;
      }).catch(e => {
        this.loading = false;
        this.error = true;
        this.labels.errorMessage = e.toString();
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.reject(true);
      this.dialog = false;
      const redirectTo = '/reprocessing/list';
      this.$router.replace({
        path: redirectTo,
      }).catch(/*istanbul ignore next*/() => {
        //do nothing because it's just page redirection
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.selector-container {
  width: 700px;

  form {
    margin-bottom: 35px;
  }

  .checkbox-area {
    padding: 0;

    > div {
      display: inline-block;
      margin-right: 50px;
    }
  }

  .date-area {
    padding: 0;

    > div {
      width: 45%;
      display: inline-block;
    }

    > div:nth-child(2) {
      margin-left: 70px;
    }

    span.date-error {
      font-size: 13px;
      text-align: center;
      display: block;
      caret-color: #fff !important;
      background-color: rgba(201, 76, 76, 0.3);
      border-radius: 5px;
      padding: 5px;
    }
  }

  .result-percentage {
    div:first-child {
      float: left;
      padding-top: 15px;
      padding-right: 15px;

      span {
        display: block;
      }
    }
  }
}

.v-card {
  background: #333;
  border-radius: 6px;
  padding: 48px;
  text-align: center;
}

.card_title {
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.theme--dark.v-card > .v-card__text {
  color: #ffff;
  padding: 24px 0;
  text-align: justify;

  > b {
    color: #ff5400;
  }
}

.v-card__actions > .v-btn.v-btn {
  border: 1px solid;
  border-radius: 40px;
  box-sizing: border-box;
  margin: 0 16px;
}

.button-dialog-update {
  border-color: #00ccbe;
  background-color: #00ccbe;
  color: #242c5a;
}
</style>
