var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "v-form",
        {
          ref: "updatePodForm",
          attrs: { "lazy-validation": false },
          model: {
            value: _vm.formData.valid,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "valid", $$v)
            },
            expression: "formData.valid",
          },
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: { right: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-text-field",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                placeholder: _vm.labels.placeusergroup,
                                label: _vm.labels.usergroup,
                                value: "",
                                rules: _vm.rules.required,
                                dark: "",
                              },
                              model: {
                                value: _vm.formData.usergroup,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "usergroup", $$v)
                                },
                                expression: "formData.usergroup",
                              },
                            },
                            "v-text-field",
                            attrs,
                            false
                          ),
                          on
                        )
                      ),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v(_vm._s(_vm.layers.ttusergroup))])]
          ),
          _c("v-select", {
            attrs: {
              items: _vm.items,
              label: _vm.labels.pod,
              rules: _vm.rules.required,
            },
            model: {
              value: _vm.formData.selectedPod,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "selectedPod", $$v)
              },
              expression: "formData.selectedPod",
            },
          }),
          _c(
            "v-btn",
            {
              attrs: { disabled: !_vm.formData.valid || _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.updatePod()
                },
              },
            },
            [_vm._v("\n      Alterar\n    ")]
          ),
        ],
        1
      ),
      _c("StLoader", { attrs: { loading: _vm.loading } }),
      _vm.success
        ? _c(
            "v-alert",
            {
              attrs: {
                "colored-border": "",
                type: "success",
                outlined: "",
                elevation: "2",
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.formData.successMessage) + "\n  ")]
          )
        : _vm._e(),
      _vm.warning
        ? _c(
            "v-alert",
            {
              attrs: {
                "colored-border": "",
                type: "warning",
                outlined: "",
                elevation: "2",
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.layers.warningmessage) + "\n  ")]
          )
        : _vm._e(),
      _vm.error
        ? _c(
            "v-alert",
            {
              attrs: {
                "colored-border": "",
                type: "error",
                outlined: "",
                elevation: "2",
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.layers.errormessage) + "\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }