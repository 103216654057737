<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model.lazy="dateInDMY"
          :label="label"
          :rules="isDate"
          prepend-icon="mdi-calendar"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="dateInISO"
        locale="pt-br"
        :max="maxDate"
        :min="minDate"
        no-title
        @input="menu = false"
        @change="sendDateToInput(dateInISO)"
      />
    </v-menu>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'StOneDatePicker',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: 'Escolha uma data'
    },
    minDate: {
      type: String,
      default: '2016-09-16'
    },
    maxDate: {
      type: [String, Function],
      default: moment().format('YYYY-MM-DD')
    },
  },
  data: vm => ({
    dateInISO: moment().format('YYYY-MM-DD'),
    dateInDMY: vm.formatDateToDMY(moment().format('YYYY-MM-DD')),
    minDateInDMY: vm.formatDateToDMY(vm.minDate),
    menu: false,
    pattern: /^[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]$/u,
  }),
  computed: {
    isDate() {
      const rule = [
        v => {
          if (!this.pattern.test(v)) {
            return 'Data inválida';
          }
          this.sendDateToCalendar(v);
          const date = this.formatDateToISO(v);
          if (date < this.minDate) {
            return `Data anterior a ${this.minDateInDMY}`;
          }
          if (date > this.maxDate) {
            return 'Data está no futuro';
          }
          this.sendDateToSubmit(date);
          return true;
        }
      ];
      return rule;
    }
  },

  methods: {
    sendDateToSubmit(date) {
      this.$emit('dateToSubmit', date);
    },
    sendDateToInput(date) {
      this.dateInDMY = this.formatDateToDMY(date);
    },
    sendDateToCalendar(date) {
      this.dateInISO = this.formatDateToISO(date);
    },
    formatDateToISO(date) {
      if (!date) return null;
      const [day, month, year] = date.split('/');
      return `${year.padStart(4, '20')}-${month.padStart(2, '0')}-${day.padStart(2,
        '0')}`;
    },
    formatDateToDMY(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year.padStart(4,
        '20')}`;
    }
  }
};
</script>
