<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <v-form
      ref="updatePodForm"
      v-model="formData.valid"
      :lazy-validation="false"
    >
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formData.usergroup"
            :placeholder="labels.placeusergroup"
            :label="labels.usergroup"
            value=""
            :rules="rules.required"
            dark
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <span>{{ layers.ttusergroup }}</span>
      </v-tooltip>
      <v-select
        v-model="formData.selectedPod"
        :items="items"
        :label="labels.pod"
        :rules="rules.required"
      />
      <v-btn
        :disabled="!formData.valid || loading"
        @click="updatePod()"
      >
        Alterar
      </v-btn>
    </v-form>
    <StLoader :loading="loading" />
    <v-alert
      v-if="success"
      colored-border
      type="success"
      outlined
      elevation="2"
    >
      {{ formData.successMessage }}
    </v-alert>
    <v-alert
      v-if="warning"
      colored-border
      type="warning"
      outlined
      elevation="2"
    >
      {{ layers.warningmessage }}
    </v-alert>
    <v-alert
      v-if="error"
      colored-border
      type="error"
      outlined
      elevation="2"
    >
      {{ layers.errormessage }}
    </v-alert>
  </div>
</template>

<script>
import PageHeader from '@/components/base/PageHeader';
import UserGroupService from '@/services/DataStoreService/UserGroupService';
import StLoader from '@stilingue/st-loader';
export default {
  name: 'Update',
  components: {
    PageHeader, StLoader,
  },
  data: () => ({
    labels: {
      title: 'Alteração de POD',
      description: 'Permite alterar o POD de um usergroup.',
      placeusergroup: 'Entre com o usergroup',
      pod: 'POD',
      usergroup: 'Usergroup',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Usergroups',
          to: '/usergroup'
        },
        {
          text: 'Alteração de Pod',
          to: '/usergroup/update'
        },
      ],
    },
    layers: {
      ttusergroup: 'O usergroup deve ser preenchido exatamente como esta cadstrado, letras maiúsculas e minúsculas, espaços, underlines, etc.',
      warningmessage: 'Alteração não realizada! Por favor, revise o usergroup.',
      errormessage: 'Erro inesperado! Por favor, entre em contato com o N3.',
    },
    formData: {
      usergroups: undefined,
      selectedPod: undefined,
      successMessage: undefined,
      valid: false,
    },
    rules: {
      required: [v => {
        return Boolean(v) || 'Não pode ser vazio';
      }],
    },
    items: ['SALESFORCE SYNC', 'PARTNERS', 'MIDDLE MARKET', 'INTERNA', 'GOVERNO', 'ENTERPRISE'],
    success: false,
    error: false,
    warning: false,
    loading: false,
  }),
  methods: {
    async updatePod() {
      this.loading = true;
      this.error = false;
      this.warning = false;
      this.success = false;
      await UserGroupService.setPod(this.formData.usergroup, this.formData.selectedPod).then(resp => {
        this.success = true;
        this.formData.successMessage = resp.result;
      }).catch (e => {
        console.error(e);
        const error = e.toString().slice(-3);
        switch (error) {
        case '500':
          this.warning = true;
          break;
        default:
          this.error = true;
          break;
        }
      }).finally(() => {
        this.loading = false;
      });
    }
  },
};
</script>
<style lang="scss" scoped>
</style>
