<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <section class="selector-container">
      <v-form
        ref="actionsHistoryForm"
        v-model="models.valid"
        :lazy-validation="false"
      >
        <v-row>
          <v-col cols="4">
            <UniverseSearch />
          </v-col>
          <v-col
            cols="5"
            class="pt-8 pl-6"
          >
            <SingleSelect
              v-model="models.actionSelected"
              required
              :items="actions"
              placeholder="Selecione uma ação"
              text="name"
              sub-text=" "
            />
            <v-btn
              class="mr-4 mt-8"
              @click="$router.go(-1)"
            >
              Voltar
            </v-btn>
            <v-btn
              :disabled="!models.valid || loading"
              color="primary"
              class="mr-4 mt-8"
              @click="getActionsHistory"
            >
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </section>
    <section class="table-container">
      <v-alert
        v-if="error"
        border="right"
        colored-border
        type="error"
        elevation="2"
      >
        {{ labels.errorMessage }}
      </v-alert>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="table.search"
            class="mx-4"
            append-icon="mdi-refresh"
            label="Filtrar histórico por"
            single-line
            hide-details
          />
        </v-card-title>
        <StLoader :loading="loading" />
        <v-data-table
          v-if="table.items"
          :headers="table.headers"
          :items="table.items"
          :search="table.search"
          :custom-sort="customSort"
        >
          <template v-slot:item.user="{ item }">
            <v-btn
              :loading="loadingUser&&item.user===userFind"
              :disabled="loadingUser&&item.user===userFind"
              color="blue-grey"
              left
              @click="showUser(item.user)"
            >
              {{ item.user }}
              <v-icon
                small
                right
                dark
              >
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog
        v-model="dialog"
        max-width="514px"
        persistent
      >
        <v-card
          v-if="userData"
          class="owners"
          tile
        >
          <v-system-bar
            height="14"
          />
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-img :src="ownl" />
              </v-list-item-avatar>
              <v-list-item-title class="text-h10">
                {{ userData.name }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-icon
                    v-if="userData.lock"
                    small
                    left
                  >
                    mdi-lock
                  </v-icon>
                  <v-icon
                    v-else
                    small
                    left
                  >
                    mdi-lock-open
                  </v-icon>
                  {{ userData.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Permissão</v-list-item-title>
                <v-list-item-subtitle
                  v-if="userData.role"
                >
                  {{ userData.role }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  v-else
                >
                  --
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Grupo</v-list-item-title>
                <v-list-item-subtitle>{{ userData.user_group }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Criado em</v-list-item-title>
                <v-list-item-subtitle>{{ userData.created_at }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="cancel"
            >
              Fechar
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </section>
  </div>
</template>
<script>
import moment from 'moment';
import StLoader from '@stilingue/st-loader';
import SingleSelect from '../SingleSelect.vue';
import ownl from '@/assets/img/client-default.jpg';
import UniverseSearch from '../universe/Search.vue';
import PageHeader from '@/components/base/PageHeader';
import { mapState } from 'vuex';
import ActionHistoryService from '@/services/DataStoreService/ActionHistoryService';
import UserService from '@/services/DataStoreService/UserService';

export default {
  name: 'AccountActionsHistory',
  components: {
    StLoader,
    PageHeader,
    SingleSelect,
    UniverseSearch
  },
  data() {
    return {
      labels: {
        title: 'Ações',
        description: 'Permite visualização do histórico de ações realizadas na plataforma.',
        breadcrumbs: [
          {
            text: 'Home',
            href: '/'
          },
          {
            text: 'Histórico',
            to: '/history'
          },
          {
            text: 'Ações',
            to: '/history/actions'
          }
        ]
      },
      models: {
        valid: false,
        action: null,
      },
      table: {
        headers: [
          {
            text: 'Descrição',
            value: 'details',
            align: 'center'
          }, {
            text: 'Pesquisa',
            value: 'universe',
            align: 'center',
            width: 200
          }, {
            text: 'Responsável',
            value: 'user',
            align: 'center',
            width: 200
          }, {
            text: 'Data',
            value: 'created_at_formatted',
            align: 'center',
            width: 200
          },
        ],
        search: '',
        'must-sort': true,
        items: []
      },
      error: false,
      loading: false,
      dialog: false,
      userData: null,
      userFind: null,
      loadingUser: false,
      actions: [
        { name: 'alerta_api_instagram_01' },
        { name: 'ADMIN Remover conta' },
        { name: 'ADMIN Compartilhar conta' },
        { name: 'Agendar Post' },
        { name: 'Ativar conta' },
        { name: 'Ativar página proprietária' },
        { name: 'Ativar universo' },
        { name: 'Bloqueando login' },
        { name: 'BuscaExpress' },
        { name: 'Backend - Criar grupo no universo' },
        { name: 'Backend - Criar tema no universo' },
        { name: 'Busca Instagram API' },
        { name: 'Criação de novo universo' },
        { name: 'Criar alerta' },
        { name: 'Cadastrar conta' },
        { name: 'Cadastrar conta cliente' },
        { name: 'Cadastrar usuário' },
        { name: 'Central de influciadores removida' },
        { name: 'Convidando Worker' },
        { name: 'Central de influenciadores criada' },
        { name: 'Deletar Rascunho' },
        { name: 'Deletar Post Agendado' },
        { name: 'Deletando conta' },
        { name: 'Desbloqueando login' },
        { name: 'Editar Rascunho' },
        { name: 'Editar Publicação' },
        { name: 'Editar universo' },
        { name: 'Editar universo - Configurações de sac' },
        { name: 'Editar universo - Configurações de Instagram Business' },
        { name: 'Forcando pesquisa free/premium' },
        { name: 'Grupo criado:' },
        { name: 'Iniciar/Parar universo' },
        { name: 'Iniciar/Pausar alerta' },
        { name: 'Login' },
        { name: 'Mudando permissão de segurança da conta' },
        { name: 'Registrar blog' },
        { name: 'Registrar grupo do facebook' },
        { name: 'Remover alerta' },
        { name: 'Reprocessar universo' },
        { name: 'Salvar Rascunho' },
        { name: 'Trocando dono da pesquisa' },
        { name: 'Trocando Prioridade Cliente' },
        { name: 'Tema criado' },
        { name: 'Usando a key privada do Stilingue' },
        { name: 'Universo removido' }
      ]
    };
  },
  computed: {
    ...mapState('account', ['activeAccount']),
    ...mapState('universe', ['activeUniverse']),
  },
  async created() {
    this.models.actionSelected = this.actions[7];
    this.ownl = ownl;
  },
  methods: {
    getParameters() {
      const parameters = {
        // eslint-disable-next-line radix
        accountId: parseInt(this.activeAccount.id),
        action: this.models.actionSelected.name,
      };
      if (this.activeUniverse) {
        // eslint-disable-next-line radix
        parameters.universeId = parseInt(this.activeUniverse.id);
      }
      return parameters;
    },
    async getActionsHistory() {
      this.loading = true;
      this.error = false;
      this.table.items = [];

      await ActionHistoryService.getActionsHistory(this.getParameters()).then(resp => {
        this.loading = false;
        if (resp.result === 'Nenhum histórico encontrado.') {
          resp.result = [];
        }
        this.table.items = resp.result.map(item => {
          item.created_at = moment(item.created_at).add(-3, 'hour').valueOf();
          item.created_at_formatted = moment(item.created_at).format('DD/MM/YYYY HH:mm:ss');
          const newItem = { ...item };
          return newItem;
        });
      }).catch(e => {
        this.error = true;
        this.loading = false;
        if (e.response.status === 422) {
          this.labels.errorMessage = 'Falha na autenticação!';
        } else {
          this.labels.errorMessage = 'Erro inesperado ao tentar realizar operação.';
        }
      });
    },
    async showUser(id) {
      this.userData = null;
      this.dialog = true;
      this.loadingUser = true;
      this.userFind = id;

      await UserService.getUserByUserId(id).then(resp => {
        resp.result.created_at = moment(resp.result.created_at).add(-3, 'hour').format('DD/MM/YYYY HH:mm:ss');
        this.userData = resp.result;
        this.loadingUser = false;
      }).catch(e => {
        this.error = true;
        this.dialog = false;
        this.loadingUser = false;
        if (e.response.status === 422) {
          this.labels.errorMessage = 'Falha na autenticação!';
        } else {
          this.labels.errorMessage = 'Erro inesperado ao tentar realizar operação.';
        }
      });
    },
    customSort(items, index, isDesc) {
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line complexity
      items.sort((a, b) => {
        if (index[0] === 'created_at_formatted') {
          if (!isDesc[0]) {
            return a.created_at - b.created_at;
          }
          return b.created_at - a.created_at;
        } else if (!isDesc[0]) {
          return a[index] < b[index] ? -1 : 1;
        }
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    },
    cancel() {
      this.dialog = false;
    }
  }
};
</script>

<style scoped>

.content-body {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}

.v-form {
  margin-bottom: 20px;
}

.table-container {
  width: 100%;
  min-height: 600px;
}
</style>
