<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              :label="labels.inputs.executionDate"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="date"
            @input="menu = false, getServiceHistory()"
          />
        </v-menu>
      </v-col>
      <template>
        <v-data-table
          :headers="headers"
          :items="editions"
          :sort-by="editions.execution_date"
          :sort-desc="[false, true]"
          class="elevation-1"
          :loading="loading"
          :loading-text="labels.table.loading"
          :no-data-text="labels.table.noData"
        >
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.email }}</td>
              <td>{{ row.item.filterId }}</td>
              <td>{{ row.item.accountId }}</td>
              <td>{{ row.item.universeId }}</td>
              <td m="12px">
                {{ row.item.execution_date }}
              </td>
              <td m="20px">
                <v-btn
                  id="no-background-hover"
                  :ripple="false"
                  icon
                  color="blue"
                  @click="handleTaskClick(row.item.task)"
                >
                  {{ row.item.task }}
                </v-btn>
              </td>
              <td>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  @click="handleSmartcareClick(row.item.universeId, row.item.accountId)"
                >
                  <v-icon dark>
                    mdi-vector-link
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
    </v-row>
  </div>
</template>

<script>
import PageHeader from '@/components/base/PageHeader';
import ITService from '@/services/ITService';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';

export default {
  name: 'MultipleEditionHistory',
  components: {
    PageHeader
  },
  data: () => ({
    labels: {
      title: 'Histórico de Alteração em massa',
      description: 'Exibe as alterações de status em massa realizadas por usuarios da Stilingue.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Historico de alteração de status',
          to: '/batch'
        }
      ],
      inputs: {
        executionDate: 'Escolha o periodo retroativo que deseja consultar:'
      },
      table: {
        loading: 'Carregando, aguarde.',
        noData: 'Não há edições durante esse periodo de tempo.'
      },
    },
    headers: [
      { text: 'Responsavel' },
      { text: 'Filtro' },
      { text: 'Id da conta' },
      { text: 'Id da pesquisa' },
      { text: 'Data de alteração' },
      {
        text: 'Tarefa',
        sortable: false,
      },
      { text: 'Pesquisa' },

    ],
    editions: [],
    loading: true,
    menu: false,
    date: (new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000))).toISOString().substr(0, 10),
  }),

  mounted() {
    this.getServiceHistory();
  },

  methods: {
    async getServiceHistory() {
      this.loading = true;
      const body = {
        path: '/edit-call-status',
        limit: 1000,
        execution_date: this.date.replaceAll('-', ''),
        order_by: 'DESC',
      };
      try {
        await ITService.serviceHistory(body).then((response) => {
          this.editions = response;
        });
      } catch (err) {
        this.editions = [];
        console.log(err);
      } finally {
        this.loading = false;
        this.setExecutionDate();
      }
    },

    setExecutionDate() {
      this.editions.map((edition) => (
        edition.execution_date = new Date(edition.execution_date).toLocaleDateString()
      ));
    },

    handleTaskClick(task) {
      const splitedTask = task.split('-');
      const url = splitedTask[0] === 'ZENDESK'
        ? `https://stilinguesupport.zendesk.com/agent/tickets/${splitedTask[1]}`
        : `https://stilingue-jira.atlassian.net/browse/${task}`;
      this.openUrlOnTab(url);
    },

    handleSmartcareClick(universeId, accountId) {
      const activateWarromAccount = `ativarcontapesquisa/${accountId}/${universeId}`;
      const url = isProdVersion()
        ? `https://warroom.stilingue.com.br/${activateWarromAccount}`
        : `https://smartcare.warroom-test.com.br/${activateWarromAccount}`;
      this.openUrlOnTab(url);
    },

    openUrlOnTab(url) {
      window.open(url, '_blank');
    }
  },

};
</script>
