<template>
  <div class="auth-errors">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <section class="selector-container">
      <SingleSelect
        v-model="selectedAccount"
        :items="accounts"
        placeholder="Selecione uma conta"
        text="name"
        sub-text="group"
      />

      <SingleSelect
        v-if="activeAccount"
        v-model="selectedPage"
        :items="pages"
        :placeholder="pagePlaceholder"
        text="name"
        sub-text="channel"
      />
    </section>
    <section class="auth-errors__table-container">
      <StLoader :loading="loadingHistory" />
      <v-data-table
        v-if="historyIsLoaded&&!loadingHistory"
        :headers="headers"
        :items="history"
        :multi-sort="multiSort"
        :custom-sort="customSort"
        dark
      >
        <template v-slot:item.occurrences="{ item }">
          <StMenu
            :items="item.occurrences"
          />
        </template>
      </v-data-table>
      <section
        v-if="historyError"
        class="failure-notification"
      >
        <span @click="getPageAuthError"><v-icon>mdi-reload</v-icon> Recarregar</span>
      </section>
      <section
        v-if="accountWithoutPage"
        class="failure-notification failure-notification--no-pages"
      >
        <span><v-icon>mdi-alert-outline</v-icon> Nenhuma página conectada encontrada para a conta.</span>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import StLoader from '@stilingue/st-loader';
import SingleSelect from '../components/SingleSelect';
import PageHeader from '@/components/base/PageHeader';
import StMenu from '@/components/StMenu.vue';

export default {
  name: 'PageHistory',
  components: {
    SingleSelect,
    StLoader,
    PageHeader,
    StMenu
  },
  data() {
    return {
      labels: {
        title: 'Falha de Autenticação',
        description: 'Permite visualização do histórico de falhas de autenticação das páginas ' +
          'proprietárias ocorridos nos últimos 30 dias, a fim de auxiliar na análise de possível falha de coleta.',
        breadcrumbs: [
          {
            text: 'Home',
            href: '/'
          },
          {
            text: 'Histórico',
            to: '/history'
          },
          {
            text: 'Falha de Autenticação',
            to: '/history/page-auth-error'
          }
        ]
      },
      headers: [
        {
          text: 'Mensagem',
          value: 'message',
          sortable: false
        }, {
          text: 'Data',
          value: 'date_formatted',
          align: 'center'
        }, {
          text: 'Total',
          value: 'total',
          sortable: false,
          align: 'center'
        },
        {
          text: 'Últimas 100 ocorrências',
          value: 'occurrences',
          sortable: false,
          align: 'center',
          width: '100px'
        }
      ],
      search: '',
      menu: false,
      filterDate: '',
      shallFilterByMonth: false,
      multiSort: true,
      pagePlaceholder: 'Selecione uma Página',
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    ...mapGetters('authErrors', ['history']),
    ...mapState('authErrors', [
      'historyIsLoaded',
      'loadingHistory',
      'requestStatus',
      'activePage',
      'pages',
    ]),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    selectedPage: {
      get() {
        return this.activePage;
      },
      set(selectedPage) {
        this.selectPage(selectedPage);
      },
    },
    historyError: {
      get() {
        return !this.historyIsLoaded && !this.loadingHistory && !this.accountWithoutPage;
      },
    },
    accountWithoutPage: {
      get() {
        return this.pages.length === 0 && this.requestStatus === 'empty';
      }
    },
    userId: {
      get() {
        return this.user.id;
      }
    },
  },
  async created() {
    await this.getAccounts();
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount);
    }
  },
  methods: {
    ...mapActions('account', ['getAccounts', 'setAccount']),
    ...mapActions('authErrors', [
      'getPageAuthError',
      'getAccountPages',
      'filterByMonth',
      'setFilterDate',
      'setPage',
    ]),
    selectAccount(selectedAccount) {
      this.setAccount({ selectedAccount });
      this.getAccountPages({ selectedAccount });
    },
    selectPage(selectedPage) {
      this.setPage({ selectedPage });
      this.getPageAuthError({ selectedAccount: this.selectedAccount });
    },
    customSort(items, index, isDesc) {
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line complexity
      items.sort((a, b) => {
        if (index[0] === 'date_formatted') {
          if (!isDesc[0]) {
            return a.date - b.date;
          }
          return b.date - a.date;
        } else if (!isDesc[0]) {
          return a[index] < b[index] ? -1 : 1;
        }
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    }
  },
};
</script>

<style scoped lang='scss'>
h4 {
  margin: 0 0 40px;
}

.auth-errors {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}

.selector-container {
  width: 700px;
  display: flex;
  justify-content: left;
  margin-top: 20px;

  .single-select {
    width: 50%;
    margin-right: 20px;
  }
}

.auth-errors__table-container {
  max-height: 600px;
}

.failure-notification {
  display: flex;
  height: 300px;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}

.failure-notification span {
  width: 130px;
  text-align: center;
  cursor: pointer;
}

.failure-notification--no-pages span {
  width: 260px;
}

.auth-errors__date-modal {
  .v-date-picker-title__date {
    font-size: 22px;
  }

  .accent--text {
    text-align: center;
  }
}

.auth-errors__date-filter {
  margin-right: 30px;
}

.v-card__title {
  width: 545px !important;
  flex-wrap: nowrap;
}
</style>
