var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-retroactive" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "div",
        { staticClass: "new-retroactive__form-and-result" },
        [
          _c("StLoader", { attrs: { loading: _vm.loading } }),
          _c(
            "v-form",
            {
              ref: "newRetroactiveForm",
              staticClass: "new-retroactive__form",
              attrs: { "lazy-validation": false },
              model: {
                value: _vm.models.valid,
                callback: function ($$v) {
                  _vm.$set(_vm.models, "valid", $$v)
                },
                expression: "models.valid",
              },
            },
            [
              _c("v-textarea", {
                attrs: { hint: _vm.labels.hint },
                model: {
                  value: _vm.retroativeList,
                  callback: function ($$v) {
                    _vm.retroativeList = $$v
                  },
                  expression: "retroativeList",
                },
              }),
              _c("v-text-field", {
                attrs: {
                  required: "",
                  name: Math.random(),
                  rules: _vm.rules.isLink,
                  label: _vm.labels.link,
                },
                on: { input: _vm.setErrorFalse },
                model: {
                  value: _vm.models.link,
                  callback: function ($$v) {
                    _vm.$set(_vm.models, "link", $$v)
                  },
                  expression: "models.link",
                },
              }),
              _c("StOneDatePicker", {
                attrs: { label: _vm.labels.startDate },
                on: {
                  dateToSubmit: _vm.setStartDate,
                  input: _vm.setErrorFalse,
                },
              }),
              _c("StOneDatePicker", {
                attrs: { label: _vm.labels.endDate },
                on: { dateToSubmit: _vm.setEndDate, input: _vm.setErrorFalse },
              }),
              _c("v-select", {
                attrs: {
                  items: _vm.availableChannels,
                  multiple: "",
                  chips: "",
                  label: _vm.labels.channels,
                },
                on: { input: _vm.setErrorFalse },
                model: {
                  value: _vm.models.channels,
                  callback: function ($$v) {
                    _vm.$set(_vm.models, "channels", $$v)
                  },
                  expression: "models.channels",
                },
              }),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: {
                    disabled: !_vm.models.valid || _vm.loading,
                    color: "light-blue",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.startNewRetroactiveList.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.labels.startRetroactive) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm.error
            ? _vm._l(_vm.labels.errorMessage, function (errorMessage) {
                return _c(
                  "v-alert",
                  {
                    key: errorMessage,
                    attrs: {
                      border: "right",
                      "colored-border": "",
                      type: "error",
                      elevation: "2",
                    },
                  },
                  [_vm._v("\n        " + _vm._s(errorMessage) + "\n      ")]
                )
              })
            : _vm._e(),
          _vm.success
            ? _vm._l(_vm.labels.successMessage, function (successMessage) {
                return _c(
                  "v-alert",
                  {
                    key: successMessage,
                    attrs: {
                      border: "right",
                      "colored-border": "",
                      type: "success",
                      elevation: "2",
                    },
                  },
                  [_vm._v("\n        " + _vm._s(successMessage) + "\n      ")]
                )
              })
            : _vm._e(),
          _vm.hasAlreadyRetroactive
            ? _c(
                "v-dialog",
                {
                  attrs: { "max-width": "514px", persistent: "" },
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("span", { staticClass: "card_title" }, [
                        _vm._v(" Atualização de coleta retroativa "),
                      ]),
                      _c("v-card-text", [
                        _vm._v(
                          "\n          Identificamos uma solicitação de retroativo para a mesma pesquisa no período de " +
                            _vm._s(
                              _vm.hasAlreadyRetroactive.begin.format(
                                _vm.brazilFormatWithoutHours
                              )
                            ) +
                            "\n          a " +
                            _vm._s(
                              _vm.hasAlreadyRetroactive.end.format(
                                _vm.brazilFormatWithoutHours
                              )
                            ) +
                            "."
                        ),
                        _c("br"),
                        _vm._v(
                          "\n\n          Deseja atualizar o período para " +
                            _vm._s(
                              _vm.compareDates(_vm.hasAlreadyRetroactive)
                            ) +
                            "?\n        "
                        ),
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            { attrs: { text: "" }, on: { click: _vm.cancel } },
                            [_vm._v("\n            Cancelar\n          ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "button-dialog-update",
                              attrs: { text: "" },
                              on: { click: _vm.agree },
                            },
                            [_vm._v("\n            Atualizar\n          ")]
                          ),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }