var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "section",
        { staticClass: "selector-container" },
        [
          _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "5", sm: "5" } },
                    [
                      _c("SingleSelect", {
                        attrs: {
                          items: _vm.accounts,
                          placeholder: "Selecione uma conta",
                          text: "name",
                          "sub-text": "group",
                        },
                        model: {
                          value: _vm.selectedAccount,
                          callback: function ($$v) {
                            _vm.selectedAccount = $$v
                          },
                          expression: "selectedAccount",
                        },
                      }),
                      _vm.activeAccount
                        ? _c("SingleSelect", {
                            attrs: {
                              items: _vm.pages,
                              placeholder: "Selecione uma Página",
                              text: "name",
                              "sub-text": "channel",
                            },
                            model: {
                              value: _vm.selectedPage,
                              callback: function ($$v) {
                                _vm.selectedPage = $$v
                              },
                              expression: "selectedPage",
                            },
                          })
                        : _vm._e(),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.rules,
                          placeholder: "Link da publicação",
                        },
                        model: {
                          value: _vm.postLink,
                          callback: function ($$v) {
                            _vm.postLink = $$v
                          },
                          expression: "postLink",
                        },
                      }),
                      !_vm.models.adaccounts
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn-first-step",
                              attrs: { depressed: "", color: "primary" },
                              on: { click: _vm.getAllAdAccounts },
                            },
                            [
                              _vm._v(
                                "\n            Verificar Coleta\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "7", sm: "7" } },
                    [
                      _vm.models.adaccounts
                        ? _c(
                            "v-card",
                            { attrs: { flat: "", tile: "" } },
                            [
                              _c(
                                "v-window",
                                {
                                  staticClass: "elevation-1",
                                  model: {
                                    value: _vm.window,
                                    callback: function ($$v) {
                                      _vm.window = $$v
                                    },
                                    expression: "window",
                                  },
                                },
                                _vm._l(
                                  _vm.models.adaccounts,
                                  function (item, n) {
                                    return _c(
                                      "v-window-item",
                                      { key: item },
                                      [
                                        _c(
                                          "v-card",
                                          { attrs: { flat: "" } },
                                          [
                                            _c(
                                              "v-card-text",
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticClass: "mb-4",
                                                    attrs: { align: "center" },
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      { attrs: { icon: "" } },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-account"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "strong",
                                                      {
                                                        staticClass: "text-h6",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(n + 1) +
                                                            "ª Ad Account (" +
                                                            _vm._s(item.name) +
                                                            ")"
                                                        ),
                                                      ]
                                                    ),
                                                    _c("v-spacer"),
                                                  ],
                                                  1
                                                ),
                                                _c("p", [
                                                  _vm._v(
                                                    "\n                      Verifica se a publicação Dark Post está entre os 120 últimos anúncios da "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(n + 1) +
                                                        "ª Ad Account"
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    " de acesso do usuário\n                      de conexão da página "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.activePage.name
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    ".\n                    "
                                                  ),
                                                ]),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "btn-window",
                                                    attrs: {
                                                      depressed: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.checkDarkPost(
                                                          item.account_id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      Verificar Coleta\n                    "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                              _c(
                                "v-item-group",
                                {
                                  staticClass: "text-center",
                                  attrs: { mandatory: "" },
                                  model: {
                                    value: _vm.window,
                                    callback: function ($$v) {
                                      _vm.window = $$v
                                    },
                                    expression: "window",
                                  },
                                },
                                _vm._l(_vm.models.adaccounts, function (n) {
                                  return _c("v-item", {
                                    key: n,
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ active, toggle }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    "input-value": active,
                                                    icon: "",
                                                  },
                                                  on: { click: toggle },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-record"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("StLoader", {
                attrs: { loading: _vm.loadingDarPostChecking },
              }),
              _vm.shouldDarkPostBeCollected !== null &&
              _vm.shouldDarkPostBeCollected
                ? _c(
                    "v-alert",
                    {
                      attrs: {
                        border: "right",
                        "colored-border": "",
                        type: "success",
                        elevation: "2",
                      },
                    },
                    [
                      _c("b", [_vm._v("Dark Post encontrado")]),
                      _vm._v(" dentre os últimos 120 anúncios da Ad Account "),
                      _c("b", [
                        _vm._v(_vm._s(_vm.backendResponse.adAccountId)),
                      ]),
                      _vm._v("."),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n        Por favor, execute um filtro por publicações deletadas no painel do cliente e tente localizar a publicação. "
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        "\n        Se publicação não localizada no filtro, crie um incidente de coleta com os seguintes dados:\n        "
                      ),
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "AD_ACCOUNT_ID: " +
                              _vm._s(_vm.backendResponse.adAccountId)
                          ),
                        ]),
                        _c("li", [
                          _vm._v(
                            "CREATIVE_ID: " + _vm._s(_vm.backendResponse.id)
                          ),
                        ]),
                        _c("li", [
                          _vm._v("AD_ID: " + _vm._s(_vm.backendResponse.ad_id)),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.shouldDarkPostBeCollected !== null &&
              !_vm.shouldDarkPostBeCollected
                ? _c(
                    "v-alert",
                    {
                      attrs: {
                        border: "right",
                        "colored-border": "",
                        type: "error",
                        elevation: "2",
                      },
                    },
                    [
                      !_vm.adAnalized
                        ? _c("span", [
                            _c("b", [_vm._v("Dark Post não encontrado")]),
                            _vm._v(
                              " dentre os últimos 120 anúncios das Ad Accounts que o usuário de conexão da página tem acesso."
                            ),
                            _c("br"),
                          ])
                        : _vm._e(),
                      _vm.adAnalized
                        ? _c("span", [
                            _c("b", [_vm._v("Dark Post não encontrado")]),
                            _vm._v(
                              " dentre os últimos 120 anúncios da Ad Account verificada."
                            ),
                            _c("br"),
                          ])
                        : _vm._e(),
                      _c("span", [
                        _vm._v(
                          "Para maior esclarecimento do cenário, por favor, execute os seguintes passos:"
                        ),
                      ]),
                      _c("br"),
                      _c("br"),
                      _c("ol", [
                        _c("li", [
                          _c("b", [_vm._v("Clique")]),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.postLink + "?__a=1",
                                target: "_blank",
                              },
                            },
                            [_vm._v(" aqui\n            ")]
                          ),
                          _vm._v("e localize o campo "),
                          _c("b", [_vm._v("is_ad=true")]),
                          _vm._v(" com o auxílio de busca do seu navegador."),
                          _c("br"),
                        ]),
                        _c("li", [
                          _c("b", [_vm._v("Solicite")]),
                          _vm._v(
                            " ao cliente que identifique em sua operação o número da Ad Account em que o Dark Post fora publicado."
                          ),
                          _c("br"),
                        ]),
                        _c("li", [
                          _c("b", [_vm._v("Valide")]),
                          _vm._v(
                            " o número da Ad Account informada e se o usuário de conexão tem acesso por meio da "
                          ),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.goToAdAccount()
                                },
                              },
                            },
                            [_vm._v("ferramenta")]
                          ),
                          _vm._v(".\n          "),
                        ]),
                      ]),
                      _c("br"),
                      _vm._v("Acesse maiores informações na\n        "),
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: "https://stilingue-jira.atlassian.net/wiki/spaces/AC/pages/802652258/Admin+Tools#Instagram.1",
                          },
                        },
                        [_vm._v("documentação")]
                      ),
                      _vm._v(
                        " se retorno positivo da execução de todos os passos.\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.serverError
                ? _c(
                    "v-alert",
                    {
                      attrs: {
                        border: "right",
                        "colored-border": "",
                        type: "error",
                        elevation: "2",
                      },
                    },
                    [
                      _vm._v(
                        "\n        Ops, ocorreu um erro inesperado! Entre em contato com o time de N2.\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }