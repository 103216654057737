var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "section",
        { staticClass: "selector-container" },
        [
          _c(
            "v-form",
            {
              ref: "actionsHistoryForm",
              attrs: { "lazy-validation": false },
              model: {
                value: _vm.models.valid,
                callback: function ($$v) {
                  _vm.$set(_vm.models, "valid", $$v)
                },
                expression: "models.valid",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [_c("UniverseSearch")],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pt-8 pl-6", attrs: { cols: "5" } },
                    [
                      _c("SingleSelect", {
                        attrs: {
                          required: "",
                          items: _vm.actions,
                          placeholder: "Selecione uma ação",
                          text: "name",
                          "sub-text": " ",
                        },
                        model: {
                          value: _vm.models.actionSelected,
                          callback: function ($$v) {
                            _vm.$set(_vm.models, "actionSelected", $$v)
                          },
                          expression: "models.actionSelected",
                        },
                      }),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4 mt-8",
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [_vm._v("\n            Voltar\n          ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4 mt-8",
                          attrs: {
                            disabled: !_vm.models.valid || _vm.loading,
                            color: "primary",
                          },
                          on: { click: _vm.getActionsHistory },
                        },
                        [_vm._v("\n            Buscar\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "table-container" },
        [
          _vm.error
            ? _c(
                "v-alert",
                {
                  attrs: {
                    border: "right",
                    "colored-border": "",
                    type: "error",
                    elevation: "2",
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.labels.errorMessage) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("v-text-field", {
                    staticClass: "mx-4",
                    attrs: {
                      "append-icon": "mdi-refresh",
                      label: "Filtrar histórico por",
                      "single-line": "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.table.search,
                      callback: function ($$v) {
                        _vm.$set(_vm.table, "search", $$v)
                      },
                      expression: "table.search",
                    },
                  }),
                ],
                1
              ),
              _c("StLoader", { attrs: { loading: _vm.loading } }),
              _vm.table.items
                ? _c("v-data-table", {
                    attrs: {
                      headers: _vm.table.headers,
                      items: _vm.table.items,
                      search: _vm.table.search,
                      "custom-sort": _vm.customSort,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item.user",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    loading:
                                      _vm.loadingUser &&
                                      item.user === _vm.userFind,
                                    disabled:
                                      _vm.loadingUser &&
                                      item.user === _vm.userFind,
                                    color: "blue-grey",
                                    left: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showUser(item.user)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(item.user) +
                                      "\n            "
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { small: "", right: "", dark: "" },
                                    },
                                    [
                                      _vm._v(
                                        "\n              mdi-magnify\n            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      810605951
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "514px", persistent: "" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _vm.userData
                ? _c(
                    "v-card",
                    { staticClass: "owners", attrs: { tile: "" } },
                    [
                      _c("v-system-bar", { attrs: { height: "14" } }),
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-avatar",
                                [_c("v-img", { attrs: { src: _vm.ownl } })],
                                1
                              ),
                              _c(
                                "v-list-item-title",
                                { staticClass: "text-h10" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.userData.name) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-subtitle",
                                    [
                                      _vm.userData.lock
                                        ? _c(
                                            "v-icon",
                                            { attrs: { small: "", left: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-lock\n                "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "v-icon",
                                            { attrs: { small: "", left: "" } },
                                            [
                                              _vm._v(
                                                "\n                  mdi-lock-open\n                "
                                              ),
                                            ]
                                          ),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.userData.email) +
                                          "\n              "
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Permissão"),
                                  ]),
                                  _vm.userData.role
                                    ? _c("v-list-item-subtitle", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.userData.role) +
                                            "\n              "
                                        ),
                                      ])
                                    : _c("v-list-item-subtitle", [
                                        _vm._v(
                                          "\n                --\n              "
                                        ),
                                      ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            { attrs: { "two-line": "" } },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [_vm._v("Grupo")]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(_vm.userData.user_group)),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            { attrs: { "two-line": "" } },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Criado em"),
                                  ]),
                                  _c("v-list-item-subtitle", [
                                    _vm._v(_vm._s(_vm.userData.created_at)),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", color: "primary" },
                              on: { click: _vm.cancel },
                            },
                            [_vm._v("\n            Fechar\n          ")]
                          ),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }