<template>
  <v-autocomplete
    v-model="itemValue"
    :items="items"
    :label="placeholder"
    :filter="customFilter"
    :item-text="text"
    item-value="id"
    return-object
    :search-input.sync="searchInput"
    v-bind="$attrs"
    class="single-select"
    @change="searchInput=''"
    v-on="$listeners"
  >
    <template
      slot="selection"
      slot-scope="{item}"
    >
      <v-list-item>
        <v-list-item-content>
          <span v-if="searchInput">
            {{ searchInput }}
          </span>
          <span v-else>
            {{ item[text] }}
            <span v-if="item[subText]">({{ item[subText] }})</span>
          </span>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template
      slot="item"
      slot-scope="{item}"
    >
      <v-list-item-content>
        <v-list-item-title>
          <span>
            {{ item[text] }}
            <span v-if="item[subText]">({{ item[subText] }})</span>
          </span>
        </v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'SingleSelect',
  props: {
    placeholder: {
      type: String,
      default: 'Selecione um Item'
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: 'name',
    },
    subText: {
      type: String,
      default: 'id',
    },
    value: {
      type: Object,
      default: () => {},
    },
    secondFilter: {
      type: [Number, String],
      default: 'id'
    }
  },
  data() {
    return {
      searchInput: '',
    };
  },
  computed: {
    itemValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('change', value);
      }
    }
  },
  methods: {
    customFilter(item, queryText) {
      const firstFilter = item[this.text].toLowerCase();
      const searchText = queryText.toLowerCase();
      if (!item[this.secondFilter]) {
        return firstFilter.indexOf(searchText) > -1;
      }
      const secondFilter = item[this.secondFilter].toString().toLowerCase();
      return firstFilter.indexOf(searchText) > -1 ||
        secondFilter.indexOf(searchText) > -1;
    },
  }
};
</script>

<style scoped>
  .v-list-item__content {
    flex-flow: row;
  }
</style>
