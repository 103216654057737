<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <v-row class="selector-container">
      <v-col cols="5">
        <v-form
          ref="postDetailsForm"
          v-model="valid"
          class="post-details__form"
          :lazy-validation="false"
        >
          <v-text-field
            v-model="accountId"
            required
            :rules="isNumber"
            label="ID da Conta"
            @keydown="post=null"
          />
          <v-text-field
            v-model="universeId"
            required
            :rules="isNumber"
            label="ID da pesquisa"
            @keydown="post=null"
          />
          <v-text-field
            v-model="postId"
            required
            :rules="isNotEmpty"
            label="ID da publicação"
            @keydown="post=null"
          />
          <v-btn
            :disabled="!valid"
            color="light-blue"
            class="mr-4"
            @click="loadPostInfo"
          >
            Visualizar informações da publicação
          </v-btn>
        </v-form>
        <v-alert
          v-model="errorToGetPost"
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          {{ labels.errorMessage }}
        </v-alert>
      </v-col>
      <v-col cols="7">
        <StLoader :loading="loading" />
        <v-card
          v-if="post"
          class="mx-auto post-details"
        >
          <v-toolbar
            color="teal"
            dark
          >
            <v-toolbar-title>Detalhes</v-toolbar-title>
          </v-toolbar>

          <v-list
            subheader
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Publicada em:</v-list-item-title>
                <v-list-item-subtitle>{{ post.postedAt }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Coletada em:</v-list-item-title>
                <v-list-item-subtitle>{{ post.crawledAt }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="post.instagramHashtag"
            >
              <v-list-item-content>
                <v-list-item-title>Pela hashtag:</v-list-item-title>
                <v-list-item-subtitle>
                  {{ post.instagramHashtag }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Processada em:</v-list-item-title>
                <v-list-item-subtitle>{{ post.processedAt }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Descritores de temas:</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(theme, index) in post.themeDescriptors"
                :key="`theme-${index}`"
              >
                <v-list-item-content>
                  <v-list-item-subtitle
                    class="descriptor-info"
                  >
                    <span><b>Descritor</b>: {{ theme.descriptor }}</span>
                    <span><b>Tema:</b> {{ theme.theme }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-group
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Descritores de grupo:</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(name, index) in post.nameDescriptors"
                :key="`name-${index}`"
              >
                <v-list-item-content>
                  <v-list-item-subtitle
                    class="descriptor-info"
                  >
                    <span><b>Descritor</b>: {{ name.descriptor }}</span>
                    <span><b>Grupo:</b> {{ name.name }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-group
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Tags</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(tag, index) in post.tag"
                :key="`tag-${index}`"
              >
                <v-list-item-content>
                  <v-list-item-subtitle> {{ tag.tag }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-group
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Termos</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(term, index) in post.term"
                :key="`term-${index}`"
              >
                <v-list-item-content>
                  <v-list-item-subtitle> {{ term.term }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-group
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Links</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(link, index) in post.link"
                :key="`link-${index}`"
              >
                <v-list-item-content>
                  <v-list-item-subtitle> {{ link.link }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminToolsService from '../services/AdminToolsService';
import moment from 'moment';
import StLoader from '@stilingue/st-loader';
import { mapState } from 'vuex';
import PageHeader from '@/components/base/PageHeader';

export default {
  name: 'PostDetails',
  components: {
    PageHeader,
    StLoader
  },
  data: () => ({
    labels: {
      title: 'Detalhes na plataforma',
      errorMessage: 'Publicação não encontrada.',
      description: 'Permite conferência de detalhes de coleta e processamento da publicação.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Publicação',
          to: '/publications'
        },
        {
          text: 'Plataforma',
          to: '/publications/platform-details'
        }
      ]
    },
    universeId: '',
    accountId: '',
    postId: '',
    loading: false,
    valid: true,
    errorToGetPost: false,
    post: undefined,
    isNumber: [v => (v && !isNaN(v)) || 'Este campo deve ser preenchido com um ID.'],
    isNotEmpty: [v => (v && v.length) > 0 || 'Este campo deve ser preenchido'],
  }),
  computed: {
    ...mapState('account', ['activeAccount']),
    ...mapState('universe', ['activeUniverse']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      }
    },
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      }
    }
  },
  async created() {
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount.id);
    }
    if (this.selectedUniverse) {
      this.selectUniverse(this.selectedUniverse.id);
    }
  },
  methods: {
    loadPostInfo() {
      this.loading = true;
      this.errorToGetPost = false;
      this.post = undefined;
      AdminToolsService.getPostById({
        accountId: this.accountId,
        universeId: this.universeId,
        pid: this.postId
      }).then(data => {
        this.loading = false;

        for (const timeKey of ['crawledAt', 'processedAt', 'postedAt']) {
          data[timeKey] = moment(data[timeKey]).format('DD/MM/YYYY HH:mm:ss');
        }

        data.themeDescriptors = data.descriptors.filter(t => t.theme && t.theme.length);
        data.nameDescriptors = data.descriptors.filter(n => n.name && n.name.length);

        this.post = data;
      }).catch(error => {
        this.loading = false;
        console.error(error);
        this.errorToGetPost = true;
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    reset() {
      this.$refs.form.reset();
    },
    selectAccount(selectedAccount) {
      this.accountId = selectedAccount;
    },
    selectUniverse(selectedUniverse) {
      this.universeId = selectedUniverse;
    }
  }
};
</script>

<style lang="scss" scoped>

  .post-details__form-and-result {
    display: flex;
    flex-direction: row;
  }

 .post-info__data {
    margin: 20px 0;
  }

  .descriptor-info span:first-child {
    margin-right: 40px;
  }

  .post-details {
    .v-list-group--no-action > .v-list-group__items > .v-list-item {
      padding-left: 40px;
    }
  }

  .v-form {
    margin-bottom: 20px;
  }

  .selector-container {
    width: 1200px;
  }

</style>
