import StService from './StService';
import { createApi } from '@stilingue/st-auth-utils';
import gateway from './Gateway';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import { featureFlagFlags } from '@/plugins/rollout';

import { getDebbuginId } from '../utils/utils';

const version = isProdVersion()
  ? featureFlagFlags.EndpointServiceVersionProd.getValue()
  : featureFlagFlags.EndpointServiceVersionStaging.getValue();

const afterParser = response => response.data;

class EndPointService extends StService {
  constructor(gateway, version) {
    const api = createApi(
      `stilingue-endpoint/${version}`,
      gateway,
      { timeout: 120000 },
    );
    super(api, { afterParser });
  }

  getMessagesCountSummary({ filter_id, account_id, universe_id, date_range }) {
    const params = {
      filter_id,
      account_id,
      universe_id,
      debugin_id: getDebbuginId(),
      date_range,
    };

    return this.api.get({ params, url: '/smartcare/count-messages' }).then(this.afterParser);
  }
}

export default new EndPointService(gateway, version);
