import { MUTATE, MUTATE_MULTIPLE } from '@/store/modules/mutation_types';
import SingleSignOnService from '@/services/SingleSignOnService';

export function getUsergroups({ commit }) {
  commit(MUTATE, {
    property: 'requestStatus',
    with: 'loading'
  });

  return SingleSignOnService.getUserGroups()
    .then(data => {
      commit(MUTATE_MULTIPLE, [
        {
          property: 'requestStatus',
          with: 'success'
        }, {
          property: 'usergroups',
          with: data
        }
      ]);

      if (data.length >= 1) {
        commit(MUTATE, {
          property: 'activeUsergroup',
          with: data[0]
        });
      }

      return Promise.resolve(data);
    }).catch((e) => {
      console.error(e);
      commit(MUTATE, {
        property: 'requestStatus',
        with: 'error',
      });
    });
}

export function setUsergroup({ commit }, { selectedUsergroup }) {
  commit(MUTATE, {
    property: 'activeUsergroup',
    with: selectedUsergroup,
  });
}
