<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <section>
      <v-form
        ref="sla-analyze"
        :lazy-validation="false"
      >
        <v-row>
          <v-col cols="5">
            <UniverseSearch />
            <SingleSelect
              v-if="activeAccount"
              v-model="selectedPage"
              :items="pages"
              placeholder="Selecione uma Página"
              text="name"
              sub-text="channel"
            />
          </v-col>
          <v-col
            cols="5"
            class="pa-10"
          >
            <SingleSelect
              v-model="models.selectedSacType"
              :items="sacTypes"
              placeholder="Selecione o Tipo de Interação"
              text="name"
              sub-text=" "
            />
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="models.averageTime"
                  placeholder="Tempo Médio"
                  :rules="rules.isInteger"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="models.limitTime"
                  placeholder="Tempo Limite"
                  :rules="rules.isInteger"
                />
              </v-col>
            </v-row>
            <StDatePicker
              v-model="models.dates"
              range
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="6"
          >
            <v-btn
              color="primary"
              elevation="1"
              raised
              :disabled="isSearchDisabled()"
              @click="submitAnalyze"
            >
              Analisar
            </v-btn>
          </v-col>
        </v-row>
        <v-alert
          v-if="warning"
          border="right"
          colored-border
          type="warning"
          elevation="2"
        >
          Nenhuma publicação encontrada. Por favor, verifique os dados de entrada.
        </v-alert>
        <v-alert
          v-if="error"
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          Erro inesperado ao analisar SLA. Favor entrar em contato com o time de N2.
        </v-alert>
      </v-form>
      <StLoader :loading="loading" />
    </section>
    <section
      v-if="success && response"
      class="mt-2"
    >
      <v-row justify="space-around">
        <v-col
          v-for="(report, i) in response.report"
          :key="`report-${i}`"
        >
          <v-sheet
            class="pa-12"
            color="gray lighten-3"
            rounded
          >
            <div><strong>{{ report.type }}</strong></div>
            <div class="pa-5">
              <strong>{{ response.posts_analyzed }}</strong> publicações <strong v-if="models.dates.length === 0"> das
                últimas 24 horas
              </strong> foram analisadas e <strong>{{ report.posts_total }}</strong> tem tempo de processamento superior a <strong>{{ report.time }}m</strong>.
            </div>

            <v-carousel
              height="605"
            >
              <v-carousel-item
                v-for="(post, post_index) in report.posts"
                :key="`post-${post_index}`"
              >
                <v-sheet
                  color="teal lighten-3"
                  height="100%"
                  class="pa-10"
                >
                  <v-card
                    class="mx-auto"
                    max-width="344"
                  >
                    <v-img
                      :src="postImage"
                      height="200px"
                    />

                    <v-card-title>
                      {{ post.text }}
                    </v-card-title>

                    <v-card-subtitle>
                      {{ post.post_url }}
                    </v-card-subtitle>

                    <v-card-actions>
                      <v-btn
                        color="orange lighten-2"
                        text
                      >
                        Detalhes
                      </v-btn>

                      <v-spacer />

                      <v-btn
                        icon
                        @click="show = !show"
                      >
                        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      </v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                      <div v-show="show">
                        <v-divider />

                        <v-card-text>
                          <div> Identificador: <strong>{{ post.pid }}</strong> </div>
                          <div>Publicada em <strong>{{ post.posted_at }}</strong></div>
                          <div>Coletada em <strong>{{ post.crawled_at }}</strong></div>
                          <div>Processada em <strong>{{ post.processed_at }}</strong></div>
                        </v-card-text>
                      </div>
                    </v-expand-transition>
                  </v-card>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-sheet>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>

import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import UniverseSearch from '../universe/Search.vue';
import StLoader from '@stilingue/st-loader';
import SingleSelect from '@/components/SingleSelect';
import StDatePicker from '@/components/StDatePicker';
import PageHeader from '@/components/base/PageHeader';
import AdminToolsService from '@/services/AdminToolsService';
import postImage from '@/assets/img/post.png';

export default {
  name: 'SLAAnalyze',
  components: {
    PageHeader,
    SingleSelect,
    StDatePicker,
    StLoader,
    UniverseSearch
  },
  data: () => ({
    labels: {
      title: 'SLA de Coleta',
      description: 'Permite conferência de cumprimento do SLA de Coleta dado o Tempo Médio e o Tempo Limite informado. Consulte a documentação para entrada dos valores de cada tipo de publicação.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'SLA',
          to: '/sla'
        },
        {
          text: 'Coleta',
          to: '/sla/proprietary-crawler'
        }
      ]
    },
    rules: {
      isInteger: [v => {
        const pattern = /^([1-9]\d{0,7})$/u;
        if (v && v.length > 0) {
          return pattern.test(v) || 'Por favor, é necessário que entre com o número do tempo.';
        }
        return true;
      }]
    },
    pagePlaceholder: 'Selecione uma Página',
    models: {
      selectedSacType: null,
      averageTime: null,
      limitTime: null,
      dates: []
    },
    sacTypes: [
      {
        id: 'PostComment',
        name: 'Comentários',
      },
      {
        id: 'Inbox',
        name: 'Inbox'
      },
      {
        id: 'VisitorPost',
        name: 'Publicações de visitante'
      },
      {
        id: 'VisitorComment',
        name: 'Comentário em publicação de visitante'
      },
      {
        id: 'Review',
        name: 'Avaliações'
      },
      {
        id: 'ReviewComment',
        name: 'Comentários em avaliações'
      },
      {
        id: 'Mention',
        name: 'Menções'
      },
      {
        id: 'MentionComment',
        name: 'Comentários em menções'
      }
    ],
    loading: false,
    error: false,
    success: false,
    warning: false,
    show: false,
    response: null
  }),
  computed: {
    ...mapState('account', ['activeAccount']),
    ...mapState('universe', ['activeUniverse']),
    ...mapState('page', ['pages', 'activePage']),
    selectedPage: {
      get() {
        return this.activePage;
      },
      set(selectedPage) {
        this.selectPage(selectedPage);
      }
    }
  },
  async created() {
    this.models.selectedSacType = this.sacTypes[0];
    this.postImage = postImage;
  },
  methods: {
    ...mapActions('page', ['setPage']),
    selectPage(selectedPage) {
      this.setPage({ selectedPage });
    },
    isSearchDisabled() {
      return !this.activeAccount ||
        !this.activeUniverse ||
        !this.models.averageTime ||
        !this.models.limitTime;
    },
    async submitAnalyze() {
      const parameters = {
        account_id: this.activeAccount.id,
        universe_id: this.activeUniverse.id,
        page_id: this.selectedPage.id,
        sac_type: this.models.selectedSacType.id,
        average_time: this.models.averageTime,
        limit_time: this.models.limitTime,
        begin_date: null,
        end_date: null
      };

      if (this.models.dates && this.models.dates.length >= 1) {
        parameters.begin_date = this.models.dates[0].replaceAll('-', '').concat('0000');
        if (this.models.dates[1]) {
          parameters.end_date = this.models.dates[1].replaceAll('-', '').concat('2359');
        }
      }

      this.analizeSLA(parameters);
    },
    async analizeSLA(parameters) {
      this.loading = true;
      this.success = false;
      this.warning = false;
      this.error = false;
      this.response = null;
      AdminToolsService.analizeSLA(parameters).then(resp => {
        this.loading = false;
        if (resp.posts_analyzed > 0) {
          this.success = true;
          this.response = resp;
          this.response.report = resp.report.map(item => {
            if (item.type === 'AVERAGE') {
              item.type = 'TEMPO MÉDIO';
            } else {
              item.type = 'TEMPO LIMITE';
            }
            item.posts.map(post => {
              post.crawled_at = moment(post.crawled_at).format('DD/MM/YYYY HH:mm:ss');
              post.posted_at = moment(post.posted_at).format('DD/MM/YYYY HH:mm:ss');
              post.processed_at = moment(post.processed_at).format('DD/MM/YYYY HH:mm:ss');

              const newPost = { ...post };
              return newPost;
            });
            const newItem = { ...item };
            return newItem;
          });
        } else {
          this.warning = true;
        }
      }).catch(e => {
        this.loading = false;
        this.error = true;
        this.labels.errorMessage = e.toString();
      });
    }
  }
};
</script>
<style lang="scss" scoped>
  form {
    margin-bottom: 0;
  }

  .v-alert {
    margin-top: 20px;
  }
</style>
