export default {
  phName: 'Nome:',
  phLimit: 'Insira o limite de pesquisa da conta:',
  phEmail: 'Insira o email do usuário master existente:',
  lbDate: 'Data de expiração do contrato',
  create: 'Criar novo Usergroup',
  title: 'Novo Usergroup',
  description: 'Permite a criação de um novo Grupo de Usuário para um usuário Master cadastrado.',
  errorLimit: 'Por favor, é necessário que entre com número limite de pesquisas da conta.',
  errorName: 'Necessário entrar com o nome Grupo de Usuário a ser criado.',
  errorEmail: 'Endereço de e-mail inválido',
  errorAuth: 'Falha na autenticação!',
  error: 'Erro inesperado ao tentar realizar operação.'

};
