import StService from '@/services/StService';
import { createApi } from '@stilingue/st-auth-utils';
import { featureFlagFlags } from '@/plugins/rollout';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import { authentication } from '@stilingue/st-requests';
import gateway from './Gateway';

const authStore = authentication({ tokenName: 'usr_id' });

const version = isProdVersion()
  ? featureFlagFlags.SsoServiceVersionProd.getValue()
  : featureFlagFlags.SsoServiceVersionStaging.getValue();

const afterParser = response => response.data;

class SingleSignOnService extends StService {
  constructor(gateway, version = 'staging') {
    const api = createApi(`upms-legacy/${version}/`, gateway);
    super(api, { afterParser });
  }

  createNewSsoConfig(params = {}) {
    const url = 'federations';
    const usr_id = authStore.getSessionId();
    const headers = { usr_id };
    return this.api.post({ url, ...params }, { headers }).then(this.afterParser);
  }

  getUserGroups(params = {}) {
    return this.api.get({ params, url: 'federations/usergroups' }).then(this.afterParser);
  }

  getSsoList(params = {}) {
    return this.api.get({ params, url: 'federations' }).then(this.afterParser);
  }

  updateSso(params) {
    const { lock, passwordLogin, id } = params;
    const url = `federations?lock=${lock}&passwordLogin=${passwordLogin}&id=${id}`;
    const usr_id = authStore.getSessionId();
    const headers = { usr_id };
    return this.api.patch({ url }, { headers }).then(this.afterParser);
  }
}

export default new SingleSignOnService(gateway, version);
