<template>
  <StCompanySelection
    :companies="companies"
    :page-title="$t('StCompanySelection.pageTitle')"
    @selected="updateCompany"
  />
</template>

<script>
import StCompanySelection from '@stilingue/st-company-selection';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'CompanySelection',
  components: {
    StCompanySelection
  },
  computed: {
    ...mapState('company', [
      'companies'
    ])
  },
  async created() {
    await this.getCompanies();
  },
  methods: {
    ...mapActions('company', ['getCompanies']),
    ...mapMutations('company', {
      mutate: 'MUTATE'
    }),
    updateCompany(company) {
      this.mutate({
        property: 'activeCompany',
        with: company
      });

      this.$router.replace({ name: 'home' });
    }
  }
};
</script>

<style scoped lang="scss">
  .organizations-view {
    background-color: var(--gray-050);
  }

  .company-container__logo {
    margin: -50% auto 20px;
  }

  .company-container__card * {
    background-color: var(--gray-100);
  }

  .company-container__card__list {
    padding-top: 0;
    width: 100%;
  }

  ::v-deep {
    .company-container__card__list-item {
      border-radius: 3px;
      width: 100%;
      height: 51px;
      margin-bottom: 5px;
      transition: none;

      .v-list__tile--link {
        transition: none !important;
      }

      &:hover,
      &:hover * {
        background-color: var(--gray-050) !important;
      }
    }
  }
</style>
