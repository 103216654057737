import { MUTATE, MUTATE_MULTIPLE } from '@/store/modules/mutation_types';
import CompanyService from '@/services/CompanyService';

export function getCompanies({ state, commit }) {
  if (state.companies && state.companies.length) {
    commit(MUTATE, {
      property: 'requestStatus',
      with: 'success'
    });
    return Promise.resolve(state.companies);
  }

  commit(MUTATE, {
    property: 'requestStatus',
    with: 'loading'
  });

  return CompanyService.find()
    .then(data => {
      commit(MUTATE_MULTIPLE, [
        {
          property: 'requestStatus',
          with: 'success'
        }, {
          property: 'companies',
          with: data
        }
      ]);
      if (data.length === 1) {
        commit(MUTATE, {
          property: 'activeCompany',
          with: data[0]
        });
      }

      return Promise.resolve(data);
    }).catch((e) => {
      console.error(e);
      commit(MUTATE, {
        property: 'requestStatus',
        with: 'error'
      });
    });
}

export async function fetchCompanies({ commit }) {
  commit(MUTATE, {
    property: 'requestStatus',
    with: 'loading'
  });

  try {
    const companies = await CompanyService.find();
    commit(MUTATE_MULTIPLE, [
      {
        property: 'requestStatus',
        with: 'success'
      }, {
        property: 'companies',
        with: companies
      }
    ]);
    return companies;
  } catch (e) {
    console.error(e);
    commit(MUTATE, {
      property: 'requestStatus',
      with: 'error'
    });
    return e;
  }
}
