var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", [_vm._v(" Olá Coruja! Seja bem-vinda ao Admin Tools! ")]),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "514px", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-toolbar",
            {
              attrs: { flat: "" },
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function () {
                    return [
                      _c(
                        "v-tabs",
                        {
                          attrs: { "fixed-tabs": "" },
                          model: {
                            value: _vm.tabs,
                            callback: function ($$v) {
                              _vm.tabs = $$v
                            },
                            expression: "tabs",
                          },
                        },
                        [
                          _c("v-tabs-slider"),
                          _c(
                            "v-tab",
                            {
                              staticClass: "primary--text",
                              attrs: { href: "#mobile-tabs-5-1" },
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                { attrs: { dark: "" } },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                  mdi-desktop-mac-dashboard\n                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [_c("span", [_vm._v("Universo")])]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab",
                            {
                              staticClass: "primary--text",
                              attrs: { href: "#mobile-tabs-5-2" },
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                { attrs: { dark: "" } },
                                                "v-icon",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                  mdi-account-key\n                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [_c("span", [_vm._v("Acessos e Permissões")])]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("v-toolbar-title", [
                _vm._v("\n        Vamos otimizar o atendimento?\n      "),
              ]),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tabs,
                callback: function ($$v) {
                  _vm.tabs = $$v
                },
                expression: "tabs",
              },
            },
            _vm._l(2, function (i) {
              return _c(
                "v-tab-item",
                { key: i, attrs: { value: "mobile-tabs-5-" + i } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      i == 1
                        ? _c(
                            "v-card-text",
                            [
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.labels.universeDescription) +
                                    "\n            "
                                ),
                              ]),
                              _c("UniverseSearch"),
                            ],
                            1
                          )
                        : _vm._e(),
                      i == 2
                        ? _c(
                            "v-card-text",
                            [
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.labels.userGroupDescription) +
                                    "\n            "
                                ),
                              ]),
                              _c("AccessSearch"),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            { attrs: { text: "" }, on: { click: _vm.cancel } },
                            [_vm._v("\n              Agora não\n            ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-4",
                              attrs: { color: "light-blue" },
                              on: {
                                click: function ($event) {
                                  return _vm.overview(i)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              Visão Geral\n            "
                              ),
                            ]
                          ),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }