<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="dateText"
        label="Selecione intervalo de datas"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="dateValue"
      no-title
      scrollable
      :range="range"
    />
  </v-menu>
</template>

<script>
export default {
  name: 'StDatePicker',
  props: {
    value: {
      type: [String, Array],
      default() {
        return this.range ? [] : '';
      }
    },
    range: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      date: this.value,
      menu: false,
    };
  },
  computed: {
    dateValue: {
      get() {
        return this.date;
      },
      set(newDate) {
        this.date = newDate;
        this.$emit('input', newDate);
      }
    },
    dateText() {
      return this.range ? this.date.map(d => this.formatDate(d)).join(' até ') : this.formatDate(this.date);
    },
  },
  methods: {
    formatDate(date) {
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    }
  }
};
</script>
