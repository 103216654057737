import Vue from 'vue';
import Vuex from 'vuex';

// Load modules
import user from './modules/user';
import featureFlag from './modules/st_feature_flag';
import company from './modules/company';
import page from './modules/page';
import routing from './modules/routing';
import universe from './modules/universe';
import account from './modules/account';
import misc from './modules/misc';
import authErrors from './modules/auth_errors';
import usergroup from './modules/usergroup';
import benchmark from './modules/benchmark';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    featureFlag,
    company,
    page,
    routing,
    universe,
    account,
    misc,
    authErrors,
    usergroup,
    benchmark,
  },
});
