<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <v-row class="selector-container">
      <v-col cols="6">
        <v-form
          ref="actionBablicForm"
          v-model="models.valid"
          :lazy-validation="false"
        >
          <v-textarea
            v-model="models.emails"
            placeholder="E-mail ou lista de e-mails"
            value=""
            hint="Separe os e-mails utilizando ponto e vígula (;)"
            :rules="rules.isEmail"
            @input="setErrorFalse"
          />
          <v-radio-group v-model="models.actionType">
            <div class="action-type">
              <v-radio
                label="Ativar"
                value="Ativar"
              />
              <v-radio
                label="Desativar"
                value="Desativar"
              />
            </div>
          </v-radio-group>
          <v-btn
            :disabled="!models.valid || !models.actionType || loading"
            color="light-blue"
            class="mr-4"
            @click="actionBablic"
          >
            Salvar
          </v-btn>
        </v-form>
        <v-alert
          v-if="success"
          border="right"
          colored-border
          type="success"
          elevation="2"
        >
          {{ labels.successMessage }}
        </v-alert>
        <v-alert
          v-if="warning"
          border="right"
          colored-border
          type="warning"
          elevation="2"
        >
          Não foi possível encontrar todos os e-mails! Verifque os detalhes na tabela.
        </v-alert>
        <v-alert
          v-if="error"
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          {{ labels.errorMessage }} Informe ao time de N2.
        </v-alert>
      </v-col>
      <v-col cols="6">
        <StLoader :loading="loading" />
        <v-data-table
          v-if="result.length > 0"
          :headers="logHeaders"
          :items="result"
          :items-per-page="10"
          class="elevation-1"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StLoader from '@stilingue/st-loader';
import PageHeader from '@/components/base/PageHeader';
import UserService from '@/services/DataStoreService/UserService';

export default {
  name: 'ActionBablic',
  components: {
    PageHeader, StLoader
  },
  data: () => ({
    labels: {
      title: 'Bablic',
      description: 'Permite ativar ou desativar o Bablic para o(s) usuário(s).',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Usuário',
          to: '/user'
        },
        {
          text: 'Bablic',
          to: '/user/bablic'
        }
      ],
      successMessage: 'Solicitação executada com sucesso! Verifique os detalhes na tabela.',
      errorMessage: 'Erro inesperado ao tentar realizar operação!',
    },
    logHeaders: [
      { text: 'Usuário', value: 'email' },
      { text: 'Status', value: 'operationLabel' }
    ],
    models: {
      valid: false,
      emails: '',
      actionType: '',
    },
    result: [],
    error: false,
    success: false,
    loading: false,
    warning: false,
    rules: {
      // eslint-disable-next-line complexity
      isEmail: [v => {
        if (v === null || v.length === 0) {
          return false;
        }
        const validEmailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/u;
        const EMAIL_SEPARATOR = ';';
        let errorMsg = 'Por favor, é necessário que entre com o email do usuário ou com a lista de emails.';

        if (v.includes(EMAIL_SEPARATOR)) {
          let emailList = v.split(EMAIL_SEPARATOR);
          emailList = emailList.filter((a) => a);
          if (emailList.length > 25) {
            errorMsg = 'Por favor, entre com no máximo 25 e-mails por vez.';
            return errorMsg;
          }
          const allValidEmails = emailList.every(email => validEmailRegex.test(email.trim()));
          return allValidEmails || errorMsg;
        }
        return validEmailRegex.test(v.trim()) || errorMsg;
      }]
    }
  }),
  computed: {
    ...mapState('user', ['user']),
    userId: {
      get() {
        return this.user.id;
      }
    }
  },
  methods: {
    setErrorFalse() {
      this.error = false;
    },
    getParameters() {
      let emails = this.models.emails;
      if (emails.slice(-1) === ';') {
        emails = emails.slice(0, -1);
      }
      const parameters = {
        emails,
        activate: this.models.actionType
      };
      return parameters;
    },
    async actionBablic() {
      this.loading = true;
      this.error = false;
      this.success = false;
      this.warning = false;
      const parameters = this.getParameters();

      UserService.postUserActionBablic(parameters).then(resp => {
        this.loading = false;
        this.result = [];
        const operationLabel = this.models.actionType === 'Ativar' ? 'Ativado' : 'Desativado';
        if (resp.result.not_found.length > 0) {
          this.warning = true;
        }
        if (resp.result.success.length > 0 && resp.result.not_found.length === 0) {
          this.success = true;
        }
        this.formatResponse(resp.result.success, operationLabel);
        this.formatResponse(resp.result.not_found, 'Não encontrado');
      }).catch(e => {
        this.error = true;
        this.loading = false;
        if (e.response.status === 422) {
          this.labels.errorMessage = 'Falha na autenticação!';
        }
      });
    },
    formatResponse(changes, operationLabel) {
      changes.forEach(email => {
        this.result.push({ email, operationLabel });
      });
    },
  }
};
</script>

<style lang="scss" scoped>
  .selector-container {
    width: 1200px;

    form {
      margin-bottom: 35px;
    }

    .action-type {
      > div.v-radio {
        float: left;
        padding-right: 30px;
      }
    }
  }
</style>
