var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "section",
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("v-text-field", {
                    staticClass: "mx-4",
                    attrs: { label: "Search" },
                    model: {
                      value: _vm.table.search,
                      callback: function ($$v) {
                        _vm.$set(_vm.table, "search", $$v)
                      },
                      expression: "table.search",
                    },
                  }),
                  _c("DsButton", {
                    attrs: {
                      icon: "",
                      name: "refresh",
                      color: "ghost",
                      "data-color-theme": "dark",
                    },
                    on: { click: _vm.getList },
                  }),
                ],
                1
              ),
              _c("StLoader", { attrs: { loading: _vm.loading } }),
              _c("v-data-table", {
                attrs: {
                  headers: _vm.table.headers,
                  items: _vm.table.items,
                  search: _vm.table.search,
                },
                scopedSlots: _vm._u([
                  {
                    key: "header.status",
                    fn: function ({ header }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "table-th__tooltip teste" },
                          [
                            _c("span", { staticClass: "mr-1" }, [
                              _vm._v(" " + _vm._s(header.text) + " "),
                            ]),
                            _c(
                              "DsTooltip",
                              {
                                attrs: {
                                  position: "left",
                                  content: _vm.labels.tooltips.status,
                                  "data-color-theme": "dark",
                                },
                              },
                              [
                                _c("DsIcon", {
                                  attrs: { name: "questions", size: "small" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "header.onlySsoLogin",
                    fn: function ({ header }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "table-th__tooltip" },
                          [
                            _c("span", { staticClass: "mr-1" }, [
                              _vm._v(" " + _vm._s(header.text) + " "),
                            ]),
                            _c(
                              "DsTooltip",
                              {
                                attrs: {
                                  position: "left",
                                  content: _vm.labels.tooltips.onlySsoLogin,
                                  "data-color-theme": "dark",
                                },
                              },
                              [
                                _c("DsIcon", {
                                  attrs: { name: "questions", size: "small" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.status",
                    fn: function ({ item }) {
                      return [
                        _c("DsSwitch", {
                          attrs: { "data-color-theme": "dark" },
                          on: {
                            toggle: function ($event) {
                              return _vm.handleModal(
                                item,
                                "status",
                                item.status
                              )
                            },
                          },
                          model: {
                            value: item.status,
                            callback: function ($$v) {
                              _vm.$set(item, "status", $$v)
                            },
                            expression: "item.status",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "item.onlySsoLogin",
                    fn: function ({ item }) {
                      return [
                        _c("DsSwitch", {
                          attrs: {
                            disabled: !item.shouldAllowSsoOnly,
                            "data-color-theme": "dark",
                          },
                          on: {
                            toggle: function ($event) {
                              return _vm.handleModal(
                                item,
                                "onlySsoLogin",
                                item.onlySsoLogin
                              )
                            },
                          },
                          model: {
                            value: item.onlySsoLogin,
                            callback: function ($$v) {
                              _vm.$set(item, "onlySsoLogin", $$v)
                            },
                            expression: "item.onlySsoLogin",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showSnackbar
        ? _c("DsSnackbar", {
            attrs: {
              type: _vm.typeSnackbar,
              show: _vm.showSnackbar,
              text: _vm.labels.snackbar.current,
              "custom-max-length": _vm.customMaxLengthSnackbar,
              "data-color-theme": "dark",
            },
            on: {
              close: function ($event) {
                _vm.showSnackbar = !_vm.showSnackbar
              },
            },
          })
        : _vm._e(),
      _c("DsModal", {
        attrs: {
          open: _vm.openModal,
          title: _vm.labels.modal.title.current,
          text: _vm.labels.modal.text.current,
          "primary-button-label": _vm.labels.modal.primaryButton.current,
          "secondary-button-label": _vm.labels.modal.secondaryButton,
          "data-color-theme": "dark",
          width: "600px",
        },
        on: { primary: _vm.accept, secondary: _vm.cancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }