var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("StCompanySelection", {
    attrs: {
      companies: _vm.companies,
      "page-title": _vm.$t("StCompanySelection.pageTitle"),
    },
    on: { selected: _vm.updateCompany },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }