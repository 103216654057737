<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <section class="selector-container">
      <v-container
        fluid
        class="pa-0"
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
          >
            <SingleSelect
              v-model="selectedAccount"
              :items="accounts"
              placeholder="Selecione uma conta"
              text="name"
              sub-text="group"
            />
            <SingleSelect
              v-if="activeAccount"
              v-model="selectedPage"
              :items="pages"
              placeholder="Selecione uma Página"
              text="name"
              sub-text="channel"
            />
            <v-text-field
              v-model="adAccountId"
              placeholder="Ad Account ID válida"
            />
            <v-btn
              depressed
              color="primary"
              @click="checkAdAccount"
            >
              Verificar Ad Account
            </v-btn>
          </v-col>
        </v-row>
        <StLoader :loading="loadingAdAccountChecking" />
        <v-alert
          v-if="adAccountFound !== null && !adAccountFound"
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          A página foi conectada na plataforma pelo usuário da Stilingue <b>{{ activePage.usr_name }}</b> utilizando o
          usuário do facebook <b>{{ tokenUserName }}</b> que não tem acesso à Ad Account informada. <br><br> Favor solicitar
          ao cliente que entre em contato com o mesmo.
        </v-alert>
        <v-alert
          v-if="adAccountFound !== null && adAccountFound"
          border="right"
          colored-border
          type="success"
          elevation="2"
        >
          {{ adAccountProprietary }} é o proprietário(a) desta Ad Account.<br>
        </v-alert>
      </v-container>
    </section>
    <v-alert
      v-if="serverError"
      border="right"
      colored-border
      type="error"
      elevation="2"
    >
      Comportamento inesperado! Contate o suporte! Ops, você é o suporte neh?!? Não se desespere...
    </v-alert>
  </div>
</template>

<script>
import SingleSelect from '../components/SingleSelect';
import StLoader from '@stilingue/st-loader';
import { mapActions, mapState } from 'vuex';
import AdminToolsService from '@/services/AdminToolsService';
import WarroomService from '@/services/WarroomService';
import PageHeader from '@/components/base/PageHeader';

const { PagesService } = WarroomService;

export default {
  name: 'AdAccountChecking',
  components: {
    SingleSelect,
    StLoader,
    PageHeader
  },
  data() {
    return {
      adAccountId: '',
      postLink: '',
      pages: [],
      activePage: {},
      ownerConnection: '',
      adAccountFound: null,
      loadingAdAccountChecking: false,
      serverError: false,
      adAccountProprietary: '',
      tokenUserName: '',
      labels: {
        title: 'Instagram Ad Account',
        description: 'Verifica se a Ad Account aparece dentre as que o usuário dono do token de conexão ' +
          'da página tem acesso, não se preocupando em validar se o ID informado é realmente de uma Ad Account. ' +
          'Portanto, parte do pressuposto de que esteja correta e que seja realmente onde o Dark Post fora realizado.',
        breadcrumbs: [
          {
            text: 'Home',
            href: '/'
          },
          {
            text: 'Ad Account',
            to: '/adaccounts'
          },
          {
            text: 'Instagram',
            to: '/adaccounts/instagram/validate'
          }
        ]
      }
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    selectedPage: {
      get() {
        return this.activePage;
      },
      set(selectedPage) {
        this.activePage = selectedPage;
      },
    },
  },
  async created() {
    await this.getAccounts();
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount);
    }
  },
  methods: {
    ...mapActions('account', ['getAccounts', 'setAccount']),
    selectAccount(selectedAccount) {
      this.setAccount({ selectedAccount });
      this.getAccountPages({ selectedAccount });
    },
    selectPage(selectedPage) {
      this.setPage({ selectedPage });
    },
    getAccountPages({ selectedAccount }) {
      const { id: accountId } = selectedAccount;

      PagesService.find({ url: `/${accountId}` })
        .then(data => {
          const { message = [] } = data;

          if (message.length >= 1) {
            this.pages = message.filter(page => page.channel === 'instagram');
            this.activePage = this.pages.length > 0 ? this.pages[0] : null;
          }
        }).catch((e) => {
          console.error(e);
        });
    },
    checkAdAccount() {
      this.loadingAdAccountChecking = true;

      AdminToolsService.find({
        url: `accounts/${this.selectedAccount.id}/pages/${this.selectedPage.id}/instagram/adaccounts/${this.adAccountId}`,
      })
        .then(data => {
          this.serverError = false;
          this.tokenUserName = data.name;
          this.loadingAdAccountChecking = false;
          if ('adaccounts' in data) {
            this.adAccountFound = true;
            this.adAccountProprietary = data.adaccounts[0].name;
          } else {
            this.adAccountFound = false;
          }
        }).catch((e) => {
          console.error(e);
          this.serverError = true;
          this.adAccountFound = null;
          this.loadingAdAccountChecking = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.selector-container {
  width: 500px;
}

.content-body {
  h1 {
    font-size: 24px;
  }

  h2 {
    margin-top: 10px;
    font-size: 12px;
    text-align: justify;
  }

  .v-btn {
    float: right;
  }
}

::v-deep {
  .v-list-item {
    padding: 0;
    min-height: 26px;
  }

  .v-select__selections {
    padding: 12px 0;
  }

  .v-list-item__content {
    padding: 0;
  }

  .v-alert {
    max-width: 465px;
  }

  .v-alert__content {
    text-align: justify;
    margin-right: 15px;
  }
}
</style>
