import { MUTATE, MUTATE_MULTIPLE, MUTATE_OBJECT_KEY } from '@/store/modules/mutation_types';
import { mutate, mutateMultiple, mutateObjectKey } from '@/store/modules/mutations';
import {
  SET_USER_LOGOUT
} from './mutation_types';

export default {
  [MUTATE]: mutate,
  [MUTATE_MULTIPLE]: mutateMultiple,
  [MUTATE_OBJECT_KEY]: mutateObjectKey,
  [SET_USER_LOGOUT](state) {
    state.stSessionId = '';
  }
};
