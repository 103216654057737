import StService from './StService';
import { createApi } from '@stilingue/st-auth-utils';
import { featureFlagFlags } from '@/plugins/rollout';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';

import gateway from './Gateway';

const version = isProdVersion()
  ? featureFlagFlags.CreditsSystemServiceVersionProd.getValue()
  : featureFlagFlags.CreditsSystemServiceVersionStaging.getValue();

const afterParser = response => response.data;

class CreditsSystemGeneralService extends StService {
  constructor(gateway, version) {
    const api = createApi(`credits-system/${version}/`, gateway, { timeout: 30000 });
    super(api, { afterParser });
  }

  addCredits = (params = {}) => {
    const urlStart = version === 'prod' ? 'https://secure-backend-api-internal.stilingue.com.br/' : 'https://secure-backend-api-internal-staging.stilingue.com.br/';

    const { isItToSendEmail, body } = params;

    const headersOptions = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'POST',
      headers: headersOptions,
      body: JSON.stringify(body)
    };
    const urlMounted = `${urlStart}credits-system/${version}/process?changeStatus=false&sendEmails=${(isItToSendEmail)}`;
    return fetch(urlMounted, requestOptions);
  }

  renewCredits = (params = {}) => {
    const urlStart = version === 'prod' ? 'https://secure-backend-api-internal.stilingue.com.br/' : 'https://secure-backend-api-internal-staging.stilingue.com.br/';

    const { isItToSendEmail, body } = params;

    const headersOptions = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
    const requestOptions = {
      method: 'POST',
      headers: headersOptions,
      body: JSON.stringify(body)
    };
    const urlMounted = `${urlStart}credits-system/${version}/renew-process?changeStatus=false&sendEmails=${(isItToSendEmail)}`;
    return fetch(urlMounted, requestOptions);
  }
}

export default new CreditsSystemGeneralService(gateway, version);
