
<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <template v-slot:default="{ open }">
          <v-row no-gutters>
            <v-col cols="4">
              Grupo
            </v-col>
          </v-row>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <SingleSelect
          v-model="selectedUsergroup"
          :items="usergroups"
          required
          text="name"
          placeholder="Selecione"
          sub-text="group"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header v-slot="{ open }">
        <v-row no-gutters>
          <v-col cols="4">
            Conta
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <SingleSelect
          v-model="selectedAccount"
          :items="accounts"
          required
          text="name"
          placeholder="Selecione"
          sub-text="group"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header v-slot="{ open }">
        <v-row no-gutters>
          <v-col cols="4">
            Usuário
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row
          justify="space-around"
          no-gutters
        >
          <v-text-field
            v-model="selectedUserEmail"
            required
            label="E-mail"
            prepend-icon="mdi-email"
            :rules="isEmail"
          />
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import SingleSelect from '../SingleSelect.vue';

export default {
  name: 'UserGroupSearch',
  components: {
    SingleSelect
  },
  data: () => ({
    // eslint-disable-next-line require-unicode-regexp
    isEmail: [v => {
      const pattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/u;
      if (v.length > 0) {
        return pattern.test(v) || 'Endereço de e-mail inválido';
      }
    }]
  }),
  computed: {
    ...mapState('user', ['user', 'activeUserEmail']),
    ...mapState('account', ['accounts', 'activeAccount', 'usergroups', 'activeUsergroup']),
    selectedUsergroup: {
      get() {
        return this.activeUsergroup;
      },
      set(selectedUsergroup) {
        this.selectUsergroup(selectedUsergroup);
      }
    },
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    userId: {
      get() {
        return this.user.id;
      }
    },
    selectedUserEmail: {
      get() {
        return this.activeUserEmail;
      },
      set(selectedUserEmail) {
        this.selectUserEmail(selectedUserEmail);
      }
    }
  },
  async created() {
    this.selectUserEmail(this.user.email);
    await this.getAccounts({ userId: this.userId });
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount);
    }
    this.getUsergroups();
  },
  methods: {
    ...mapActions('user', ['setUserEmail']),
    ...mapActions('account', ['getAccounts', 'setAccount', 'getUsergroups', 'setUsergroup']),
    ...mapMutations('account', {
      mutate: 'MUTATE',
    }),
    selectUsergroup(selectedUsergroup) {
      this.setUsergroup({ selectedUsergroup });
    },
    selectAccount(selectedAccount) {
      this.setAccount({ selectedAccount });
    },
    selectUserEmail(selectedUserEmail) {
      this.setUserEmail({ selectedUserEmail });
    }
  }
};
</script>
<style lang="scss" scoped>
  .v-item-group {
    margin-bottom: 25px;
    margin-top: 30px;
  }
</style>
