<template>
  <div class="container-list">
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="table.search"
          class="mx-4"
          :label="$t('ListLiberation.label.placeholder.tableSearch')"
        />
        <div>
          <v-icon
            class="container-list__refresh mx-8"
            :class="{'container-list__refresh--loading': loading}"
            @click="getList"
          >
            mdi-refresh
          </v-icon>
        </div>
      </v-card-title>

      <DsSnackbar
        v-if="httpShowResponseSnackbar"
        :type="httpResponseSnackbarType"
        :show="httpShowResponseSnackbar"
        :text="httpShowResponseSnackbarText"
        @close="httpShowResponseSnackbar = !httpShowResponseSnackbar"
      />

      <v-data-table
        :page="page"
        :page-count="totalPages"
        :headers="table.headers"
        :items="table.items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :search="table.search"
        :custom-filter="searchTable"
        class="elevation-1"
      >
        <template v-slot:item.isAvailable="{ item }">
          <v-chip
            outlined
            filter
            :color="enabledChipStatus(item.isAvailable).color"
          >
            {{ enabledChipStatus(item.isAvailable).text }}
          </v-chip>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
        </template>

        <template v-slot:top>
          <v-dialog
            v-model="dialog"
            max-width="450px"
          >
            <v-card>
              <v-card-title class="dialog-title">
                {{ $t('ListLiberation.label.placeholder.editProduct') }}
              </v-card-title>
              <v-alert
                v-if="error"
                colored-border
                type="error"
                dense
                outlined
                elevation="2"
              >
                {{ $t('HttpResponses.label.message.error.requestRepeat') }}
              </v-alert>
              <div class="product-edit-line">
                <v-chip>
                  {{ $t('ListLiberation.label.placeholder.currentValue') }}
                </v-chip>
                <v-chip>
                  {{ $t('ListLiberation.label.placeholder.newValue') }}
                </v-chip>
              </div>
              <div class="product-edit-line">
                <span>{{ models.itemOfList && models.itemOfList.expirationDate }}</span>
                <div class="line-block">
                  <DsTooltip
                    position="top"
                    :content="$t('ListLiberation.label.info.newExpirationDate')"
                  >
                    <v-icon
                      medium
                      class="mr-2"
                    >
                      mdi-information
                    </v-icon>
                  </DsTooltip>
                  <span>
                    <StOneDatePicker
                      v-model="models.newExpirationDate"
                      :label="$t('Liberation.label.placeholder.expirationDate')"
                      :max-date="fiveYearsDate"
                      @dateToSubmit="setNewEndDate"
                      @input="setErrorFalse"
                      @change="isAtLeastOneFieldChanged"
                    />
                  </span>
                </div>
              </div>
              <div class="product-edit-line">
                <span>{{ models.itemOfList && getOnlyCreditValueName(models.itemOfList.productName) }}</span>
                <div class="line-block">
                  <DsTooltip
                    position="top"
                    :content="$t('ListLiberation.label.info.newCreditValue')"
                  >
                    <v-icon
                      medium
                      class="mr-2"
                    >
                      mdi-information
                    </v-icon>
                  </DsTooltip>
                  <span>
                    <v-select
                      v-model="models.newCreditValue"
                      required
                      :item-value="models.itemOfList && models.itemOfList.creditValue"
                      :items="models.itemOfList && CREDITS_TYPE[getCompleteCreditTypeName(CREDITS_TYPE, getOnlyCreditValueName(models.itemOfList.productName))]"
                      :label="$t('Liberation.label.placeholder.creditValue')"
                      @input="setErrorFalse"
                      @change="isAtLeastOneFieldChanged"
                    />
                  </span>
                </div>
              </div>

              <v-card-actions>
                <v-btn
                  class="btn-close"
                  outlined
                  @click="closeModal"
                >
                  {{ $t('Forms.label.button.cancel') }}
                </v-btn>
                <v-btn
                  color="primary"
                  :disabled="isAtLeastOneFieldChanged()"
                  @click.stop="updateItem()"
                >
                  {{ $t('Forms.label.button.submit') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import SeCreditsSystemService from '@/services/SeCreditsSystemService';
import ITService from '@/services/ITService';
import StOneDatePicker from '@/components/StOneDatePicker';
import dateComponents from '@/utils/date-utils';
import { HTTP_RESPONSES } from '@/utils/constants';
import { CREDITS_TYPE } from '@/components/liberation/components/constants';
import { DsSnackbar, DsTooltip } from '@ds/components';

export default {
  name: 'ListLiberation',
  components: { StOneDatePicker, DsSnackbar, DsTooltip },
  data() {
    return {
      CREDITS_TYPE,
      httpResponseSnackbarType: '',
      httpShowResponseSnackbar: false,
      httpShowResponseSnackbarText: '',
      table: {
        headers: [
          {
            text: this.$t('ListLiberation.label.placeholder.opportunityId'),
            value: 'opportunityId',
          },
          {
            text: this.$t('ListLiberation.label.placeholder.quotationId'),
            value: 'quotationId',
          },
          {
            text: this.$t('ListLiberation.label.placeholder.userGroup'),
            value: 'userGroup',
          },
          {
            text: this.$t('ListLiberation.label.placeholder.creditType'),
            value: 'productModule',
          },
          {
            text: this.$t('ListLiberation.label.placeholder.panel'),
            value: 'productName',
          },
          {
            text: this.$t('ListLiberation.label.placeholder.isCreditAvailable'),
            value: 'isAvailable',
            align: 'center'
          },
          {
            text: this.$t('ListLiberation.label.placeholder.creationDate'),
            value: 'creationDate',
            align: 'center'
          },
          {
            text: this.$t('ListLiberation.label.placeholder.expirationDate'),
            value: 'expirationDate',
            align: 'center'
          },
          {
            text: this.$t('ListLiberation.label.placeholder.creditEdition'),
            value: 'action',
            align: 'center'
          },
        ],
        search: '',
        items: [],
      },
      models: {
        itemOfList: null,
        creditValue: null,
        expirationDate: null,
        newCreditValue: null,
        newExpirationDate: null,
        date: null,
      },
      page: 1,
      totalItems: 0,
      totalPages: 0,
      itemsPerPage: 10,
      error: false,
      success: false,
      warning: false,
      loading: false,
      loadingTable: true,
      confirmation: false,
      dialog: false,
      paginationParams: {
        page: 1,
      },
      options: {},
    };
  },
  computed: {
    todayDate() {
      return dateComponents.formatDate(new Date(), 'YYYY-MM-DD');
    },
    fiveYearsDate() {
      const YEARS_AHEAD = 3;
      const currentYearArr = this.todayDate.split('-');
      const fiveYearsAhead = Number(currentYearArr[0]) + YEARS_AHEAD;
      currentYearArr[0] = fiveYearsAhead.toString();
      return currentYearArr.join('-');
    },
    pageCount() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    searchTable() {
      this.table.search === '' ? this.getList() : this.getSortedFilteredList();
      return null;
    },
  },
  watch: {
    options: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    customFilter() {
      this.table.search === '' ? this.getList() : this.getSortedFilteredList();
    },
    setErrorFalse() {
      this.error = false;
    },
    setNewEndDate(date) {
      this.models.newExpirationDate = date;
    },
    closeModal() {
      this.dialog = false;
    },
    editItem(item) {
      this.setErrorFalse();
      this.confirmation = false;
      this.models.itemOfList = item;
      this.models.creditValue = this.models.itemOfList.creditValue;
      this.models.newCreditValue = this.models.itemOfList.creditValue;
      const expirationDate = this.models.itemOfList && this.models.itemOfList.expirationDate;
      this.setNewEndDate(this.convertToIsoDate(expirationDate));
      this.dialog = true;
    },
    convertToLocalDate(date) {
      const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
      return new Date(date).toLocaleString('pt-BR', options);
    },
    convertFromIsoToLocalDate(date) {
      const instantiatedDate = new Date(date);
      const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
      return instantiatedDate.toLocaleString('pt-BR', options);
    },
    convertToIsoDate(date) {
      const dateComponents = date.split('/');
      const dateObj = new Date(dateComponents[2], dateComponents[1] - 1, dateComponents[0]);
      const isoDate = dateObj.toISOString().split('T')[0];
      return isoDate;
    },
    convertFromLocaleStringPtBrToMillisecondsDate(date) {
      const dateConverted = new Date(this.convertToIsoDate(date));
      return dateConverted.getTime();
    },
    convertFromIsoToMillisecondsDate(date) {
      const dateConverted = new Date(date);
      return dateConverted.getTime();
    },
    showSnackbar(typeOfSnackbarParam, textToShowIntoSnackbarParam) {
      this.models.isItToShowSnackbar = false;
      this.models.typeOfSnackbar = typeOfSnackbarParam;
      this.models.textToShowIntoSnackbar = textToShowIntoSnackbarParam;
      this.models.isItToShowSnackbar = !this.models.isItToShowSnackbar;
    },
    wasCreditValueChanged() {
      const oldCreditValue = this.models.itemOfList && this.getOnlyCreditValueName(this.models.itemOfList.productName);
      const newCreditValue = this.models.newCreditValue;
      let wasCreditValueChanged = true;
      if (oldCreditValue === newCreditValue || newCreditValue === null || newCreditValue === undefined) {
        wasCreditValueChanged = false;
      }
      return wasCreditValueChanged;
    },
    wasExpirationDateChanged() {
      const oldExpirationDate = this.models.itemOfList && this.models.itemOfList.expirationDate;
      const d = new Date(this.models.newExpirationDate);
      d.setDate(d.getDate() + 1);
      const newExpirationDate = this.convertToLocalDate(d);
      const wasExpirationDateChanged = oldExpirationDate !== newExpirationDate;
      if (wasExpirationDateChanged) {
        return true;
      }
      return false;
    },
    isAtLeastOneFieldChanged() {
      let isAtLeastOneFieldFilled = true;
      if (this.wasCreditValueChanged() || this.wasExpirationDateChanged()) {
        isAtLeastOneFieldFilled = false;
      }
      return isAtLeastOneFieldFilled;
    },
    getCompleteCreditTypeName(object, value) {
      let result = '';
      const arrWithOnlyKeys = Object.keys(object);
      result = arrWithOnlyKeys.find(key => object[key].find(k => k === value) === value);
      if (result === undefined) {
        const foundIndex = this.getNameOfCreditType(arrWithOnlyKeys).indexOf(value);
        return arrWithOnlyKeys[foundIndex];
      }
      return result;
    },
    doesItHaveSpecialCharacters(word) {
      return !/^\d+$/u.test(word);
    },
    getOnlyCreditValueName(str) {
      const specialCharacters = /[^\w\s]|_/ug;
      const oneOrMoreWhitespaces = /\s+/u;
      const trimmedStr = str.trim().replace(specialCharacters, '');
      const words = trimmedStr.split(oneOrMoreWhitespaces);
      for (let i = words.length - 1; i >= 0; i--) {
        const word = words[i];
        if (this.doesItHaveSpecialCharacters(word) && word.length > 1) {
          return word;
        }
      }
      return '';
    },
    getNameOfCreditType(strings) {
      return strings.map(string => {
        const words = string.split(' ');
        if (words.length === 1) {
          return string;
        } else if (words.length === 2) {
          return words[1];
        }
        const middleIndex = Math.floor(words.length / 2);
        const middleWord = words[middleIndex];
        if (middleWord.length === 1) {
          return words[middleIndex - 1];
        }
        return middleWord;
      });
    },
    paramsToUpdateCredit() {
      const parameters = {
        id: this.models.itemOfList.id,
        ...(this.wasExpirationDateChanged() && { expirationDate: this.models.newExpirationDate }),
        ...(this.wasCreditValueChanged() && { productName: this.models.newCreditValue }),
      };
      return parameters;
    },
    paramsToUpdateByCreditsSystem() {
      const parameters = {
        id: this.models.itemOfList.id,
        quotationItemId: this.models.itemOfList.quotationItemId,
        creationDate: this.convertFromLocaleStringPtBrToMillisecondsDate(this.models.itemOfList.creationDate),
        expirationDate: this.wasExpirationDateChanged() ? this.convertFromIsoToMillisecondsDate(this.models.newExpirationDate) : this.convertFromLocaleStringPtBrToMillisecondsDate(this.models.itemOfList.expirationDate),
        productId: this.models.itemOfList.productId,
        productModule: this.models.itemOfList.productModule,
        productName: this.wasCreditValueChanged() ? `${this.models.itemOfList.productModule} ${this.models.newCreditValue}` : `${this.models.itemOfList.productModule} ${this.models.itemOfList.productName}`,
        userGroup: this.models.itemOfList.userGroup,
        quotationId: this.models.itemOfList.quotationId,
        isAvailable: this.models.itemOfList.isAvailable,
        limitMonth: this.models.itemOfList.limitMonth,
        limitTotal: this.models.itemOfList.limitTotal,
        isDeleted: this.models.itemOfList.isDeleted,
        opportunityId: this.models.itemOfList.opportunityId,
      };
      return parameters;
    },
    updateItem() {
      this.error = false;
      this.loading = true;
      const parameters = this.paramsToUpdateByCreditsSystem();
      ITService.creditsUpdate(parameters).then(response => {
        this.showVAlert(response);
        if (response.status === 200) {
          this.closeModal();
          this.getList();
        }
      }).catch(err => {
        this.error = true;
        this.loading = false;
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    getList() {
      this.error = false;
      this.loading = true;

      const { itemsPerPage, page } = this.options;
      const pageNumber = page - 1;

      const urlParams = { size: itemsPerPage, page: pageNumber };
      SeCreditsSystemService.listCredits(urlParams).then(response => {
        this.setTableItems(response.content);
        this.totalItems = response.totalElements;
        this.totalPages = response.totalPages;
      }).catch(err => {
        this.error = true;
        this.loading = false;
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    async getSortedFilteredList() {
      this.error = false;
      this.loading = true;

      const { itemsPerPage, page } = this.options;
      const pageNumber = page - 1;

      const urlParams = { size: itemsPerPage, page: pageNumber, quotationId: this.table.search };

      SeCreditsSystemService.listCreditsByQuotationId(urlParams).then(response => {
        this.setTableItems(response.content);
        this.totalItems = response.totalElements;
        this.totalPages = response.totalPages;
      }).catch(err => {
        this.error = true;
        this.loading = false;
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    setTableItems(array) {
      const arrSorted = array.sort((a, b) => b.creationDate - a.creationDate);
      this.table.items = arrSorted.map(item => {
        item.productModule = this.getCompleteCreditTypeName(CREDITS_TYPE, this.getOnlyCreditValueName(item.productName));
        item.productName = this.getOnlyCreditValueName(item.productName);
        item.creationDate = this.convertToLocalDate(item.creationDate);
        item.expirationDate = this.convertToLocalDate(item.expirationDate);
        const newItem = { ...item };
        return newItem;
      });
    },
    enabledChipStatus(itemReceived) {
      if (itemReceived === true) {
        return { color: 'primary', text: this.$t('Forms.label.placeholder.yes') };
      }
      return { color: 'red', text: this.$t('Forms.label.placeholder.no') };
    },
    showVAlert(res) {
      const respStOk = () => {
        this.httpResponseSnackbarType = 'success';
        this.httpShowResponseSnackbar = true;
        this.httpShowResponseSnackbarText = this.$t('HttpResponses.label.message.success.ok');
      };
      const respStBadRequest = () => {
        this.httpResponseSnackbarType = 'error';
        this.httpShowResponseSnackbar = true;
        this.httpShowResponseSnackbarText = this.$t('HttpResponses.label.message.error.badRequest');
      };
      const respStInternalServerError = () => {
        this.httpResponseSnackbarType = 'error';
        this.httpShowResponseSnackbar = true;
        this.httpShowResponseSnackbarText = this.$t('HttpResponses.label.message.error.internalServerError');
      };
      const respStErr = () => {
        this.httpResponseSnackbarType = 'warning';
        this.httpShowResponseSnackbar = true;
        this.httpShowResponseSnackbarText = this.$t('HttpResponses.label.message.error.requestRepeat');
      };
      const cases = {
        [HTTP_RESPONSES.STATUS_200]: respStOk,
        [HTTP_RESPONSES.STATUS_400]: respStBadRequest,
        [HTTP_RESPONSES.STATUS_500]: respStInternalServerError,
      };
      const switchFn = (lookupObject, defaultCase = '_default') =>
        expression => (lookupObject[expression] || lookupObject[defaultCase])();
      const caseSwitch = switchFn(cases, respStErr);
      caseSwitch(res.status);
    },
  },
};

</script>

<style scoped lang="scss">

.container-list {
  .container-list__refresh {
    cursor: pointer;

    &--loading {
      color: #00aaa7;
      -webkit-animation: spin 1s linear infinite;
      -moz-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
    }
  }

  .btn-close {
    width: 115px;
    display: flex;
    justify-content: center;
  }
}

.product-edit-line {
  padding: 16px 24px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .line-block {
    display: flex;
    align-items: center;
  }
}

@-moz-keyframes spin-moz {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-web {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>
