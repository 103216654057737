var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "section",
        { staticClass: "selector-container" },
        [
          _c("SingleSelect", {
            attrs: {
              items: _vm.accounts,
              placeholder: "Selecione uma conta",
              text: "name",
              "sub-text": "group",
            },
            model: {
              value: _vm.selectedAccount,
              callback: function ($$v) {
                _vm.selectedAccount = $$v
              },
              expression: "selectedAccount",
            },
          }),
          _vm.activeAccount
            ? _c("SingleSelect", {
                attrs: {
                  items: _vm.universes,
                  placeholder: "Selecione uma Pesquisa",
                  text: "name",
                  "sub-text": "channel",
                },
                model: {
                  value: _vm.selectedUniverse,
                  callback: function ($$v) {
                    _vm.selectedUniverse = $$v
                  },
                  expression: "selectedUniverse",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "table-container" },
        [
          _c("StLoader", { attrs: { loading: _vm.loadingHistory } }),
          _vm.historyIsLoaded && !_vm.loadingHistory
            ? _c(
                "v-expansion-panels",
                { attrs: { accordion: "", dark: "" } },
                _vm._l(_vm.history, function (item, i) {
                  return _c(
                    "v-expansion-panel",
                    { key: i },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v(
                          "\n          Editado em: " +
                            _vm._s(item.created_at) +
                            " - Editado por: " +
                            _vm._s(item.author) +
                            "\n        "
                        ),
                      ]),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          "\n          " + _vm._s(item.changes) + "\n        "
                        ),
                      ]),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.historyError
            ? _c("section", { staticClass: "failure-notification" }, [
                _c(
                  "span",
                  { on: { click: _vm.getUniverseHistory } },
                  [_c("v-icon", [_vm._v("mdi-reload")]), _vm._v(" Recarregar")],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }