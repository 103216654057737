var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-body" }, [
    !_vm.isConfigurationSubmited
      ? _c(
          "div",
          [
            _c("PageHeader", {
              attrs: {
                title: _vm.labels.title,
                description: _vm.labels.description,
                breadcrumbs: _vm.labels.breadcrumbs,
              },
            }),
            _c(
              "v-card",
              [
                _c("v-card-title", { staticClass: "font-weight-bold pb-6" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.labels.tutorial.stepOne.title) +
                      "\n      "
                  ),
                ]),
                _c("v-card-subtitle", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.labels.tutorial.stepOne.description) +
                      "\n      "
                  ),
                ]),
                _c("v-card-text", [
                  _c(
                    "ul",
                    { staticClass: "font-weight-bold" },
                    _vm._l(_vm.documents, function (document, index) {
                      return _c("li", { key: index }, [
                        _c(
                          "a",
                          { attrs: { href: document.link, target: "_blank" } },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(document.text) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                  _c("div", { staticClass: "mt-4" }, [
                    _c("strong", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.labels.tutorial.stepOne.important) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.labels.tutorial.stepOne.reminder) +
                        "\n        "
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _c("StLoader", { attrs: { loading: _vm.loading } }),
            _c(
              "v-card",
              [
                _c("v-card-title", { staticClass: "font-weight-bold pb-6" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.labels.tutorial.stepTwo.title) +
                      "\n      "
                  ),
                ]),
                _c("v-card-subtitle", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.labels.tutorial.stepTwo.description) +
                      "\n      "
                  ),
                ]),
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-form",
                      {
                        ref: "newsso",
                        attrs: { "lazy-validation": false },
                        model: {
                          value: _vm.models.valid,
                          callback: function ($$v) {
                            _vm.$set(_vm.models, "valid", $$v)
                          },
                          expression: "models.valid",
                        },
                      },
                      [
                        _c("SingleSelect", {
                          attrs: {
                            items: _vm.usergroups,
                            placeholder:
                              _vm.labels.placeholders.selectUsergroup,
                            text: "userGroupName",
                          },
                          model: {
                            value: _vm.selectedUsergroup,
                            callback: function ($$v) {
                              _vm.selectedUsergroup = $$v
                            },
                            expression: "selectedUsergroup",
                          },
                        }),
                        !_vm.groupHasSso
                          ? [
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.rules.isNotEmpty,
                                  placeholder: _vm.labels.placeholders.idp,
                                  required: "",
                                },
                                model: {
                                  value: _vm.models.idpIssuer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.models, "idpIssuer", $$v)
                                  },
                                  expression: "models.idpIssuer",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.rules.isLink,
                                  placeholder: _vm.labels.placeholders.idpUrl,
                                  required: "",
                                },
                                model: {
                                  value: _vm.models.idpUrl,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.models, "idpUrl", $$v)
                                  },
                                  expression: "models.idpUrl",
                                },
                              }),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.groupHasSso && _vm.selectedUsergroup
              ? _c(
                  "v-alert",
                  {
                    attrs: {
                      border: "right",
                      "colored-border": "",
                      type: "error",
                      color: "warning",
                    },
                  },
                  [
                    _vm._v(
                      "\n      Cliente já possui SSO configurado. Para verificar as informações acesse a página "
                    ),
                    _c("a", { on: { click: _vm.goToStatusSso } }, [
                      _vm._v("Status"),
                    ]),
                    _vm._v(".\n    "),
                  ]
                )
              : _vm._e(),
            _c(
              "DsButton",
              {
                attrs: {
                  disabled:
                    !_vm.models.valid ||
                    _vm.loading ||
                    _vm.groupHasSso ||
                    !_vm.selectedUsergroup,
                  "data-color-theme": "dark",
                },
                on: { click: _vm.submitSso },
              },
              [_vm._v("\n      Finalizar configuração\n    ")]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.isConfigurationSubmited
      ? _c("section", [
          _vm.error
            ? _c(
                "div",
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "pa-7",
                      attrs: {
                        border: "right",
                        "colored-border": "",
                        color: "red darken-2",
                        icon: "mdi-cancel",
                      },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "pb-3 d-flex align-center",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "v-col",
                            { staticClass: "px-3", attrs: { cols: "11" } },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(
                                  " Configuração da conta " +
                                    _vm._s(
                                      _vm.selectedUsergroup.userGroupName
                                    ) +
                                    " não foi concluída! "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("span", [
                        _vm._v(
                          "\n          Não conseguimos concluir a operação. Verifique se os dados inseridos estão corretos ou se houve algum problema na criação da aplicação na ferramenta de gestão do cliente. Caso o problema persista, acione o time de Onboarding e Setup.\n        "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "DsButton",
                    {
                      attrs: { color: "secondary", "data-color-theme": "dark" },
                      on: { click: _vm.redirectToConfiguration },
                    },
                    [_vm._v("\n        Voltar para configuração\n      ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.success
            ? _c(
                "div",
                [
                  true
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "pa-7",
                          attrs: {
                            border: "right",
                            "colored-border": "",
                            color: "success",
                            icon: "mdi-check",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "pb-3 d-flex align-center",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-col",
                                { staticClass: "px-3", attrs: { cols: "11" } },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [
                                      _vm._v(
                                        " Configuração da conta " +
                                          _vm._s(
                                            _vm.selectedUsergroup.userGroupName
                                          ) +
                                          " concluída! "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("span", [
                            _vm._v(
                              "\n          Acompanhe o andamento da solicitação e altere as configurações necessárias na página de "
                            ),
                            _c("a", { on: { click: _vm.goToStatusSso } }, [
                              _vm._v(" Status"),
                            ]),
                            _vm._v(
                              ". É importante lembrar que o cliente deve cadastrar seus usuários na ferramenta de gestão e também na STILINGUE para o processo funcionar corretamente.\n        "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "DsButton",
                    {
                      attrs: { "data-color-theme": "dark" },
                      on: { click: _vm.newConfiguration },
                    },
                    [_vm._v("\n        Nova configuração\n      ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }