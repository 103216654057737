<template>
  <section v-show="true">
    <v-data-table
      :headers="headers"
      :items="prepareData(routings)"
      :items-per-page="15"
      :custom-sort="customSort"
      @click:row="handleClick"
    />
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'RouterList',
  data() {
    return {
      headers: [
        { text: 'Nome do Roteamento', value: 'name' },
        { text: 'Nº de Usuários', value: 'amount' },
        { text: 'Última atualização', value: 'updated_at_formatted' },
      ],
    };
  },
  computed: {
    ...mapState('account', ['activeAccount']),
    ...mapState('routing', ['routings']),
    ...mapState('universe', ['activeUniverse']),
    selectedAccount() {
      return this.activeAccount;
    },
    selectedUniverse() {
      return this.activeUniverse;
    },
  },
  created() {
    if (this.activeUniverse) {
      this.changeBreadcrumbs({
        text: `Pesquisa ${this.activeUniverse.name}`,
        to: '/routing/list',
        index: 3
      });
    }
  },
  methods: {
    ...mapActions('routing', ['getRoutingList', 'getRouterSettings', 'changeBreadcrumbs']),
    async handleClick(routing) {
      await this.getRouterSettings({
        selectedAccount: this.selectedAccount,
        selectedUniverse: this.selectedUniverse,
        routerId: routing.id
      });

      this.$router.push('/routing/user-list');
    },
    prepareData(data) {
      return data.map(routing => {
        return {
          name: routing.name,
          amount: routing.operators.length,
          id: routing.id,
          updated_at_formatted: new Date(routing.updated_at).toLocaleDateString('pt-br'),
          updated_at: new Date(routing.updated_at)
        };
      });
    },
    customSort(items, index, isDesc) {
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line complexity
      items.sort((a, b) => {
        if (index[0] === 'updated_at_formatted') {
          if (!isDesc[0]) {
            return a.updated_at - b.updated_at;
          }
          return b.updated_at - a.updated_at;
        } else if (!isDesc[0]) {
          return a[index] < b[index] ? -1 : 1;
        }
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    }
  },
};
</script>
