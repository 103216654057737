var render = function render(_c, _vm) {
  return _c(
    "a",
    { staticClass: "logo-container", attrs: { href: _vm.props.link } },
    [
      _c("svg", { attrs: { viewBox: "0 0 117.071 27.311" } }, [
        _c("defs"),
        _c("path", {
          attrs: {
            fill: "#fff",
            "fill-rule": "evenodd",
            d: "M16.738 4.492a2.276 2.276 0 001.7-2.692A2.2 2.2 0 0015.831.046a2.249 2.249 0 00-1.739 2.016h-9.7a2.466 2.466 0 00-.036-.259A2.2 2.2 0 001.749.049a2.274 2.274 0 00-1.7 2.692 2.228 2.228 0 001.945 1.8V9.73a1.414 1.414 0 00-.246.033 2.274 2.274 0 00-1.7 2.692 2.206 2.206 0 002.607 1.757 2.419 2.419 0 00.252-.072l4.515 9.628a2.324 2.324 0 00.306 2.921 2.153 2.153 0 003.108-.084 2.327 2.327 0 00.225-2.84l4.519-9.635a2.117 2.117 0 001.157.072 2.277 2.277 0 001.7-2.7 2.224 2.224 0 00-1.945-1.793V4.531c.083-.009.166-.021.246-.039zm-2.6-1.757a2.354 2.354 0 00.228.632l-3.408 2.334c-.018-.024-.033-.045-.053-.069a2.154 2.154 0 00-3.1-.22 2.442 2.442 0 00-.267.289L4.122 3.37a2.285 2.285 0 00.267-.887h9.7c.015.081.027.168.045.255zm-.038 9.033H9.451V9.387a2.157 2.157 0 001.24-.548 2.434 2.434 0 00.267-.289l3.407 2.334a2.271 2.271 0 00-.27.888zm-9.706 0a2.638 2.638 0 00-.036-.262 2.325 2.325 0 00-.225-.629l3.4-2.331c.018.024.033.045.053.069a2.174 2.174 0 001.453.767v2.39zm-1.74-7.276a2.182 2.182 0 001.24-.776l3.413 2.337a2.337 2.337 0 000 2.148L3.9 10.535a2.188 2.188 0 00-1.489-.818V4.531c.08-.009.16-.021.243-.039zm1.738 7.7h4.644v2.274a2.148 2.148 0 00-1.388.695 2.352 2.352 0 00-.172.216l-3.351-2.3a2.367 2.367 0 00.267-.885zm6.364 2.888a2.169 2.169 0 00-1.3-.614v-2.274h4.647a2.576 2.576 0 00.036.259 2.308 2.308 0 00.216.608l-3.354 2.3a2.636 2.636 0 00-.245-.278zm5.077-5.321a2.178 2.178 0 00-1.243.779l-3.41-2.337a2.346 2.346 0 000-2.148l3.41-2.337a2.185 2.185 0 001.489.815V9.72a2.194 2.194 0 00-.246.039zm-11.939 3.67l3.369 2.307a2.323 2.323 0 00.466 2.638 2.148 2.148 0 001.308.611v3.793a2.161 2.161 0 00-1.352.659l-4.436-9.458a2.129 2.129 0 00.645-.55zm6.907 10.014c-.015-.015-.03-.033-.044-.048a2.169 2.169 0 00-1.3-.614v-3.8a2.149 2.149 0 001.388-.695 2.323 2.323 0 00.371-2.578l3.366-2.307a2.207 2.207 0 00.664.572z",
            "data-name": "Caminho 3644",
          },
        }),
        _c("path", {
          attrs: {
            fill: "#fff",
            d: "M68.688 8.292h-2.375v11.809h2.375z",
            "data-name": "Caminho 3645",
          },
        }),
        _c("path", {
          attrs: {
            fill: "#fff",
            d: "M54.009 8.286h-2.37v11.806h2.369z",
            "data-name": "Caminho 3646",
          },
        }),
        _c("path", {
          attrs: {
            fill: "#fff",
            "fill-rule": "evenodd",
            d: "M58.392 8.286h-2.337v11.819h8.336v-2.133h-6z",
            "data-name": "Caminho 3647",
          },
        }),
        _c("path", {
          attrs: {
            fill: "#fff",
            "fill-rule": "evenodd",
            d: "M39.643 10.433h3.779v9.677h2.405v-9.692h3.763V8.292h-9.95z",
            "data-name": "Caminho 3648",
          },
        }),
        _c("path", {
          attrs: {
            fill: "#fff",
            "fill-rule": "evenodd",
            d: "M36.186 13.739a14.364 14.364 0 00-1.684-.6c-.738-.247-1.5-.439-2.218-.737a1.29 1.29 0 01-.611-.7 1.035 1.035 0 01.691-1.26 4.128 4.128 0 012.236-.256c.9.12 1.8.3 2.752.463.077-.557.175-1.17.24-1.79.009-.069-.139-.208-.231-.229a21.955 21.955 0 00-2.349-.508 7.174 7.174 0 00-3.47.325 3.288 3.288 0 00-2.206 3.122 2.907 2.907 0 001.969 2.909c.7.295 1.435.478 2.15.725a13.161 13.161 0 011.794.683 1.05 1.05 0 01.288 1.636 1.777 1.777 0 01-1.213.671 7.348 7.348 0 01-4.416-.725l-.347-.154c-.1.719-.2 1.384-.3 2.052a1.062 1.062 0 00.086.063 8.337 8.337 0 006.032.614 3.4 3.4 0 002.452-2.187 3.091 3.091 0 00-1.645-4.117z",
            "data-name": "Caminho 3649",
          },
        }),
        _c("path", {
          attrs: {
            fill: "#fff",
            "fill-rule": "evenodd",
            d: "M93.422 14.242c0-.117-.009-.232-.015-.367h-2.073v.4c0 1.1-.015 2.208.009 3.312.006.31-.107.412-.368.463a8.063 8.063 0 01-2.779.069 2.544 2.544 0 01-2.245-2.052 8.521 8.521 0 010-3.7 2.471 2.471 0 011.854-1.988 7.5 7.5 0 011.53-.217 9.768 9.768 0 013.612.559c.08-.635.148-1.224.226-1.811.026-.2-.021-.292-.223-.352a10.887 10.887 0 00-5.815-.244 4.561 4.561 0 00-3.53 3.278 9.415 9.415 0 00-.14 4.744 4.586 4.586 0 002.064 3.1 6.239 6.239 0 002.74.848 11.479 11.479 0 004.828-.508.42.42 0 00.33-.478c-.005-1.684-.005-3.372-.005-5.056z",
            "data-name": "Caminho 3650",
          },
        }),
        _c("path", {
          attrs: {
            fill: "#fff",
            "fill-rule": "evenodd",
            d: "M106.087 8.286h-2.373v.475c-.005 2.373 0 4.75-.026 7.123a2.07 2.07 0 01-1.768 2.166 7.133 7.133 0 01-1.877.075 2.219 2.219 0 01-2.156-2.379c-.027-2.337-.015-4.678-.018-7.015v-.439h-2.39v.695c.021 2.458-.03 4.915.089 7.367a3.766 3.766 0 002.547 3.55 7.786 7.786 0 005.042.126 3.67 3.67 0 002.69-2.816 9.475 9.475 0 00.231-2c.027-2.172.009-4.344.009-6.516v-.409z",
            "data-name": "Caminho 3651",
          },
        }),
        _c("path", {
          attrs: {
            fill: "#fff",
            "fill-rule": "evenodd",
            d: "M110.503 18.035v-3.1h5.338V13.06h-5.327v-2.708h6.276v-2.06h-8.654v11.815h8.935v-2.072z",
            "data-name": "Caminho 3652",
          },
        }),
        _c("path", {
          attrs: {
            fill: "#fff",
            "fill-rule": "evenodd",
            d: "M79.1 16.215c-.112-.126-.192-.2-.264-.295Q75.914 12.252 73 8.577a.7.7 0 00-.632-.319c-.546.021-1.091.006-1.634.006v11.828h2.12v-8.218a.841.841 0 01.1.075q3.248 3.993 6.491 7.99c.06.075.152.171.235.174.5.015 1 .009 1.506.009V8.292h-2.085v7.923z",
            "data-name": "Caminho 3653",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }