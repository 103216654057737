export const CREDITS_TYPE = {
  'SLA': ['Ultra', 'Elite', 'Light'],
  'Armazenamento': ['A1', 'A12', 'A120k', 'A14', 'A15', 'A18', 'A2', 'A20', 'A24', 'A240k', 'A3', 'A36', 'A360k', 'A4', 'A6', 'A7', 'A9', 'M10', 'M20', 'M30', 'M50', 'M75'],
  'URL Métricas Proprietárias': ['Live', 'Regular'],
  'URL Atendimento': ['Community', 'Pro'],
};

export const PRODUCT_MODULE = {
  'SLA': 'Listening',
  'Armazenamento': 'Base (Capacidade)',
  'URL Métricas Proprietárias': 'Métricas Proprietárias',
  'URL Atendimento': 'Atendimento',
};

export const PRODUCT_ID = {
  'Ultra': '01t0a000004VPEHAA4',
  'Elite': '01t0a000004VPEIAA4',
  'Light': '01t0a000004VPEGAA4',
  'A1': '01t0a000004VPEAAA4',
  'A12': '01t0a000004VPEDAA4',
  'A12Ok': '01t0a000004VPE6AAO',
  'A14': '01t0a000005NBrrAAG',
  'A15': '01t0a000005NEMBAA4',
  'A18': '01t0a000005NEHoAAO',
  'A2': '01t5b000005fECQAA2',
  'A20': '01t0a000005NEI3AAO',
  'A24': '01t0a000004VPEEAA4',
  'A240k': '01t0a000004VPE7AAO',
  'A3': '01t0a000004VPEBAA4',
  'A36': '01t0a000004VPEFAA4',
  'A360k': '01t0a000004VPE8AAO',
  'A4': '01t5b000006ExskAAC',
  'A6': '01t0a000004VPECAA4',
  'A7': '01t0a000005NBrmAAG',
  'A9': '01t0a000005Mw8oAAC',
  'M10': '01t0a000004VPE1AAO',
  'M20': '01t0a000004VPE2AAO',
  'M30': '01t0a000004VPE3AAO',
  'M50': '01t0a000004VPE4AAO',
  'M75': '01t0a000004VPE5AAO',
  'Live': '01t0a000004VPELAA4',
  'Regular': '01t0a000004VPEKAA4',
  'Community': '01t0a000004VPEMAA4',
  'Pro': '01t0a000004VPENAA4',
};

export const STORAGE_LIMIT = {
  'A1': 1000000,
  'A12': 12000000,
  'A14': 14000000,
  'A120k': 120000,
  'A14': 14000000,
  'A15': 15000000,
  'A18': 18000000,
  'A2': 2000000,
  'A20': 20000000,
  'A24': 24000000,
  'A240k': 240000,
  'A3': 3000000,
  'A360k': 360000,
  'A4': 4000000,
  'A6': 6000000,
  'A7': 7000000,
  'A9': 9000000,
  'M10': 30000,
  'M20': 20000,
  'M30': 30000,
  'M50': 50000,
  'M75': 75000,
};

export const ACTIVATION_TYPE_LIST = [
  { id: 0, name: 'Novo contrato p/ novo cliente', value: 'New Agreement', isNew: true },
  { id: 1, name: 'Novo contrato p/ cliente existente', value: 'IB - New Agreement', isNew: true },
  { id: 2, name: 'Novos painéis ou upgrade de um painel dentro de um contrato', value: 'IB - Add On', isNew: false },
  { id: 3, name: 'Extensão de um contrato que já está para vencer', value: 'IB - Extension', isNew: false },
  { id: 4, name: 'Renovação do contrato', value: 'IB - Renewal', isNew: false },
  { id: 5, name: 'Contrato não anual/pontual para um cliente novo, com duração de 1 mês', value: 'IB - Adhoc (On/Off)', isNew: true },
  { id: 6, name: 'Contrato não anual/pontual para um cliente já existente, com duração de 1 mês', value: 'IB - Adhoc (On/Off)', isNew: false },
  { id: 7, name: 'Contrato não anual/pontual para um cliente já existente, com duração de 2 até 11 meses', value: 'IB - Overage', isNew: false },
];
