var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panels",
    [
      _c(
        "v-expansion-panel",
        [
          _c("v-expansion-panel-header", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ open }) {
                  return [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c("v-col", { attrs: { cols: "4" } }, [
                          _vm._v("\n            Grupo\n          "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "v-expansion-panel-content",
            [
              _c("SingleSelect", {
                attrs: {
                  items: _vm.usergroups,
                  required: "",
                  text: "name",
                  placeholder: "Selecione",
                  "sub-text": "group",
                },
                model: {
                  value: _vm.selectedUsergroup,
                  callback: function ($$v) {
                    _vm.selectedUsergroup = $$v
                  },
                  expression: "selectedUsergroup",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-expansion-panel",
        [
          _c("v-expansion-panel-header", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ open }) {
                  return [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c("v-col", { attrs: { cols: "4" } }, [
                          _vm._v("\n          Conta\n        "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "v-expansion-panel-content",
            [
              _c("SingleSelect", {
                attrs: {
                  items: _vm.accounts,
                  required: "",
                  text: "name",
                  placeholder: "Selecione",
                  "sub-text": "group",
                },
                model: {
                  value: _vm.selectedAccount,
                  callback: function ($$v) {
                    _vm.selectedAccount = $$v
                  },
                  expression: "selectedAccount",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-expansion-panel",
        [
          _c("v-expansion-panel-header", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ open }) {
                  return [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c("v-col", { attrs: { cols: "4" } }, [
                          _vm._v("\n          Usuário\n        "),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "v-expansion-panel-content",
            [
              _c(
                "v-row",
                { attrs: { justify: "space-around", "no-gutters": "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      required: "",
                      label: "E-mail",
                      "prepend-icon": "mdi-email",
                      rules: _vm.isEmail,
                    },
                    model: {
                      value: _vm.selectedUserEmail,
                      callback: function ($$v) {
                        _vm.selectedUserEmail = $$v
                      },
                      expression: "selectedUserEmail",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }