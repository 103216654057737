var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "v-row",
        { staticClass: "selector-container" },
        [
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c(
                "v-form",
                {
                  ref: "fb-postDetailsForm",
                  staticClass: "fb-post-details__form",
                  attrs: { "lazy-validation": false },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      required: "",
                      rules: _vm.isNumber,
                      label: "ID da página",
                    },
                    on: {
                      keydown: function ($event) {
                        _vm.post = null
                      },
                    },
                    model: {
                      value: _vm.pageId,
                      callback: function ($$v) {
                        _vm.pageId = $$v
                      },
                      expression: "pageId",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      required: "",
                      rules: _vm.isNumber,
                      label: "ID da publicação",
                    },
                    on: {
                      keydown: function ($event) {
                        _vm.post = null
                      },
                    },
                    model: {
                      value: _vm.postId,
                      callback: function ($$v) {
                        _vm.postId = $$v
                      },
                      expression: "postId",
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { disabled: !_vm.valid, color: "light-blue" },
                      on: { click: _vm.loadFbPostInfo },
                    },
                    [
                      _vm._v(
                        "\n          Visualizar informações da publicação\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "7" } },
            [
              _c("StLoader", { attrs: { loading: _vm.loading } }),
              _vm.post
                ? _c(
                    "v-card",
                    [
                      _vm.post.text
                        ? _c(
                            "v-list",
                            [
                              _c("v-subheader", [_vm._v("Conteúdo")]),
                              _c(
                                "v-list-item",
                                [
                                  _c("v-list-item-content", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.post.text) +
                                        "\n            "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider"),
                      _c(
                        "v-list",
                        {
                          staticClass: "fb-post-details__details-list",
                          attrs: { subheader: "", "two-line": "" },
                        },
                        [
                          _c("v-subheader", [_vm._v("Detalhes")]),
                          _vm._l(_vm.postDetails, function (item, index) {
                            return _c(
                              "v-list-item",
                              { key: index },
                              [
                                _c("StIcon", {
                                  attrs: { "icon-name": item.icon },
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.label) +
                                    "\n            "
                                ),
                                _c("v-chip", { attrs: { label: "" } }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.value) +
                                      "\n            "
                                  ),
                                ]),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.postNotFound
                ? _c("v-alert", { attrs: { type: "warning" } }, [
                    _vm._v("\n        Publicação inválida.\n      "),
                  ])
                : _vm._e(),
              _vm.error
                ? _c("v-alert", { attrs: { type: "error" } }, [
                    _vm._v(
                      "\n        Erro inesperado ao tentar buscar pela publicação. Por favor, entre em contato com o N2.\n      "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }