var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "v-row",
        { staticClass: "selector-container" },
        [
          _c(
            "v-col",
            { attrs: { cols: "5" } },
            [
              _c(
                "v-form",
                {
                  ref: "notCrawledForm",
                  staticClass: "not-crawled__form",
                  attrs: { "lazy-validation": false },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      required: "",
                      rules: _vm.isNumber,
                      label: "ID da conta",
                    },
                    on: { keydown: _vm.resetRequestResultAttributes },
                    model: {
                      value: _vm.accountId,
                      callback: function ($$v) {
                        _vm.accountId = $$v
                      },
                      expression: "accountId",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      required: "",
                      rules: _vm.isNumber,
                      label: "ID da pesquisa",
                    },
                    on: { keydown: _vm.resetRequestResultAttributes },
                    model: {
                      value: _vm.universeId,
                      callback: function ($$v) {
                        _vm.universeId = $$v
                      },
                      expression: "universeId",
                    },
                  }),
                  _c("v-text-field", {
                    attrs: {
                      required: "",
                      rules: _vm.isNotEmpty,
                      label: "ID da publicação",
                    },
                    on: { keydown: _vm.resetRequestResultAttributes },
                    model: {
                      value: _vm.postId,
                      callback: function ($$v) {
                        _vm.postId = $$v
                      },
                      expression: "postId",
                    },
                  }),
                  _c("v-select", {
                    attrs: { items: _vm.items, label: "Canal" },
                    on: { change: _vm.resetRequestResultAttributes },
                    model: {
                      value: _vm.channel,
                      callback: function ($$v) {
                        _vm.channel = $$v
                      },
                      expression: "channel",
                    },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { disabled: !_vm.valid, color: "light-blue" },
                      on: { click: _vm.loadPostInfo },
                    },
                    [_vm._v("\n          Verificar correspondência\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "7" } },
            [
              _vm.postIsNotInOurDataSource
                ? _c("v-alert", { attrs: { type: "warning" } }, [
                    _vm._v("\n        Publicação não encontrada.\n      "),
                  ])
                : _vm._e(),
              _vm.noTermMatchedWithUniverseSetting
                ? _c("v-alert", { attrs: { type: "info" } }, [
                    _vm._v(
                      "\n        Nenhuma correspondência com descritores da pesquisa identificada.\n      "
                    ),
                  ])
                : _vm._e(),
              _vm.error
                ? _c("v-alert", { attrs: { type: "error" } }, [
                    _vm._v(
                      "\n        Erro inesperado ao tentar verificar correspondência com pesquisa.\n      "
                    ),
                  ])
                : _vm._e(),
              _vm.success
                ? _c(
                    "v-alert",
                    { attrs: { border: "left", color: "primary", dark: "" } },
                    [
                      _vm._v(
                        "\n        Correspondência identificada! Publicação deveria estar no painel.\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.showTable
                ? _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.table.headers,
                      items: _vm.table.items,
                      "hide-default-footer": "",
                      loading: _vm.loading,
                      dense: "",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }