<template>
  <div class="credits-by-usergroup">
    <PageHeader
      :title="$t('CreditsByUsergroup.title')"
      :description="$t('CreditsByUsergroup.description')"
      :breadcrumbs="labels.breadcrumbs"
    />
    <StLoader :loading="loadingGroups" />
    <div class="usergroups-selector">
      <SingleSelect
        v-model="selectedUsergroup"
        :items="usergroups"
        placeholder="Selecione o Usergroup"
        text="name"
        sub_text="  "
      />
    </div>
    <StLoader :loading="loadingCredits" />
    <div class="usergroups-credits">
      <h2 class="usergroups-credits__overview">
        <span>
          {{ $t('CreditsByUsergroup.total') }} {{ total }}
        </span>
        <span>
          {{ $t('CreditsByUsergroup.usedTotal') }} {{ usedCreditsTable.credits.total }}
        </span>
        <span>
          {{ $t('CreditsByUsergroup.nonUsedTotal') }} {{ nonUsedCreditsTable.credits.total }}
        </span>
      </h2>
      <h3 class="usergroups-credits__table-section-title">
        {{ $t('CreditsByUsergroup.usedTotal') }}
      </h3>
      <v-data-table
        :headers="usedCreditsTable.headers"
        :items="usedCreditsTable.credits"
        :items-per-page="10"
        class="usergroups-credits__table"
      />
      <h3 class="usergroups-credits__table-section-title">
        {{ $t('CreditsByUsergroup.nonUsedTotal') }}
      </h3>
      <v-data-table
        :headers="usedCreditsTable.headers"
        :items="nonUsedCreditsTable.credits"
        :items-per-page="10"
        class="usergroups-credits__table"
      />
      <h3 class="usergroups-credits__table-section-title">
        {{ $t('CreditsByUsergroup.usedDetails') }}
      </h3>
      <v-data-table
        :headers="table.headers"
        :items="table.credits"
        :items-per-page="10"
        class="usergroups-credits__table"
      />
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/base/PageHeader';
import SingleSelect from '@/components/SingleSelect';
import StLoader from '@stilingue/st-loader';
import CompanyLegacyService from '@/services/CompanyLegacyService';
import CreditsSystemService from '@/services/CreditsSystemService';
import PanelsService from '@/services/PanelsService';
import ProprietaryPageService from '@/services/ProprietaryPageService';
export default {
  name: 'CreditsByUsergroup',
  components: {
    PageHeader,
    SingleSelect,
    StLoader,
  },
  data() {
    return {
      labels: {
        breadcrumbs: [{
          text: 'Home',
          href: '/'
        },
        {
          text: 'Usergroups',
          to: '/usergroup'
        },
        {
          text: 'Créditos',
          to: '/usergroup/credits'
        }]
      },
      usedCreditsTable: {
        headers: [
          { text: this.$t('CreditsByUsergroup.productModule'), value: 'productModule' },
          { text: this.$t('CreditsByUsergroup.productName'), value: 'productName' },
          { text: this.$t('CreditsByUsergroup.productAmount'), value: 'amount' },
        ],
        credits: []
      },
      nonUsedCreditsTable: {
        credits: []
      },
      table: {
        headers: [
          { text: this.$t('CreditsByUsergroup.productId'), align: 'start', sortable: false, value: 'productId' },
          { text: this.$t('CreditsByUsergroup.productModule'), value: 'productModule' },
          { text: this.$t('CreditsByUsergroup.productName'), value: 'productName' },
          { text: this.$t('CreditsByUsergroup.opportunityId'), value: 'opportunityId' },
          { text: this.$t('CreditsByUsergroup.creationDate'), value: 'creationDate' },
          { text: this.$t('CreditsByUsergroup.expirationDate'), value: 'expirationDate' },
          { text: this.$t('CreditsByUsergroup.monthLimit'), value: 'monthLimit' },
          { text: this.$t('CreditsByUsergroup.totalLimit'), value: 'totalLimit' },
          { text: this.$t('CreditsByUsergroup.account'), value: 'account' },
          { text: this.$t('CreditsByUsergroup.universe'), value: 'universe' },
          { text: this.$t('CreditsByUsergroup.page'), value: 'page' },
        ],
        credits: []
      },
      selectedUsergroup: { name: undefined, id: undefined },
      usergroups: this.loadUsergroups(),
      loadingGroups: false,
      loadingCredits: false,
      usedCredits: [],
      requests: [],
      filters: [
        { type: 'page', moduleName: this.$t('CreditsByUsergroup.service'), queryBy: 'quotation_item_id_smart_care' },
        { type: 'page', moduleName: this.$t('CreditsByUsergroup.proprietaryMetrics'), queryBy: 'quotation_item_id_performance' },
        { type: 'panel', moduleName: this.$t('CreditsByUsergroup.listening'), queryBy: 'sla_quotation_item_id' },
        { type: 'panel', moduleName: this.$t('CreditsByUsergroup.capacity'), queryBy: 'storage_quotation_item_id' }
      ]
    };
  },
  computed: {
    total() {
      const total = this.usedCreditsTable.credits.total + this.nonUsedCreditsTable.credits.total;
      return total >= 0 ? total : undefined;
    }
  },
  watch: {
    selectedUsergroup(act, last) {
      if (Boolean(act.name) && act.name !== last.name) {
        this.loadCredits(act.name);
      }
    },
    requests(act, last) {
      if (act !== last) {
        this.getPagesOrPanelsByModule();
      }
    }
  },
  methods: {
    loadUsergroups() {
      this.loadingGroups = true;
      CompanyLegacyService.getUserGroupsWithCS([{ 'useUniverseCreditSystem': true }]).then(resp => {
        this.usergroups = resp.map(item => ({
          id: item.name,
          name: item.name
        }));
      }).catch((e) => {
        console.error(e);
      }).finally(() => {
        this.loadingGroups = false;
      });
    },
    async loadCredits(name) {
      this.loadingCredits = true;
      await CreditsSystemService.getCredits([{ 'userGroup': name, 'isDeleted': false }]).then(resp => {
        const credits = resp;
        this.usedCredits = credits.filter(item => item.isAvailable === false).map(item => {
          item.creationDate = new Date(item.creationDate).toLocaleDateString();
          item.expirationDate = new Date(item.expirationDate).toLocaleDateString();
          return item;
        });
        const nonUsedCredits = credits.filter(item => item.isAvailable === true);
        this.usedCreditsTable.credits = this.removeDuplicate(this.usedCredits);
        this.usedCreditsTable.credits.total = this.usedCredits.length;
        this.nonUsedCreditsTable.credits = this.removeDuplicate(nonUsedCredits);
        this.nonUsedCreditsTable.credits.total = nonUsedCredits.length;
        this.setRequests();
      }).catch((e) => {
        console.error(e);
      }).finally(() => {
        this.loadingCredits = false;
      });
    },
    removeDuplicate(credits) {
      return credits.reduce((acc, credit) => {
        if (acc.length === 0) {
          credit.amount = 1;
          acc.push(credit);
        } else if (acc.some(item => item.productId === credit.productId)) {
          acc.find((it => {
            return it.productId === credit.productId;
          })).amount++;
        } else {
          credit.amount = 1;
          acc.push(credit);
        }
        return acc;
      }, []);
    },
    setRequests() {
      this.requests = [];
      this.filters.forEach(filter => {
        const filteredCredits = this.usedCredits.filter(item => item.productModule === filter.moduleName);
        const values = filteredCredits.map(credit => credit.quotationItemId);
        if (values.length > 0) {
          const params = {
            field: filter.queryBy,
            quotationItemId: values.toString(),
          };
          switch (filter.type) {
          case 'page':
            params.service = ProprietaryPageService;
            params.request = 'getPageByQuery';
            params.fields = 'account_id,id,'.concat(filter.queryBy);
            this.requests.push(params);
            break;
          default:
            params.service = PanelsService;
            params.request = 'getUniverseByQuery';
            params.fields = 'account_id,universe_id,month_posts_limit,total_posts_limit,name,'.concat(filter.queryBy);
            this.requests.push(params);
            break;
          }
        }
      });
    },
    async getPagesOrPanelsByModule() {
      this.table.credits = [];
      await this.requests.forEach(params => {
        params.service[params.request](params).then(resp => {
          if (resp.length > 0) {
            resp.map(it => {
              this.usedCredits.forEach(element => {
                if (element.quotationItemId === it[params.field]) {
                  element.account = it.account_id;
                  element.universe = it.universe_id;
                  element.page = it.id;
                  element.monthLimit = it.month_posts_limit;
                  element.totalLimit = it.total_posts_limit;
                  this.table.credits.push(element);
                }
              });
              return it;
            });
          }
        });
      });
    },
  }
};
</script>
<style lang="scss" scoped>

    .usergroups-credits__overview > span {
      padding-right: 60px;
    }

    .usergroups-credits__ {
      &table {
        margin: 60px 20px;
      }

      &table-section-title {
        margin-top: 30px;
      }
    }
</style>
