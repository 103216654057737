<template>
  <div class="crawling-health">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <div class="crawling-health__form-and-result">
      <div>
        <v-form
          ref="crawling-health-form"
          v-model="valid"
          class="crawling-health__form mb-10"
          :lazy-validation="false"
        >
          <SingleSelect
            v-model="selectedAccount"
            required
            :items="accounts"
            :placeholder="labels.account"
            text="name"
            sub-text="group"
          />
          <SingleSelect
            v-if="activeAccount"
            v-model="selectedUniverse"
            :items="universes"
            :placeholder="labels.universe"
            text="name"
            sub-text="channel"
          />
          <StDatePicker
            v-model="models.dates"
            range
            @input="validFormData"
          />
          <v-text-field
            v-model="models.task"
            required
            :rules="rules.taskId"
            :placeholder="labels.task"
          />
          <v-btn
            :disabled="!valid || !validFormData() || loading"
            color="light-blue"
            class="mr-4"
            @click="loadCrawlingHealthData"
          >
            {{ labels.getDataButton }}
          </v-btn>
        </v-form>
        <v-alert
          v-if="error || errorToLoadData"
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          {{ labels.errorMessage }}
        </v-alert>
      </div>
      <div
        v-if="chart.crawlingHealthData"
        class="crawling-health__chart"
      >
        <h3 class="crawling-health__showing-charts-for">
          {{ showingChartsFor }}
        </h3>
        <div class="mt-15 mb-15">
          <Chart :options="crawlingHealthChartConfig.byAmountOfPosts" />
        </div>
        <div class="mt-15 mb-15">
          <Chart :options="crawlingHealthChartConfig.byAverageDelayInHours" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StDatePicker from '@/components/StDatePicker';
import { Chart } from 'highcharts-vue';
import { highchartThemeDark } from '@/utils/highchart-theme-dark';
import merge from 'lodash/merge';
import ITService from '@/services/ITService';
import SingleSelect from '@/components/SingleSelect.vue';
import { mapActions, mapState } from 'vuex';
import PageHeader from '@/components/base/PageHeader';

const lastMonth = new Date();
lastMonth.setMonth(lastMonth.getMonth() - 1);
const today = new Date();
const defaultDateRange = [
  `${lastMonth.getFullYear()}-${(lastMonth.getMonth() + 1).toString().padStart(2, '0')}-${lastMonth.getDate().toString().padStart(2, '0')}`,
  `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`,
];

export default {
  name: 'ProprietaryCrawlingHealth',
  components: {
    StDatePicker,
    SingleSelect,
    Chart,
    PageHeader
  },
  data: () => ({
    labels: {
      account: 'Selecione uma conta',
      universe: 'Selecione uma pesquisa',
      getDataButton: 'Verificar Saúde da Coleta',
      task: 'Entre com ID da demanda no Jira e se não existir preencha com ITSM-000',
      errorMessage: '',
      title: 'Coleta Proprietária',
      description: 'Permite a verificação do tempo e quantidade de publicações coletadas para o período.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Monitoramento',
          to: '/monitoring'
        },
        {
          text: 'Coleta Proprietária',
          to: '/monitoring/crawling-health'
        }
      ],
    },
    models: {
      account: undefined,
      universe: undefined,
      dates: defaultDateRange,
    },
    rules: {
      isNumber: [v => (v && !isNaN(v)) || 'Este campo deve ser preenchido com um ID.'],
      taskId: [v => (v && (v.replace(/\s/gu, '').match(/^ *[A-Z]{1,6}-\d{1,7} *$/u) !== null)) || 'Preencha com um ID válido de tarefa. Ex: ITSM-1234']
    },
    chart: {
      crawlingHealthData: undefined,
    },
    valid: false,
    errorToLoadData: false,
    error: false,
    loading: false,
    lastAccountName: '',
    lastUniverseName: '',
  }),
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    ...mapState('universe', ['universes', 'activeUniverse']),
    showingChartsFor() {
      return `Mostrando gráficos da conta/pesquisa: ${this.lastAccountName}/${this.lastUniverseName}`;
    },
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      },
      set(selectedUniverse) {
        this.selectUniverse(selectedUniverse);
      },
    },
    crawlingHealthChartConfig() {
      const formatDate = millis => {
        const date = new Date(millis);
        return `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
      };

      const byAmountOfPosts = merge({}, highchartThemeDark, {
        title: {
          text: 'Publicações coletadas',
          style: {
            fontSize: '25px',
            color: 'white',
          },
        },
        yAxis: {
          title: 'Quantidade de publicações',
          showLastLabel: true,
        },
        xAxis: {
          categories: this.chart.crawlingHealthData.data.total_posts.axes[0].values.map(formatDate),
        },
        series: this.chart.crawlingHealthData.data.total_posts.points,
      });

      const byAverageDelayInHours = merge({}, highchartThemeDark, {
        title: {
          text: 'Atraso médio da coleta',
          style: {
            fontSize: '25px',
            color: 'white',
          },
        },
        yAxis: {
          title: 'Atraso médio na coleta em horas'
        },
        xAxis: {
          categories: this.chart.crawlingHealthData.data.avg_delay_hours.axes[0].values.map(formatDate),
        },
        tooltip: {
          formatter() {
            const delayedHours = this.y.toFixed(2);
            const minutes = Math.floor((delayedHours - Math.floor(delayedHours)) * 60);
            const hours = Math.floor(delayedHours);
            return `${this.x}<br><span style="fill:${this.color}">●</span> ${this.series.name}: <b>${hours} horas e ${minutes} minutos</b>`;
          },
        },
        series: this.chart.crawlingHealthData.data.avg_delay_hours.points,
      });

      return {
        byAmountOfPosts,
        byAverageDelayInHours,
      };
    }
  },
  watch: {
    selectedAccount() {
      this.validFormData();
    },
    selectedUniverse() {
      this.validFormData();
    },
  },
  async created() {
    await this.getAccounts();
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount, this.selectedUniverse);
    }
  },
  methods: {
    ...mapActions('account', ['getAccounts', 'setAccount']),
    ...mapActions('universe', ['getAccountUniverses', 'setUniverse']),
    validFormData() {
      if (!this.models.dates || this.models.dates.length < 2) {
        return false;
      }

      const isValidRange = this.isValidDateRange();

      const validUniverseAndAccount = this.isValidAccountAndUniverse();
      if (!validUniverseAndAccount) {
        this.labels.errorMessage = 'Conta ou pesquisa inválida.';
      }

      const isValid = isValidRange && validUniverseAndAccount;
      this.error = !isValid;

      return isValid;
    },
    isValidDateRange() {
      const date1Split = this.models.dates[0].split('-');
      const date2Split = this.models.dates[1].split('-');
      const initialDate = new Date(Number(date1Split[0]), date1Split[1] - 1, Number(date1Split[2]));
      const finalDate = new Date(Number(date2Split[0]), date2Split[1] - 1, Number(date2Split[2]));
      const minDate = new Date(finalDate.getTime());
      minDate.setDate(minDate.getDate() - 90);

      const isValidRange = minDate.getTime() <= initialDate.getTime();

      if (!isValidRange) {
        this.labels.errorMessage = 'O intervalo máximo é de 90 dias';
      }

      const hasFutureInitialDate = initialDate.getTime() > today.getTime();
      if (hasFutureInitialDate) {
        this.labels.errorMessage = 'A data inicial é um data futura';
      }

      const initialDateIsBigger = initialDate.getTime() > finalDate.getTime();
      if (initialDateIsBigger) {
        this.labels.errorMessage = 'A data inicial é maior que a final.';
      }
      return !hasFutureInitialDate && !initialDateIsBigger && isValidRange;
    },
    isValidAccountAndUniverse() {
      return this.selectedAccount && this.selectedAccount.id && this.selectedUniverse && this.selectedUniverse.id;
    },
    selectAccount(selectedAccount, selectedUniverse) {
      this.setAccount({ selectedAccount });
      this.getAccountUniverses({ selectedAccount, fetchHistory: true }).then(() => {
        if (selectedUniverse) {
          this.selectedUniverse = selectedUniverse;
        }
        this.selectUniverse(this.selectedUniverse);
      });
    },
    selectUniverse(selectedUniverse) {
      this.setUniverse({ selectedUniverse });
    },
    getParams() {
      return {
        accountId: this.selectedAccount.id,
        universeId: this.selectedUniverse.id,
        dateRange: this.models.dates.join('0000:').replaceAll('-', '').concat('2359'),
        email: this.user.email,
        task: this.models.task.replaceAll('-', '_').replace(/\s/gu, '').toLowerCase().trim(),
        fullHealthCheck: true,
      };
    },
    async loadCrawlingHealthData() {
      this.errorToLoadData = false;
      this.loading = true;
      const params = this.getParams();
      try {
        this.chart.crawlingHealthData = await ITService.getProprietaryCrawlingHealth(params);
        this.lastAccountName = this.selectedAccount.name;
        this.lastUniverseName = this.selectedUniverse.name;
      } catch (e) {
        this.errorToLoadData = true;
        this.labels.errorMessage = 'Houve um erro para carregar os dados.';
        console.error('Error getting data');
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.crawling-health {
  width: 100%;

  &__form {
    width: 600px;
  }

  &__chart {
    width: 100%;
  }

  &__showing-charts-for {
    text-align: center;
  }
}
</style>
