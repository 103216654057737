import StService from '@/services/StService';
import { createApi } from '@stilingue/st-auth-utils';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import { StagingUrl, ProductionUrl } from './config';

const gateway = isProdVersion()
  ? ProductionUrl
  : StagingUrl;

const afterParser = response => response.data;

class UserGroupService extends StService {
  constructor(gateway) {
    const api = createApi('', gateway);
    super(api, { afterParser });
  }

  async getUniverseCreateLimit(id, type) {
    return this.api.get({ url: `/usergroup/${id}?type=${type}` }).then(this.afterParser);
  }

  async setPod(usergroup, pod) {
    return this.api.patch({ url: `/usergroup/${usergroup}/pod?pod=${pod}` }).then(this.afterParser);
  }

  async createUsergroup(params = {}) {
    const url = `/usergroup/?usergroup_name=${params.usergroup_name}&universe_limit=${params.universe_limit}&plan=${params.plan}&expiration_date=${params.expiration_date}&email=${params.email}`;
    return this.api.post({ url }).then(this.afterParser);
  }
}

export default new UserGroupService(gateway);
