<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <section class="selector-container">
      <v-container
        fluid
        class="pa-0"
      >
        <v-row>
          <v-col
            cols="5"
            sm="5"
          >
            <SingleSelect
              v-model="selectedAccount"
              :items="accounts"
              placeholder="Selecione uma conta"
              text="name"
              sub-text="group"
            />
            <SingleSelect
              v-if="activeAccount"
              v-model="selectedPage"
              :items="pages"
              placeholder="Selecione uma Página"
              text="name"
              sub-text="channel"
            />
            <v-text-field
              v-model="postLink"
              :rules="rules"
              placeholder="Link da publicação"
            />
            <v-btn
              v-if="!models.adaccounts"
              class="btn-first-step"
              depressed
              color="primary"
              @click="getAllAdAccounts"
            >
              Verificar Coleta
            </v-btn>
          </v-col>
          <v-col
            cols="7"
            sm="7"
          >
            <v-card
              v-if="models.adaccounts"
              flat
              tile
            >
              <v-window
                v-model="window"
                class="elevation-1"
              >
                <v-window-item
                  v-for="(item, n) in models.adaccounts"
                  :key="item"
                >
                  <v-card flat>
                    <v-card-text>
                      <v-row
                        class="mb-4"
                        align="center"
                      >
                        <v-btn icon>
                          <v-icon>mdi-account</v-icon>
                        </v-btn>
                        <strong class="text-h6">{{ n + 1 }}ª Ad Account ({{ item.name }})</strong>
                        <v-spacer />
                      </v-row>
                      <p>
                        Verifica se a publicação Dark Post está entre os 120 últimos anúncios da <b>{{ n + 1 }}ª Ad Account</b> de acesso do usuário
                        de conexão da página <b>{{ activePage.name }}</b>.
                      </p>
                      <v-btn
                        class="btn-window"
                        depressed
                        color="primary"
                        @click="checkDarkPost(item.account_id)"
                      >
                        Verificar Coleta
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-window-item>
              </v-window>
              <v-item-group
                v-model="window"
                class="text-center"
                mandatory
              >
                <v-item
                  v-for="n in models.adaccounts"
                  :key="n"
                  v-slot="{ active, toggle }"
                >
                  <v-btn
                    :input-value="active"
                    icon
                    @click="toggle"
                  >
                    <v-icon>mdi-record</v-icon>
                  </v-btn>
                </v-item>
              </v-item-group>
            </v-card>
          </v-col>
        </v-row>
        <StLoader :loading="loadingDarPostChecking" />
        <v-alert
          v-if="shouldDarkPostBeCollected !== null && shouldDarkPostBeCollected"
          border="right"
          colored-border
          type="success"
          elevation="2"
        >
          <b>Dark Post encontrado</b> dentre os últimos 120 anúncios da Ad Account <b>{{ backendResponse.adAccountId }}</b>.<br><br>
          Por favor, execute um filtro por publicações deletadas no painel do cliente e tente localizar a publicação. <br><br>
          Se publicação não localizada no filtro, crie um incidente de coleta com os seguintes dados:
          <ul>
            <li>AD_ACCOUNT_ID: {{ backendResponse.adAccountId }}</li>
            <li>CREATIVE_ID: {{ backendResponse.id }}</li>
            <li>AD_ID: {{ backendResponse.ad_id }}</li>
          </ul>
        </v-alert>
        <v-alert
          v-if="shouldDarkPostBeCollected !== null && !shouldDarkPostBeCollected"
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          <span v-if="!adAnalized">
            <b>Dark Post não encontrado</b> dentre os últimos 120 anúncios das Ad Accounts que o usuário de conexão da página tem acesso.<br>
          </span>
          <span v-if="adAnalized">
            <b>Dark Post não encontrado</b> dentre os últimos 120 anúncios da Ad Account verificada.<br>
          </span>
          <span>Para maior esclarecimento do cenário, por favor, execute os seguintes passos:</span><br><br>
          <ol>
            <li>
              <b>Clique</b>
              <a
                :href="postLink + '?__a=1'"
                target="_blank"
              > aqui
              </a>e localize o campo <b>is_ad=true</b> com o auxílio de busca do seu navegador.<br>
            </li>
            <li><b>Solicite</b> ao cliente que identifique em sua operação o número da Ad Account em que o Dark Post fora publicado.<br></li>
            <li>
              <b>Valide</b> o número da Ad Account informada e se o usuário de conexão tem acesso por meio da <a @click="goToAdAccount()">ferramenta</a>.
            </li>
          </ol>
          <br>Acesse maiores informações na
          <a
            target="_blank"
            href="https://stilingue-jira.atlassian.net/wiki/spaces/AC/pages/802652258/Admin+Tools#Instagram.1"
          >documentação</a> se retorno positivo da execução de todos os passos.
        </v-alert>
        <v-alert
          v-if="serverError"
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          Ops, ocorreu um erro inesperado! Entre em contato com o time de N2.
        </v-alert>
      </v-container>
    </section>
  </div>
</template>

<script>
import SingleSelect from '../components/SingleSelect';
import StLoader from '@stilingue/st-loader';
import { mapActions, mapState } from 'vuex';
import AdminToolsService from '@/services/AdminToolsService';
import WarroomService from '@/services/WarroomService';
import PageHeader from '@/components/base/PageHeader';

const { PagesService } = WarroomService;

export default {
  name: 'ColetaDarkPost',
  components: {
    SingleSelect,
    StLoader,
    PageHeader
  },
  data() {
    return {
      window: 0,
      backendResponse: {},
      displayImage: false,
      adAccountId: '',
      postLink: '',
      pages: [],
      activePage: {},
      shouldDarkPostBeCollected: null,
      loadingDarPostChecking: false,
      adAnalized: 0,
      serverError: false,
      rules: [
        value => Boolean(value) || 'Necessário preencher este campo!',
        value => value.indexOf('instagram.com/p/') > -1 || 'Este link não é uma URL de instagram válida!',
      ],
      labels: {
        title: 'Dark Post de Instagram',
        description: 'Verifica se o dark post é retornado em alguma das Ad Accounts que o usuário de conexão da página tem acesso. Requisito fundamental para coleta.',
        breadcrumbs: [
          {
            text: 'Home',
            href: '/'
          },
          {
            text: 'Dark Post',
            to: '/darkposts'
          },
          {
            text: 'Instagram',
            to: '/darkposts/instagram/validate'
          }
        ]
      },
      models: {
        adaccounts: null
      }
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
        this.models.adaccounts = null;
        this.shouldDarkPostBeCollected = null;
      },
    },
    selectedPage: {
      get() {
        return this.activePage;
      },
      set(selectedPage) {
        this.activePage = selectedPage;
        this.models.adaccounts = null;
        this.shouldDarkPostBeCollected = null;
      },
    },
  },
  async created() {
    await this.getAccounts();
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount);
    }
  },
  methods: {
    ...mapActions('account', ['getAccounts', 'setAccount']),
    selectAccount(selectedAccount) {
      this.setAccount({ selectedAccount });
      this.getAccountPages({ selectedAccount });
    },
    selectPage(selectedPage) {
      this.setPage({ selectedPage });
    },
    getShortCode() {
      const instaPost = 'instagram.com/p/';
      if (this.postLink && this.postLink.indexOf(instaPost) > -1) {
        const startIndex = this.postLink.indexOf(instaPost) + instaPost.length;
        const lastIndex = this.postLink.length;
        const endIndex = this.postLink.lastIndexOf('/') === lastIndex ? lastIndex - 2 : lastIndex - 1;
        return this.postLink.substring(startIndex, endIndex);
      }

      return '';
    },
    getAccountPages({ selectedAccount }) {
      const { id: accountId } = selectedAccount;

      PagesService.find({ url: `/${accountId}` })
        .then(data => {
          const { message = [] } = data;

          if (message.length >= 1) {
            this.pages = message.filter(page => page.channel === 'instagram');
            this.activePage = this.pages.length > 0 ? this.pages[0] : null;
          }
        }).catch((e) => {
          console.error(e);
        });
    },
    checkDarkPost(adAccountId) {
      const shortCode = this.getShortCode();
      if (shortCode) {
        this.loadingDarPostChecking = true;
        this.shouldDarkPostBeCollected = null;

        let serviceUrl = `accounts/${this.selectedAccount.id}/pages/${this.selectedPage.id}/instagram/darkposts/${shortCode}`;
        if (adAccountId) {
          this.adAnalized = true;
          serviceUrl = `${serviceUrl}?ad_account_id=${adAccountId}`;
        }

        AdminToolsService.find({
          url: serviceUrl
        })
          .then(data => {
            this.loadingDarPostChecking = false;
            if ('effective_instagram_story_id' in data) {
              this.shouldDarkPostBeCollected = true;
              this.backendResponse = {
                adAccountId: 'ad_account_id' in data ? data.ad_account_id : 'não encontrado',
                id: 'id' in data ? data.id : 'não encontrado',
                ad_id: 'ad_id' in data ? data.ad_id : 'não encontrado',
              };
            } else {
              this.backendResponse = {};
            }
          }).catch((e) => {
            if ('response' in e && 'status' in e.response && e.response.status === 404) {
              this.serverError = false;
              this.shouldDarkPostBeCollected = false;
            } else {
              this.serverError = true;
              this.shouldDarkPostBeCollected = null;
            }

            this.loadingDarPostChecking = false;
          });
      }
    },
    goToAdAccount() {
      this.$router.push({
        path: '/adaccounts/instagram/validate',
      }).catch(() => {});
    },
    getAllAdAccounts() {
      this.shouldDarkPostBeCollected = null;
      AdminToolsService.find({
        url: `accounts/${this.selectedAccount.id}/pages/${this.selectedPage.id}/instagram/adaccounts`,
      })
        .then(data => {
          if (data.length >= 3) {
            this.models.adaccounts = data;
            this.loadingDarPostChecking = false;
          } else {
            this.checkDarkPost();
          }
        }).catch((e) => {
          this.checkDarkPost();
          console.error(e);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  .content-body {
    h1 {
      font-size: 24px;
    }

    h2 {
      margin-top: 10px;
      font-size: 12px;
      text-align: justify;
    }

    .btn-first-step {
      float: right;
    }

    .container > div.row {
      > div:first-child {
        max-width: 500px;
        margin-right: 40px;
      }

      > div:nth-child(2) {
        .v-card {
          p,
          strong {
            color: white;
          }
        }
      }
    }
  }

  .v-alert {
    width: 70%;
    margin-top: 10px;
  }

  ::v-deep {
    .v-list-item {
      padding: 0;
      min-height: 26px;
    }

    .v-select__selections {
      padding: 12px 0;
    }

    .v-list-item__content {
      padding: 0;
    }

    .v-alert__content {
      text-align: justify;
      margin-right: 15px;
    }
  }
</style>
