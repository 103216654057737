var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.length > 1
    ? _c(
        "div",
        { staticClass: "text-center pt-2" },
        [
          _c("v-pagination", {
            attrs: { length: _vm.length },
            model: {
              value: _vm.page,
              callback: function ($$v) {
                _vm.page = $$v
              },
              expression: "page",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }