var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-autocomplete",
    _vm._g(
      _vm._b(
        {
          staticClass: "single-select",
          attrs: {
            items: _vm.items,
            label: _vm.placeholder,
            filter: _vm.customFilter,
            "item-text": _vm.text,
            "item-value": "id",
            "return-object": "",
            "search-input": _vm.searchInput,
          },
          on: {
            "update:searchInput": function ($event) {
              _vm.searchInput = $event
            },
            "update:search-input": function ($event) {
              _vm.searchInput = $event
            },
            change: function ($event) {
              _vm.searchInput = ""
            },
          },
          scopedSlots: _vm._u([
            {
              key: "selection",
              fn: function ({ item }) {
                return [
                  _c(
                    "v-list-item",
                    [
                      _c("v-list-item-content", [
                        _vm.searchInput
                          ? _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.searchInput) +
                                  "\n        "
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item[_vm.text]) +
                                  "\n          "
                              ),
                              item[_vm.subText]
                                ? _c("span", [
                                    _vm._v(
                                      "(" + _vm._s(item[_vm.subText]) + ")"
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "item",
              fn: function ({ item }) {
                return [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _c("span", [
                          _vm._v(
                            "\n          " +
                              _vm._s(item[_vm.text]) +
                              "\n          "
                          ),
                          item[_vm.subText]
                            ? _c("span", [
                                _vm._v("(" + _vm._s(item[_vm.subText]) + ")"),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.itemValue,
            callback: function ($$v) {
              _vm.itemValue = $$v
            },
            expression: "itemValue",
          },
        },
        "v-autocomplete",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }