import StService from '@/services/StService';
import { createApi } from '@stilingue/st-auth-utils';
import { featureFlagFlags } from '@/plugins/rollout';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import gateway from './Gateway';

const version = isProdVersion()
  ? featureFlagFlags.AuthServiceVersionProd.getValue()
  : featureFlagFlags.AuthServiceVersionStaging.getValue();

const afterParser = response => response.data;

const passkey = isProdVersion()
  ? 'bGVzc2lzbW9yZWxlc3Npc21vcmVsZXNzaXNtb3Jl'
  : '2NVHxvwK2dXNndUFUDmCDBiWro8ITelCiizo6C0D';

class ReprocessingService extends StService {
  constructor(gateway, version = 'staging') {
    const api = createApi(`reprocessor-service/${version}/`, gateway);
    super(api, { afterParser });
  }

  createReprocessing(params = {}) {
    const url = `reprocess/register?passkey=${passkey}`;
    const headers = {
      'system': 'admin-tools'
    };
    return this.api.post({ url, ...params }, { headers }).then(this.afterParser);
  }

  getAllReprocessing(params = {}) {
    params.passkey = passkey;
    return this.api.get({ params, url: 'reprocess' }).then(this.afterParser);
  }

  getReprocessHistory(id) {
    const params = { passkey } ;
    return this.api.get({ params, url: `reprocess/${id}/history` }).then(this.afterParser);
  }
}

export default new ReprocessingService(gateway, version);
