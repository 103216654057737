export default {
  removePeriod: 'Atenção! Você tem certeza que deseja remover a data de expiração do período gratuito?',
  errorLoadList: 'Erro inesperado ao carregar lista de Benchmarks. Favor contactar o time de N2.',
  errorUnexpected: 'Erro inesperado! Por favor, refaça a operação.',
  cancel: 'Cancelar',
  save: 'Salvar',
  yes: 'Sim',
  no: 'Não',
  expired: 'Expirado em',
  yesConfirm: 'Sim. Tenho certeza.',
  editFreeTrial: 'Editar período gratuito',
  confirmFreeTrial: 'É uma versão gratuita?',
};
