export default {
  task: 'Pedido',
  search: 'Buscar',
  link: 'Link',
  statusLbl: 'Filtrar por status',
  selectAnAccount: 'Selecione uma Conta',
  selectAnUniverse: 'Selecione um Painel',
  confirmDeactivationTitle: 'Desativação do Eyes',
  confirmDeactivationContent: 'Desativar Eyes do Painel abaixo?',
  confirmDeactivationNoBtn: 'Cancelar',
  confirmDeactivationYesBtn: 'Desativar',
  internalEdited: 'Alteração interna',
  respStatusOk: 'OK',
  respStatusNotModified: 'NOT_MODIFIED',
  respStatusServiceUnavailable: 'SERVICE_UNAVAILABLE',
  successfulyActivated: 'Ativado com sucesso.',
  successfulyDeactivated: 'Desativado com sucesso.',
  alreadyActivated: 'Eyes já está ativado.',
  alreadyDeactivated: 'Eyes já está desativado.',
  serverBusy: 'Servidor ocupado. Tente novamente em alguns instantes.',
  requestError: 'Erro ao processar a solicitação. Repita a operação.',
  tableStatusNoAuth: 'no-auth/internal',
  tableStatusStopped: 'Parado',
  tableStatusPaused: 'Paused',
  tableStatusInExecution: 'Em execução',
  tableStatusActive: 'Ativo',
  tableStatusNotAvailable: 'N/D',
  statusNameDeleted: 'Deletado',
  statusNameLimited: 'Limitado',
  statusNamePaused: 'Pausado',
  warroomProdPath: 'warroom.stilingue',
  warroomStagingPath: 'master.warroom-test',
  colorRed: 'red',
  colorWhite: 'White',
  colorOrange: 'orange',
  colorBlack: 'black',
  colorGreen: 'green',
  colorYellow: 'yellow',
  colorPrimary: 'primary',
  textYes: 'Sim',
  textNo: 'Não',
  inProgress: 'Em execução',
  status: 'Selecione uma opção',
  requestActivation: 'Ativar',
};
