<template>
  <section v-show="true">
    <v-data-table
      :headers="headers"
      :items="prepareData(activeRouting)"
      :items-per-page="15"
      class="elevation-1"
      @click:row="handleClick"
    />
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'RouterUserList',
  data() {
    return {
      headers: [
        {
          text: 'Usuário',
          align: 'start',
          sortable: false,
          value: 'name',
        },
      ],
    };
  },
  computed: {
    ...mapState('account', ['activeAccount']),
    ...mapState('routing', ['routings', 'activeRouting']),
    ...mapState('universe', ['activeUniverse']),
    selectedAccount() {
      return this.activeAccount;
    },
    selectedUniverse() {
      return this.activeUniverse;
    },
  },
  created() {
    this.changeBreadcrumbs({
      text: `Usuários em <${this.activeRouting.name}>`,
      to: '/routing/user-list',
      index: 4
    });
  },
  methods: {
    ...mapActions('routing', ['getUserStatusHistory', 'changeBreadcrumbs']),
    async handleClick(user) {
      await this.getUserStatusHistory({
        selectedAccount: this.selectedAccount,
        selectedUniverse: this.selectedUniverse,
        userId: user.id
      });
      this.$router.push('/routing/user');
      this.changeBreadcrumbs({ text: user.name, to: '/routing/user', index: 5 });
    },
    prepareData(data) {
      return data.operators.map(operator => {
        return {
          name: operator.name,
          id: operator.user_id,
        };
      });
    }
  },
};
</script>
