var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _vm.error
        ? _c(
            "v-alert",
            {
              attrs: {
                border: "right",
                "colored-border": "",
                type: "error",
                elevation: "2",
              },
            },
            [
              _vm._v(
                "\n    Erro inesperado ao carregar lista de reprocessamento.\n  "
              ),
            ]
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-text-field", {
                staticClass: "mx-4",
                attrs: { label: _vm.labels.search },
                model: {
                  value: _vm.table.search,
                  callback: function ($$v) {
                    _vm.$set(_vm.table, "search", $$v)
                  },
                  expression: "table.search",
                },
              }),
              _c(
                "div",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "reprocessing-list__refresh mx-8",
                      class: {
                        "reprocessing-list__refresh--loading": _vm.loading,
                      },
                      on: { click: _vm.getReprocessing },
                    },
                    [_vm._v("\n          mdi-refresh\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("StLoader", { attrs: { loading: _vm.loading } }),
          _c("v-data-table", {
            attrs: {
              headers: _vm.table.headers,
              items: _vm.table.items,
              search: _vm.table.search,
              "custom-sort": _vm.customSort,
            },
            scopedSlots: _vm._u([
              {
                key: "item.percentage_formatted",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center percentage" },
                      [
                        _c(
                          "v-progress-circular",
                          {
                            attrs: {
                              rotate: -90,
                              size: 90,
                              width: 15,
                              value: item.percentage_formatted,
                              color: "teal",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.percentage_formatted) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.types",
                fn: function ({ item }) {
                  return [_c("StMenu", { attrs: { items: item.types } })]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [
                    item.status === "Finalizado"
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-2",
                            attrs: {
                              label: "",
                              color: "green",
                              "text-color": "white",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.status) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    item.status !== "Finalizado" && item.status !== "Cancelado"
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-2",
                            attrs: {
                              label: "",
                              color: "orange",
                              "text-color": "white",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.status) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    item.status === "Cancelado"
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-2",
                            attrs: {
                              label: "",
                              color: "silver",
                              "text-color": "white",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(item.status) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("br"),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  item.status !== "Finalizado" &&
                                  item.status !== "Cancelado" &&
                                  item.limit !== 0
                                    ? _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                loading:
                                                  _vm.loadingCancel &&
                                                  _vm.itemCanceled ===
                                                    item.account_id +
                                                      item.universe_id,
                                                disabled:
                                                  _vm.loadingCancel &&
                                                  _vm.itemCanceled ===
                                                    item.account_id +
                                                      item.universe_id,
                                                dark: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.cancelReprocessing(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-minus-circle"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v("Cancelar")])]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }