<template>
  <div class="retroactive-list">
    <PageHeader
      :title="$t('Liberation.label.placeholder.pageTitle')"
      :description="$t('Liberation.label.placeholder.pageDescription')"
      :breadcrumbs="labels.breadcrumbs"
    />
    <section class="side-by-side">
      <section class="selector-container">
        <v-form
          v-if="selectedStep === 0"
          ref="liberationFormStep1"
          v-model="models.valid"
          :lazy-validation="false"
          @submit.enter.prevent=""
        >
          <v-text-field
            v-model="models.opportunityId"
            :rules="mustBeFilled"
            required
            :label="$t('Liberation.label.placeholder.opportunityId')"
            @input="setErrorFalse"
          />
          <v-text-field
            v-model="models.quotationId"
            :rules="mustBeFilled"
            required
            :label="$t('Liberation.label.placeholder.quotationId')"
            @input="setErrorFalse"
          />
          <SingleSelect
            v-model="models.activationType"
            required
            :items="ACTIVATION_TYPE_LIST"
            :rules="mustBeFilled"
            :placeholder="$t('Liberation.label.placeholder.activationType')"
            :label="$t('Liberation.label.placeholder.activationType')"
            text="name"
            sub-text="value"
            @input="setErrorFalse"
            @change="judgeIfIsNewContract"
          />

          <div v-if="isNewContract">
            <div class="line-block">
              <v-text-field
                v-model="models.newUserGroup"
                required
                :rules="mustBeFilled"
                :placeholder="$t('Liberation.label.placeholder.userGroup')"
                :label="$t('Liberation.label.placeholder.userGroup')"
                @input="setErrorFalse"
              />
              <DsTooltip
                position="left"
                :content="$t('Liberation.label.info.newUserGroup')"
              >
                <v-icon
                  medium
                  class="mr-2"
                >
                  mdi-information
                </v-icon>
              </DsTooltip>
            </div>
            <v-text-field
              v-model="models.newEmailMaster"
              required
              :rules="mustBeFilled.concat(isEmail)"
              :placeholder="$t('Liberation.label.placeholder.emailMaster')"
              :label="$t('Liberation.label.placeholder.emailMaster')"
              @input="setErrorFalse"
            />
            <v-text-field
              v-model="models.clientName"
              required
              :rules="mustBeFilled"
              :placeholder="$t('Liberation.label.placeholder.clientName')"
              :label="$t('Liberation.label.placeholder.clientName')"
              @input="setErrorFalse"
            />
            <v-text-field
              v-model="models.accountName"
              required
              :rules="mustBeFilled"
              :placeholder="$t('Liberation.label.placeholder.accountName')"
              :label="$t('Liberation.label.placeholder.accountName')"
              @input="setErrorFalse"
            />
            <v-text-field
              v-model="models.accountExecutiveEmail"
              required
              :rules="mustBeFilled.concat(isEmail)"
              :placeholder="$t('Liberation.label.placeholder.accountManagerEmail')"
              :label="$t('Liberation.label.placeholder.accountManagerEmail')"
              @input="setErrorFalse"
            />
            <v-text-field
              v-model="models.accountExecutiveName"
              required
              :rules="mustBeFilled"
              :placeholder="$t('Liberation.label.placeholder.accountManagerName')"
              :label="$t('Liberation.label.placeholder.accountManagerName')"
              @input="setErrorFalse"
            />
          </div>
          <div v-else>
            <SingleSelect
              v-model="models.userGroup"
              required
              :items="userGroupList"
              :rules="mustBeFilled"
              :placeholder="$t('Liberation.label.placeholder.userGroup')"
              :label="$t('Liberation.label.placeholder.userGroup')"
              text="name"
              sub-text=" "
              @input="setErrorFalse"
              @change="loadEmailMastersFromUserGroup"
            />
            <SingleSelect
              v-model="models.emailMaster"
              required
              :items="emailMastersFromUserGroupList"
              :rules="mustBeFilled"
              :placeholder="$t('Liberation.label.placeholder.emailMaster')"
              :label="$t('Liberation.label.placeholder.emailMaster')"
              text="emailMaster"
              sub-text=" "
              @input="setErrorFalse"
            />
            <v-text-field
              v-model="models.accountName"
              required
              :rules="mustBeFilled"
              :placeholder="$t('Liberation.label.placeholder.accountName')"
              :label="$t('Liberation.label.placeholder.accountName')"
              @input="setErrorFalse"
            />
            <v-text-field
              v-model="models.accountExecutiveEmail"
              required
              :rules="mustBeFilled.concat(isEmail)"
              :placeholder="$t('Liberation.label.placeholder.accountManagerEmail')"
              :label="$t('Liberation.label.placeholder.accountManagerEmail')"
              @input="setErrorFalse"
            />
            <v-text-field
              v-model="models.accountExecutiveName"
              required
              :rules="mustBeFilled"
              :placeholder="$t('Liberation.label.placeholder.accountManagerName')"
              :label="$t('Liberation.label.placeholder.accountManagerName')"
              @input="setErrorFalse"
            />
          </div>

          <v-col
            cols="12"
            class="checkbox-area"
          >
            <v-checkbox
              v-model="models.isItToSendEmail"
              :label="$t('Liberation.label.placeholder.isItToSendEmail')"
              :value="models.isItToSendEmail"
            />
          </v-col>
        </v-form>

        <v-form
          v-if="selectedStep === 1"
          ref="liberationFormStep2"
          v-model="models.valid"
          :lazy-validation="false"
          @submit.enter.prevent=""
        >
          <v-select
            v-model="models.creditType"
            required
            :items="Object.keys(CREDITS_TYPE)"
            :rules="mustBeFilled"
            :label="$t('Liberation.label.placeholder.creditType')"
            @input="setErrorFalse"
            @change="loadSubCreditTypes"
          />
          <v-select
            v-model="models.creditValue"
            required
            :items="selectedSubCreditType"
            :rules="mustBeFilled"
            :label="$t('Liberation.label.placeholder.creditValue')"
            @input="setErrorFalse"
          />
          <v-text-field
            v-model="models.prodQty"
            :placeholder="$t('Liberation.label.placeholder.prodNum')"
            :rules="mustBeFilled"
            type="number"
            required
            :label="$t('Liberation.label.placeholder.prodQty')"
            @input="setErrorFalse"
          />
          <StOneDatePicker
            v-model="models.expirationDate"
            :label="$t('Liberation.label.placeholder.expirationDate')"
            :max-date="fiveYearsDate"
            :min-date="todayDate"
            @dateToSubmit="setEndDate"
            @input="setErrorFalse"
          />
        </v-form>

        <v-btn
          v-if="selectedStep === 0"
          :disabled="isFilledFieldsOfFirstStep()"
          class="mr-4"
          @click.stop="resetFormStep1"
        >
          {{ $t('Forms.label.button.clean') }}
        </v-btn>
        <v-btn
          v-if="selectedStep !== 0"
          class="mr-4"
          @click.stop="backStep"
        >
          {{ $t('Forms.label.button.back') }}
        </v-btn>
        <v-btn
          v-if="selectedStep === 0"
          :disabled="isFilledFieldsOfFirstStep()"
          color="light-blue"
          class="mr-4"
          @click.stop="nextStep"
        >
          {{ $t('Forms.label.button.next') }}
        </v-btn>
        <v-btn
          v-if="selectedStep === 1"
          :disabled="!models.valid || loading"
          color="light-blue"
          class="mr-4"
          @click.stop="addProductToList"
        >
          {{ $t('Forms.label.button.add') }}
        </v-btn>
        <v-btn
          v-if="selectedStep === 1"
          :disabled="isFilledFieldsOfSecondStep()"
          color="light-blue"
          class="mr-4"
          @click.stop="resetFormStep2"
        >
          {{ $t('Forms.label.button.clean') }}
        </v-btn>

        <StLoader :loading="loading" />
        <DsAlert
          v-if="error"
          class="text-black"
          type="error"
        >
          {{ $t('HttpResponses.label.message.error.requestNotCompleted') }}
        </DsAlert>
      </section>

      <DsSnackbar
        v-if="models.isItToShowSnackbar "
        :type="models.typeOfSnackbar"
        :show="models.isItToShowSnackbar "
        :text="models.textToShowIntoSnackbar"
        @close="models.isItToShowSnackbar = !models.isItToShowSnackbar "
      />
      <DsSnackbar
        v-if="httpShowResponseSnackbar"
        :type="httpResponseSnackbarType"
        :show="httpShowResponseSnackbar"
        :text="httpShowResponseSnackbarText"
        @close="httpShowResponseSnackbar = !httpShowResponseSnackbar"
      />

      <section class="cart-container">
        <div class="contract-common-data-container">
          <v-card-title>{{ $t('Liberation.label.placeholder.contractCommonDataTitle') }}</v-card-title>
          <template v-if="!models.isStep1Submitted">
            <p class="pl-6">
              {{ $t('Liberation.label.placeholder.contractCommonDataTitleEmpty') }}
            </p>
          </template>
          <template v-if="models.isStep1Submitted">
            <p class="contract-title">
              {{ $t('Liberation.label.placeholder.opportunityId') }}
            </p>
            <span class="contract-text">{{ models.opportunityId }}</span>
            <p class="contract-title">
              {{ $t('Liberation.label.placeholder.quotationId') }}
            </p>
            <span class="contract-text">{{ models.quotationId }}</span>
            <p class="contract-title">
              {{ $t('Liberation.label.placeholder.activationType') }}
            </p>
            <span class="contract-text">{{ models.activationType && models.activationType.name }}</span>
            <p class="contract-title">
              {{ $t('Liberation.label.placeholder.userGroup') }}
            </p>
            <span class="contract-text">{{ isNewContract ? models.newUserGroup : (models.userGroup && models.userGroup.name) }}</span>
            <p class="contract-title">
              {{ $t('Liberation.label.placeholder.emailMaster') }}
            </p>
            <span class="contract-text">{{ isNewContract ? models.newEmailMaster : (models.emailMaster && models.emailMaster.emailMaster) }}</span>
            <p class="contract-title">
              {{ $t('Liberation.label.placeholder.clientName') }}
            </p>
            <span class="contract-text">{{ isNewContract ? models.clientName : (models.emailMaster && models.emailMaster.name) }}</span>
            <p class="contract-title">
              {{ $t('Liberation.label.placeholder.accountName') }}
            </p>
            <span class="contract-text">{{ models.accountName }}</span>
            <p class="contract-title">
              {{ $t('Liberation.label.placeholder.accountManagerEmail') }}
            </p>
            <span class="contract-text">{{ models.accountExecutiveEmail }}</span>
            <p class="contract-title">
              {{ $t('Liberation.label.placeholder.accountManagerName') }}
            </p>
            <span class="contract-text">{{ models.accountExecutiveName }}</span>
            <p class="contract-title">
              {{ $t('Liberation.label.placeholder.contractCommonDataIsItToSendEmail') }}
            </p>
            <span class="contract-text">{{ models.isItToSendEmail ? $t('Forms.label.placeholder.yes') : $t('Forms.label.placeholder.no') }}</span>
          </template>
        </div>
        <div class="cart-content-cards">
          <v-card-title>{{ $t('Liberation.label.placeholder.productsListTitle') }}</v-card-title>
        </div>
        <div class="cart-cards">
          <template
            v-if="models.productsCart.length"
          >
            <v-data-table
              class="table"
              :headers="table.headers"
              :items="models.productsCart"
              :search="table.search"
            >
              <template v-slot:item.expirationDate="{ item }">
                {{ convertToLocalDate(item.expirationDate) }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon
                  class="mr-2"
                  @click="removeProductFromList(item)"
                >
                  mdi-delete-forever
                </v-icon>
              </template>
            </v-data-table>
          </template>
          <template>
            <p
              v-if="models.productsCart.length === 0"
              class="pl-6"
            >
              {{ $t('Liberation.label.placeholder.productsListTitleEmpty') }}
            </p>
          </template>
        </div>
        <div class="cart-buttons">
          <v-btn
            :disabled="!models.productsCart.length || loading"
            color="light-blue"
            class="mr-4"
            @click.stop="cleanCart"
          >
            {{ $t('Forms.label.button.clean') }}
          </v-btn>
          <v-btn
            :disabled="!models.productsCart.length || loading"
            color="light-blue"
            class="mr-4"
            @click.stop="registerNewCredit"
          >
            {{ $t('Forms.label.button.activate') }}
          </v-btn>
        </div>
      </section>
    </section>
    <ListLiberation
      :key="componentKey"
      :is-new-contract="isNewContract"
      class="mt-4"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/base/PageHeader';
import StLoader from '@stilingue/st-loader';
import StOneDatePicker from '@/components/StOneDatePicker.vue';
import SingleSelect from '@/components/SingleSelect';
import dateComponents from '@/utils/date-utils';
import CompanyLegacyService from '@/services/CompanyLegacyService';
import UserUpmsLegacyService from '@/services/UserUpmsLegacyService';
import CreditsSystemGeneralService from '@/services/CreditsSystemGeneralService';
import { DsSnackbar, DsAlert, DsTooltip } from '@ds/components';
import formRules from '@/mixins/form-rules.mixin';
import { HTTP_RESPONSES } from '@/utils/constants';
import { CREDITS_TYPE, ACTIVATION_TYPE_LIST, STORAGE_LIMIT, PRODUCT_MODULE, PRODUCT_ID } from '@/components/liberation/components/constants';
import ListLiberation from '@/components/liberation/components/ListLiberation.vue';

export default {
  name: 'Liberation',
  components: { PageHeader, StLoader, StOneDatePicker, SingleSelect, DsSnackbar, DsAlert, ListLiberation, DsTooltip },
  mixins: [
    formRules,
  ],
  data() {
    return {
      ACTIVATION_TYPE_LIST,
      CREDITS_TYPE,
      STORAGE_LIMIT,
      PRODUCT_MODULE,
      PRODUCT_ID,
      selectedStep: 0,
      isNewContract: true,
      httpResponseSnackbarType: '',
      httpShowResponseSnackbar: false,
      httpShowResponseSnackbarText: '',
      userGroupList: this.loadUserGroups() || [],
      userGroupListNames: [],
      emailMastersFromUserGroupList: [],
      selectedSubCreditType: [],
      selectedEmailMasterFromUserGroup: [],
      componentKey: 0,
      labels: {
        breadcrumbs: [
          {
            text: 'Home',
            href: '/'
          },
          {
            text: this.$t('Liberation.label.placeholder.breadCrumbSidePath'),
            to: '/liberation'
          },
          {
            text: this.$t('Liberation.label.placeholder.breadCrumbPage'),
            to: '/liberation/activation-credits'
          }
        ],
      },
      table: {
        headers: [
          {
            text: this.$t('Liberation.label.placeholder.creditType'),
            value: 'creditType',
            align: 'center'
          },
          {
            text: this.$t('Liberation.label.placeholder.creditValue'),
            value: 'creditValue',
            align: 'center'
          },
          {
            text: this.$t('Liberation.label.placeholder.prodQtyTable'),
            value: 'prodQty',
            align: 'center'
          },
          {
            text: this.$t('Liberation.label.placeholder.expirationDate'),
            value: 'expirationDate',
            align: 'center'
          },
          {
            text: this.$t('Forms.label.button.remove'),
            value: 'action',
            align: 'center'
          },
        ],
        search: '',
        items: [],
      },
      models: {
        valid: false,
        opportunityId: null,
        quotationId: null,
        activationType: null,
        userGroup: null,
        newUserGroup: null,
        emailMaster: null,
        newEmailMaster: null,
        clientName: null,
        accountName: null,
        accountExecutiveEmail: null,
        accountExecutiveName: null,
        emailList: [],
        isItToSendEmail: true,
        creditType: null,
        creditValue: null,
        expirationDate: null,
        prodQty: 1,
        date: '',
        productsCart: [],
        productsCartToShow: [],
        contractData: {},
        isStep1Submitted: false,
        typeOfSnackbar: '',
        isItToShowSnackbar: false,
        textToShowIntoSnackbar: '',
      },
      rules: {
      },
      error: false,
      success: false,
      warning: false,
      loading: false,
      dialog: false,
      openMyModal: false,
      byQuotationIdOrManual: 'byQuotationId',
    };
  },
  computed: {
    todayDate() {
      return dateComponents.formatDate(new Date(), 'YYYY-MM-DD');
    },
    fiveYearsDate() {
      const currentYearArr = this.todayDate.split('-');
      const fiveYearsAhead = Number(currentYearArr[0]) + 5;
      currentYearArr[0] = fiveYearsAhead.toString();
      return currentYearArr.join('-');
    },
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    setErrorFalse() {
      this.error = false;
    },
    setEndDate(date) {
      this.models.expirationDate = date;
    },
    loadSubCreditTypes() {
      this.selectedSubCreditType = [];
      this.selectedSubCreditType = CREDITS_TYPE[this.models.creditType];
    },
    backStep() {
      if (this.selectedStep > 0) {
        this.selectedStep--;
      }
    },
    nextStep() {
      if (this.selectedStep < 1) {
        this.selectedStep++;
        this.models.isStep1Submitted = true;
      }
    },
    addProductToList() {
      const fieldsForm = {
        creditType: this.models.creditType,
        creditValue: this.models.creditValue,
        expirationDate: this.models.expirationDate,
        prodQty: this.models.prodQty,
      };
      this.models.productsCart.push(fieldsForm);
      this.resetFormStep2();
      this.showSnackbar('success', this.$t('Liberation.label.message.success.productAddedToCart'));
    },
    cleanCommonDataOfContract() {
      this.models.isStep1Submitted = false;
      this.models.opportunityId = '';
      this.models.quotationId = '';
      this.models.activationType = null;
      this.models.userGroup = null;
      this.models.newUserGroup = null;
      this.models.emailMaster = null;
      this.models.newEmailMaster = null;
      this.models.clientName = null;
      this.models.emailList = '';
      this.models.isItToSendEmail = true;
    },
    showSnackbar(typeOfSnackbarParam, textToShowIntoSnackbarParam) {
      this.models.isItToShowSnackbar = false;
      this.models.typeOfSnackbar = typeOfSnackbarParam;
      this.models.textToShowIntoSnackbar = textToShowIntoSnackbarParam;
      this.models.isItToShowSnackbar = !this.models.isItToShowSnackbar;
    },
    cleanCart(isItToShowSnackbarParam = true) {
      this.setErrorFalse();
      if (isItToShowSnackbarParam) {
        this.showSnackbar('success', this.$t('Liberation.label.message.success.productListDeleted'));
      }
      this.models.productsCart = [];
    },
    removeProductFromList(item) {
      const index = this.models.productsCart.indexOf(item);
      this.models.productsCart.splice(index, 1);
      this.showSnackbar('success', this.$t('Liberation.label.message.success.productRemovedFromCart'));
    },
    isNotUserGroupFilled() {
      return this.isNewContract ? this.models.newUserGroup === null : this.models.userGroup === null;
    },
    isNotEmailMasterFilled() {
      return this.isNewContract ? this.models.newEmailMaster === null : this.models.emailMaster === null;
    },
    isNotClientNameFilled() {
      return this.isNewContract && this.models.clientName === null;
    },
    isNotAccountNameFilled() {
      return this.isNewContract && this.models.accountName === null;
    },
    isNotAccountExecutiveEmailFilled() {
      return this.isNewContract && this.models.accountExecutiveEmail === null;
    },
    isNotAccountExecutiveNameFilled() {
      return this.isNewContract && this.models.accountExecutiveName === null;
    },
    isNotNewNewFilled() {
      return this.isNotClientNameFilled() || this.isNotAccountNameFilled() || this.isNotAccountExecutiveEmailFilled() || this.isNotAccountExecutiveNameFilled();
    },
    isFilledFieldsOfFirstStep() {
      const isCommonDatafilled = this.models.opportunityId === null || this.models.quotationId === null || this.models.activationType === null;
      const isAtLeastOneNotFilled = isCommonDatafilled || this.isNotUserGroupFilled() || this.isNotEmailMasterFilled() || this.isNotNewNewFilled();
      return isAtLeastOneNotFilled;
    },
    isFilledFieldsOfSecondStep() {
      const prodQtyValidation = this.models.prodQty === null || this.models.prodQty === 0;
      const isAtLeastOneNotFilled = this.models.creditType === null || this.models.creditValue === null || this.models.expirationDate === null || prodQtyValidation;
      return isAtLeastOneNotFilled;
    },
    resetFormStep1() {
      this.$refs.liberationFormStep1.reset();
    },
    resetFormStep2() {
      this.$refs.liberationFormStep2.reset();
    },
    async resetAllForms() {
      await this.resetFormStep2();
      await this.backStep();
      if (this.selectedStep === 0) {
        await this.resetFormStep1();
      }
    },
    loadUserGroups() {
      this.loading = true;
      CompanyLegacyService.getUserGroupsWithCS([{ 'useUniverseCreditSystem': true }]).then(resp => {
        this.userGroupList = resp.map(item => ({
          id: item.id,
          name: item.name
        }));
        this.userGroupListNames = resp.map(item => ({
          name: item.name
        }));
      }).catch((e) => {
        console.error(e);
      }).finally(() => {
        this.loading = false;
      });
    },
    loadEmailMaster() {
      this.loading = true;
      UserUpmsLegacyService.getEmailMasterWithUserGroup([{ 'useUniverseCreditSystem': true }]).then(resp => {
        this.emailMasterAndUserGroupList = resp.map(item => ({
          idUser: item.id,
          name: item.name,
          createdAt: item.createdAt,
          emailMaster: item.email,
          userGroup: item.userGroup,
        }));
      }).catch((e) => {
        console.error(e);
      }).finally(() => {
        this.loading = false;
      });
    },
    loadEmailMastersFromUserGroup() {
      this.loading = true;
      if (this.models.userGroup) {
        UserUpmsLegacyService.getEmailMasterWithUserGroup([{ 'userGroup': this.models.userGroup.name }]).then(resp => {
          this.emailMastersFromUserGroupList = resp.map(item => ({
            idUser: item.id,
            name: item.name,
            createdAt: item.createdAt,
            emailMaster: item.email,
            userGroup: item.userGroup,
          }));
        }).catch((e) => {
          console.error(e);
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    judgeIfIsNewContract() {
      if (this.models.activationType) {
        this.models.activationType.isNew ? this.isNewContract = true : this.isNewContract = false;
      }
    },
    latestExpirationDate(products) {
      let latestDate = null;
      products.forEach(product => {
        const expirationDate = new Date(product.expirationDate);
        if (!latestDate || expirationDate > latestDate) {
          latestDate = expirationDate;
        }
      });
      return latestDate.toISOString().split('T')[0];
    },
    getCompleteCreditTypeName(object, value) {
      let result = '';
      const arrWithOnlyKeys = Object.keys(object);
      result = arrWithOnlyKeys.find(key => object[key].find(k => k === value) === value);
      return result;
    },
    mountProductName(value) {
      if (value[0] === 'A' || value[0] === 'M') {
        return `Painel ${value}`;
      }
      return `${this.getCompleteCreditTypeName(CREDITS_TYPE, value)} ${value}`;
    },
    convertToLocalDate(date) {
      const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
      return new Date(date).toLocaleString('pt-BR', options);
    },
    getLimitMonthly(product) {
      return product.creditValue[0] === 'M' ? STORAGE_LIMIT[product.creditValue] : 0;
    },
    getLimitTotal(product) {
      return product.creditValue[0] === 'A' ? STORAGE_LIMIT[product.creditValue] : 0;
    },
    getMasterEmail() {
      return this.models.activationType.isNew ? this.models.newEmailMaster : (this.models.emailMaster && this.models.emailMaster.emailMaster);
    },
    getMasterName() {
      return this.models.activationType.isNew ? this.models.clientName : (this.models.emailMaster && this.models.emailMaster.name);
    },
    paramsToRegisterNewNewCredit() {
      const products = [];
      this.models.productsCart.forEach((product, index) => {
        products.push({
          quoteLineItemId: `${this.models.quotationId}_${index + 1}`,
          product: {
            id: PRODUCT_ID[product.creditValue],
            family: 'WarRoom',
            limitMonthly: this.getLimitMonthly(product),
            limitTotal: this.getLimitTotal(product),
            modulo: PRODUCT_MODULE[product.creditType],
            name: this.mountProductName(product.creditValue),
          },
          quantity: product.prodQty,
          quote: {
            id: this.models.quotationId,
            master: {
              email: this.getMasterEmail(),
              name: this.getMasterName(),
            },
            opportunity: {
              id: this.models.opportunityId,
              account: {
                name: this.models.accountName,
                accountExecutive: {
                  email: this.models.accountExecutiveEmail,
                  name: this.models.accountExecutiveName,
                },
                customerSuccess: {
                  email: this.models.accountExecutiveEmail,
                  name: this.models.accountExecutiveName,
                },
              },
              creationDate: Date.parse(this.todayDate),
              expirationDate: Date.parse(product.expirationDate),
            }
          },
          userGroup: {
            name: this.models.activationType.isNew ? this.models.newUserGroup : this.models.userGroup.name,
          },
        });
      });
      const parameters = {
        isItToSendEmail: this.models.isItToSendEmail,
        body: products,
      };
      return parameters;
    },
    registerNewCredit() {
      this.isNewContract ? this.registerNewNewCredit() : this.registerRenewCredit();
    },
    async registerNewNewCredit() {
      this.setErrorFalse();
      this.loading = true;
      const parameters = this.paramsToRegisterNewNewCredit();
      await CreditsSystemGeneralService.addCredits(parameters).then(async response => {
        this.showVAlert(response);
        if (response.status === 200) {
          await this.resetAllForms();
          this.cleanCommonDataOfContract();
          this.cleanCart(false);
          this.forceRerender();
          this.loading = false;
        }
      }).catch(err => {
        this.error = true;
        this.loading = false;
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    async registerRenewCredit() {
      this.setErrorFalse();
      this.loading = true;
      const parameters = this.paramsToRegisterNewNewCredit();
      await CreditsSystemGeneralService.renewCredits(parameters).then(async response => {
        this.showVAlert(response);
        if (response.status === 200) {
          await this.resetAllForms();
          this.cleanCommonDataOfContract();
          this.cleanCart(false);
          this.forceRerender();
          this.loading = false;
        }
      }).catch(err => {
        this.error = true;
        this.loading = false;
        console.error(err);
      }).finally(() => {
        this.loading = false;
      });
    },
    showVAlert(res) {
      const respStOk = () => {
        this.httpResponseSnackbarType = 'success';
        this.httpShowResponseSnackbar = true;
        this.httpShowResponseSnackbarText = this.$t('HttpResponses.label.message.success.ok');
      };
      const respStBadRequest = () => {
        this.httpResponseSnackbarType = 'error';
        this.httpShowResponseSnackbar = true;
        this.httpShowResponseSnackbarText = this.$t('HttpResponses.label.message.error.badRequest');
      };
      const respStInternalServerError = () => {
        this.httpResponseSnackbarType = 'error';
        this.httpShowResponseSnackbar = true;
        this.httpShowResponseSnackbarText = this.$t('HttpResponses.label.message.error.internalServerError');
      };
      const respStErr = () => {
        this.httpResponseSnackbarType = 'warning';
        this.httpShowResponseSnackbar = true;
        this.httpShowResponseSnackbarText = this.$t('HttpResponses.label.message.error.requestRepeat');
      };
      const cases = {
        [HTTP_RESPONSES.STATUS_200]: respStOk,
        [HTTP_RESPONSES.STATUS_400]: respStBadRequest,
        [HTTP_RESPONSES.STATUS_500]: respStInternalServerError,
      };
      const switchFn = (lookupObject, defaultCase = '_default') =>
        expression => (lookupObject[expression] || lookupObject[defaultCase])();
      const caseSwitch = switchFn(cases, respStErr);
      caseSwitch(res.status);
    },
  },
};

</script>

<style scoped lang="scss">

.retroactive-list {
  .v-chip.green {
    background: #2c9688 !important;
  }
}

@-moz-keyframes spin-moz {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin-web {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.side-by-side {
  display: flex;
}

.cart-container {
  width: 800px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .contract-common-data-container {
    background-color: #1f1f1f;
    padding: 4px 0;
    margin: 8px 0;
    border-radius: 8px;
    width: 100%;

    .contract-title {
      padding: 0 16px;
      font-size: 12px;
      color: #d2d2d4;
      margin-bottom: 0;
      margin-top: 4px;
    }

    .contract-text {
      padding: 0 16px;
    }
  }

  .cart-content-cards {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cart-cards {
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 10px 0;

    .table {
      width: 100%;
    }
  }
}

.cart-buttons {
  margin: 2px 0;
  display: flex;
  justify-content: flex-start;
}

.selector-container {
  width: 700px;

  .line-block {
    display: flex;
    align-items: center;
  }

  form {
    margin-bottom: 35px;
  }

  .checkbox-area {
    padding: 0;

    > div {
      display: inline-block;
      margin-right: 50px;
    }
  }
}

.text-black {
  color: #1f1f1f;
}

</style>
