var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "6", md: "4" } },
            [
              _c(
                "v-menu",
                {
                  attrs: {
                    "close-on-content-click": false,
                    "nudge-right": 40,
                    transition: "scale-transition",
                    "offset-y": "",
                    "min-width": "auto",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    label: _vm.labels.inputs.executionDate,
                                    "prepend-icon": "mdi-calendar",
                                    readonly: "",
                                  },
                                  model: {
                                    value: _vm.date,
                                    callback: function ($$v) {
                                      _vm.date = $$v
                                    },
                                    expression: "date",
                                  },
                                },
                                "v-text-field",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.menu,
                    callback: function ($$v) {
                      _vm.menu = $$v
                    },
                    expression: "menu",
                  },
                },
                [
                  _c("v-date-picker", {
                    on: {
                      input: function ($event) {
                        ;(_vm.menu = false), _vm.getServiceHistory()
                      },
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          [
            _c("v-data-table", {
              staticClass: "elevation-1",
              attrs: {
                headers: _vm.headers,
                items: _vm.editions,
                "sort-by": _vm.editions.execution_date,
                "sort-desc": [false, true],
                loading: _vm.loading,
                "loading-text": _vm.labels.table.loading,
                "no-data-text": _vm.labels.table.noData,
              },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function (row) {
                    return [
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(row.item.email))]),
                        _c("td", [_vm._v(_vm._s(row.item.filterId))]),
                        _c("td", [_vm._v(_vm._s(row.item.accountId))]),
                        _c("td", [_vm._v(_vm._s(row.item.universeId))]),
                        _c("td", { attrs: { m: "12px" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(row.item.execution_date) +
                              "\n            "
                          ),
                        ]),
                        _c(
                          "td",
                          { attrs: { m: "20px" } },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  id: "no-background-hover",
                                  ripple: false,
                                  icon: "",
                                  color: "blue",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTaskClick(row.item.task)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(row.item.task) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mx-2",
                                attrs: { fab: "", dark: "", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleSmartcareClick(
                                      row.item.universeId,
                                      row.item.accountId
                                    )
                                  },
                                },
                              },
                              [
                                _c("v-icon", { attrs: { dark: "" } }, [
                                  _vm._v(
                                    "\n                  mdi-vector-link\n                "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }