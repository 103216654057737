<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <section class="selector-container">
      <v-container
        fluid
        class="pa-0"
      >
        <v-row>
          <v-col
            cols="12"
            sm="12"
          >
            <SingleSelect
              v-model="selectedAccount"
              :items="accounts"
              :placeholder="labels.selectAccountPlaceholder"
              text="name"
              sub-text="group"
            />
            <SingleSelect
              v-if="activeAccount"
              v-model="selectedPage"
              :items="pages"
              :placeholder="labels.selectPagePlaceholder"
              text="name"
              sub-text="channel"
            />
            <v-text-field
              v-model="adAccountId"
              placeholder="Ad Account ID válida"
            />
            <v-btn
              depressed
              :disabled="shallDisableBtnAction"
              color="primary"
              @click="checkAdAccount"
            >
              {{ labels.btnLabel }}
            </v-btn>
          </v-col>
        </v-row>
        <StLoader
          class="st-loader"
          :loading="isFetching"
        />
        <template v-if="!isFetching && !changedAdIdValue">
          <v-alert
            v-if="adAccountFound !== null && !adAccountFound"
            border="right"
            colored-border
            type="warning"
            elevation="2"
          >
            A página foi conectada na plataforma pelo usuário da Stilingue <b>{{ activePage.usr_name }}</b> utilizando um usuário do linkedin que não tem acesso à Ad Account informada. <br><br> Favor solicitar
            ao cliente que entre em contato com o mesmo.
          </v-alert>
          <v-alert
            v-if="adAccountFound !== null && adAccountFound"
            border="right"
            colored-border
            type="success"
            elevation="2"
          >
            A Ad Account "{{ adAccountProprietary }}" pertence ao usuário que conectou a página.<br>
          </v-alert>
        </template>
      </v-container>
    </section>
    <v-alert
      v-if="serverError"
      border="right"
      colored-border
      type="error"
      elevation="2"
    >
      {{ labels.unexpectedError }}
    </v-alert>
  </div>
</template>

<script>
import SingleSelect from '../components/SingleSelect';
import StLoader from '@stilingue/st-loader';
import { mapActions, mapState } from 'vuex';
import AdminToolsService from '@/services/AdminToolsService';
import WarroomService from '@/services/WarroomService';
import PageHeader from '@/components/base/PageHeader';

const { PagesService } = WarroomService;

export default {
  name: 'LinkedinAdAccountCheck',
  components: {
    SingleSelect,
    StLoader,
    PageHeader
  },
  data() {
    return {
      labels: {
        title: 'Linkedin Ad Account',
        unexpectedError: 'Comportamento inesperado! Contate o suporte!',
        btnLabel: 'Verificar Ad Account',
        selectPagePlaceholder: 'Selecione uma Página',
        selectAccountPlaceholder: 'Selecione uma Conta',
        description: 'Verifica se a Ad Account aparece dentre as que o usuário dono do token de conexão ' +
          'da página tem acesso, não se preocupando em validar se o ID informado é realmente de uma Ad Account. ' +
          'Portanto, parte do pressuposto de que esteja correta e que seja realmente onde o Dark Post fora realizado.',
        breadcrumbs: [
          {
            text: 'Home',
            href: '/'
          },
          {
            text: 'Ad Account',
            to: '/adaccounts'
          },
          {
            text: 'Linkedin',
            to: '/adaccounts/linkedin/validate'
          }
        ]
      },
      adAccountId: '',
      searchedId: '',
      postLink: '',
      pages: [],
      activePage: {},
      ownerConnection: '',
      adAccountFound: null,
      isFetching: false,
      changedAdIdValue: false,
      serverError: false,
      adAccountProprietary: '',
    };
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    selectedPage: {
      get() {
        return this.activePage;
      },
      set(selectedPage) {
        this.activePage = selectedPage;
      },
    },
    shallDisableBtnAction() {
      const isSelectsFilled = this.selectedPage.id && this.selectedAccount.id && this.adAccountId;
      return !isSelectsFilled || this.isFetching;
    },
  },
  watch: {
    adAccountId() {
      this.changedAdIdValue = true;
    },
  },
  async created() {
    await this.getAccounts({ userId: this.userId });
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount);
    }
  },
  methods: {
    ...mapActions('account', ['getAccounts', 'setAccount']),
    selectAccount(selectedAccount) {
      this.setAccount({ selectedAccount });
      this.getAccountPages({ selectedAccount });
    },
    selectPage(selectedPage) {
      this.setPage({ selectedPage });
    },
    getAccountPages({ selectedAccount }) {
      const { id: accountId } = selectedAccount;
      this.isFetching = true;
      PagesService.find({ url: `/${accountId}` })
        .then(data => {
          const { message = [] } = data;

          if (message.length >= 1) {
            this.pages = message.filter(page => page.channel === 'linkedin');
            this.activePage = this.pages.length > 0 ? this.pages[0] : null;
          } else {
            this.pages = [];
          }

          this.isFetching = false;
        }).catch((e) => {
          console.error(e);
        });
    },
    checkAdAccount() {
      this.isFetching = true;
      this.changedAdIdValue = false;
      this.searchedId = this.adAccountId;

      const url = `accounts/${this.selectedAccount.id}/pages/${this.selectedPage.id}/linkedin/adaccounts/${this.adAccountId}`;

      AdminToolsService.find({ url })
        .then(data => {
          if ('name' in data) {
            this.adAccountProprietary = data.name;
            this.adAccountFound = true;
            this.isFetching = false;
            this.serverError = false;
          }
        }).catch((e) => {
          if (e.response.status === 404) {
            this.serverError = false;
            this.adAccountFound = false;
          } else {
            this.adAccountFound = null;
            this.serverError = true;
          }

          this.isFetching = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .content-body {
    h1 {
      font-size: 24px;
    }

    h2 {
      margin-top: 10px;
      font-size: 12px;
      text-align: justify;
    }

    .v-btn {
      float: right;
    }

    .st-loader {
      margin-top: 80px;
      position: relative;
    }
  }

  .selector-container {
    width: 500px;
  }

  ::v-deep {
    .v-list-item {
      padding: 0;
      min-height: 26px;
    }

    .v-select__selections {
      padding: 12px 0;
    }

    .v-list-item__content {
      padding: 0;
    }

    .v-alert {
      max-width: 465px;
    }

    .v-alert__content {
      text-align: justify;
      margin-right: 15px;
      max-width: 310px;
      word-wrap: break-word;
    }
  }
</style>
