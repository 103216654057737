<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <section class="selector-container">
      <v-row>
        <v-col
          cols="6"
        >
          <SingleSelect
            v-model="selectedAccount"
            :items="accounts"
            placeholder="Selecione uma conta:"
            text="name"
            sub-text="group"
          />
        </v-col>
        <v-col cols="6">
          <SingleSelect
            v-if="activeAccount"
            v-model="selectedUniverse"
            :items="universes"
            placeholder="Selecione uma Pesquisa:"
            text="name"
            sub-text="channel"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <h1 style="font-size: 14px">
            {{ labels.urlJira }}
          </h1>
          <v-text-field
            v-model="taskLink"
            :placeholder="placeholders.task"
          />
        </v-col>
        <v-col
          cols="12"
        >
          <h1 style="font-size: 14px">
            {{ urlSmartcare }}
          </h1>
          <v-text-field
            v-model="filterUrl"
            :placeholder="placeholders.smartcare.replace(/_URL_/, `${smartcarePermitedUrl}`)"
          />
          <h4 style="font-size: 12px">
            {{ labels.filterWarning }}
          </h4>
        </v-col>
        <v-col>
          <v-btn
            :disabled="!(filterUrl && taskLink && selectedAccount && selectedUniverse)"
            color="primary"
            :loading="loading"
            @click="startMultipleEdition"
          >
            Editar
          </v-btn>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="error"
      >
        {{ labels.errorMessage }}
        <v-btn
          color="blue"
          text
          @click="error = false"
        >
          Fechar
        </v-btn>
      </v-snackbar>
      <v-snackbar
        v-model="success"
      >
        {{ successMessage }}
        <v-btn
          color="blue"
          text
          @click="success = false"
        >
          Fechar
        </v-btn>
      </v-snackbar>
    </section>
    <v-dialog
      v-if="show"
      v-model="show"
      max-width="514px"
      persistent
    >
      <v-card>
        <span class="card_title"> {{ confirmCard.title }} </span>

        <v-card-text> {{ confirmMessage }} </v-card-text>

        <span> {{ confirmCard.quest }} </span>

        <v-text-field
          v-model="confirm"
          :placeholder="placeholders.confirm"
        />

        <span> {{ confirmCard.warn }}</span>

        <v-card-actions>
          <v-spacer />
          <v-btn
            class="button-dialog-update"
            text
            @click="cancel"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="messagesCount == confirm"
            text
            @click="agree"
          >
            Continuar
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import PageHeader from '@/components/base/PageHeader';
import SingleSelect from '@/components/SingleSelect.vue';
import ITService from '@/services/ITService';
import EndPointService from '@/services/EndPointService';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';

export default {
  name: 'MultipleEdition',
  components: {
    PageHeader,
    SingleSelect
  },
  data: () => ({
    labels: {
      title: 'Alteração de status em massa',
      description: 'Essa área é responsável por aplicar status ignorado em massa para conversas do Smart Care.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Alteração de status',
          to: '/batch'
        }
      ],
      successMessage: 'Pronto! As _MESSAGECOUNT_ interações do filtro tiveram o status alterado para ignorado.',
      filterWarning: '*Importante: Não use padrões predefinidos para escolher a data.',
      urlJira: 'Entre com a URL do ticket associado ao zendesk ou a solicitação do jira:',
      urlSmartCare: 'Entre com a URL do filtro do Smartcare:'
    },
    placeholders: {
      task: 'https://stilinguesupport.zendesk.com/agent/tickets/12345 ou https://stilingue-jira.atlassian.net/browse/XXXX-YYY',
      smartcare: '_URL_date_range=XXd&filters=XXXXXaaXXaaXXXXaXXaXXaXX',
      confirm: 'Entre com o número de mensagens afetadas por sua ação.'
    },
    confirmCard: {
      title: 'Confirmação de Alteração',
      text: 'Você está prestes a anotar _MESSAGECOUNT_ conversas para o status ignorado na conta ',
      quest: 'Digite a quantidade de mensagens afetadas',
      warn: 'Tem certeza que deseja realizar essa ação?'
    },
    taskLink: '',
    filterUrl: '',
    taskPermitedUrl: ['https://stilinguesupport.zendesk.com', 'https://stilingue-jira.atlassian.net'],
    smartcarePermitedUrl: `https://smartcare.${isProdVersion() ? 'stilingue' : 'warroom-test'}.com.br/conversas/minhasconversas/`,
    urlErrors: { task: 'do ticket associado ao Zendesk ou da solicitação do jira', smartcare: 'do filtro do SmartCare' },
    messagesCount: 0,
    loading: false,
    error: false,
    success: false,
    confirm: undefined,
    show: false,
    resolve: null,
    reject: null,
  }),
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    ...mapState('universe', ['universes', 'activeUniverse']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      },
      set(selectedUniverse) {
        this.selectUniverse(selectedUniverse);
      },
    },
    userId() {
      return this.user.id;
    },
    confirmMessage() {
      return this.confirmCard.text.replace('_MESSAGECOUNT_', `${this.messagesCount}`).concat(`${this.selectedAccount.name}`);
    },
    successMessage() {
      return this.labels.successMessage.replace('_MESSAGECOUNT_', `${this.messagesCount}`);
    }
  },
  async created() {
    await this.getAccounts({ userId: this.userId });
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount, this.selectedUniverse);
    }
  },
  methods: {
    ...mapActions('account', ['getAccounts', 'setAccount']),
    ...mapActions('universe', ['getAccountUniverses', 'setUniverse', 'unsetUniverse']),
    ...mapMutations('account', {
      mutate: 'MUTATE',
    }),
    selectAccount(selectedAccount, selectedUniverse) {
      this.setAccount({ selectedAccount });
      this.unsetUniverse();
      this.getAccountUniverses({ selectedAccount }).then(() => {
        if (selectedUniverse) {
          this.selectedUniverse = selectedUniverse;
        }
        this.selectUniverse(this.selectedUniverse);
      });
    },
    selectUniverse(selectedUniverse) {
      this.setUniverse({ selectedUniverse });
    },

    setError(message) {
      console.error(message);
      this.labels.errorMessage = message;
      this.error = true;
    },

    getUrl(data, inputError) {
      try {
        return new URL(data);
      } catch (err) {
        throw new Error(`O campo URL ${inputError} não é uma URL. Confira o exemplo. `);
      }
    },

    validateUrl(url, permited, inputError) {
      if (!permited.includes(url)) {
        throw new Error(`O campo URL ${inputError} está incorreto. Confira o exemplo. `);
      }
    },

    getTask() {
      const url = this.getUrl(this.taskLink, this.urlErrors.task);
      this.validateUrl(url.origin, this.taskPermitedUrl, this.urlErrors.task);
      return (
        url.origin === this.taskPermitedUrl[0]
          ? `ZENDESK-${url.pathname.split('/agent/tickets/')[1]}`
          : url.pathname.split('/browse/')[1]
      );
    },

    getSmartCareUrlParameters() {
      const url = this.getUrl(this.filterUrl, this.urlErrors.smartcare);
      this.validateUrl(url.origin + url.pathname, this.smartcarePermitedUrl, this.urlErrors.smartcare);
      const params = new URLSearchParams(url.search);
      const dateRange = params.get('date_range');
      const filterId = params.get('filters');
      return { dateRange, filterId };
    },

    async validateMessageLimit(date_range, filter_id) {
      const params = {
        filter_id,
        account_id: this.selectedAccount.id,
        universe_id: this.selectedUniverse.id,
        date_range
      };
      try {
        const response = await EndPointService.getMessagesCountSummary(params);
        const messageLimit = 100000;
        if (response.data.total.calls > messageLimit) {
          throw new Error('O filtro do Smart Care pode ter até cem mil conversas. Refaça o filtro e tente novamente.');
        }
        this.messagesCount = response.data.total.calls;
        return true;
      } catch (err) {
        this.setError(err);
        return false;
      }
    },

    async startMultipleEdition() {
      try {
        const { dateRange, filterId } = this.getSmartCareUrlParameters();
        const task = this.getTask();
        if (await this.validateMessageLimit(dateRange, filterId)) {
          const body = {
            newStatus: 'IGNORED',
            accountId: this.selectedAccount.id,
            universeId: this.selectedUniverse.id,
            filterId,
            dateRange,
            email: this.user.email,
            task,
          };
          this.show = true;
          return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
          }).then(() => {
            this.loading = false;
            return this.makeEditCallStatus(body);
          }).catch(() => {
            this.loading = false;
          });
        }
      } catch (err) {
        this.setError(err);
      }
    },

    setEditCallStatusApiErrors(apiError) {
      let errorLabel = apiError.description;
      if (apiError.error.includes('Bad Request')) {
        errorLabel = 'Verifique os seguintes dados inválidos na requisição e tente novamente:';
        errorLabel += apiError.data.map((data) => {
          if (data.field.includes('task')) {
            return ' campo de ticket/solicitação';
          }

          if (data.field.includes('filterId')) {
            return ' ID do filtro';
          }

          if (data.field.includes('dateRange')) {
            return ' data do filtro';
          }

          return '';
        });
        errorLabel += '.';
      }
      this.setError(errorLabel);
    },

    async makeEditCallStatus(body) {
      this.loading = false;
      try {
        this.loading = true;
        await ITService.editCallStatus(body);
        this.success = true;
      } catch (err) {
        this.setEditCallStatusApiErrors(err.response.data);
      } finally {
        this.loading = false;
      }
    },
    agree() {
      this.resolve(true);
      this.loading = true;
      this.show = false;
    },
    cancel() {
      this.reject(true);
      this.show = false;
    },
  }
};
</script>
<style lang="scss" scoped>
.v-card {
  background: #333;
  border-radius: 6px;
  padding: 48px;
  text-align: center;
}

.card_title {
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.theme--dark.v-card > .v-card__text {
  color: #ffff;
  padding: 24px 0;
  text-align: center;
}

.v-card__actions > .v-btn.v-btn {
  border: 1px solid;
  border-radius: 40px;
  box-sizing: border-box;
  margin: 0 16px;
}

.button-dialog-update {
  border-color: #00ccbe;
  background-color: #00ccbe;
  color: #242c5a;
}
</style>
