<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <v-row class="selector-container">
      <v-col cols="5">
        <v-form
          ref="notCrawledForm"
          v-model="valid"
          class="not-crawled__form"
          :lazy-validation="false"
        >
          <v-text-field
            v-model="accountId"
            required
            :rules="isNumber"
            label="ID da conta"
            @keydown="resetRequestResultAttributes"
          />
          <v-text-field
            v-model="universeId"
            required
            :rules="isNumber"
            label="ID da pesquisa"
            @keydown="resetRequestResultAttributes"
          />
          <v-text-field
            v-model="postId"
            required
            :rules="isNotEmpty"
            label="ID da publicação"
            @keydown="resetRequestResultAttributes"
          />
          <v-select
            v-model="channel"
            :items="items"
            label="Canal"
            @change="resetRequestResultAttributes"
          />
          <v-btn
            :disabled="!valid"
            color="light-blue"
            class="mr-4"
            @click="loadPostInfo"
          >
            Verificar correspondência
          </v-btn>
        </v-form>
      </v-col>
      <v-col cols="7">
        <v-alert
          v-if="postIsNotInOurDataSource"
          type="warning"
        >
          Publicação não encontrada.
        </v-alert>
        <v-alert
          v-if="noTermMatchedWithUniverseSetting"
          type="info"
        >
          Nenhuma correspondência com descritores da pesquisa identificada.
        </v-alert>
        <v-alert
          v-if="error"
          type="error"
        >
          Erro inesperado ao tentar verificar correspondência com pesquisa.
        </v-alert>
        <v-alert
          v-if="success"
          border="left"
          color="primary"
          dark
        >
          Correspondência identificada! Publicação deveria estar no painel.
        </v-alert>
        <v-data-table
          v-if="showTable"
          :headers="table.headers"
          :items="table.items"
          hide-default-footer
          class="elevation-1"
          :loading="loading"
          dense
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AdminToolsService from '../services/AdminToolsService';
import PageHeader from '@/components/base/PageHeader';
import { mapState } from 'vuex';

export default {
  name: 'NotCrawled',
  components: {
    PageHeader,
  },
  data: () => ({
    items: ['FACEBOOK', 'FACEBOOK_COMMENTS', 'NEWS', 'YOUTUBE', 'YOUTUBE_COMMENTS', 'TWITTER'],
    labels: {
      title: 'Correspondência Pesquisa',
      description: 'Verifica correspondência com descritores configurados na pesquisa. Indicativo de que a publicação deveria estar no painel.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Publicação',
          to: '/publications'
        },
        {
          text: 'Pesquisa',
          to: '/publications/setup-validate'
        }
      ]
    },
    table: {
      headers: [
        {
          text: 'Descritor',
          value: 'descriptor',
          align: 'center'
        },
        {
          text: 'Grupo',
          value: 'title',
          align: 'center'
        }
      ],
      items: [],
    },
    universeId: '',
    accountId: '',
    postId: '',
    channel: 'FACEBOOK',
    valid: true,
    error: false,
    success: false,
    loading: false,
    showTable: false,
    postIsNotInOurDataSource: false,
    noTermMatchedWithUniverseSetting: false,
    isNumber: [v => (v && !isNaN(v)) || 'Este campo deve ser preenchido com um ID.'],
    isNotEmpty: [v => (v && v.length) > 0 || 'Este campo deve ser preenchido']
  }),
  computed: {
    ...mapState('account', ['activeAccount']),
    ...mapState('universe', ['activeUniverse']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      }
    },
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      }
    }
  },
  async created() {
    if (this.selectedAccount) {
      this.selectAccount(this.selectedAccount.id);
    }
    if (this.selectedUniverse) {
      this.selectUniverse(this.selectedUniverse.id);
    }
  },
  methods: {
    resetRequestResultAttributes() {
      this.postIsNotInOurDataSource = false;
      this.noTermMatchedWithUniverseSetting = false;
      this.success = false;
      this.error = false;
      this.showTable = false;
    },
    loadPostInfo() {
      this.error = false;
      this.success = false;
      this.loading = true;
      this.showTable = true;
      AdminToolsService.getDescriptorsInfo({
        accountId: this.accountId,
        universeId: this.universeId,
        postId: this.postId,
        channel: this.channel,
      }).then(data => {
        this.loading = false;
        if (data.result.length === 0) {
          this.showTable = false;
          this.noTermMatchedWithUniverseSetting = true;
          return;
        }
        this.success = true;
        this.table.items = data.result;
      }).catch(error => {
        this.loading = false;
        this.showTable = false;
        if (error.response.status === 404) {
          this.postIsNotInOurDataSource = true;
          return;
        }
        this.error = true;
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    reset() {
      this.$refs.form.reset();
    },
    selectAccount(selectedAccount) {
      this.accountId = selectedAccount;
    },
    selectUniverse(selectedUniverse) {
      this.universeId = selectedUniverse;
    }
  }
};
</script>

<style scoped>
  .selector-container {
    width: 1200px;
  }
</style>
