var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.labels.title,
          description: _vm.labels.description,
          breadcrumbs: _vm.labels.breadcrumbs,
        },
      }),
      _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: true,
              expression: "true",
            },
          ],
          staticClass: "selector-container",
        },
        [
          _c(
            "v-form",
            {
              ref: "newLimitForm",
              attrs: { "lazy-validation": false },
              model: {
                value: _vm.models.valid,
                callback: function ($$v) {
                  _vm.$set(_vm.models, "valid", $$v)
                },
                expression: "models.valid",
              },
            },
            [
              _c("SingleSelect", {
                attrs: {
                  required: "",
                  items: _vm.accounts,
                  placeholder: "Selecione uma Conta",
                  text: "name",
                  "sub-text": "group",
                },
                model: {
                  value: _vm.selectedAccount,
                  callback: function ($$v) {
                    _vm.selectedAccount = $$v
                  },
                  expression: "selectedAccount",
                },
              }),
              _vm.activeAccount
                ? _c("SingleSelect", {
                    attrs: {
                      items: _vm.universes,
                      placeholder: "Selecione uma Pesquisa",
                      text: "name",
                      "sub-text": "",
                    },
                    model: {
                      value: _vm.selectedUniverse,
                      callback: function ($$v) {
                        _vm.selectedUniverse = $$v
                      },
                      expression: "selectedUniverse",
                    },
                  })
                : _vm._e(),
              _c(
                "v-radio-group",
                {
                  model: {
                    value: _vm.models.limitType,
                    callback: function ($$v) {
                      _vm.$set(_vm.models, "limitType", $$v)
                    },
                    expression: "models.limitType",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "universe-limit-type" },
                    [
                      _c("v-radio", {
                        attrs: { label: "Total", value: "Total" },
                      }),
                      _c("v-radio", {
                        attrs: { label: "Mensal", value: "Mensal" },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("v-text-field", {
                attrs: {
                  placeholder: "Limite de publicações",
                  rules: _vm.limitRules,
                },
                on: { input: _vm.setErrorFalse },
                model: {
                  value: _vm.models.limit,
                  callback: function ($$v) {
                    _vm.$set(_vm.models, "limit", $$v)
                  },
                  expression: "models.limit",
                },
              }),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  attrs: {
                    disabled: !_vm.models.valid || _vm.loading,
                    color: "light-blue",
                  },
                  on: { click: _vm.updateLimiteValue },
                },
                [_vm._v("\n        Salvar alteração\n      ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-4",
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("\n        Voltar\n      ")]
              ),
            ],
            1
          ),
          _c("StLoader", { attrs: { loading: _vm.loading } }),
          _vm.success
            ? _c(
                "v-alert",
                {
                  attrs: {
                    border: "right",
                    "colored-border": "",
                    type: "success",
                    elevation: "2",
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.labels.successMessage) + "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm.error
            ? _c(
                "v-alert",
                {
                  attrs: {
                    border: "right",
                    "colored-border": "",
                    type: "error",
                    elevation: "2",
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.labels.errorMessage) +
                      " Solicite ajuda ao time de N2.\n    "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }