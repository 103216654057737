import { MUTATE, MUTATE_MULTIPLE } from '@/store/modules/mutation_types';
import RoutingService from '@/services/RoutingService';

let breadcrumbsMap = [
  { text: 'Home', to: '/' }
];

export function getRoutingList({ commit }, { selectedAccount, selectedUniverse }) {
  commit(MUTATE, {
    property: 'requestStatus',
    with: 'loading',
  });

  const { id: accountId } = selectedAccount;
  const { id: universeId } = selectedUniverse;

  return RoutingService.getRoutes({
    account_id: accountId,
    universe_id: universeId,
  }).then(message => {
    const { data = {} } = message;
    commit(MUTATE_MULTIPLE, [
      {
        property: 'requestStatus',
        with: 'success',
      }, {
        property: 'routings',
        with: data,
      },
    ]);

    return Promise.resolve(data);
  }).catch((e) => {
    console.error(e);
    commit(MUTATE, {
      property: 'requestStatus',
      with: 'error',
    });
  });
}

export function getRouterSettings({ commit }, { selectedAccount, selectedUniverse, routerId }) {
  commit(MUTATE, {
    property: 'requestStatus',
    with: 'loading',
  });

  const { id: accountId } = selectedAccount;
  const { id: universeId } = selectedUniverse;

  return RoutingService.getRouterSettings(routerId, {
    account_id: accountId,
    universe_id: universeId,
  }).then(message => {
    const { data = {} } = message;
    commit(MUTATE_MULTIPLE, [
      {
        property: 'requestStatus',
        with: 'success',
      }, {
        property: 'activeRouting',
        with: data,
      },
    ]);

    return Promise.resolve(data);
  }).catch((e) => {
    console.error(e);
    commit(MUTATE, {
      property: 'requestStatus',
      with: 'error',
    });
  });
}

export function getUserStatusHistory({ commit }, {
  selectedAccount,
  selectedUniverse,
  userId,
  period = ''
}) {
  commit(MUTATE, {
    property: 'requestStatus',
    with: 'loading',
  });

  const { id: accountId } = selectedAccount;
  const { id: universeId } = selectedUniverse;

  return RoutingService.getUserStatusHistory({
    userId,
    accountId,
    universeId,
    period
  }).then(message => {
    const { data = {} } = message;
    data.id = userId;
    commit(MUTATE_MULTIPLE, [
      {
        property: 'requestStatus',
        with: 'success',
      }, {
        property: 'activeUser',
        with: data,
      }
    ]);

    return Promise.resolve(data);
  }).catch((e) => {
    console.error(e);
    commit(MUTATE_MULTIPLE, [
      {
        property: 'requestStatus',
        with: 'error',
      }, {
        property: 'activeUser',
        with: { id: userId },
      }
    ]);
  });
}

export function changeBreadcrumbs({ commit }, { text, to, index }) {
  if (index > breadcrumbsMap.length) {
    breadcrumbsMap.push({ text, to });
  }
  breadcrumbsMap = Object.values(breadcrumbsMap).splice(0, index);

  commit(MUTATE, {
    property: 'breadcrumbs',
    with: breadcrumbsMap,
  });
}

