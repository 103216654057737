var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "page-header" } },
    [
      _c("v-breadcrumbs", { attrs: { divider: ">", items: _vm.breadcrumbs } }),
      _c("h1", [_vm._v(" " + _vm._s(_vm.title) + " ")]),
      _c("h3", [_vm._v(" " + _vm._s(_vm.description) + " ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }