import { render, staticRenderFns } from "./AnnotationsHistory.vue?vue&type=template&id=40ae0eec&scoped=true&"
import script from "./AnnotationsHistory.vue?vue&type=script&lang=js&"
export * from "./AnnotationsHistory.vue?vue&type=script&lang=js&"
import style0 from "./AnnotationsHistory.vue?vue&type=style&index=0&id=40ae0eec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40ae0eec",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/runner/_work/admin-tools-frontend/admin-tools-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('40ae0eec')) {
      api.createRecord('40ae0eec', component.options)
    } else {
      api.reload('40ae0eec', component.options)
    }
    module.hot.accept("./AnnotationsHistory.vue?vue&type=template&id=40ae0eec&scoped=true&", function () {
      api.rerender('40ae0eec', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/AnnotationsHistory.vue"
export default component.exports