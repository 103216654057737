export default {
  user: state => {
    return {
      name: state.userName,
      image: state.userImage,
      isSuperAdmin: state.isSuperAdmin
    };
  },
  selectedUserEmail: state => state.activeUserEmail
};
