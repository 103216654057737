var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app",
        { attrs: { dark: "" } },
        [
          _vm.hasInitialData
            ? _c(
                "v-row",
                { staticClass: "no-gutters" },
                [
                  _c(
                    "transition",
                    [_c("keep-alive", [_c("router-view")], 1)],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("StLoader", { attrs: { loading: !_vm.hasInitialData } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }