<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="breadcrumbs"
    />
    <section
      v-show="true"
      class="selector-container"
    >
      <router-view />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PageHeader from '@/components/base/PageHeader';

export default {
  name: 'RouterHistory',
  components: {
    PageHeader
  },
  data: () => ({
    labels: {
      title: 'Busca roteamentos e status dos usuários',
      description: 'Permite visualização detalhada dos roteamentos e seus usuários, bem como o ' +
        'histórico de status de cada um, uma vez que ter usuários ONLINE no SmartCare ' +
        'é requisito fundamental para o correto roteamento de conversas.'
    }
  }),
  computed: {
    ...mapState('routing', ['breadcrumbs'])
  }
};
</script>

<style scoped lang='scss'>
.content-body {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}

.selector-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: left;
  padding-top: 30px;
}

.v-data-footer {
  justify-content: center;
}
</style>
