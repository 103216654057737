<template>
  <section class="routing-status__routing-user">
    <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <v-menu
        ref="menu"
        v-model="menu"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Data"
            readonly
            class="routing-status__date-filter"
            v-bind="attrs"
            outlined
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="date"
          type="month"
          class="routing-status__date-modal"
          scrollable
          @change="changeDateFilter"
        />
      </v-menu>
    </v-col>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :items-per-page="15"
      :expanded="expanded"
      class="routing-status__user-table"
      item-key="date"
      single-expand
      show-expand
    >
      <template v-slot:item="{ item, expand, isExpanded }">
        <tr @click="expand(!isExpanded)">
          <td />
          <td>
            <div class="datatable-cell-wrapper">
              {{ item.date }}
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td
          :colspan="3"
          class="expanded_item"
        >
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Data
                  </th>
                  <th class="text-left">
                    Entrada
                  </th>
                  <th class="text-left">
                    Saida
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(row, index) in item.items"
                  :key="index"
                >
                  <td>{{ row.date }}</td>
                  <td>{{ row.online }}</td>
                  <td>{{ row.offline }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'RouterUser',
  data() {
    return {
      headers: [
        { text: 'Data', value: 'date' },
      ],
      date: moment().format('YYYY-MM'),
      menu: false,
      tableData: [],
      expanded: [],
    };
  },
  computed: {
    ...mapState('routing', ['activeUser']),
    ...mapState('account', ['activeAccount']),
    ...mapState('universe', ['activeUniverse']),
  },
  created() {
    this.tableData = [];
    this.handleData(this.activeUser);
  },
  methods: {
    ...mapActions('routing', ['changeBreadcrumbs', 'getUserStatusHistory']),
    isIsolatedOff(currentStatus, index) {
      return currentStatus === 'offline' && index === 0;
    },
    isIsolatedOn(currentStatus, index, length) {
      return currentStatus === 'online' && index === length - 1;
    },
    handleData(data) {
      this.rowData = [];
      this.tableData = [];

      if (!Array.isArray(data)) return [];

      data.forEach(changesByDate => {
        const formattedDates = this.formattedates(changesByDate.changes);
        const sessions = this.buildSessions(formattedDates);
        const sessionsByDate = this.organizeByDate(sessions);
        this.tableData = [...sessionsByDate, ...this.tableData];
      });
    },
    formattedates(dates) {
      return dates.map(userStatus => {
        const date = this.changeDateFormatTo(userStatus.date.split('T')[0]);
        const time = userStatus.date
          .split('T')[1]
          .substr(0, 11);
        const status = userStatus.status;
        return { time, status, date };
      });
    },
    changeDateFormatTo(date) {
      const [yy, mm, dd] = date.split(/-/u);
      return `${dd}/${mm}/${yy}`;
    },
    buildSessions(dates) {
      const [sessions, datesProcessed] = [[], []];
      const [online, offline] = ['online', 'offline'];
      let newSession = {};

      dates.reverse();
      dates.forEach((userStatus, index) => {
        const status = userStatus.status.toLowerCase();
        const oppositeStatus = status === offline ? online : offline;
        const isIsolatedOff = this.isIsolatedOff(status, index);
        const isIsolatedOn = this.isIsolatedOn(status, index, dates.length);
        newSession[status] = userStatus.time;

        if (isIsolatedOff || isIsolatedOn) newSession[oppositeStatus] = '-';
        if (!this.isCompleteSession(newSession)) return;

        if (!datesProcessed.includes(userStatus.date)) {
          sessions.push({ ...newSession, date: userStatus.date });
        }
        newSession = {};
      });

      return sessions.reverse();
    },
    isCompleteSession(session) {
      return 'online' in session && 'offline' in session;
    },
    organizeByDate(sessions) {
      const rowData = {};
      sessions.forEach(userStatus => {
        const key = userStatus.date;

        if (!rowData[key]) rowData[key] = [];
        rowData[key] = [...rowData[key], userStatus];
      });

      return Object.entries(rowData).map(([date, items]) => ({ date, items }));
    },
    async changeDateFilter() {
      this.$refs.menu.save(this.date);
      const message = await this.getUserStatusHistory({
        selectedAccount: this.activeAccount,
        selectedUniverse: this.activeUniverse,
        userId: this.activeUser.id,
        period: this.date,
      });
      this.handleData(message);
    },
  },
};
</script>

<style lang="scss">
  .routing-status__routing-user {
    .expanded_item {
      padding: 0;

      tbody tr:nth-of-type(odd) {
        background-color: var(--gray-100);
      }

      td,
      th {
        padding-left: 120px;
      }

      th {
        background-color: var(--blue-300);
        font-weight: 900;
      }

      .v-list-item__title {
        font-size: 14px;
      }
    }
  }

  .routing-status__date-modal {
    .v-date-picker-title__date {
      font-size: 22px;
    }

    .accent--text {
      text-align: center;
    }
  }

  .routing-status__date-filter {
    width: 150px;
  }
</style>
