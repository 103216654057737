var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-body" },
    [
      _c("PageHeader", {
        attrs: {
          title: _vm.title,
          description: _vm.description,
          breadcrumbs: _vm.breadcrumbs,
        },
      }),
      _c(
        "section",
        { staticClass: "new-benchmarks-container" },
        [
          _c("v-text-field", {
            staticClass: "text-field-group",
            attrs: { label: _vm.textField.benchmarksName },
          }),
          _c("v-text-field", {
            staticClass: "text-field-group",
            attrs: { label: _vm.textField.userGroup },
          }),
          _c(
            "div",
            { staticClass: "new-benchmarks-trial" },
            [
              _c("v-checkbox", {
                attrs: { color: "white", label: "Trial" },
                model: {
                  value: _vm.terms,
                  callback: function ($$v) {
                    _vm.terms = $$v
                  },
                  expression: "terms",
                },
              }),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { icon: "" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("DsIcon", {
                                staticClass: "new-benchmarks-tooltip",
                                attrs: { name: "questions", size: "large" },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v(_vm._s(_vm.textTooltip))])]
              ),
            ],
            1
          ),
          _vm.terms
            ? _c(
                "v-radio-group",
                { staticClass: "new-benchmarks-radios", attrs: { row: "" } },
                [
                  _c("v-radio", {
                    attrs: {
                      label: _vm.labelRadios.radioOne,
                      color: "white",
                      value: "1",
                    },
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.labelRadios.radioTwo,
                      color: "white",
                      value: "2",
                    },
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: _vm.labelRadios.radioThree,
                      color: "white",
                      value: "3",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "new-benchmarks-button" },
        [
          _c(
            "v-btn",
            {
              staticClass: "text-capitalize",
              attrs: { width: "116px", rounded: "", color: "#00CCBE" },
            },
            [_vm._v("\n      " + _vm._s(_vm.textButton) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }