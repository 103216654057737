var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("DsModal", {
        attrs: {
          open: _vm.show,
          title: this.$t("RetroactiveList.cancelation.confirmation.title"),
          text: this.$t(
            "RetroactiveList.cancelation.confirmation.descriptionBody"
          ),
          "primary-button-label": this.$t(
            "RetroactiveList.cancelation.confirmation.yes"
          ),
          "secondary-button-label": this.$t(
            "RetroactiveList.cancelation.confirmation.cancel"
          ),
          "data-color-theme": "dark",
          width: "400",
        },
        on: { primary: _vm.agree, secondary: _vm.cancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }