import { MUTATE, MUTATE_MULTIPLE } from '@/store/modules/mutation_types';
import WarroomService from '@/services/WarroomService';
import moment from 'moment';

const { PagesService, PageHistoryService } = WarroomService;

export function getAccountPages({ state, commit }, { selectedAccount }) {
  commit(MUTATE_MULTIPLE, [{
    property: 'loadingHistory',
    with: true,
  }, {
    property: 'historyIsLoaded',
    with: false,
  }, {
    property: 'requestStatus',
    with: 'loading',
  }]);

  const { id: accountId } = selectedAccount;

  return PagesService.find({ url: `/${accountId}` })
    .then(data => {
      const { message = [] } = data;
      commit(MUTATE_MULTIPLE, [
        {
          property: 'requestStatus',
          with: 'success',
        }, {
          property: 'pages',
          with: message,
        },
      ]);
      if (message.length >= 1) {
        commit(MUTATE_MULTIPLE, [{
          property: 'activePage',
          with: message[0],
        }]);
        getPageHistory({ state, commit }, { selectedAccount });
      }
      commit(MUTATE, {
        property: 'loadingHistory',
        with: false,
      });
      commit(MUTATE, {
        property: 'requestStatus',
        with: 'empty',
      });

      return Promise.resolve(message);
    }).catch((e) => {
      console.error(e);
      commit(MUTATE, {
        property: 'requestStatus',
        with: 'error',
      });
    });
}

export function setPage({ commit }, { selectedPage }) {
  commit(MUTATE_MULTIPLE, [{
    property: 'activePage',
    with: selectedPage,
  }, {
    property: 'loadingHistory',
    with: true,
  }, {
    property: 'historyIsLoaded',
    with: false,
  }]);
}

export function getPageHistory({ state, commit }, { selectedAccount }) {
  commit(MUTATE, {
    property: 'requestStatus',
    with: 'loading',
  });

  const { id: accountId } = selectedAccount;
  const { id: pageId } = state.activePage;

  return PageHistoryService.find({ url: `/${pageId}/${accountId}` })
    .then(data => {
      const { message = [] } = data;
      message.map(item => {
        item.created_at = moment(item.created_at).add(-3, 'hour').valueOf();
        item.created_at_formatted = moment(item.created_at).format('DD/MM/YYYY HH:mm:ss');
        const newItem = { ...item };
        return newItem;
      });
      commit(MUTATE_MULTIPLE, [
        {
          property: 'requestStatus',
          with: 'success',
        }, {
          property: 'history',
          with: message,
        }, {
          property: 'loadingHistory',
          with: false,
        }, {
          property: 'historyIsLoaded',
          with: true,
        },
      ]);

      return Promise.resolve(message);
    }).catch((e) => {
      console.error(e);
      commit(MUTATE_MULTIPLE, [{
        property: 'requestStatus',
        with: 'error',
      }, {
        property: 'historyIsLoaded',
        with: false,
      }, {
        property: 'loadingHistory',
        with: false,
      }]);
    });
}
