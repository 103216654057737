<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />

    <v-toolbar flat>
      <v-icon large>
        mdi-desktop-mac-dashboard
      </v-icon>
      <v-toolbar-title v-if="selectedUniverse && selectedAccount">
        {{ selectedUniverse.name }} ({{ selectedAccount.name }})
      </v-toolbar-title>
    </v-toolbar>

    <v-alert
      v-if="error"
      border="right"
      colored-border
      type="error"
      elevation="2"
    >
      Erro inesperado ao carregar o histórico de reprocessamento.
    </v-alert>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="table.search"
          :label="labels.search"
          class="mx-4"
        />
        <div>
          <v-icon
            class="reprocessing-list__refresh mx-8"
            :class="{'reprocessing-list__refresh--loading': loading}"
            @click="getReprocessing"
          >
            mdi-refresh
          </v-icon>
        </div>
      </v-card-title>
      <StLoader :loading="loading" />
      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :search="table.search"
        :custom-sort="customSort"
        hide-default-footer
      >
        <template v-slot:item.percentage_formatted="{ item }">
          <div class="text-center percentage">
            <v-progress-circular
              :rotate="-90"
              :size="90"
              :width="15"
              :value="item.percentage_formatted"
              color="teal"
            >
              {{ item.percentage_formatted }}
            </v-progress-circular>
          </div>
        </template>
        <template v-slot:item.types="{ item }">
          <StMenu
            :items="item.types"
          />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PageHeader from '@/components/base/PageHeader';
import ReprocessingService from '@/services/ReprocessingService';
import moment from 'moment';
import StLoader from '@stilingue/st-loader';
import StMenu from '../StMenu.vue';

export default {
  name: 'ReprocessingHistory',
  components: {
    PageHeader, StMenu, StLoader
  },
  data: () => ({
    labels: {
      title: 'Histórico de Reprocessamento',
      description: 'Permite visualização detalhada do histórico de reprocessamentos da pesquisa.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Pesquisa',
          to: '/reprocessing'
        },
        {
          text: 'Reprocessamentos',
          to: '/universe/reprocess-history'
        }
      ]
    },
    table: {
      headers: [
        {
          text: 'Conta',
          value: 'account_id',
          align: 'center'
        },
        {
          text: 'Pesquisa',
          value: 'universe_id',
          align: 'center'
        },
        {
          text: 'Data Início',
          value: 'init_date',
          align: 'center'
        },
        {
          text: 'Data Fim',
          value: 'end_date',
          align: 'center'
        },
        {
          text: 'Limite',
          value: 'limit',
          align: 'center'
        },
        {
          text: 'Tipos',
          value: 'types',
          align: 'center'
        },
        {
          text: 'Solicitado em',
          value: 'reprocess_executed_date_formatted',
          align: 'center'
        },
        {
          text: 'Responsável',
          value: 'responsible_user',
          align: 'center'
        },
      ],
      search: '',
      'must-sort': true,
      items: [],
    },
    loading: false,
    error: false,
  }),
  computed: {
    ...mapState('account', ['accounts', 'activeAccount']),
    ...mapState('universe', ['universes', 'activeUniverse']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      }
    },
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      }
    },
  },
  async created() {
    if (this.selectedUniverse) {
      await this.getReprocessing(this.selectedUniverse.id);
    }
  },
  methods: {
    async getReprocessing(id) {
      try {
        this.error = false;
        this.table.items = [];
        this.loading = true;
        const response = await ReprocessingService.getReprocessHistory(id);
        this.setTableItems(response);
      } catch (e) {
        console.log(e);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    setTableItems(items) {
      const brazilFormat = 'DD/MM/YYYY HH:mm:ss';
      const translateMap = {
        tag: 'TAG',
        term: 'TERMO',
        theme: 'TEMA',
        group: 'GRUPO',
        polarity: 'SENTIMENTO'
      };

      this.table.items = items.map(item => {
        item.original_init_date = moment(item.init_date).format('YYYYMMDDHHmm');
        item.original_end_date = moment(item.end_date).format('YYYYMMDDHHmm');
        item.init_date = moment(item.init_date).format(brazilFormat);
        item.end_date = item.end_date ? moment(item.end_date).format(brazilFormat) : '-';
        item.reprocess_executed_date_formatted = moment(item.reprocess_executed_date).format(brazilFormat);
        item.original_types = item.types;
        item.types = item.types.map(type => {
          return translateMap[type.toLowerCase()];
        });
        return item;
      });
    },
    customSort(items, index, isDesc) {
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line complexity
      items.sort((a, b) => {
        if (index[0] === 'reprocess_executed_date_formatted') {
          if (!isDesc[0]) {
            return a.reprocess_executed_date - b.reprocess_executed_date;
          }
          return b.reprocess_executed_date - a.reprocess_executed_date;
        } else if (!isDesc[0]) {
          return a[index] < b[index] ? -1 : 1;
        }
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    }
  }
};
</script>

<style lang="scss" scoped>
.content-body {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  .v-toolbar__content {
    i {
      padding-right: 10px;
    }
  }

  .reprocessing-list__refresh {
    cursor: pointer;

    &--loading {
      color: #00aaa7;
      -webkit-animation: spin 1s linear infinite;
      -moz-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
    }
  }
}
</style>
