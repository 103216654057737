<template>
  <div class="retroactive-list">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :label="labels.inputs.executionDate"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="date"
          @input="menu = false, getRetroactiveData()"
        />
      </v-menu>
    </v-col>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="items"
        :sort-by="items.execution_date"
        :sort-desc="[false, true]"
        class="elevation-1"
      >
        <template v-slot:item="row">
          <tr>
            <td>{{ row.item.accountId }}</td>
            <td>{{ row.item.pageIds }}</td>
            <td>{{ row.item.flowTypes }}</td>
            <td>{{ row.item.retroactiveDays }}</td>
            <td>{{ row.item.state }}</td>
            <td>{{ row.item.authenticated_entity }}</td>
            <td
              m="12px"
            >
              {{ row.item.execution_date }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import PageHeader from '@/components/base/PageHeader';
import ITService from '../../services/ITService';

const minutesToMilliseconds = 60000;

export default {
  name: 'RetroactiveList',
  components: { PageHeader },
  data: () => ({
    labels: {
      title: 'Retroativos proprietários',
      description: 'Permite visualização detalhada do histórico de retroativos proprietários executados por solicitação dos clientes.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Retroativo Proprietário',
          to: '/proprietary-retroactive'
        },
        {
          text: 'Lista',
          to: '/proprietary-retroactive/list'
        }
      ],
      inputs: {
        executionDate: 'Escolha o periodo retroativo que deseja consultar:'
      },
    },
    headers: [
      { text: 'Conta', },
      { text: 'Page Ids' },
      { text: 'Interações' },
      { text: 'Data inicial ' },
      { text: 'Status' },
      { text: 'Responsável' },
      { text: 'Data de execução' },
    ],
    items: [],
    menu: false,
    date: (new Date(Date.now() - ((new Date()).getTimezoneOffset() * minutesToMilliseconds))).toISOString().substr(0, 10),
  }),
  mounted() {
    this.getRetroactiveData();
  },
  methods: {
    async getRetroactiveData() {
      const body = {
        path: '/retroactive',
        limit: 1000,
        execution_date: this.date.replaceAll('-', ''),
        order_by: 'DESC',
      };
      try {
        await ITService.serviceHistory(body).then((response) => {
          this.items = response;
        });
      } catch (err) {
        this.items = [];
        console.log(err);
      } finally {
        this.setValues();
      }
    },
    setValues() {
      this.items.map(item => {
        item.retroactiveDays = new Date(item.execution_date - (item.retroactiveDays * 86400000)).toLocaleDateString();
        item.execution_date = new Date(item.execution_date).toLocaleDateString();
        item.flowTypes = (String(item.flowTypes).replaceAll(',', ', ')).replace(undefined, 'Todas');
        item.pageIds = String(item.pageIds).replaceAll(',', ', ');
        return this.items;
      });
    },
  }
};
</script>
