<template>
  <div class="post-insertion">
    <PageHeader
      :title="labels.headerInfo.title"
      :description="labels.headerInfo.description"
      :breadcrumbs="labels.headerInfo.breadcrumbs"
    />
    <div class="post-insertion__form-and-result">
      <div>
        <v-form
          ref="postInsertionForm"
          v-model="formData.valid"
          class="post-insertion__form"
          :lazy-validation="false"
        >
          <SingleSelect
            v-model="selectedAccount"
            :items="accounts"
            placeholder="Selecione uma conta"
            text="name"
            sub-text="group"
          />

          <SingleSelect
            v-if="activeAccount"
            v-model="selectedUniverse"
            :items="universes"
            placeholder="Selecione uma Pesquisa"
            text="name"
            sub-text="channel"
          />
          <v-select
            v-if="groups.length"
            v-model="formData.groups"
            :items="groups"
            :label="labels.groupSelect"
            multiple
            chips
            :hint="labels.groupsHint"
            persistent-hint
          />
          <v-text-field
            v-model="formData.postIds"
            required
            :rules="rules.isNotEmpty"
            :label="labels.postId"
            :hint="labels.postsHint"
          />
          <v-select
            v-model="formData.channel"
            :items="formOptions.channels"
            label="Canal"
          />
          <v-text-field
            v-model="formData.task"
            required
            :name="Math.random()"
            :rules="rules.isTask"
            :label="labels.task"
          />
          <v-btn
            :disabled="!formData.valid || loading"
            color="light-blue"
            class="mr-4"
            @click="insertPost"
          >
            {{ labels.insertPost }}
          </v-btn>
        </v-form>
      </div>
    </div>

    <v-snackbar
      v-model="error"
    >
      {{ labels.errorMessage }}
      <v-btn
        color="blue"
        text
        @click="error = false"
      >
        {{ labels.close }}
      </v-btn>
    </v-snackbar>

    <v-snackbar
      v-model="success"
    >
      {{ labels.successMessage }}
      <v-btn
        color="blue"
        text
        @click="success = false"
      >
        {{ labels.close }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import PageHeader from '@/components/base/PageHeader';
import SingleSelect from '../components/SingleSelect';
import { mapActions, mapState } from 'vuex';
import ITService from '@/services/ITService';
import diacritics from 'diacritics';

export default {
  name: 'PostInsertion',
  components: {
    PageHeader,
    SingleSelect,
  },
  data: () => ({
    formOptions: {
      channels: [
        'Facebook',
        'FacebookComments',
        'Instagram',
        'Twitter',
        'YouTube',
        'YouTubeComments',
        'ReclameAqui',
        'Tumblr',

        // 'Blogs',
        'Blogs',

        // 'Fóruns',
        'DiariosOficiais',
        'Comentários em Artigos',
        'Fóruns',

        'Impressas',
        'Rádio',

        // Portais
        'BoxNetTV',
        'BoxNetWebNews',
        'Notícias',
        'TV',
      ],
    },
    labels: {
      close: 'Fechar',
      successMessage: 'A publicação foi inserida com sucesso',
      errorMessage: 'Erro ao inserir a publicação',
      task: 'Id da tarefa do Jira',
      groupSelect: 'Em quais grupos a publicação deve ser inserida?',
      postId: 'Ids da publicação na rede social (pid)',
      insertPost: 'Inserir publicação',
      groupsHint: 'É possível tentar inserir a publicação sem algum grupo selecionado, porém, desta forma, não é garantido que a publicação será inserida.',
      postsHint: 'Insira os ids da publicações de um mesmo canal separados por vírgula',
      headerInfo: {
        title: 'Publicação',
        description: 'Permite inserir na pesquisa uma publicação que já se encontre em nosso banco de dados e que o canal seja um dos processados ' +
          'pela configuração de pesquisa. Necessário averiguar os pontos por meio de utilização do Radar e do Editar Pesquisa, respectivamente.',
        breadcrumbs: [
          {
            text: 'Home',
            href: '/home'
          },
          {
            text: 'Pesquisa',
            to: '/universe'
          },
          {
            text: 'Inserção Publicação',
            to: '/universe/post-insertion'
          }
        ]
      },
    },
    formData: {
      postIds: '',
      channel: 'FACEBOOK',
      task: '',
      groups: [],
      valid: false,
    },
    rules: {
      isNumber: [v => (v && !isNaN(v)) || 'Este campo deve ser preenchido com um ID.'],
      isNotEmpty: [v => (v && v.length) > 0 || 'Este campo deve ser preenchido'],
      // eslint-disable-next-line require-unicode-regexp
      isTask: [v => (v && v.length > 0 && v.match(/[A-Za-z]{1,6}-[0-9]+/)) || 'Este campo deve ser preenchido e no format de id de tasks do Jira, ex: ITSM-2345 '],
    },
    success: false,
    error: false,
  }),
  computed: {
    ...mapState('user', ['user']),
    ...mapState('account', ['accounts', 'activeAccount']),
    ...mapState('universe', ['universes', 'activeUniverse', 'groups']),
    selectedAccount: {
      get() {
        return this.activeAccount;
      },
      set(selectedAccount) {
        this.selectAccount(selectedAccount);
      },
    },
    selectedUniverse: {
      get() {
        return this.activeUniverse;
      },
      set(selectedUniverse) {
        this.selectUniverse(selectedUniverse);
      },
    },
    userId: {
      get() {
        return this.user.id;
      }
    },
  },
  async created() {
    await this.getAccounts({
      userId: this.userId
    });
    if (this.selectedAccount) {
      await this.selectAccount(this.selectedAccount, this.selectedUniverse);
    }
    if (this.selectedUniverse) {
      this.getGroupsByUniverse(this.selectedUniverse.id);
    }
  },
  methods: {
    ...mapActions('account', ['getAccounts', 'setAccount']),
    ...mapActions('universe', ['getAccountUniverses', 'setUniverse', 'getGroupsByUniverse']),
    getParameters() {
      const postIds = this.formData.postIds.split(',');
      const channelsValueMap = {
        blogs: 'Feedly',
        comentariosemartigos: 'Disqus',
        foruns: 'HardMob',
        impressas: 'BoxNetPrintedNews',
        radio: 'BoxNetRadio',
        noticias: 'News',
      };

      const normalizedChannel = diacritics.remove(this.formData.channel.toLowerCase().replaceAll(' ', ''));
      const channel = normalizedChannel in channelsValueMap ? channelsValueMap[normalizedChannel] : this.formData.channel;
      const postInfos = postIds.map(id => ({
        id: id.trim(),
        channel,
      }));

      return {
        accountId: this.selectedAccount.id,
        universeId: this.selectedUniverse.id,
        postInfos,
        groups: this.formData.groups,
        email: this.user.email,
        task: this.formData.task.replace('-', '_').toLowerCase(),
      };
    },
    insertPost() {
      this.error = false;
      this.loading = true;
      ITService.insertPostInUniverse(this.getParameters()).then(() => {
        this.success = true;
        this.clearForm();
      }).catch(e => {
        console.error(e);
        this.error = true;
      }).finally(() => {
        this.loading = false;
      });
    },
    clearForm() {
      this.formData.postIds = '';
      this.formData.task = '';
      this.$refs.postInsertionForm.resetValidation();
    },
    selectAccount(selectedAccount, selectedUniverse) {
      this.setAccount({ selectedAccount });
      this.getAccountUniverses({ selectedAccount, fetchHistory: false }).then(() => {
        if (selectedUniverse) {
          this.selectedUniverse = selectedUniverse;
        }
        this.selectUniverse(this.selectedUniverse);
        console.log(this.selectedUniverse);
        this.getGroupsByUniverse(this.selectedUniverse.id);
      });
    },
    selectUniverse(selectedUniverse) {
      this.setUniverse({ selectedUniverse }).then(() => {
        this.getGroupsByUniverse(selectedUniverse.id);
      });
    },
  }
};
</script>

<style scoped>
</style>
