<template>
  <div>
    <v-app
      dark
    >
      <v-row
        v-if="hasInitialData"
        class="no-gutters"
      >
        <transition>
          <keep-alive>
            <router-view />
          </keep-alive>
        </transition>
      </v-row>

      <StLoader
        :loading="!hasInitialData"
      />
    </v-app>
  </div>
</template>

<script>
import StLoader from '@stilingue/st-loader';
import { checkForAuthentication, logoutAndSendUserTo403Page } from '@stilingue/st-auth-utils/StAuthUtils';

export default {
  name: 'App',
  components: {
    StLoader
  },
  data() {
    return {
      hasInitialData: false
    };
  },
  async created() {
    this.hasInitialData = false;
    try {
      const authenticated = this.checkForAuthentication();
      if (!authenticated) {
        return this.sendToLoginPage();
      }

      await this.startLoadingInitialData();
    } catch (e) {
      console.error(e);
      await this.$router.push({ name: 'error', params: { errorStatus: '500' } });
    } finally {
      // Should await the initial Data to be loaded
      this.hasInitialData = true;
      this.$vuetify.theme.dark = true;
    }
  },
  methods: {
    checkForAuthentication,
    logoutAndSendUserTo403Page,
    async startLoadingInitialData() {
      try {
        await this.fetchUserAndCompanies();

        this.$router.push({
          name: 'home'
        }).catch(() => {});
      } catch (err) {
        // istanbul ignore next
        throw err;
      }
    },
    async fetchUserAndCompanies() {
      const user = await this.$store.dispatch('user/getUserMe');

      return [user];
    },
    sendToLoginPage() {
      return this.$router.push({
        name: 'login'
      });
    },
  }
};
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');
  @import '~@stilingue/st-styles/main.scss';
  @import '~vuejs-noty/dist/vuejs-noty.css';
  @import './plugins/v-tooltip/v-tooltip.scss';

  * {
    font-family: 'Open Sans', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #app {
    text-align: center;
    background: var(--gray-050);
  }

  html {
    overflow-y: auto !important;
  }

  h1,
  h2,
  h3,
  h4,
  p {
    color: white;
  }

  .disabled__container {
    > * {
      opacity: 0.6;
      cursor: default;
      pointer-events: none;
    }
  }

  .single-select .v-input__slot {
    min-height: 48px;
  }

  .v-input--has-state.theme--dark.error--text,
  .v-icon.mdi.theme--dark.error--text,
  .v-messages.theme--dark.error--text,
  .v-label.theme--dark.error--text {
    color: #fff !important;
  }

  .v-input--has-state.theme--dark.error--text {
    caret-color: #fff !important;
    background-color: rgba(201, 76, 76, 0.3);
    border-radius: 5px;
    padding: 15px 0 8px;
  }
</style>
