<template>
  <div class="content-body">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <v-alert
      v-if="error"
      border="right"
      colored-border
      type="error"
      elevation="2"
    >
      Erro inesperado ao carregar lista de reprocessamento.
    </v-alert>
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="table.search"
          :label="labels.search"
          class="mx-4"
        />
        <div>
          <v-icon
            class="reprocessing-list__refresh mx-8"
            :class="{'reprocessing-list__refresh--loading': loading}"
            @click="getReprocessing"
          >
            mdi-refresh
          </v-icon>
        </div>
      </v-card-title>
      <StLoader :loading="loading" />
      <v-data-table
        :headers="table.headers"
        :items="table.items"
        :search="table.search"
        :custom-sort="customSort"
      >
        <template v-slot:item.percentage_formatted="{ item }">
          <div class="text-center percentage">
            <v-progress-circular
              :rotate="-90"
              :size="90"
              :width="15"
              :value="item.percentage_formatted"
              color="teal"
            >
              {{ item.percentage_formatted }}
            </v-progress-circular>
          </div>
        </template>
        <template v-slot:item.types="{ item }">
          <StMenu
            :items="item.types"
          />
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            v-if="item.status === 'Finalizado'"
            class="ma-2"
            label
            color="green"
            text-color="white"
          >
            {{ item.status }}
          </v-chip>
          <v-chip
            v-if="item.status !== 'Finalizado' && item.status !== 'Cancelado'"
            class="ma-2"
            label
            color="orange"
            text-color="white"
          >
            {{ item.status }}
          </v-chip>
          <v-chip
            v-if="item.status === 'Cancelado'"
            class="ma-2"
            label
            color="silver"
            text-color="white"
          >
            {{ item.status }}
          </v-chip>
          <br>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.status !== 'Finalizado' && item.status !== 'Cancelado' && item.limit !== 0"
                :loading="loadingCancel && itemCanceled === item.account_id+item.universe_id"
                :disabled="loadingCancel && itemCanceled === item.account_id+item.universe_id"
                class="ma-2"
                dark
                v-bind="attrs"
                v-on="on"
                @click="cancelReprocessing(item)"
              >
                <v-icon>mdi-minus-circle</v-icon>
              </v-btn>
            </template>
            <span>Cancelar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PageHeader from '@/components/base/PageHeader';
import ReprocessingService from '@/services/ReprocessingService';
import moment from 'moment';
import StLoader from '@stilingue/st-loader';
import StMenu from '../StMenu.vue';

export default {
  name: 'ReprocessingList',
  components: {
    PageHeader, StMenu, StLoader
  },
  data: () => ({
    labels: {
      title: 'Reprocessamentos',
      description: 'Permite visualização detalhada do histórico de reprocessamentos de publicações executados por solicitação dos clientes.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Reprocessamento',
          to: '/reprocessing'
        },
        {
          text: 'Lista',
          to: '/reprocessing/list'
        }
      ]
    },
    table: {
      headers: [
        {
          text: 'Conta',
          value: 'account_id',
          align: 'center'
        },
        {
          text: 'Pesquisa',
          value: 'universe_id',
          align: 'center'
        },
        {
          text: 'Data Início',
          value: 'init_date',
          align: 'center'
        },
        {
          text: 'Data Fim',
          value: 'end_date',
          align: 'center'
        },
        {
          text: 'Status',
          value: 'status',
          align: 'center'
        },
        {
          text: 'Limite',
          value: 'limit',
          align: 'center'
        },
        {
          text: 'Percentual',
          value: 'percentage_formatted',
          align: 'center'
        },
        {
          text: 'Progresso',
          value: 'progress',
          align: 'center'
        },
        {
          text: 'Total',
          value: 'total',
          align: 'center'
        },
        {
          text: 'Tipos',
          value: 'types',
          align: 'center'
        },
        {
          text: 'Iniciado em',
          value: 'init_millis_time_formatted',
          align: 'center'
        }
      ],
      search: '',
      'must-sort': true,
      items: [],
    },
    loading: false,
    loadingCancel: false,
    error: false,
    itemCanceled: null
  }),
  computed: {
    ...mapState('user', ['user']),
    userId: {
      get() {
        return this.user.id;
      }
    }
  },
  mounted() {
    this.getReprocessing();
  },
  methods: {
    async getReprocessing() {
      try {
        this.error = false;
        this.table.items = [];
        this.loading = true;
        const response = await ReprocessingService.getAllReprocessing();
        this.setTableItems(response);
      } catch (e) {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    setTableItems(items) {
      const brazilFormat = 'DD/MM/YYYY HH:mm:ss';
      const translateMap = {
        tag: 'TAG',
        term: 'TERMO',
        theme: 'TEMA',
        group: 'GRUPO',
        polarity: 'SENTIMENTO'
      };

      this.table.items = items.map(item => {
        item.original_init_date = moment(item.init_date).format('YYYYMMDDHHmm');
        item.original_end_date = moment(item.end_date).format('YYYYMMDDHHmm');
        item.init_date = moment(item.init_date).format(brazilFormat);
        item.end_date = item.end_date ? moment(item.end_date).format(brazilFormat) : '-';
        item.init_millis_time_formatted = moment(item.init_millis_time).format(brazilFormat);
        item.original_types = item.types;
        item.types = item.types.map(type => {
          return translateMap[type.toLowerCase()];
        });
        this.fixProgress(item);
        this.treatStatus(item);
        this.treatPercentage(item);
        return item;
      });
    },
    customSort(items, index, isDesc) {
      // eslint-disable-next-line array-callback-return
      // eslint-disable-next-line complexity
      items.sort((a, b) => {
        if (index[0] === 'init_millis_time_formatted') {
          if (!isDesc[0]) {
            return a.init_millis_time - b.init_millis_time;
          }
          return b.init_millis_time - a.init_millis_time;
        } else if (index[0] === 'percentage_formatted') {
          if (!isDesc[0]) {
            return a.percentage - b.percentage;
          }
          return b.percentage - a.percentage;
        } else if (!isDesc[0]) {
          return a[index] < b[index] ? -1 : 1;
        }
        return b[index] < a[index] ? -1 : 1;
      });
      return items;
    },
    getParameters(item) {
      const parameters = {
        account_id: item.account_id,
        universe_id: item.universe_id,
        types: item.original_types,
        modular_reprocessing: true,
        automation: false,
        limit: 0,
        responsible_user: this.user.email
      };
      parameters.init_date = item.original_init_date;
      parameters.end_date = item.original_end_date;
      return parameters;
    },
    async cancelReprocessing(item) {
      this.loadingCancel = true;
      this.itemCanceled = item.account_id + item.universe_id;
      const parameters = this.getParameters(item);
      ReprocessingService.createReprocessing(parameters).then(resp => {
        this.loadingCancel = false;

        item.limit = resp.value.limit;
        item.percentage = resp.value.percentage;
        item.progress = resp.value.progress;
        item.total = resp.value.total;

        this.treatStatus(item);
        this.treatPercentage(item);
      }).catch(e => {
        this.loadingCancel = false;
        this.error = true;
        this.labels.errorMessage = e.toString();
      });
    },
    treatPercentage(item) {
      item.percentage = item.percentage.toFixed(0);
      if (item.percentage > 100) {
        item.percentage = 100;
      }
      item.percentage_formatted = `${item.percentage}%`;
    },
    treatStatus(item) {
      if (item.limit === 0) {
        item.status = 'Cancelado';
      }
    },
    fixProgress(item) {
      if (item.total > 0 && item.progress >= item.total) {
        item.progress = item.total;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content-body {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  .percentage {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .reprocessing-list__refresh {
    cursor: pointer;

    &--loading {
      color: #00aaa7;
      -webkit-animation: spin 1s linear infinite;
      -moz-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
    }
  }

  .v-chip.green {
    background: #2c9688 !important;
  }
}
</style>
