var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "routing-status__routing-user" },
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "6", md: "4" } },
        [
          _c(
            "v-menu",
            {
              ref: "menu",
              attrs: {
                "return-value": _vm.date,
                transition: "scale-transition",
                "offset-y": "",
                "min-width": "290px",
              },
              on: {
                "update:returnValue": function ($event) {
                  _vm.date = $event
                },
                "update:return-value": function ($event) {
                  _vm.date = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-text-field",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "routing-status__date-filter",
                              attrs: {
                                label: "Data",
                                readonly: "",
                                outlined: "",
                              },
                              model: {
                                value: _vm.date,
                                callback: function ($$v) {
                                  _vm.date = $$v
                                },
                                expression: "date",
                              },
                            },
                            "v-text-field",
                            attrs,
                            false
                          ),
                          on
                        )
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.menu,
                callback: function ($$v) {
                  _vm.menu = $$v
                },
                expression: "menu",
              },
            },
            [
              _c("v-date-picker", {
                staticClass: "routing-status__date-modal",
                attrs: { type: "month", scrollable: "" },
                on: { change: _vm.changeDateFilter },
                model: {
                  value: _vm.date,
                  callback: function ($$v) {
                    _vm.date = $$v
                  },
                  expression: "date",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "routing-status__user-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.tableData,
          "items-per-page": 15,
          expanded: _vm.expanded,
          "item-key": "date",
          "single-expand": "",
          "show-expand": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item, expand, isExpanded }) {
              return [
                _c(
                  "tr",
                  {
                    on: {
                      click: function ($event) {
                        return expand(!isExpanded)
                      },
                    },
                  },
                  [
                    _c("td"),
                    _c("td", [
                      _c("div", { staticClass: "datatable-cell-wrapper" }, [
                        _vm._v(
                          "\n            " + _vm._s(item.date) + "\n          "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "expanded-item",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "expanded_item", attrs: { colspan: 3 } },
                  [
                    _c("v-simple-table", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(
                                        "\n                  Data\n                "
                                      ),
                                    ]),
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(
                                        "\n                  Entrada\n                "
                                      ),
                                    ]),
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(
                                        "\n                  Saida\n                "
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(item.items, function (row, index) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [_vm._v(_vm._s(row.date))]),
                                      _c("td", [_vm._v(_vm._s(row.online))]),
                                      _c("td", [_vm._v(_vm._s(row.offline))]),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }