import { render, staticRenderFns } from "./ProprietaryCrawlingHealth.vue?vue&type=template&id=74e22a36&scoped=true&"
import script from "./ProprietaryCrawlingHealth.vue?vue&type=script&lang=js&"
export * from "./ProprietaryCrawlingHealth.vue?vue&type=script&lang=js&"
import style0 from "./ProprietaryCrawlingHealth.vue?vue&type=style&index=0&id=74e22a36&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74e22a36",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/runner/_work/admin-tools-frontend/admin-tools-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74e22a36')) {
      api.createRecord('74e22a36', component.options)
    } else {
      api.reload('74e22a36', component.options)
    }
    module.hot.accept("./ProprietaryCrawlingHealth.vue?vue&type=template&id=74e22a36&scoped=true&", function () {
      api.rerender('74e22a36', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ProprietaryCrawlingHealth.vue"
export default component.exports