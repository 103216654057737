import StService from './StService';
import { createApi } from '@stilingue/st-auth-utils';
import { featureFlagFlags } from '@/plugins/rollout';
import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import gateway from './Gateway';

const version = isProdVersion()
  ? featureFlagFlags.PanelsServiceVersionProd.getValue()
  : featureFlagFlags.PanelsServiceVersionStaging.getValue();

const afterParser = response => response.data;

class PanelsService extends StService {
  constructor(gateway, version = 'staging') {
    const api = createApi(`panels-service/${version}/`, gateway, { timeout: 120000 });
    super(api, { afterParser });
  }

  getUniverseByQuery(params = {}) {
    const url = `v2/panels/by-query?field=${params.field}&values=${params.quotationItemId}&projection=${params.fields}`;
    return this.api.get({ url }).then(this.afterParser);
  }
}

export default new PanelsService(gateway, version);
