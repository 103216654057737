import { isProdVersion } from '@stilingue/st-environment-controller-utils';
import { featureFlagFlags } from '@/plugins/rollout';
import StService from '@/services/StService';
import { createApi } from '@stilingue/st-auth-utils';
import gateway from './Gateway';

const version = isProdVersion()
  ? featureFlagFlags.AuthServiceVersionProd.getValue()
  : featureFlagFlags.AuthServiceVersionStaging.getValue();

const afterParser = response => response.data;

class RetroactiveService extends StService {
  constructor(gateway, version = 'staging') {
    const api = createApi(`retroactive-service-api/${version}/`, gateway);
    super(api, { afterParser });
  }

  getRetroactives(params = {}) {
    return this.api.get({ params, url: 'get-retroactives' }).then(this.afterParser);
  }

  newRetroactive(params = {}) {
    let url = 'retroactive';
    if (params.group) {
      url = 'group-retroactive';
    }
    return this.api.post({ ...params, url }).then(this.afterParser);
  }

  cancelRetroactive(accountId, universeId) {
    return this.api.put({ url: `retroactive/cancel/${accountId}/${universeId}` }).then(this.afterParser);
  }
}

export default new RetroactiveService(gateway, version);
