<template>
  <div class="retroactive-list">
    <PageHeader
      :title="labels.title"
      :description="labels.description"
      :breadcrumbs="labels.breadcrumbs"
    />
    <v-col
      cols="12"
      sm="6"
      md="15"
    >
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :label="labels.inputs.executionDate"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="date"
          @input="menu = false, getRetroactiveData()"
        />
      </v-menu>
    </v-col>
    <template>
      <v-data-table
        :headers="table.headers"
        :items="items"
        :sort-by="items.execution_date"
        :sort-desc="[false, true]"
        class="elevation-1"
      >
        <template v-slot:item="row">
          <tr>
            <td>{{ row.item.accountId }}</td>
            <td>{{ row.item.pageId }}</td>
            <td>{{ row.item.sinceDateMillis }}</td>
            <td>{{ row.item.authenticated_entity }}</td>
            <td
              m="12px"
            >
              {{ row.item.execution_date }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
import PageHeader from '@/components/base/PageHeader';
import ITService from '@/services/ITService';
import { setDateInBrazilianFormat } from '@/utils/date-utils';

export default {
  name: 'InboxRetroactiveList',
  components: { PageHeader },
  data: () => ({
    labels: {
      title: 'Retroativos proprietários',
      search: 'Buscar',
      description: 'Permite visualização detalhada do histórico de retroativos proprietários executados por solicitação dos clientes.',
      breadcrumbs: [
        {
          text: 'Home',
          href: '/'
        },
        {
          text: 'Retroativo Proprietário',
          to: '/proprietary-retroactive'
        },
        {
          text: 'Lista DM Instagram',
          to: '/proprietary-retroactive/inbox-list'
        }
      ],
      inputs: {
        executionDate: 'Escolha o periodo retroativo que deseja consultar:'
      },
    },
    table: {
      headers: [
        { text: 'Conta', },
        { text: 'Page Ids' },
        { text: 'Data Inicial' },
        { text: 'Responsável' },
        { text: 'Data de execução' },
      ],
    },
    items: [],
    menu: false,
    date: null,
  }),
  mounted() {
    this.getRetroactiveData();
  },
  methods: {
    async getRetroactiveData() {
      const body = {
        path: '/etl-crawler-retroactive',
        limit: 1000,
        execution_date: this.date.replaceAll('-', ''),
        order_by: 'DESC',
      };
      try {
        await ITService.serviceHistory(body).then((response) => {
          this.items = response;
        });
      } catch (err) {
        this.items = [];
        console.log(err);
      } finally {
        this.setValues();
      }
    },
    setValues() {
      this.items.map(item => {
        item.sinceDateMillis = setDateInBrazilianFormat(item.sinceDateMillis);
        item.execution_date = setDateInBrazilianFormat(item.execution_date);
        item.pageIds = String(item.pageIds).replaceAll(',', ', ');
        return this.items;
      });
    },
  }
};
</script>
