import Vue from 'vue';
import VueNoty from 'vuejs-noty';

import './noty.scss';

Vue.use(VueNoty, {
  timeout: 4000,
  progressBar: false,
  layout: 'bottomRight',
  theme: 'bootstrap-v4'
});
